
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useTenant } from "@/contexts/TenantContext";
import { toast } from "sonner";

export function useJoinRequests() {
  const { currentTenant } = useTenant();
  const [pendingRequests, setPendingRequests] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [joinRequests, setJoinRequests] = useState<any[]>([]);

  const fetchPendingRequests = async () => {
    if (!currentTenant) return;

    const { data: allRequests, error, count } = await supabase
      .from('association_join_requests')
      .select('*, association_settings:tenant_id(name)', { count: 'exact' })
      .eq('tenant_id', currentTenant.id)
      .eq('dismissed', false)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching requests:', error);
      return;
    }

    // Filter pending requests for the count
    const pendingRequests = allRequests?.filter(req => req.status === 'pending') || [];
    
    setJoinRequests(allRequests || []);
    setPendingRequests(pendingRequests.length);
  };

  useEffect(() => {
    if (!currentTenant) return;

    fetchPendingRequests();

    // Set up real-time subscription
    const channel = supabase
      .channel('join-requests-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'association_join_requests',
          filter: `tenant_id=eq.${currentTenant.id}`
        },
        () => {
          fetchPendingRequests();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [currentTenant]);

  const handleJoinRequest = async (requestId: string, status: 'approved' | 'rejected', notes?: string) => {
    try {
      // If approving, first add the tenant membership
      if (status === 'approved') {
        // First get the join request to access the applicant's user_id
        const { data: joinRequest, error: requestError } = await supabase
          .from('association_join_requests')
          .select('user_id')
          .eq('id', requestId)
          .single();

        if (requestError) {
          console.error('Error fetching join request:', requestError);
          toast.error("Failed to fetch join request details");
          return;
        }

        const { error: membershipError } = await supabase
          .from('tenant_memberships')
          .insert([{
            tenant_id: currentTenant?.id,
            user_id: joinRequest.user_id,
            role: 'member'
          }]);

        if (membershipError) {
          console.error('Error adding tenant membership:', membershipError);
          toast.error("Failed to add tenant membership");
          return;
        }
      }
    
      const user = (await supabase.auth.getUser()).data.user;
      if (!user) {
        throw new Error('No authenticated user found');
      }

      const { error } = await supabase
        .from('association_join_requests')
        .update({ 
          status, 
          review_notes: notes,
          reviewed_by: user.id
        })
        .eq('id', requestId);

      if (error) throw error;

      // After successful update, fetch the updated list of pending requests
      await fetchPendingRequests();
      
      toast.success(`Request ${status} successfully`);
    } catch (error) {
      console.error('Error handling join request:', error);
      toast.error(`Failed to ${status} request`);
    }
  };

  return {
    pendingRequests,
    joinRequests,
    handleJoinRequest,
    refetchRequests: fetchPendingRequests
  };
}
