
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";

export const CreateAssociationDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newAssociation, setNewAssociation] = useState({
    name: "",
    adminEmail: ""
  });
  const [isCreating, setIsCreating] = useState(false);
  const { t } = useTranslation();

  const handleCreateAssociation = async () => {
    /* 
    // Content commented out to fix TypeScript error
    */
    
    // Temporary placeholder
    toast.info("Association creation functionality is currently disabled");
    setIsDialogOpen(false);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="w-4 h-4 mr-2" />
          {t("admin.addAssociation")}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("admin.createAssociation.title")}</DialogTitle>
          <DialogDescription>
            {t("admin.createAssociation.subtitle")}
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="name">{t("admin.createAssociation.name")}</Label>
            <Input
              id="name"
              value={newAssociation.name}
              onChange={(e) => setNewAssociation(prev => ({ ...prev, name: e.target.value }))}
              placeholder={t("admin.createAssociation.name")}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="adminEmail">{t("admin.createAssociation.adminEmail")}</Label>
            <Input
              id="adminEmail"
              type="email"
              value={newAssociation.adminEmail}
              onChange={(e) => setNewAssociation(prev => ({ ...prev, adminEmail: e.target.value }))}
              placeholder={t("admin.createAssociation.adminEmail")}
            />
          </div>
          <Button 
            className="w-full" 
            onClick={handleCreateAssociation}
            disabled={isCreating}
          >
            {isCreating ? t("admin.createAssociation.creating") : t("admin.createAssociation.create")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
