
import { useTranslation } from "react-i18next";

export const WelcomeHeader = () => {
  const { t } = useTranslation();
  
  return (
    <div className="space-y-4">
      <h1 className="text-4xl font-bold">{t("home.welcome.title")}</h1>
      <p className="text-xl text-muted-foreground">
        {t("home.welcome.subtitle")}
      </p>
    </div>
  );
};
