import { useState, useEffect } from "react";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ArrowLeft, Building2, Eye, EyeOff } from "lucide-react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/contexts/AuthContext";
import { Label } from "@/components/ui/label";
import { getGravatarName } from "@/utils/gravatar";

interface OnboardingCodeData {
  id: string;
  code: string;
  tenant_id: string;
  association_name: string;
  street_name: string;
  street_number: string;
  use_count: number;
}

export default function JoinWithCodeForm() {
  const { code } = useParams<{ code: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { user, signUp, signIn } = useAuth();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState(user?.email || "");
  const [phone, setPhone] = useState("");
  const [codeData, setCodeData] = useState<OnboardingCodeData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Auth form states
  const [isSignIn, setIsSignIn] = useState(false);
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isAuthLoading, setIsAuthLoading] = useState(false);

  // Fetch the code data only once when the component mounts
  useEffect(() => {
    if (!code) {
      toast.error("No onboarding code provided");
      navigate("/tenants/onboarding-code");
      return;
    }

    const fetchCodeData = async () => {
      setIsLoading(true);
      try {
        const { data, error } = await supabase
          .from('onboarding_codes')
          .select('*')
          .eq('code', code)
          .single();
        
        if (error) throw error;
        
        if (data) {
          setCodeData(data as OnboardingCodeData);
          
          // Only try to get name from Gravatar if fullName is empty and user is logged in
          if (user?.email && !fullName) {
            try {
              const gravatarName = await getGravatarName(user.email);
              if (gravatarName) {
                setFullName(gravatarName);
                toast.success("Name found from Gravatar profile");
              }
            } catch (gravatarError) {
              console.error("Error fetching Gravatar name:", gravatarError);
            }
          }
        } else {
          toast.error("Invalid onboarding code");
          navigate("/tenants/onboarding-code");
        }
      } catch (error) {
        console.error("Error fetching code data:", error);
        toast.error("Failed to verify onboarding code");
        navigate("/tenants/onboarding-code");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCodeData();
  }, [code, navigate, user]);

  // Update user details when auth state changes
  useEffect(() => {
    if (user) {
      setEmail(user.email || "");
      
      // Try to get user's name from user metadata
      const metadata = user.user_metadata;
      if (metadata && metadata.first_name && metadata.last_name) {
        setFullName(`${metadata.first_name} ${metadata.last_name}`);
      } else if (metadata && metadata.name) {
        setFullName(metadata.name);
      } else if (!fullName) {
        // If no name in metadata, try Gravatar
        getGravatarName(user.email || "").then(name => {
          if (name) {
            setFullName(name);
          }
        }).catch(error => {
          console.error("Error fetching name from Gravatar:", error);
        });
      }
    }
  }, [user]);

  const handleAuthSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsAuthLoading(true);

    try {
      if (isSignIn) {
        await signIn(email, password, currentPath);
        // Not adding toast here as navigation will happen via the AuthContext
      } else {
        // Signup - create a new account
        await signUp(email, password, firstName, lastName, currentPath);
        // Toast is handled in AuthContext
      }
    } catch (error) {
      console.error("Authentication error:", error);
      toast.error(error instanceof Error ? error.message : "Authentication failed");
    } finally {
      setIsAuthLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!user) {
      toast.error("You must be logged in to join an association");
      return;
    }
    
    if (!codeData) {
      toast.error("Invalid onboarding code");
      return;
    }

    setIsSubmitting(true);
    try {
      // Verify if code is registered with a cadastre
      const { data: cadastreData } = await supabase
        .from('cadastres')
        .select('id, onboarding_code')
        .eq('tenant_id', codeData.tenant_id)
        .eq('street_name', codeData.street_name)
        .eq('street_number', codeData.street_number)
        .eq('onboarding_code', code)
        .single();

      const autoApprove = !!cadastreData;
      const cadastreId = cadastreData?.id;
      
      // Create join request
      const { error: joinError } = await supabase
        .from('association_join_requests')
        .insert([{
          user_id: user.id,
          tenant_id: codeData.tenant_id,
          street_name: codeData.street_name,
          street_number: codeData.street_number,
          full_name: fullName,
          email: email,
          phone: phone || null,
          onboarding_code: code,
          status: autoApprove ? 'approved' : 'pending'
        }]);

      if (joinError) throw joinError;

      // Update onboarding code usage count
      await supabase
        .from('onboarding_codes')
        .update({ use_count: codeData.use_count + 1 })
        .eq('id', codeData.id);

      // If auto-approved, add tenant membership immediately
      if (autoApprove) {
        console.log("Auto-approving user:", user.id, "for tenant:", codeData.tenant_id);
        
        // Check if membership already exists
        const { data: existingMembership, error: membershipCheckError } = await supabase
          .from('tenant_memberships')
          .select('*')
          .eq('tenant_id', codeData.tenant_id)
          .eq('user_id', user.id)
          .maybeSingle();
          
        if (membershipCheckError) {
          console.error('Error checking existing membership:', membershipCheckError);
        }
        
        // Only add if no membership exists
        if (!existingMembership) {
          const { error: membershipError } = await supabase
            .from('tenant_memberships')
            .insert([{
              tenant_id: codeData.tenant_id,
              user_id: user.id,
              role: 'member'
            }]);
  
          if (membershipError) {
            console.error('Error adding tenant membership:', membershipError);
            toast.error("Failed to add you as a member. Please contact support.");
            // Continue with the flow despite the error
          } else {
            console.log("Successfully added tenant membership");
          }
        } else {
          console.log("Membership already exists, skipping creation");
        }

        // Create a corresponding member record in the members table
        const today = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD format
        
        // Check if member already exists
        const { data: existingMember, error: memberCheckError } = await supabase
          .from('members')
          .select('*')
          .eq('tenant_id', codeData.tenant_id)
          .eq('email', email)
          .maybeSingle();
          
        if (memberCheckError) {
          console.error('Error checking existing member:', memberCheckError);
        }
        
        // Only add if no member exists with this email for this tenant
        if (!existingMember) {
          const memberData = {
            tenant_id: codeData.tenant_id,
            name: fullName,
            email: email,
            phone: phone || '',
            street_name: codeData.street_name,
            street_number: codeData.street_number,
            join_date: today,
            start_date: today,
            is_permanent_resident: true,
            status: 'active',
            cadastre_id: cadastreId // Include cadastre_id but also keep street name/number
          };
          
          const { error: memberError } = await supabase
            .from('members')
            .insert([memberData]);
  
          if (memberError) {
            console.error('Error creating member record:', memberError);
            toast.error("Failed to create your member profile. Please contact an administrator.");
            // Continue with the flow despite the error
          } else {
            console.log("Successfully created member record");
          }
        } else {
          console.log("Member already exists, skipping creation");
        }
        
        toast.success("You've been automatically approved to join the association!");
      } else {
        toast.success("Join request submitted successfully");
      }
      
      // Redirect to home with a slight delay to allow the toast to be seen
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (error) {
      console.error('Submit error:', error);
      toast.error("Failed to submit join request");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Simple change handlers without side effects
  const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
        <Card className="w-full max-w-md p-6">
          <div className="text-center py-8">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary mx-auto mb-4"></div>
            <p>Verifying onboarding code...</p>
          </div>
        </Card>
      </div>
    );
  }

  // Render authentication form if user is not logged in
  if (!user) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
        <Card className="w-full max-w-md">
          <CardHeader>
            <CardTitle>{isSignIn ? "Sign in" : "Create an account"}</CardTitle>
            <CardDescription>
              {isSignIn 
                ? "Sign in to join" 
                : "Create an account to join"} {codeData?.association_name || "the association"}
            </CardDescription>
          </CardHeader>
          <CardContent>
            {codeData && (
              <div className="p-4 bg-accent/10 rounded-lg mb-6">
                <div className="flex items-start gap-4">
                  <Building2 className="h-5 w-5 text-muted-foreground shrink-0 mt-1" />
                  <div className="space-y-1">
                    <p className="font-bold text-base">{codeData.association_name}</p>
                    <p className="text-sm text-muted-foreground">
                      Address: {codeData.street_name} {codeData.street_number}
                    </p>
                    <p className="text-sm text-muted-foreground">
                      Code: {codeData.code}
                    </p>
                  </div>
                </div>
              </div>
            )}
            
            <form onSubmit={handleAuthSubmit} className="space-y-4">
              {!isSignIn && (
                <>
                  <Input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                  <Input
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </>
              )}
              <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="relative">
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="absolute right-2 top-1/2 -translate-y-1/2"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    setShowPassword(true);
                  }}
                  onMouseUp={() => setShowPassword(false)}
                  onMouseLeave={() => setShowPassword(false)}
                >
                  {showPassword ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                </Button>
              </div>
              <Button type="submit" className="w-full" disabled={isAuthLoading}>
                {isAuthLoading ? "Loading..." : isSignIn ? "Sign In" : "Create Account"}
              </Button>
            </form>
            
            <div className="mt-4 text-center">
              <Button
                variant="link"
                onClick={() => setIsSignIn(!isSignIn)}
                className="text-sm text-blue-600"
              >
                {isSignIn ? "Need an account? Sign up" : "Already have an account? Sign in"}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  // Render join form if user is logged in
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
      <Card className="w-full max-w-md p-6">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold">Join Association</h1>
          <Link to="/tenants/onboarding-code">
            <Button variant="ghost" size="icon" disabled={isSubmitting}>
              <ArrowLeft className="h-5 w-5" />
            </Button>
          </Link>
        </div>

        {codeData && (
          <div className="p-4 bg-accent/10 rounded-lg mb-6">
            <div className="flex items-start gap-4">
              <Building2 className="h-5 w-5 text-muted-foreground shrink-0 mt-1" />
              <div className="space-y-1">
                <p className="font-bold text-base">{codeData.association_name}</p>
                <p className="text-sm text-muted-foreground">
                  Address: {codeData.street_name} {codeData.street_number}
                </p>
                <p className="text-sm text-muted-foreground">
                  Code: {codeData.code}
                </p>
              </div>
            </div>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={handleEmailChange}
                required
                disabled={isSubmitting}
                readOnly
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="fullName">Full Name</Label>
              <Input
                id="fullName"
                value={fullName}
                onChange={handleFullNameChange}
                required
                disabled={isSubmitting}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="phone">Phone Number (Optional)</Label>
              <Input
                id="phone"
                type="tel"
                value={phone}
                onChange={handlePhoneChange}
                disabled={isSubmitting}
              />
            </div>
          </div>

          <Button 
            type="submit" 
            className="w-full" 
            disabled={isSubmitting}
          >
            Submit Request
          </Button>
        </form>
      </Card>
    </div>
  );
}
