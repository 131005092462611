import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PageContainer } from "@/components/PageContainer";
import { PageHeader } from "@/components/PageHeader";
import { useDocuments } from "@/hooks/useDocuments";
import { FolderCard } from "@/components/documents/FolderCard";
import { FileCard } from "@/components/documents/FileCard";
import { NewFolderDialog } from "@/components/documents/NewFolderDialog";
import { DeleteConfirmDialog } from "@/components/documents/DeleteConfirmDialog";
import { EmptyState } from "@/components/documents/EmptyState";
import { FileUploadDialog } from "@/components/documents/FileUploadDialog";
import { FolderBreadcrumb } from "@/components/documents/FolderBreadcrumb";
import { FileViewer } from "@/components/documents/FileViewer";
import { CompactFileList } from "@/components/documents/CompactFileList";
import { BulkActions } from "@/components/documents/BulkActions";
import { DocumentFolder, DocumentFile, BreadcrumbItem } from "@/types/document";
import { FolderPlus, Upload, Loader2, Grid, List } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useRole } from "@/hooks/useRole";
import { Separator } from "@/components/ui/separator";
import { useTenant } from "@/contexts/TenantContext";
import { toast } from "sonner";
import JSZip from "jszip";

export default function Documents() {
  const { t } = useTranslation();
  const { canCreate } = useRole();
  const { currentTenant } = useTenant();
  const {
    rootFolders,
    isLoadingFolders,
    getSubfolders,
    getFilesByFolder,
    createFolder,
    renameFolder,
    deleteFolder,
    uploadFile,
    deleteFile,
    getFileUrl,
    getBreadcrumbs
  } = useDocuments();

  const [currentFolderId, setCurrentFolderId] = useState<string | null>(null);
  const [subfolders, setSubfolders] = useState<DocumentFolder[]>([]);
  const [files, setFiles] = useState<DocumentFile[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([{ id: null, name: t("documents.root") }]);
  const [isLoading, setIsLoading] = useState(false);
  
  // View mode states
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('list');
  const [selectedItems, setSelectedItems] = useState<{
    folders: DocumentFolder[];
    files: DocumentFile[];
  }>({ folders: [], files: [] });
  
  // Dialog states
  const [isNewFolderDialogOpen, setIsNewFolderDialogOpen] = useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [folderToEdit, setFolderToEdit] = useState<DocumentFolder | undefined>(undefined);
  const [itemToDelete, setItemToDelete] = useState<{ id: string; type: 'folder' | 'file'; name: string; path?: string }>();
  const [isDeleting, setIsDeleting] = useState(false);
  
  // Bulk Delete state
  const [isBulkDeleteDialogOpen, setIsBulkDeleteDialogOpen] = useState(false);
  
  // File viewer states
  const [viewingFile, setViewingFile] = useState<DocumentFile | null>(null);
  const [fileViewUrl, setFileViewUrl] = useState<string | null>(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Load content when currentFolderId or currentTenant changes
  useEffect(() => {
    if (!currentTenant?.id) return;
    
    loadFolderContent();
  }, [currentFolderId, currentTenant?.id]);

  // Additionally reload root folders when navigating back to this page
  useEffect(() => {
    const handleFocus = () => {
      if (currentTenant?.id) {
        loadFolderContent();
      }
    };

    window.addEventListener('focus', handleFocus);
    
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [currentTenant?.id, currentFolderId]);

  const loadFolderContent = async () => {
    if (!currentTenant?.id) return;
    
    setIsLoading(true);
    try {
      // Update breadcrumbs
      const breadcrumbsData = await getBreadcrumbs(currentFolderId);
      setBreadcrumbs(breadcrumbsData);

      // For both root and subfolders, load both folders and files
      const [subfoldersData, filesData] = await Promise.all([
        getSubfolders(currentFolderId),
        getFilesByFolder(currentFolderId !== null ? currentFolderId : 'root')
      ]);
      
      setSubfolders(subfoldersData);
      setFiles(filesData);
      
      // Clear selection when changing folders
      setSelectedItems({ folders: [], files: [] });
    } catch (error) {
      console.error('Error loading folder content:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle folder navigation
  const handleOpenFolder = (folderId: string) => {
    setCurrentFolderId(folderId);
  };

  const handleNavigateBreadcrumb = (folderId: string | null) => {
    setCurrentFolderId(folderId);
  };

  // Handle folder creation
  const handleCreateFolder = async (data: { name: string; description?: string }) => {
    await createFolder({
      name: data.name,
      parentId: currentFolderId,
      description: data.description
    });
    loadFolderContent();
  };

  // Handle folder editing
  const handleEditFolder = (folder: DocumentFolder) => {
    setFolderToEdit(folder);
    setIsNewFolderDialogOpen(true);
  };

  const handleSaveEditedFolder = async (data: { name: string; description?: string }) => {
    if (!folderToEdit) return;
    
    await renameFolder({
      folderId: folderToEdit.id,
      name: data.name,
      description: data.description
    });
    setFolderToEdit(undefined);
    loadFolderContent();
  };

  // Handle folder deletion
  const handleDeleteFolder = (folderId: string, folderName: string) => {
    setItemToDelete({ id: folderId, type: 'folder', name: folderName });
    setIsDeleteDialogOpen(true);
  };

  // File actions
  const handleViewFile = async (file: DocumentFile) => {
    try {
      const url = await getFileUrl(file.storage_path);
      setViewingFile(file);
      setFileViewUrl(url);
      setIsViewerOpen(true);
    } catch (error) {
      console.error('Error preparing file for viewing:', error);
    }
  };

  const handleCloseViewer = () => {
    if (isFullscreen) {
      setIsFullscreen(false);
    }
    setIsViewerOpen(false);
    setViewingFile(null);
    setFileViewUrl(null);
  };

  const handleToggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  // Handle file upload
  const handleUploadFile = async (file: File) => {
    if ((!currentFolderId && currentFolderId !== null)) return;
    
    await uploadFile({ file, folderId: currentFolderId || 'root' });
    loadFolderContent();
  };

  // Handle file download
  const handleDownloadFile = async (file: DocumentFile) => {
    try {
      const url = await getFileUrl(file.storage_path);
      
      // Create a temporary link and click it to start the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  // Handle file deletion
  const handleDeleteFile = (fileId: string, fileName: string, storagePath: string) => {
    setItemToDelete({ id: fileId, type: 'file', name: fileName, path: storagePath });
    setIsDeleteDialogOpen(true);
  };

  // Handle bulk actions
  const handleSelectionChange = (selection: { folders: DocumentFolder[]; files: DocumentFile[] }) => {
    setSelectedItems(selection);
  };
  
  const handleBulkDelete = () => {
    if (selectedItems.folders.length === 0 && selectedItems.files.length === 0) return;
    setIsBulkDeleteDialogOpen(true);
  };
  
  const handleConfirmBulkDelete = async () => {
    setIsDeleting(true);
    
    try {
      // Delete folders first
      for (const folder of selectedItems.folders) {
        await deleteFolder(folder.id);
      }
      
      // Then delete files
      for (const file of selectedItems.files) {
        await deleteFile(file.id, file.storage_path);
      }
      
      toast.success(t("documents.bulkDeleteSuccess"));
      setSelectedItems({ folders: [], files: [] });
      loadFolderContent();
    } catch (error) {
      console.error('Error in bulk delete:', error);
      toast.error(t("documents.bulkDeleteError"));
    } finally {
      setIsDeleting(false);
      setIsBulkDeleteDialogOpen(false);
    }
  };
  
  const handleBulkDownload = async () => {
    if (selectedItems.files.length === 0 && selectedItems.folders.length === 0) {
      toast.info(t("documents.noItemsToDownload"));
      return;
    }
    
    try {
      toast.loading(t("documents.downloadStarted"));
      const zip = new JSZip();
      
      // Add selected files
      for (const file of selectedItems.files) {
        const url = await getFileUrl(file.storage_path);
        const response = await fetch(url);
        const blob = await response.blob();
        zip.file(file.name, blob);
      }
      
      // Recursively add folder contents
      for (const folder of selectedItems.folders) {
        await addFolderToZip(zip, folder.id, folder.name);
      }
      toast.dismiss();
      

      toast.loading(t("documents.archivingStarted"));
      // Generate the zip file as a blob
      const blob = await zip.generateAsync({ type: "blob" });
      toast.dismiss();
      
      // Create a temporary link and trigger the download
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "selected_items.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      toast.success(t("documents.downloadComplete"));
    } catch (error) {
      console.error('Error downloading files:', error);
      toast.error(t("documents.downloadError"));
    }
  };
  
  // Function to recursively add folder contents to zip
  const addFolderToZip = async (zip: JSZip, folderId: string, folderPath: string) => {
    // Get subfolders
    const subfolders = await getSubfolders(folderId);
    // Get files in the current folder
    const folderFiles = await getFilesByFolder(folderId);
    
    // Add files in the current folder
    for (const file of folderFiles) {
      const url = await getFileUrl(file.storage_path);
      const response = await fetch(url);
      const blob = await response.blob();
      zip.file(`${folderPath}/${file.name}`, blob);
    }
    
    // Recursively process subfolders
    for (const subfolder of subfolders) {
      await addFolderToZip(zip, subfolder.id, `${folderPath}/${subfolder.name}`);
    }
  };

  // Handle actual deletion of a single item
  const handleConfirmDelete = async () => {
    if (!itemToDelete) return;
    
    setIsDeleting(true);
    try {
      if (itemToDelete.type === 'folder') {
        await deleteFolder(itemToDelete.id);
      } else if (itemToDelete.type === 'file' && itemToDelete.path) {
        await deleteFile(itemToDelete.id, itemToDelete.path);
      }
      loadFolderContent();
    } catch (error) {
      console.error('Error deleting item:', error);
    } finally {
      setIsDeleting(false);
      setItemToDelete(undefined);
    }
  };

  const isEmpty = subfolders.length === 0 && files.length === 0 && !isLoading && !isLoadingFolders;

  return (
    <PageContainer>
      <PageHeader 
        title={t("menu.documents")} 
        subtitle={t("documents.subtitle")}
      >
        <div className="flex items-center space-x-2">
          {/* View mode toggle */}
          <div className="hidden md:flex border rounded-md overflow-hidden">
            <Button
              variant={viewMode === 'list' ? 'default' : 'ghost'}
              size="sm"
              className="rounded-none"
              onClick={() => setViewMode('list')}
            >
              <List className="h-4 w-4 mr-1" />
            </Button>
            <Button
              variant={viewMode === 'grid' ? 'default' : 'ghost'}
              size="sm"
              className="rounded-none"
              onClick={() => setViewMode('grid')}
            >
              <Grid className="h-4 w-4 mr-1" />
            </Button>
          </div>
          
          {canCreate('documents') && (
            <div className="flex space-x-2">
              <Button 
                variant="outline" 
                onClick={() => {
                  setFolderToEdit(undefined);
                  setIsNewFolderDialogOpen(true);
                }}
              >
                <FolderPlus className="mr-2 h-4 w-4" />
              </Button>
              
              <Button 
                variant="outline" 
                onClick={() => setIsUploadDialogOpen(true)}
              >
                <Upload className="mr-2 h-4 w-4" />
              </Button>
            </div>
          )}
        </div>
      </PageHeader>

      {/* Breadcrumbs and Bulk Actions */}
      <div className="flex items-center justify-between mb-4">
        <div className="flex-grow overflow-x-auto">
          <FolderBreadcrumb 
            breadcrumbs={breadcrumbs} 
            onNavigate={handleNavigateBreadcrumb} 
          />
        </div>
        <BulkActions 
          selectedItems={selectedItems}
          onDownloadSelected={handleBulkDownload}
          onDeleteSelected={handleBulkDelete}
        />
      </div>

      {/* Content */}
      <div className="mt-2">
        {isLoading || isLoadingFolders ? (
          <div className="flex justify-center items-center h-48">
            <Loader2 className="h-8 w-8 animate-spin text-primary" />
          </div>
        ) : isEmpty ? (
          // Empty state
          <EmptyState
            title={t("documents.emptyTitle")}
            description={t("documents.emptyDescription")}
            onCreateFolder={canCreate('documents') ? () => {
              setFolderToEdit(undefined);
              setIsNewFolderDialogOpen(true);
            } : undefined}
            onUploadFile={canCreate('documents') ? () => setIsUploadDialogOpen(true) : undefined}
            showUploadButton={true}
          />
        ) : viewMode === 'list' ? (
          // List view
          <CompactFileList 
            folders={subfolders}
            files={files}
            onOpenFolder={handleOpenFolder}
            onEditFolder={handleEditFolder}
            onDeleteFolder={handleDeleteFolder}
            onViewFile={handleViewFile}
            onDownloadFile={handleDownloadFile}
            onDeleteFile={handleDeleteFile}
            onSelectionChange={handleSelectionChange}
            onUploadFile={canCreate('documents') ? handleUploadFile : undefined}
            currentFolderId={currentFolderId}
          />
        ) : (
          // Grid view
          <div className="space-y-6">
            {/* Folders */}
            {subfolders.length > 0 && (
              <div>
                <h2 className="text-lg font-medium mb-3">{t("documents.folders")}</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {subfolders.map(folder => (
                    <FolderCard
                      key={folder.id}
                      folder={folder}
                      onOpen={handleOpenFolder}
                      onEdit={handleEditFolder}
                      onDelete={(id) => handleDeleteFolder(id, folder.name)}
                    />
                  ))}
                </div>
              </div>
            )}

            {/* Files */}
            {files.length > 0 && (
              <div>
                {subfolders.length > 0 && <Separator className="my-6" />}
                <h2 className="text-lg font-medium mb-3">{t("documents.files")}</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {files.map(file => (
                    <FileCard
                      key={file.id}
                      file={file}
                      onDownload={handleDownloadFile}
                      onDelete={(id, path) => handleDeleteFile(id, file.name, path)}
                      onView={handleViewFile}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Dialogs */}
      <NewFolderDialog
        isOpen={isNewFolderDialogOpen}
        onClose={() => {
          setIsNewFolderDialogOpen(false);
          setFolderToEdit(undefined);
        }}
        onSubmit={folderToEdit ? handleSaveEditedFolder : handleCreateFolder}
        folder={folderToEdit}
      />

      <FileUploadDialog
        isOpen={isUploadDialogOpen}
        onClose={() => setIsUploadDialogOpen(false)}
        onUpload={handleUploadFile}
      />

      <DeleteConfirmDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => {
          setIsDeleteDialogOpen(false);
          setItemToDelete(undefined);
        }}
        onDelete={handleConfirmDelete}
        title={
          itemToDelete?.type === 'folder' 
            ? t("documents.deleteFolder") 
            : t("documents.deleteFile")
        }
        description={
          itemToDelete?.type === 'folder'
            ? t("documents.deleteFolderConfirm", { name: itemToDelete?.name })
            : t("documents.deleteFileConfirm", { name: itemToDelete?.name })
        }
        isDeleting={isDeleting}
      />
      
      {/* Bulk Delete Dialog */}
      <DeleteConfirmDialog
        isOpen={isBulkDeleteDialogOpen}
        onClose={() => setIsBulkDeleteDialogOpen(false)}
        onDelete={handleConfirmBulkDelete}
        title={t("documents.bulkDelete")}
        description={t("documents.bulkDeleteConfirm", { 
          count: selectedItems.folders.length + selectedItems.files.length 
        })}
        isDeleting={isDeleting}
      />

      {/* File Viewer */}
      <FileViewer
        file={viewingFile}
        url={fileViewUrl}
        isOpen={isViewerOpen}
        onClose={handleCloseViewer}
        onDownload={() => viewingFile && handleDownloadFile(viewingFile)}
        fullscreen={isFullscreen}
        onToggleFullscreen={handleToggleFullscreen}
      />
    </PageContainer>
  );
}
