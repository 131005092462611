
import { ChevronRight, Home } from "lucide-react";
import { BreadcrumbItem } from "@/types/document";
import { Button } from "@/components/ui/button";

interface FolderBreadcrumbProps {
  breadcrumbs: BreadcrumbItem[];
  onNavigate: (folderId: string | null) => void;
}

export function FolderBreadcrumb({ breadcrumbs, onNavigate }: FolderBreadcrumbProps) {
  return (
    <nav className="flex items-center space-x-1 text-sm mb-4 overflow-x-auto pb-2">
      {breadcrumbs.map((crumb, index) => (
        <div key={index} className="flex items-center">
          {index > 0 && <ChevronRight className="h-4 w-4 text-muted-foreground mx-1" />}
          <Button
            variant="ghost"
            size="sm"
            className={`h-8 flex items-center rounded-md px-2 py-1.5 text-sm font-medium ${
              index === breadcrumbs.length - 1 ? 'text-foreground' : 'text-muted-foreground hover:text-foreground'
            }`}
            onClick={() => onNavigate(crumb.id)}
          >
            {index === 0 && <Home className="h-3.5 w-3.5 mr-1" />}
            <span className="truncate max-w-[150px]">{crumb.name}</span>
          </Button>
        </div>
      ))}
    </nav>
  );
}
