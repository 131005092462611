
import { useState, useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';

interface TenantSettings {
  name: string;
  cvr_number: string;
  street_address: string;
  street_number: string;
  post_number: string;
  country: string;
  logo_url?: string;
}

interface PublicTenant {
  id: string;
  name: string;
  url_name: string | null;
  settings: TenantSettings | null;
}

export function usePublicTenant(urlName: string | undefined) {
  const [tenant, setTenant] = useState<PublicTenant | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTenant = async () => {
      if (!urlName) {
        setIsLoading(false);
        setError('No tenant URL provided');
        return;
      }

      try {
        setIsLoading(true);
        setError(null);
        
        const { data, error } = await supabase
          .from('tenants')
          .select(`
            id,
            name,
            url_name,
            settings:association_settings(
              name,
              cvr_number,
              street_address,
              street_number,
              post_number,
              country,
              logo_url
            )
          `)
          .eq('url_name', urlName)
          .single();

        if (error) {
          console.error('Error fetching tenant:', error);
          setError('Could not find the requested association');
        } else if (data) {
          // Process the data to ensure it matches our PublicTenant interface
          // The settings property comes back as an array with one item, so we need to extract that item
          const processedData: PublicTenant = {
            id: data.id,
            name: data.name,
            url_name: data.url_name,
            settings: data.settings && data.settings.length > 0 ? data.settings[0] : null
          };
          
          setTenant(processedData);
        }
      } catch (err) {
        console.error('Error in tenant fetch:', err);
        setError('An unexpected error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTenant();
  }, [urlName]);

  return { tenant, isLoading, error };
}
