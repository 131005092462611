
import { useEffect } from "react";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useTranslation } from "react-i18next";

interface AddressSectionProps {
  streetName: string;
  streetNumber: string;
  availableStreets: string[];
  availableStreetNumbers: string[];
  onStreetChange: (street: string) => void;
  onStreetNumberChange: (number: string) => void;
}

export function AddressSection({
  streetName,
  streetNumber,
  availableStreets,
  availableStreetNumbers,
  onStreetChange,
  onStreetNumberChange,
}: AddressSectionProps) {
  const { t } = useTranslation();

  // Automatically update cadastre selection when street details change
  useEffect(() => {
    // This effect will ensure the component re-renders when street data changes
    // The actual cadastre_id update happens in the parent component and useMembers hook
  }, [streetName, streetNumber]);

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="space-y-2">
        <Label htmlFor="street_name">{t("members.form.street")}</Label>
        <Select
          value={streetName}
          onValueChange={(value) => onStreetChange(value)}
          required
        >
          <SelectTrigger>
            <SelectValue placeholder={t("members.form.street")} />
          </SelectTrigger>
          <SelectContent>
            {availableStreets.map((street) => (
              <SelectItem key={street} value={street}>
                {street}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="space-y-2">
        <Label htmlFor="street_number">{t("members.form.streetNumber")}</Label>
        <Select
          value={streetNumber}
          onValueChange={onStreetNumberChange}
          disabled={!streetName}
          required
        >
          <SelectTrigger>
            <SelectValue placeholder={t("members.form.streetNumber")} />
          </SelectTrigger>
          <SelectContent>
            {availableStreetNumbers.map((number) => (
              <SelectItem key={number} value={number}>
                {number}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}
