
import { format } from "date-fns";
import { AlertCircle, CheckCircle2, XCircle } from "lucide-react";
import { Card } from "@/components/ui/card";

interface CreationRequest {
  id: string;
  status: 'pending' | 'approved' | 'rejected';
  review_notes?: string;
  created_at: string;
  updated_at: string;
  name: string;
  cvr_number: string;
}

interface CreationRequestCardProps {
  creationRequest: CreationRequest;
}

const getStatusIcon = (status: string) => {
  switch (status) {
    case 'pending':
      return <AlertCircle className="w-5 h-5 text-yellow-500" />;
    case 'approved':
      return <CheckCircle2 className="w-5 h-5 text-green-500" />;
    case 'rejected':
      return <XCircle className="w-5 h-5 text-red-500" />;
    default:
      return null;
  }
};

const getStatusColor = (status: string) => {
  switch (status) {
    case 'pending':
      return 'bg-yellow-50 border-yellow-200';
    case 'approved':
      return 'bg-green-50 border-green-200';
    case 'rejected':
      return 'bg-red-50 border-red-200';
    default:
      return '';
  }
};

export const CreationRequestCard = ({ creationRequest }: CreationRequestCardProps) => {
  return (
    <Card className={`p-6 ${getStatusColor(creationRequest.status)} text-left border`}>
      <div className="space-y-4">
        <div className="flex items-center justify-between border-b pb-4">
          <h3 className="text-lg font-semibold flex items-center gap-2">
            <span>Active Create Association Request</span>
            {getStatusIcon(creationRequest.status)}
          </h3>
          <span className="px-3 py-1 rounded-full text-sm capitalize bg-yellow-100 text-yellow-800">
            {creationRequest.status}
          </span>
        </div>
        
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <span className="text-sm text-muted-foreground">Association Name</span>
            <span className="font-medium">{creationRequest.name}</span>
          </div>
          
          <div className="flex justify-between items-center">
            <span className="text-sm text-muted-foreground">CVR Number</span>
            <span className="font-medium">{creationRequest.cvr_number}</span>
          </div>
          
          <div className="flex justify-between items-center">
            <span className="text-sm text-muted-foreground">Requested on</span>
            <span className="font-medium">
              {format(new Date(creationRequest.created_at), "MMM d, yyyy 'at' HH:mm")}
            </span>
          </div>
          
          <div className="flex justify-between items-center">
            <span className="text-sm text-muted-foreground">Last updated</span>
            <span className="font-medium">
              {format(new Date(creationRequest.updated_at), "MMM d, yyyy 'at' HH:mm")}
            </span>
          </div>

          {creationRequest.review_notes && (
            <div className="mt-4 p-3 bg-gray-50 rounded-md">
              <span className="text-sm text-muted-foreground block mb-1">Review notes</span>
              <span className="text-sm">{creationRequest.review_notes}</span>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};
