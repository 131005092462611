
import { useParams, Link, useNavigate } from "react-router-dom";
import { Building, Mail, MapPin, LogIn, Home } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardContent, CardFooter } from "@/components/ui/card";
import { usePublicTenant } from "@/hooks/usePublicTenant";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/contexts/AuthContext";
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";

const PublicTenant = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isMember, setIsMember] = useState(false);
  const {
    urlName
  } = useParams<{
    urlName: string;
  }>();
  const {
    tenant,
    isLoading,
    error
  } = usePublicTenant(urlName);

  useEffect(() => {
    // Check if the user is a member of this tenant
    const checkMembership = async () => {
      if (user && tenant) {
        const { data, error } = await supabase
          .from('tenant_memberships')
          .select('*')
          .eq('user_id', user.id)
          .eq('tenant_id', tenant.id)
          .maybeSingle();
          
        if (data && !error) {
          setIsMember(true);
        } else {
          setIsMember(false);
        }
      } else {
        setIsMember(false);
      }
    };
    
    checkMembership();
  }, [user, tenant]);

  if (isLoading) {
    return <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>;
  }

  if (error || !tenant) {
    return <div className="flex flex-col items-center justify-center min-h-screen p-4">
        <h1 className="text-2xl font-bold mb-4">{t("publicTenant.notFound")}</h1>
        <p>{error || t("publicTenant.notFoundDescription")}</p>
        <Link to="/" className="mt-4">
          <Button>{t("publicTenant.returnHome")}</Button>
        </Link>
      </div>;
  }

  const {
    settings
  } = tenant;

  const handleMemberAccess = () => {
    navigate('/');
  };

  return <div className="min-h-screen flex flex-col">
      {/* Header with logo and name */}
      <header className="bg-background border-b py-4">
        <div className="container mx-auto px-4 flex items-center justify-between">
          <div className="flex items-center space-x-4">
            {settings?.logo_url ? <img src={settings.logo_url} alt={`${settings.name} logo`} className="h-12 w-12 object-contain" /> : <Building className="h-12 w-12 text-primary" />}
            <h1 className="text-2xl font-bold">{settings?.name || tenant.name}</h1>
          </div>
          {user && isMember ? (
            <Button onClick={handleMemberAccess} className="flex items-center gap-2">
              <Home size={18} />
              {t("publicTenant.memberPage")}
            </Button>
          ) : (
            <Link to="/auth">
              <Button className="flex items-center gap-2">
                <LogIn size={18} />
                {t("publicTenant.memberLogin")}
              </Button>
            </Link>
          )}
        </div>
      </header>

      {/* Main content */}
      <main className="flex-grow container mx-auto px-4 py-8">
        <Card className="w-full max-w-4xl mx-auto">
          <CardHeader>
            <h2 className="text-2xl font-bold">{t("publicTenant.welcomeTo", { name: settings?.name || tenant.name })}</h2>
          </CardHeader>
          <CardContent className="space-y-6">
            <div>
              <h3 className="text-xl font-semibold mb-2">{t("publicTenant.aboutUs")}</h3>
              <p>{t("publicTenant.aboutUsDescription")}</p>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold mb-2 text-left">{t("publicTenant.location")}</h3>
              <div className="flex items-start gap-2">
                <MapPin className="h-5 w-5 mt-0.5 text-muted-foreground" />
                <p>
                  {settings?.street_address} {settings?.street_number}, 
                  {settings?.post_number}, {settings?.country}
                </p>
              </div>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold mb-2 text-left">{t("publicTenant.contact")}</h3>
              <div className="flex items-center gap-2">
                <Mail className="h-5 w-5 text-muted-foreground" />
                <p>{t("publicTenant.contactDescription")}</p>
              </div>
            </div>
          </CardContent>
          <CardFooter className="flex flex-col items-start border-t pt-4">
            <p className="text-sm text-muted-foreground">
              {settings?.name || tenant.name} • {t("publicTenant.cvr")}: {settings?.cvr_number || t("common.notAvailable")}
            </p>
            <p className="text-sm text-muted-foreground">
              {settings?.street_address} {settings?.street_number}, {settings?.post_number}, {settings?.country}
            </p>
          </CardFooter>
        </Card>
      </main>

      {/* Footer */}
      <footer className="bg-background border-t py-4 mt-auto">
        <div className="container mx-auto px-4 text-center text-sm text-muted-foreground">
          <p>&copy; {new Date().getFullYear()} {settings?.name || tenant.name}</p>
          <p>{t("publicTenant.cvr")}: {settings?.cvr_number || t("common.notAvailable")}</p>
        </div>
      </footer>
    </div>;
};

export default PublicTenant;
