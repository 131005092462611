import { useAuth } from "@/contexts/AuthContext";
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { WelcomeHeader } from "./WelcomeHeader";
import { JoinRequestCard } from "./JoinRequestCard";
import { CreationRequestCard } from "./CreationRequestCard";
import { ActionButtons } from "./ActionButtons";
import { AboutCard } from "./AboutCard";

interface JoinRequest {
  id: string;
  status: 'pending' | 'approved' | 'rejected';
  review_notes?: string;
  created_at: string;
  updated_at: string;
  tenant_id: string;
  full_name: string;
  street_name: string;
  street_number: string;
  phone?: string | null;
  dismissed: boolean;
  association_settings: {
    name: string;
  } | null;
}

interface CreationRequest {
  id: string;
  status: 'pending' | 'approved' | 'rejected';
  review_notes?: string;
  created_at: string;
  updated_at: string;
  name: string;
  cvr_number: string;
}

export const WelcomeView = () => {
  const { user } = useAuth();
  const [isAppAdmin, setIsAppAdmin] = useState(false);
  const [joinRequest, setJoinRequest] = useState<JoinRequest | null>(null);
  const [creationRequest, setCreationRequest] = useState<CreationRequest | null>(null);

  const fetchJoinRequest = async () => {
    if (!user) return;

    const { data: joinRequestData, error: joinRequestError } = await supabase
      .from('association_join_requests')
      .select(`
        id, 
        status, 
        review_notes, 
        created_at, 
        updated_at, 
        tenant_id,
        full_name,
        street_name,
        street_number,
        phone,
        dismissed,
        association_settings:tenant_id(name)
      `)
      .eq('user_id', user.id)
      .eq('dismissed', false)
      .order('created_at', { ascending: false })
      .maybeSingle();

    if (joinRequestError && joinRequestError.code !== 'PGRST116') {
      console.error('Error fetching join request:', joinRequestError);
      return;
    }

    if (joinRequestData) {
      const { data: settingsData, error: settingsError } = await supabase
        .from('association_settings')
        .select('name')
        .eq('tenant_id', joinRequestData.tenant_id)
        .single();

      if (settingsError) {
        console.error('Error fetching association settings:', settingsError);
        return;
      }

      const fullJoinRequest: JoinRequest = {
        ...joinRequestData,
        status: joinRequestData.status as 'pending' | 'approved' | 'rejected',
        association_settings: settingsData
      };

      setJoinRequest(fullJoinRequest);
    }
  };

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!user) return;
      
      const { data: profile } = await supabase
        .from('profiles')
        .select('is_super_admin')
        .eq('id', user.id)
        .single();
      
      setIsAppAdmin(!!profile?.is_super_admin);
    };

    const fetchCreationRequest = async () => {
      if (!user) return;

      const { data: creationRequestData, error: creationRequestError } = await supabase
        .from('association_creation_requests')
        .select('id, status, review_notes, created_at, updated_at, name, cvr_number')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .maybeSingle();

      if (creationRequestError && creationRequestError.code !== 'PGRST116') {
        console.error('Error fetching creation request:', creationRequestError);
        return;
      }

      if (creationRequestData) {
        const typedCreationRequest: CreationRequest = {
          ...creationRequestData,
          status: creationRequestData.status as 'pending' | 'approved' | 'rejected'
        };
        setCreationRequest(typedCreationRequest);
      }
    };

    checkAdminStatus();
    fetchJoinRequest();
    fetchCreationRequest();
  }, [user]);

  const handleDismiss = () => {
    setJoinRequest(null);
  };

  return (
    <div className="max-w-2xl mx-auto text-center space-y-8 mt-12">
      <WelcomeHeader />
      {creationRequest && <CreationRequestCard creationRequest={creationRequest} />}
      {joinRequest && <JoinRequestCard joinRequest={joinRequest} onDismiss={handleDismiss} />}
      <ActionButtons hasCreationRequest={!!creationRequest} />
      <AboutCard />
    </div>
  );
};
