
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Clock, Download, FileText } from "lucide-react";
import { format, parseISO } from "date-fns";
import { useStatuteDocuments } from "@/hooks/useStatuteDocuments";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

export function StatuteHistoryButton() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button 
        variant="outline" 
        onClick={() => setOpen(true)}
        size="sm"
        className="flex items-center gap-1"
      >
        <Clock className="h-4 w-4" />
        {t("settings.statutes.viewHistory")}
      </Button>
      <StatuteHistoryDialog open={open} onOpenChange={setOpen} />
    </>
  );
}

interface StatuteHistoryDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function StatuteHistoryDialog({ open, onOpenChange }: StatuteHistoryDialogProps) {
  const { t } = useTranslation();
  const { data: documents, isLoading } = useStatuteDocuments();

  const handleDownload = async (filePath: string, fileName: string) => {
    try {
      const { data, error } = await supabase.storage
        .from('documents')
        .download(filePath);
      
      if (error) throw error;
      
      const url = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading statute document:", error);
      toast.error(t("settings.statutes.downloadError"));
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>{t("settings.statutes.historyTitle")}</DialogTitle>
          <DialogDescription>
            {t("settings.statutes.historyDescription")}
          </DialogDescription>
        </DialogHeader>
        
        <div className="space-y-4 max-h-[60vh] overflow-y-auto py-2">
          {isLoading ? (
            <div className="text-center p-4 text-muted-foreground">
              {t("common.loading")}...
            </div>
          ) : documents && documents.length > 0 ? (
            documents.map((doc) => (
              <div 
                key={doc.id} 
                className="flex items-center space-x-3 border p-3 rounded-md"
              >
                <FileText className="h-5 w-5 text-primary" />
                <div className="flex-1 min-w-0">
                  <p className="font-medium truncate">{doc.title || doc.file_name}</p>
                  <p className="text-xs text-muted-foreground">
                    {t("settings.statutes.adoptedOn")}: {format(parseISO(doc.adoption_date), 'PPP')}
                  </p>
                </div>
                <Button
                  size="sm"
                  variant="ghost"
                  onClick={() => handleDownload(doc.file_path, doc.file_name)}
                >
                  <Download className="h-4 w-4" />
                </Button>
              </div>
            ))
          ) : (
            <div className="text-center p-4 text-muted-foreground">
              {t("settings.statutes.noHistoryFound")}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
