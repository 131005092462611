
import { useTranslation } from "react-i18next";
import { PageContainer } from "@/components/PageContainer";
import { PageHeader } from "@/components/PageHeader";

export default function Tasks() {
  const { t } = useTranslation();
  
  return (
    <PageContainer>
      <PageHeader 
        title={t("menu.tasks")} 
        subtitle={t("tasks.subtitle")}
      />
      {/* Content will be added in the future */}
    </PageContainer>
  );
}
