
import { useState, useEffect } from "react";
import { Loader2, PlusSquare, Check } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useTenant } from "@/contexts/TenantContext";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { StoredStreetData } from "@/types/street";
import { toast as sonnerToast } from "sonner";
import { Progress } from "@/components/ui/progress";

interface CadastreDialogProps {
  street: StoredStreetData | null;
  isOpen: boolean;
  onClose: () => void;
}

interface AddressData {
  adressebetegnelse: string;
  adgangsadresse: {
    vejstykke: {
      navn: string;
    };
    husnr: string;
    matrikelnr: string;
  };
}

interface ExistingCadastre {
  id: string;
  plot_number: string | null;
  street_name: string;
  street_number: string;
}

export const CadastreDialog = ({ street, isOpen, onClose }: CadastreDialogProps) => {
  const { toast } = useToast();
  const { currentTenant } = useTenant();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [addresses, setAddresses] = useState<AddressData[]>([]);
  const [existingCadastres, setExistingCadastres] = useState<ExistingCadastre[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [totalToProcess, setTotalToProcess] = useState(0);
  const [processedCount, setProcessedCount] = useState(0);
  const [localIsOpen, setLocalIsOpen] = useState(isOpen);
  const [isComplete, setIsComplete] = useState(false);
  const [processingResult, setProcessingResult] = useState<{
    newCount: number;
    updateCount: number;
  } | null>(null);
  
  useEffect(() => {
    setLocalIsOpen(isOpen);
    if (isOpen && street) {
      // Reset states when dialog opens
      setIsComplete(false);
      setProcessingResult(null);
      setProgress(0);
      setProcessedCount(0);
      fetchAddresses();
      fetchExistingCadastres();
    }
  }, [isOpen, street]);

  const fetchAddresses = async () => {
    if (!street || !street.kommune_kode) return;
    
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://api.dataforsyningen.dk/adresser?vejnavn=${encodeURIComponent(street.street_name)}&kommunekode=${street.kommune_kode}`
      );
      
      if (!response.ok) {
        throw new Error("Failed to fetch addresses");
      }
      
      const data = await response.json();
      setAddresses(data);
    } catch (error) {
      console.error("Error fetching addresses:", error);
      toast({
        title: "Error",
        description: "Failed to fetch addresses",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchExistingCadastres = async () => {
    if (!currentTenant || !street) return;
    
    try {
      const { data, error } = await supabase
        .from('cadastres')
        .select('id, plot_number, street_name, street_number')
        .eq('tenant_id', currentTenant.id)
        .eq('street_name', street.street_name);
      
      if (error) throw error;
      
      setExistingCadastres(data);
    } catch (error) {
      console.error("Error fetching existing cadastres:", error);
      toast({
        title: "Error",
        description: "Failed to fetch existing cadastres",
        variant: "destructive",
      });
    }
  };

  const handleDialogClose = () => {
    // Only allow closing if not currently processing
    if (!isCreating) {
      setLocalIsOpen(false);
      onClose();
    }
  };

  const handleCreateCadastres = async () => {
    // If processing is complete and the button is clicked, just close the dialog
    if (isComplete) {
      setLocalIsOpen(false);
      onClose();
      return;
    }

    if (!currentTenant || !street || addresses.length === 0) return;
    
    setIsCreating(true);
    setProgress(0);
    setProcessedCount(0);
    setIsComplete(false);
    
    try {
      // Group addresses by their plot numbers to avoid duplicates
      const addressesByPlotNumber = new Map<string, AddressData>();
      const addressesByStreetNumber = new Map<string, AddressData>();
      
      // First pass: group by plot number and street number
      addresses.forEach(address => {
        const plotNumber = address.adgangsadresse?.matrikelnr;
        const streetNumber = address.adgangsadresse?.husnr;
        
        if (plotNumber) {
          addressesByPlotNumber.set(plotNumber, address);
        }
        
        if (streetNumber) {
          addressesByStreetNumber.set(streetNumber, address);
        }
      });
      
      // Prepare data for updates and inserts
      const toUpdate: { id: string, plot_number: string }[] = [];
      const toInsert: { tenant_id: string, street_name: string, street_number: string, plot_number: string }[] = [];
      
      // Check for existing cadastres that need updating (have street number but no plot number)
      existingCadastres.forEach(cadastre => {
        // If this cadastre has no plot number but we have plot data for this street number
        if (!cadastre.plot_number && addressesByStreetNumber.has(cadastre.street_number)) {
          const address = addressesByStreetNumber.get(cadastre.street_number);
          const plotNumber = address?.adgangsadresse?.matrikelnr;
          
          if (plotNumber) {
            toUpdate.push({
              id: cadastre.id,
              plot_number: plotNumber
            });
            
            // Remove this plot number from consideration for new inserts
            addressesByPlotNumber.delete(plotNumber);
          }
        }
      });
      
      // Find existing plot numbers to exclude from insertion
      const existingPlotNumbers = new Set(
        existingCadastres.map(c => c.plot_number).filter(Boolean)
      );
      
      // Prepare new cadastres for insertion
      for (const [plotNumber, address] of addressesByPlotNumber.entries()) {
        if (!existingPlotNumbers.has(plotNumber)) {
          toInsert.push({
            tenant_id: currentTenant.id,
            street_name: street.street_name,
            street_number: address.adgangsadresse.husnr,
            plot_number: plotNumber,
          });
        }
      }
      
      const totalItems = toUpdate.length + toInsert.length;
      setTotalToProcess(totalItems);
      
      if (totalItems === 0) {
        toast({
          title: "Info",
          description: "No new cadastres to create or update",
        });
        setIsCreating(false);
        return;
      }
      
      // Show a toast notification that the process is starting
      sonnerToast(`Processing ${totalItems} cadastres...`);
      
      // First update existing cadastres
      if (toUpdate.length > 0) {
        for (let i = 0; i < toUpdate.length; i++) {
          const item = toUpdate[i];
          const { error } = await supabase
            .from('cadastres')
            .update({ plot_number: item.plot_number })
            .eq('id', item.id);
          
          if (error) {
            console.error("Error updating cadastre:", error);
            throw error;
          }
          
          // Update progress
          const newProcessed = i + 1;
          setProcessedCount(newProcessed);
          setProgress((newProcessed / totalItems) * 100);
          
          // Small delay to avoid overwhelming the database
          await new Promise(resolve => setTimeout(resolve, 10));
        }
      }
      
      // Then insert new cadastres - do them one by one to update progress properly
      if (toInsert.length > 0) {
        const updatedCount = toUpdate.length;
        
        for (let i = 0; i < toInsert.length; i++) {
          const item = toInsert[i];
          
          // Log the item we're about to insert for debugging
          console.log("Inserting cadastre:", item);
          
          const { error } = await supabase
            .from('cadastres')
            .insert([item]);
          
          if (error) {
            console.error("Error inserting cadastre:", error);
            throw error;
          }
          
          // Update progress
          const newProcessed = updatedCount + i + 1;
          setProcessedCount(newProcessed);
          setProgress((newProcessed / totalItems) * 100);
          
          // Small delay to avoid overwhelming the database
          await new Promise(resolve => setTimeout(resolve, 10));
        }
      }
      
      // Invalidate the cadastres query cache to force a refresh
      queryClient.invalidateQueries({ queryKey: ['cadastres'] });
      
      toast({
        title: "Success",
        description: `Created ${toInsert.length} new cadastres and updated ${toUpdate.length} existing cadastres`,
      });
      
      // Instead of closing the dialog, set processing as complete
      setIsComplete(true);
      setProcessingResult({
        newCount: toInsert.length,
        updateCount: toUpdate.length
      });
    } catch (error) {
      console.error("Error creating/updating cadastres:", error);
      toast({
        title: "Error",
        description: "Failed to create/update cadastres",
        variant: "destructive",
      });
    } finally {
      setIsCreating(false);
    }
  };

  const getNewCadastreCount = () => {
    const uniquePlotNumbers = new Set<string>();
    const existingPlotNumbers = new Set(
      existingCadastres.map(c => c.plot_number).filter(Boolean)
    );
    const existingStreetNumbers = new Map<string, ExistingCadastre>();
    
    // Group existing cadastres by street number for easy lookup
    existingCadastres.forEach(cadastre => {
      if (!cadastre.plot_number) {
        existingStreetNumbers.set(cadastre.street_number, cadastre);
      }
    });
    
    // Count new cadastres to create and existing to update
    addresses.forEach(address => {
      const plotNumber = address.adgangsadresse?.matrikelnr;
      const streetNumber = address.adgangsadresse?.husnr;
      
      if (plotNumber && !existingPlotNumbers.has(plotNumber)) {
        // Either this is a completely new cadastre, or it's an existing one without a plot number
        const existingCadastre = existingStreetNumbers.get(streetNumber);
        
        if (!existingCadastre) {
          // This is a completely new cadastre
          uniquePlotNumbers.add(plotNumber);
        }
        // If there is an existing cadastre with this street number, we'll update it instead
      }
    });
    
    return uniquePlotNumbers.size;
  };

  const getUpdateCadastreCount = () => {
    const toUpdateCount = existingCadastres.filter(cadastre => {
      // If this cadastre has no plot number
      if (!cadastre.plot_number) {
        // Check if we have address data with this street number
        return addresses.some(address => 
          address.adgangsadresse?.husnr === cadastre.street_number &&
          address.adgangsadresse?.matrikelnr
        );
      }
      return false;
    }).length;
    
    return toUpdateCount;
  };

  if (!street) return null;

  return (
    <Dialog open={localIsOpen} onOpenChange={handleDialogClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>
            {t("settings.streets.cadastreDialog.title", "Create Cadastres")}
          </DialogTitle>
          <DialogDescription>
            {t("settings.streets.cadastreDialog.subtitle", "Create cadastres for all addresses on this street.")}
          </DialogDescription>
        </DialogHeader>
        
        {isLoading ? (
          <div className="flex justify-center p-6">
            <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
          </div>
        ) : isCreating ? (
          <div className="space-y-4 py-4">
            <div className="text-center mb-4">
              <p className="text-sm text-muted-foreground mb-1">
                {t("settings.streets.cadastreDialog.processing", "Processing cadastres...")}
              </p>
              <p className="text-xl font-semibold">
                {processedCount} / {totalToProcess}
              </p>
            </div>
            <Progress value={progress} className="w-full" />
          </div>
        ) : isComplete ? (
          <div className="space-y-4 py-4">
            <div className="text-center mb-4">
              <div className="flex justify-center mb-2">
                <Check className="h-10 w-10 text-green-500" />
              </div>
              <p className="text-xl font-semibold text-green-600 mb-2">
                {t("settings.streets.cadastreDialog.complete", "Complete")}
              </p>
              <p className="text-sm text-muted-foreground">
                {t("settings.streets.cadastreDialog.processedSummary", "Processed {{total}} cadastres", { 
                  total: processingResult ? processingResult.newCount + processingResult.updateCount : 0 
                })}
              </p>
              {processingResult && (
                <div className="mt-4 grid grid-cols-2 gap-4">
                  <div className="border rounded p-3 text-center">
                    <p className="text-sm text-muted-foreground mb-1">
                      {t("settings.streets.cadastreDialog.created", "Created")}
                    </p>
                    <p className="text-2xl font-bold">{processingResult.newCount}</p>
                  </div>
                  <div className="border rounded p-3 text-center">
                    <p className="text-sm text-muted-foreground mb-1">
                      {t("settings.streets.cadastreDialog.updated", "Updated")}
                    </p>
                    <p className="text-2xl font-bold">{processingResult.updateCount}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="space-y-4 py-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="border rounded p-3 text-center">
                <p className="text-sm text-muted-foreground mb-1">
                  {t("settings.streets.cadastreDialog.newCadastres", "New Cadastres")}
                </p>
                <p className="text-2xl font-bold">{getNewCadastreCount()}</p>
              </div>
              <div className="border rounded p-3 text-center">
                <p className="text-sm text-muted-foreground mb-1">
                  {t("settings.streets.cadastreDialog.toUpdate", "To Update")}
                </p>
                <p className="text-2xl font-bold">{getUpdateCadastreCount()}</p>
              </div>
            </div>
            
            <div className="border rounded p-3 text-center">
              <p className="text-sm text-muted-foreground mb-1">
                {t("settings.streets.cadastreDialog.existing", "Already Registered")}
              </p>
              <p className="text-2xl font-bold">{existingCadastres.filter(c => c.plot_number).length}</p>
            </div>
          </div>
        )}
        
        <DialogFooter>
          {!isComplete && (
            <Button variant="outline" onClick={handleDialogClose} disabled={isCreating}>
              {t("common.cancel")}
            </Button>
          )}
          <Button 
            onClick={handleCreateCadastres} 
            disabled={isLoading || isCreating || (!isComplete && getNewCadastreCount() === 0 && getUpdateCadastreCount() === 0)}
          >
            {isCreating && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            {isComplete ? 
              t("common.done", "Done") : 
              `${t("settings.streets.cadastreDialog.processButton", "Process")} ${getNewCadastreCount() + getUpdateCadastreCount()} ${t("settings.streets.cadastreDialog.cadastres", "cadastres")}`
            }
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
