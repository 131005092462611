
import md5 from "md5";

export const getGravatarName = async (email: string): Promise<string | null> => {
  try {
    const hash = md5(email.trim().toLowerCase());
    const response = await fetch(`https://en.gravatar.com/${hash}.json`);
    
    if (response.status === 200) {
      const data = await response.json();
      return data.entry[0].displayName || data.entry[0].name?.formatted || null;
    }
    return null;
  } catch (error) {
    console.log("Error fetching Gravatar profile:", error);
    return null;
  }
};
