
import { format } from "date-fns";
import { AlertTriangle, CheckCircle2, XCircle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import type { BankAccessToken } from "@/hooks/useBankSettings";

interface BankConnectionStatusProps {
  isConnected: boolean;
  bankAccess: BankAccessToken | null;
}

export function BankConnectionStatus({ isConnected, bankAccess }: BankConnectionStatusProps) {
  if (!bankAccess) {
    return (
      <Alert variant="destructive" className="bg-red-50 border-red-200">
        <XCircle className="h-4 w-4 text-red-600" />
        <AlertTitle className="text-red-800">Not Connected</AlertTitle>
        <AlertDescription className="text-red-700">
          Your bank account is not connected. Configure your redirect URI and market, then click "Connect to Bank" to establish a connection.
        </AlertDescription>
      </Alert>
    );
  }

  // Check token expiration
  const accessExpires = new Date(bankAccess.access_expires);
  const refreshExpires = new Date(bankAccess.refresh_expires);
  const now = new Date();
  
  // Access token is expired but refresh token is still valid
  if (!isConnected && refreshExpires > now) {
    return (
      <Alert className="bg-amber-50 border-amber-200">
        <AlertTriangle className="h-4 w-4 text-amber-600" />
        <AlertTitle className="text-amber-800">Connection Needs Refresh</AlertTitle>
        <AlertDescription className="text-amber-700">
          Your access token has expired but can be refreshed. Please reconnect to your bank to refresh your access.
        </AlertDescription>
      </Alert>
    );
  }
  
  // Both tokens are expired
  if (!isConnected) {
    return (
      <Alert variant="destructive" className="bg-red-50 border-red-200">
        <XCircle className="h-4 w-4 text-red-600" />
        <AlertTitle className="text-red-800">Connection Expired</AlertTitle>
        <AlertDescription className="text-red-700">
          Your bank connection has expired. Please reconnect to your bank to continue accessing your account data.
        </AlertDescription>
      </Alert>
    );
  }
  
  // Valid connection
  return (
    <Alert className="bg-green-50 border-green-200">
      <CheckCircle2 className="h-4 w-4 text-green-600" />
      <AlertTitle className="text-green-800">Connected</AlertTitle>
      <AlertDescription className="text-green-700">
        Your bank account is successfully connected. Access token expires on {format(accessExpires, "PPP 'at' p")}.
      </AlertDescription>
    </Alert>
  );
}
