
import { useState } from "react";
import { PageContainer } from "@/components/PageContainer";
import { PageHeader } from "@/components/PageHeader";
import { Card } from "@/components/ui/card";
import { MemberSearch } from "@/components/members/MemberSearch";
import { useMembers } from "@/hooks/useMembers";
import { useTranslation } from "react-i18next";
import { useJoinRequests } from "@/hooks/useJoinRequests";
import { IfCanCreate } from "@/components/auth/PermissionGuard";
import { MemberActionButtons } from "@/components/members/MemberActionButtons";
import { MemberTable } from "@/components/members/MemberTable";
import { Member } from "@/types/member";

const Members = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { members, isLoading, addMember, updateMember, deleteMember } = useMembers();
  const { pendingRequests, joinRequests, handleJoinRequest } = useJoinRequests();
  const { t } = useTranslation();
  const [newMemberData, setNewMemberData] = useState<Partial<Member> | null>(null);

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  return (
    <PageContainer>
      <div className="space-y-6 animate-fadeIn">
        <PageHeader
          title={t("members.title")}
          subtitle={t("members.subtitle")}
        >
          <IfCanCreate 
            resource="member"
            fallback={null}
          >
            <MemberActionButtons
              members={members}
              pendingRequests={pendingRequests}
              joinRequests={joinRequests}
              addMember={addMember}
              handleJoinRequest={handleJoinRequest}
              newMemberData={newMemberData}
              setNewMemberData={setNewMemberData}
            />
          </IfCanCreate>
        </PageHeader>

        <Card>
          <MemberSearch 
            searchQuery={searchQuery}
            onSearchChange={handleSearchChange}
          />

          <MemberTable 
            members={members}
            isLoading={isLoading}
            searchQuery={searchQuery}
            onUpdateMember={updateMember}
            onDeleteMember={deleteMember}
          />
        </Card>
      </div>
    </PageContainer>
  );
};

export default Members;
