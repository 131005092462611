
import { useState, useEffect } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Loader2, ExternalLink, AlertCircle, CheckCircle2 } from "lucide-react";
import { Link } from "react-router-dom";
import { useUrlNameCheck } from "@/hooks/useUrlNameCheck";

interface UrlNameFieldProps {
  urlName: string;
  onChange: (value: string) => void;
  tenantId?: string;
  existingUrlName?: string;
}

export function UrlNameField({ urlName, onChange, tenantId, existingUrlName }: UrlNameFieldProps) {
  const { isChecking, isAvailable, checkUrlNameAvailability } = useUrlNameCheck();
  const [urlNameChanged, setUrlNameChanged] = useState(false);
  
  const hostname = window.location.hostname;
  const protocol = window.location.protocol;
  const baseUrl = `${protocol}//${hostname}/t/`;

  useEffect(() => {
    // Reset the urlNameChanged flag when the component receives a new urlName from props
    // that matches the existing one
    if (urlName === existingUrlName) {
      setUrlNameChanged(false);
    }
  }, [urlName, existingUrlName]);

  const handleUrlNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase().trim().replace(/[^a-z0-9-]/g, '');
    onChange(value);
    setUrlNameChanged(true);
    checkUrlNameAvailability(value, tenantId);
  };

  return (
    <div className="space-y-2">
      <Label className="text-left block" htmlFor="urlName">Public Link</Label>
      <div className="flex items-center space-x-2">
        <div className="relative flex-grow flex items-center border rounded-md bg-muted">
          <span className="pl-3 text-sm text-muted-foreground whitespace-nowrap">{baseUrl}</span>
          <Input
            id="urlName"
            value={urlName}
            onChange={handleUrlNameChange}
            placeholder="your-association"
            className={`border-0 pl-0 ${
              urlNameChanged && isAvailable === true ? "border-green-500" : 
              urlNameChanged && isAvailable === false ? "border-red-500" : ""
            }`}
          />
          {isChecking && (
            <Loader2 className="w-4 h-4 absolute right-3 animate-spin text-muted-foreground" />
          )}
          {!isChecking && urlNameChanged && isAvailable === true && (
            <CheckCircle2 className="w-4 h-4 absolute right-3 text-green-500" />
          )}
          {!isChecking && urlNameChanged && isAvailable === false && (
            <AlertCircle className="w-4 h-4 absolute right-3 text-red-500" />
          )}
        </div>
        {existingUrlName && (
          <Link to={`/t/${existingUrlName}`}>
            <Button variant="outline" size="sm" className="gap-1 whitespace-nowrap">
              <ExternalLink className="h-4 w-4" />
              View
            </Button>
          </Link>
        )}
      </div>
      {urlNameChanged && isAvailable === false && (
        <Alert variant="destructive" className="mt-2">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            This URL name is already taken. Please choose another one.
          </AlertDescription>
        </Alert>
      )}
    </div>
  );
}
