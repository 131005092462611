
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import QRCode from 'qrcode';
import i18n from '@/i18n/config';
import MarkdownIt from 'markdown-it';

// Import language templates
import enTemplate from '../i18n/templates/en.md?raw';
import daTemplate from '../i18n/templates/da.md?raw';

// Available fonts in jsPDF
// Courier
// Courier-Bold
// Courier-BoldOblique
// Courier-Oblique
// Helvetica
// Helvetica-Bold
// Helvetica-BoldOblique
// Helvetica-Oblique
// Symbol
// Times-Roman
// Times-Bold
// Time-Italic
// Time-BoldItalic
const font = 'times-roman';  // 'helvetica' is the default font in jsPDF
/**
 * Gets the markdown template for the current language
 * @returns The markdown template as string
 */
function getLanguageTemplate(): string {
  const currentLanguage = i18n.language;
  switch (currentLanguage) {
    case 'da':
      return daTemplate;
    case 'en':
    default:
      return enTemplate;
  }
}

/**
 * Generates QR code PDFs for multiple cadastres
 * @param cadastres - Array of cadastres with onboarding codes 
 * @param onProgress - Optional callback to track progress
 * @returns Promise indicating completion
 */
export async function generateQRCodePDF(
  cadastres: Array<{
    id: string;
    streetName: string;
    streetNumber: string;
    onboarding_code?: string;
  }>,
  onProgress?: (completed: number, total: number) => void
): Promise<void> {
  // Filter out cadastres with no onboarding code
  const validCadastres = cadastres.filter(c => !!c.onboarding_code);
  
  if (validCadastres.length === 0) {
    throw new Error('No cadastres with onboarding codes found');
  }
  
  // Create a zip file to hold all PDFs
  const zip = new JSZip();
  let completed = 0;
  
  try {
    // Create a PDF for each cadastre
    for (const cadastre of validCadastres) {
      // Skip cadastres without onboarding codes (shouldn't happen due to filter above)
      if (!cadastre.onboarding_code) continue;
      
      // Generate the PDF
      const pdf = await generateSinglePDF(cadastre);
      
      // Add the PDF to the zip file
      const fileName = `${cadastre.streetName}_${cadastre.streetNumber}_onboarding.pdf`;
      zip.file(fileName, pdf.output('blob'));
      
      // Update progress
      completed++;
      if (onProgress) {
        onProgress(completed, validCadastres.length);
      }
      
      // Small delay to prevent UI freezing
      await new Promise(resolve => setTimeout(resolve, 30));
    }
    
    // Generate and save the zip file
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, 'onboarding_qr_codes.zip');
    
    return Promise.resolve();
  } catch (error) {
    console.error('Error generating PDFs:', error);
    return Promise.reject(error);
  }
}

/**
 * Generates a PDF for a single cadastre
 * @param cadastre - Cadastre with onboarding code
 * @returns PDF document
 */
async function generateSinglePDF(cadastre: {
  streetName: string;
  streetNumber: string;
  onboarding_code?: string;
}): Promise<jsPDF> {
  // Create new PDF document
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4'
  });
  
  // Calculate QR code URL
  const manualJoinUrl = `${window.location.origin}/tenants/onboarding-code/`;
  const qrCodeUrl = `${window.location.origin}/tenants/join-with-code/${cadastre.onboarding_code}`;
  
  // Generate QR code as data URL
  const qrCodeDataUrl = await QRCode.toDataURL(qrCodeUrl, {
    errorCorrectionLevel: 'H',
    margin: 1,
    width: 300,
    color: {
      dark: '#000000',
      light: '#FFFFFF'
    }
  });
  
  // Get the appropriate template for the current language
  const template = getLanguageTemplate();
  
  // Replace placeholders in the template
  const content = template
    .replace(/\${STREET_NAME}/g, cadastre.streetName)
    .replace(/\${STREET_NUMBER}/g, cadastre.streetNumber)
    .replace(/\${ONBOARDING_CODE}/g, cadastre.onboarding_code || '')
    .replace(/\${QR_CODE_URL}/g, qrCodeUrl)
    .replace(/\${MANUAL_CODE_URL}/g, manualJoinUrl);
    
  // Initialize markdown parser
  const md = new MarkdownIt();
  
  // Parse markdown to tokens
  const tokens = md.parse(content, {});
  
  // Set initial position
  let y = 20;
  const leftMargin = 20;
  const rightMargin = 190;
  const pageWidth = rightMargin - leftMargin;
  
  // Track if we've rendered the QR code
  let qrCodeRendered = false;
  
  // Process tokens and render to PDF
  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    
    if (token.type === 'heading_open') {
      const level = parseInt(token.tag.substring(1));
      const contentToken = tokens[i + 1];
      
      // Set font size based on heading level
      if (level === 1) {
        doc.setFontSize(24);
        doc.setFont(font, 'bold');
      } else if (level === 2) {
        doc.setFontSize(18);
        doc.setFont(font, 'bold');
      } else {
        doc.setFontSize(14);
        doc.setFont(font, 'bold');
      }
      
      // Add heading text
      if (contentToken && contentToken.content) {
        doc.text(contentToken.content, leftMargin, y);
        switch (level) {
          case 1:
            y += 15;
            break;
          case 2:
            y += 10;
            break;
          default:
            y += 8;
            break;
        }
      }
      
      // Skip the next two tokens (heading content and heading close)
      i += 2;
    } 
    else if (token.type === 'paragraph_open') {
      doc.setFontSize(12);
      doc.setFont(font, 'normal');
      
      const contentToken = tokens[i + 1];
      
      // Add paragraph text
      if (contentToken && contentToken.content) {
        // Special handling for QR code placeholder
        if (contentToken.content.includes('![qrcode](qrcode)') && !qrCodeRendered) {
          // Add QR code image
          doc.addImage(qrCodeDataUrl, 'PNG', (leftMargin + rightMargin) / 2 - 30, y+5, 40, 40);
          qrCodeRendered = true;
          y += 60; // Space for QR code
        } else {
          // Normal paragraph text
          const lines = doc.splitTextToSize(contentToken.content, pageWidth);
          doc.text(lines, leftMargin, y);
          y += lines.length * 7; // Adjust spacing based on number of lines
        }
      }
      
      // Skip the next two tokens (paragraph content and paragraph close)
      i += 2;
    }
    else if (token.type === 'bullet_list_open') {
      // Process bullet list
      let j = i + 1;
      while (j < tokens.length && tokens[j].type !== 'bullet_list_close') {
        if (tokens[j].type === 'list_item_open') {
          // Find content within list item
          let k = j + 1;
          while (k < tokens.length && tokens[k].type !== 'list_item_close') {
            if (tokens[k].type === 'paragraph_open') {
              const contentToken = tokens[k + 1];
              if (contentToken && contentToken.content) {
                doc.setFontSize(12);
                doc.setFont(font, 'normal');
                // Add bullet point
                const bulletText = "• " + contentToken.content;
                const lines = doc.splitTextToSize(bulletText, pageWidth - 5);
                doc.text(lines, leftMargin, y);
                y += lines.length * 7;
              }
              k += 2; // Skip paragraph content and close
            } else {
              k++;
            }
          }
          j = k + 1; // Move past list_item_close
        } else {
          j++;
        }
      }
      
      // Skip to after bullet_list_close
      i = j;
    }
    else if (token.type === 'strong_open') {
      doc.setFont(font, 'bold');
      const contentToken = tokens[i + 1];
      
      if (contentToken && contentToken.content) {
        // If this is the onboarding code, make it larger
        if (contentToken.content.includes(cadastre.onboarding_code || '')) {
          doc.setFontSize(16);
          doc.text(contentToken.content, (leftMargin + rightMargin) / 2, y, { align: 'center' });
          y += 10;
        } else {
          doc.setFontSize(12);
          doc.text(contentToken.content, leftMargin, y);
          y += 7;
        }
      }
      
      doc.setFont(font, 'normal');
      i += 2; // Skip strong content and close
    }
    else if (token.type === 'hr') {
      // Add horizontal line
      doc.setDrawColor(200, 200, 200);
      doc.line(leftMargin, y, rightMargin, y);
      y += 7;
    }
    else if (token.type === 'softbreak' || token.type === 'hardbreak') {
      y += 7; // Add line break
    }
    
    // Add a small gap between elements
    if (token.type.endsWith('_close') && !token.type.includes('paragraph') && !token.type.includes('heading')) {
      y += 3;
    }
    
    // Check if we need a new page
    if (y > 270) {
      doc.addPage();
      y = 20;
    }
  }
  
  return doc;
}
