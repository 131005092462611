
import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { FileText, Download, Upload, FilePlus } from "lucide-react";
import { StatuteHistoryButton } from "./StatuteHistoryDialog";
import { format, parseISO } from "date-fns";

interface StatutesFileCardProps {
  statuteFile: { path: string; name: string } | null;
  isAdmin: boolean;
  isUploading: boolean;
  onUploadClick: () => void;
  onDownloadClick: () => void;
  adoptionDate?: string | null;
}

export function StatutesFileCard({ 
  statuteFile, 
  isAdmin, 
  isUploading, 
  onUploadClick, 
  onDownloadClick,
  adoptionDate
}: StatutesFileCardProps) {
  const { t } = useTranslation();

  // Format the adoption date if it exists
  const formattedDate = adoptionDate ? format(parseISO(adoptionDate), 'PPP') : '';

  return (
    <Card>
      <CardHeader >
          <CardTitle>{t("settings.statutes.fileTitle")}</CardTitle>
          <CardDescription>{t("settings.statutes.fileDescription")}</CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col space-y-4">
        {statuteFile ? (
          <div className="flex flex-col space-y-4">
            <div className="flex items-center space-x-4 border p-4 rounded-md ">
              <FileText className="h-8 w-8 text-primary" />
              <div className="flex-1 flex flex-row items-center justify-evenly">
                <div className="font-medium">{t("settings.statutes.fileTitle")}</div>
                <div className="font-light">
                  {formattedDate ? `${t("settings.statutes.adoptedOn")}: ${formattedDate}` : t("settings.statutes.noAdoptionDate")}
                </div>
              </div>
              <StatuteHistoryButton />
              <Button
                variant="outline"
                size="icon"
                onClick={onDownloadClick}
                title={t("common.download")}
              >
                <Download className="h-4 w-4" />
              </Button>
              {isAdmin && (
                <Button 
                  onClick={onUploadClick} 
                  variant="outline"
                  size="icon"
                  title={t("settings.statutes.addNew")}
                >
                  <FilePlus className="h-4 w-4" />
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className="flex flex-row gap-x-6 items-center justify-center p-8 border border-dashed rounded-md">
            <Upload className="h-10 w-10 text-muted-foreground mb-2" />
            <p className="text-muted-foreground mb-4 contents">  
              {t("settings.statutes.noFileUploaded")}
            </p>
            {isAdmin && (
              <Button 
                onClick={onUploadClick} 
                disabled={isUploading}
              >
                {isUploading ? t("common.uploading") : t("settings.statutes.uploadStatutes")}
              </Button>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
}
