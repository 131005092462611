
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { DocumentFolder, DocumentFile } from "@/types/document";
import { useTenant } from "@/contexts/TenantContext";
import { toast } from "sonner";
import { useAuth } from "@/contexts/AuthContext";

export function useDocuments() {
  const { currentTenant } = useTenant();
  const { user } = useAuth();
  const queryClient = useQueryClient();

  // Get all root folders (no parent_id)
  const { data: rootFolders = [], isLoading: isLoadingFolders } = useQuery({
    queryKey: ['folders', 'root', currentTenant?.id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('document_folders')
        .select('*')
        .is('parent_id', null)
        .eq('tenant_id', currentTenant?.id)
        .order('name');
      
      if (error) {
        toast.error("Failed to load folders");
        throw error;
      }
      
      return data as DocumentFolder[];
    },
    enabled: !!currentTenant?.id,
  });

  // Get subfolders by parent ID
  const getSubfolders = async (parentId: string | null) => {
    try {
      const query = supabase
        .from('document_folders')
        .select('*')
        .eq('tenant_id', currentTenant?.id)
        .order('name');
      
      if (parentId === null) {
        // Get root folders
        query.is('parent_id', null);
      } else {
        // Get subfolders for a specific folder
        query.eq('parent_id', parentId);
      }
      
      const { data, error } = await query;
      
      if (error) {
        toast.error("Failed to load folders");
        throw error;
      }
      
      return data as DocumentFolder[];
    } catch (error) {
      console.error('Error fetching subfolders:', error);
      toast.error("Failed to load folders");
      return [];
    }
  };

  // Get folder by ID
  const getFolder = async (folderId: string) => {
    const { data, error } = await supabase
      .from('document_folders')
      .select('*')
      .eq('id', folderId)
      .eq('tenant_id', currentTenant?.id)
      .single();
    
    if (error) {
      if (error.code !== 'PGRST116') { // No rows returned
        toast.error("Failed to load folder");
      }
      throw error;
    }
    
    return data as DocumentFolder;
  };

  // Get files by folder ID
  const getFilesByFolder = async (folderId: string) => {
    try {
      let query = supabase
        .from('document_files')
        .select('*')
        .eq('tenant_id', currentTenant?.id)
        .order('name');
      
      if (folderId === 'root') {
        // For root, get files without a folder (null folder_id)
        query = query.is('folder_id', null);
      } else {
        // For specific folder
        query = query.eq('folder_id', folderId);
      }
      
      const { data, error } = await query;
      
      if (error) {
        toast.error("Failed to load files");
        throw error;
      }
      
      return data as DocumentFile[];
    } catch (error) {
      console.error('Error fetching files:', error);
      toast.error("Failed to load files");
      return [];
    }
  };

  // Create a new folder
  const createFolder = async ({ name, parentId, description }: { name: string; parentId: string | null; description?: string }) => {
    try {
      const { data, error } = await supabase
        .from('document_folders')
        .insert([{
          name,
          parent_id: parentId,
          description: description || null,
          tenant_id: currentTenant?.id
        }])
        .select()
        .single();

      if (error) throw error;

      // Invalidate related queries to ensure UI updates
      queryClient.invalidateQueries({ queryKey: ['folders'] });
      
      if (parentId) {
        queryClient.invalidateQueries({ queryKey: ['folders', 'sub', parentId] });
        queryClient.invalidateQueries({ queryKey: ['breadcrumbs', parentId] });
      } else {
        queryClient.invalidateQueries({ queryKey: ['folders', 'root', currentTenant?.id] });
      }

      toast.success("Folder created successfully");
      return data as DocumentFolder;
    } catch (error) {
      console.error('Error creating folder:', error);
      toast.error("Failed to create folder");
      throw error;
    }
  };

  // Rename a folder
  const renameFolder = async ({ folderId, name, description }: { folderId: string; name: string; description?: string }) => {
    try {
      const { data, error } = await supabase
        .from('document_folders')
        .update({ name, description })
        .eq('id', folderId)
        .eq('tenant_id', currentTenant?.id)
        .select()
        .single();

      if (error) throw error;

      queryClient.invalidateQueries({ queryKey: ['folders'] });
      queryClient.invalidateQueries({ queryKey: ['folder', folderId] });
      queryClient.invalidateQueries({ queryKey: ['breadcrumbs'] });

      toast.success("Folder renamed successfully");
      return data as DocumentFolder;
    } catch (error) {
      console.error('Error renaming folder:', error);
      toast.error("Failed to rename folder");
      throw error;
    }
  };

  // Delete a folder
  const deleteFolder = async (folderId: string) => {
    try {
      const folder = await getFolder(folderId);
      
      const { error } = await supabase
        .from('document_folders')
        .delete()
        .eq('id', folderId)
        .eq('tenant_id', currentTenant?.id);

      if (error) throw error;

      queryClient.invalidateQueries({ queryKey: ['folders'] });
      
      if (folder.parent_id) {
        queryClient.invalidateQueries({ queryKey: ['folders', 'sub', folder.parent_id] });
      } else {
        queryClient.invalidateQueries({ queryKey: ['folders', 'root'] });
      }

      toast.success("Folder deleted successfully");
      return true;
    } catch (error) {
      console.error('Error deleting folder:', error);
      toast.error("Failed to delete folder");
      throw error;
    }
  };

  // Upload a file
  const uploadFile = async ({ 
    file, 
    folderId 
  }: { 
    file: File; 
    folderId: string | null;
  }) => {
    try {
      if (!currentTenant?.id || !user?.id) {
        throw new Error("User or tenant not found");
      }

      // Create a path for the file in the format tenant_id/folder_id/uuid-filename
      const fileExt = file.name.split('.').pop();
      const fileName = folderId === 'root' || folderId === null
        ? `${currentTenant.id}/root/${crypto.randomUUID()}.${fileExt}`
        : `${currentTenant.id}/${folderId}/${crypto.randomUUID()}.${fileExt}`;
      
      // Upload the file to Supabase Storage
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('documents')
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: false
        });

      if (uploadError) throw uploadError;

      // Record the file in our database
      const { data, error } = await supabase
        .from('document_files')
        .insert([{
          name: file.name,
          folder_id: folderId === 'root' ? null : folderId,
          storage_path: fileName,
          content_type: file.type,
          size: file.size,
          tenant_id: currentTenant.id,
          created_by: user.id
        }])
        .select()
        .single();

      if (error) throw error;

      // Invalidate queries to update UI
      if (folderId === 'root' || folderId === null) {
        queryClient.invalidateQueries({ queryKey: ['files', 'root'] });
      } else {
        queryClient.invalidateQueries({ queryKey: ['files', folderId] });
      }
      
      toast.success("File uploaded successfully");
      return data as DocumentFile;
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error("Failed to upload file");
      throw error;
    }
  };

  // Delete a file
  const deleteFile = async (fileId: string, storagePath: string) => {
    try {
      // First delete from storage
      const { error: storageError } = await supabase.storage
        .from('documents')
        .remove([storagePath]);

      if (storageError) throw storageError;

      // Then delete the database record
      const { error } = await supabase
        .from('document_files')
        .delete()
        .eq('id', fileId)
        .eq('tenant_id', currentTenant?.id);

      if (error) throw error;

      queryClient.invalidateQueries({ queryKey: ['files'] });
      
      toast.success("File deleted successfully");
      return true;
    } catch (error) {
      console.error('Error deleting file:', error);
      toast.error("Failed to delete file");
      throw error;
    }
  };

  // Get the URL for a file
  const getFileUrl = async (path: string) => {
    try {
      const { data, error } = await supabase.storage
        .from('documents')
        .createSignedUrl(path, 60 * 60); // 1 hour expiry

      if (error) throw error;
      
      return data.signedUrl;
    } catch (error) {
      console.error('Error getting file URL:', error);
      toast.error("Failed to get file URL");
      throw error;
    }
  };

  // Get breadcrumb for a folder
  const getBreadcrumbs = async (folderId: string | null) => {
    if (!folderId) {
      return [{ id: null, name: "Root" }];
    }

    try {
      const breadcrumbs = [];
      let currentFolderId = folderId;
      
      while (currentFolderId) {
        const { data, error } = await supabase
          .from('document_folders')
          .select('id, name, parent_id')
          .eq('id', currentFolderId)
          .eq('tenant_id', currentTenant?.id)
          .single();
        
        if (error) throw error;
        
        breadcrumbs.unshift({ id: data.id, name: data.name });
        currentFolderId = data.parent_id;
      }
      
      breadcrumbs.unshift({ id: null, name: "Root" });
      
      return breadcrumbs;
    } catch (error) {
      console.error('Error getting breadcrumbs:', error);
      return [{ id: null, name: "Root" }];
    }
  };

  return {
    rootFolders,
    isLoadingFolders,
    getSubfolders,
    getFolder,
    getFilesByFolder,
    createFolder,
    renameFolder,
    deleteFolder,
    uploadFile,
    deleteFile,
    getFileUrl,
    getBreadcrumbs
  };
}
