
import { Route, Routes as RouterRoutes, Outlet } from "react-router-dom";
import { ProtectedRoute } from "@/components/auth/ProtectedRoute";
import Auth from "@/pages/auth/Auth";
import Board from "@/pages/board/Board";
import Cadastres from "@/pages/cadastres/Cadastres";
import Members from "@/pages/members/Members";
import Settings from "@/pages/settings/Settings";
import Index from "@/pages/index/Index";
import NotFound from "@/pages/notfound/NotFound";
import Create from "@/pages/tenants/Create";
import Join from "@/pages/tenants/Join";
import RequestCreate from "@/pages/tenants/RequestCreate";
import Admin from "@/pages/admin/Admin";
import Meetings from "@/pages/meetings/Meetings";
import Documents from "@/pages/Documents";
import Calendar from "@/pages/calendar/Calendar";
import Forum from "@/pages/forum/Forum";
import Tasks from "@/pages/tasks/Tasks";
import Notifications from "@/pages/notifications/Notifications";
import OnboardingCodeEntry from "@/pages/tenants/OnboardingCodeEntry";
import JoinWithCodeForm from "@/pages/tenants/JoinWithCodeForm";
import PublicTenant from "@/pages/public/PublicTenant";

export function Routes() {
  return (
    <RouterRoutes>
      {/* Public routes */}
      <Route path="/auth" element={<Auth />} />
      <Route path="/tenants/create" element={<Create />} />
      <Route path="/tenants/join" element={<Join />} />
      <Route path="/tenants/request-create" element={<RequestCreate />} />
      <Route path="/tenants/onboarding-code" element={<OnboardingCodeEntry />} />
      <Route path="/tenants/join-with-code/:code" element={<JoinWithCodeForm />} />
      <Route path="/t/:urlName" element={<PublicTenant />} />
      
      {/* Protected routes */}
      <Route element={<ProtectedRoute><Outlet /></ProtectedRoute>}>
        <Route path="/" element={<Index />} />
        <Route path="/board" element={<Board />} />
        <Route path="/cadastres" element={<Cadastres />} />
        <Route path="/members" element={<Members />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/meetings" element={<Meetings />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/tasks" element={<Tasks />} />
        <Route path="/notifications" element={<Notifications />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </RouterRoutes>
  );
}
