
import { Card } from "@/components/ui/card";
import { useTranslation } from "react-i18next";

export const AboutCard = () => {
  const { t } = useTranslation();
  
  return (
    <Card className="p-6 mt-8">
      <h2 className="text-xl font-semibold mb-4">{t("home.welcome.about.title")}</h2>
      <p className="text-muted-foreground">
        {t("home.welcome.about.description")}
      </p>
    </Card>
  );
};
