
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ArrowLeft, ArrowRight, Ticket } from "lucide-react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";

export default function OnboardingCodeEntry() {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!code.trim()) {
      toast.error("Please enter an onboarding code");
      return;
    }

    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('onboarding_codes')
        .select('*')
        .eq('code', code.trim())
        .single();
      
      if (error) throw error;
      
      if (data) {
        // Navigate to member form with the code
        navigate(`/tenants/join-with-code/${code.trim()}`);
      } else {
        toast.error("Invalid onboarding code");
      }
    } catch (error) {
      console.error("Error validating code:", error);
      toast.error("Invalid onboarding code or code not found");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
      <Card className="w-full max-w-md p-6">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold">Enter Onboarding Code</h1>
          <Link to="/">
            <Button variant="ghost" size="icon" disabled={isLoading}>
              <ArrowLeft className="h-5 w-5" />
            </Button>
          </Link>
        </div>

        <p className="text-muted-foreground mb-6">
          Enter your onboarding code to join your association's platform. This code was provided by your association.
        </p>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-2">
            <div className="relative">
              <Ticket className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground h-5 w-5" />
              <Input
                className="pl-10"
                placeholder="Enter your code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                disabled={isLoading}
                autoFocus
              />
            </div>
          </div>

          <Button 
            type="submit" 
            className="w-full" 
            disabled={isLoading || !code.trim()}
          >
            Continue
            <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
        </form>
      </Card>
    </div>
  );
}
