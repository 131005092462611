
import { useCadastres } from "@/contexts/CadastresContext";

/**
 * Hook for handling cadastre-related functionality for members
 */
export function useMemberCadastre() {
  const { cadastres } = useCadastres();

  const findCadastreId = (streetName: string, streetNumber: string) => {
    const cadastre = cadastres.find(c => 
      c.streetName === streetName && 
      c.streetNumber == streetNumber // number might be a string ;)
    );
    return cadastre?.id;
  };

  return {
    findCadastreId,
    cadastres
  };
}
