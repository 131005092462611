
import { useTranslation } from "react-i18next";
import { PageContainer } from "@/components/PageContainer";

export const BoardLoadingState = () => {
  const { t } = useTranslation();
  
  return (
    <div className="flex items-center justify-center h-[200px]">
      <p className="text-muted-foreground">{t("board.loadingConfig")}</p>
    </div>
  );
};
