
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useTenant } from "@/contexts/TenantContext";
import { StatuteDocument } from "@/types/settings";

export function useStatuteDocuments() {
  const { currentTenant } = useTenant();
  
  return useQuery<StatuteDocument[]>({
    queryKey: ['statute-documents', currentTenant?.id],
    queryFn: async () => {
      if (!currentTenant) return [];

      const { data, error } = await supabase
        .from('statute_documents')
        .select('*')
        .eq('tenant_id', currentTenant.id)
        .order('adoption_date', { ascending: false });

      if (error) {
        console.error('Error loading statute documents:', error);
        throw error;
      }
      
      return data || [];
    },
    enabled: !!currentTenant?.id,
  });
}
