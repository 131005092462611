import { useState } from "react";
import { useTranslation } from "react-i18next";
import { User, Languages, Building2, Eye, EyeOff, CheckCircle2, ArrowRight } from "lucide-react";
import { useAuth } from "@/contexts/AuthContext";
import { useTenant } from "@/contexts/TenantContext";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { Badge } from "@/components/ui/badge";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import md5 from "md5";

export function ProfileSettings() {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const { tenants, currentTenant, setCurrentTenant } = useTenant();
  const [isUpdating, setIsUpdating] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordData, setPasswordData] = useState({
    newPassword: "",
    confirmPassword: ""
  });

  const handlePasswordChange = async () => {
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      toast.error("Passwords don't match");
      return;
    }

    setIsUpdating(true);
    try {
      const { error } = await supabase.auth.updateUser({
        password: passwordData.newPassword
      });

      if (error) throw error;

      toast.success("Password updated successfully");
      setPasswordData({
        newPassword: "",
        confirmPassword: ""
      });
    } catch (error) {
      toast.error(error instanceof Error ? error.message : "Failed to update password");
    } finally {
      setIsUpdating(false);
    }
  };

  // Get Gravatar URL for the email
  const getGravatarUrl = (email: string) => {
    const hash = md5(email.toLowerCase().trim());
    return `https://www.gravatar.com/avatar/${hash}?d=mp&s=200`;
  };

  const handleSelectTenant = (tenant: { id: string; name: string }) => {
    setCurrentTenant(tenant);
    toast.success(`Switched to ${tenant.name}`);
  };

  const handleLanguageChange = (language: string) => {
    if (language === 'system') {
      // Reset to browser default language
      const browserLang = navigator.language.split('-')[0];
      i18n.changeLanguage(browserLang);
      toast.success(`Reset to system language`);
    } else {
      i18n.changeLanguage(language);
      toast.success(`Language changed to ${language === 'en' ? 'English' : 'Dansk'}`);
    }
  };

  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <h2 className="text-2xl font-bold">{t('profile.title', 'Profile Settings')}</h2>
        <p className="text-muted-foreground">
          {t('profile.subtitle', 'Manage your profile settings and preferences')}
        </p>
      </div>

      <Separator />

      <div className="space-y-4">
        <div className="flex items-center gap-4">
          <Avatar className="h-20 w-20">
            <AvatarImage 
              src={user?.user_metadata?.avatar_url || (user?.email ? getGravatarUrl(user.email) : undefined)} 
              alt={user?.email || "User avatar"} 
            />
            <AvatarFallback>
              <User className="h-10 w-10" />
            </AvatarFallback>
          </Avatar>
          <div>
            <p className="font-medium">{user?.email}</p>
            <p className="text-sm text-muted-foreground">
              {t('profile.avatarNote', 'Profile picture is managed through Gravatar')}
            </p>
          </div>
        </div>

        <div className="space-y-4">
          <div className="space-y-2">
            <Label>{t('profile.language', 'Language')}</Label>
            <Select defaultValue={i18n.language} onValueChange={handleLanguageChange}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select language" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="system">System Default</SelectItem>
                <SelectItem value="en">English</SelectItem>
                <SelectItem value="da">Dansk</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <Separator />

          <div className="space-y-4">
            <h3 className="text-lg font-medium">{t('profile.changePassword', 'Change Password')}</h3>
            <div className="space-y-2">
              <Label>{t('profile.newPassword', 'New Password')}</Label>
              <div className="relative">
                <Input
                  type={showNewPassword ? "text" : "password"}
                  value={passwordData.newPassword}
                  onChange={(e) => setPasswordData({ ...passwordData, newPassword: e.target.value })}
                />
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="absolute right-2 top-1/2 -translate-y-1/2"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                </Button>
              </div>
            </div>
            <div className="space-y-2">
              <Label>{t('profile.confirmPassword', 'Confirm Password')}</Label>
              <div className="relative">
                <Input
                  type={showConfirmPassword ? "text" : "password"}
                  value={passwordData.confirmPassword}
                  onChange={(e) => setPasswordData({ ...passwordData, confirmPassword: e.target.value })}
                />
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="absolute right-2 top-1/2 -translate-y-1/2"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                </Button>
              </div>
            </div>
            <Button 
              onClick={handlePasswordChange} 
              disabled={isUpdating || !passwordData.newPassword || !passwordData.confirmPassword}
            >
              {isUpdating ? t('common.saving', 'Saving...') : t('common.save', 'Save')}
            </Button>
          </div>

          <Separator />

          <div className="space-y-4">
            <h3 className="text-lg font-medium">{t('profile.tenants', 'Your Tenants')}</h3>
            <div className="space-y-2">
              {tenants.map((tenant) => (
                <div key={tenant.id} className="flex items-center justify-between p-2 rounded-md border">
                  <div className="flex items-center gap-2">
                    <Building2 className="h-4 w-4 text-muted-foreground" />
                    <span>{tenant.name}</span>
                    {currentTenant?.id === tenant.id && (
                      <Badge variant="secondary" className="flex items-center gap-1">
                        <CheckCircle2 className="h-3 w-3" />
                        Selected
                      </Badge>
                    )}
                  </div>
                  {currentTenant?.id !== tenant.id && (
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => handleSelectTenant(tenant)}
                      className="text-muted-foreground hover:text-foreground"
                    >
                      <ArrowRight className="h-4 w-4" />
                      <span className="sr-only">Select {tenant.name}</span>
                    </Button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
