
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { useTenant } from "@/contexts/TenantContext";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Loader2, Plus, ShieldCheck } from "lucide-react";
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/contexts/AuthContext";

export function Layout({ children }: { children: React.ReactNode }) {
  const { currentTenant, tenants, setCurrentTenant, isLoading } = useTenant();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!user || !currentTenant) {
        setIsAdmin(false);
        return;
      }

      const { data } = await supabase
        .from('tenant_memberships')
        .select('role')
        .eq('tenant_id', currentTenant.id)
        .eq('user_id', user.id)
        .eq('role', 'admin')
        .single();

      setIsAdmin(!!data);
    };

    checkAdminStatus();
  }, [user, currentTenant]);

  return (
    <SidebarProvider>
      <div className="min-h-screen flex w-full">
        <AppSidebar />
        <main className="flex-1">
          <div className="max-w-[2000px] w-full mx-auto p-6">
            {currentTenant && (
              <div className="flex justify-between items-center mb-4">
                <div className="flex items-center gap-4">
                  <SidebarTrigger />
                  {isAdmin && (
                    <div className="flex items-center gap-2 bg-green-100 text-green-800 px-3 py-1 rounded-full">
                      <ShieldCheck className="h-4 w-4" />
                      <span className="text-sm font-medium">Admin</span>
                    </div>
                  )}
                </div>
                {isLoading ? (
                  <div className="flex items-center">
                    <Loader2 className="h-4 w-4 animate-spin mr-2" />
                    Loading tenants...
                  </div>
                ) : tenants.length > 0 ? (
                  tenants.length > 1 && (
                    <Select
                      value={currentTenant?.id}
                      onValueChange={(value) => {
                        const tenant = tenants.find((t) => t.id === value);
                        if (tenant) setCurrentTenant(tenant);
                      }}
                    >
                      <SelectTrigger className="w-[200px]">
                        <SelectValue placeholder="Select association" />
                      </SelectTrigger>
                      <SelectContent>
                        {tenants.map((tenant) => (
                          <SelectItem key={tenant.id} value={tenant.id}>
                            {tenant.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )
                ) : (
                  <Button onClick={() => navigate('/tenants/create')}>
                    <Plus className="h-4 w-4 mr-2" />
                    Create Association
                  </Button>
                )}
              </div>
            )}
            {children}
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
}
