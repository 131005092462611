import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ArrowLeft, Search, Building2 } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/contexts/AuthContext";
import { toast } from "sonner";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";

export default function Join() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<any>(null);
  const [streetName, setStreetName] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [availableStreets, setAvailableStreets] = useState<string[]>([]);
  const [availableNumbers, setAvailableNumbers] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    console.log('Selected tenant changed:', selectedTenant); // Debug log
    if (selectedTenant?.id) {
      console.log('Fetching streets for tenant:', selectedTenant.id); // Debug log
      fetchAvailableStreets();
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (selectedTenant?.id && streetName) {
      fetchAvailableNumbers();
    } else {
      setAvailableNumbers([]);
    }
  }, [selectedTenant, streetName]);

  const fetchAvailableStreets = async () => {
    try {
      console.log('Making streets query for tenant:', selectedTenant.id); // Debug log
      const { data, error } = await supabase
        .from('association_streets')
        .select('street_name')
        .eq('tenant_id', selectedTenant.id)
        .order('street_name');

      if (error) {
        console.error('Supabase error:', error); // Debug log
        throw error;
      }

      console.log('Raw streets data:', data); // Debug log
      const uniqueStreets = Array.from(new Set(data.map(item => item.street_name)));
      console.log('Available streets:', uniqueStreets); // Debug log
      setAvailableStreets(uniqueStreets);
    } catch (error) {
      console.error('Error fetching streets:', error);
      toast.error("Failed to load available streets");
    }
  };

  const fetchAvailableNumbers = async () => {
    try {
      console.log('Query parameters:', {
        tenant_id: selectedTenant.id,
        street_name: streetName,
        values_type: {
          tenant_id_type: typeof selectedTenant.id,
          street_name_type: typeof streetName
        }
      });

      // First, let's see what's actually in the cadastres table for this tenant
      const { data: allCadastres, error: allError } = await supabase
        .from('cadastres')
        .select('*')
        .eq('tenant_id', selectedTenant.id);
      
      console.log('All cadastres for tenant:', allCadastres);

      // Now do our specific query
      const { data, error } = await supabase
        .from('cadastres')
        .select('street_number')
        .eq('tenant_id', selectedTenant.id)
        .eq('street_name', streetName)
        .order('street_number');

      if (error) {
        console.error('Error fetching numbers:', error);
        throw error;
      }

      console.log('Raw cadastres data for street:', data);
      const uniqueNumbers = Array.from(new Set(data.map(item => item.street_number)));
      uniqueNumbers.sort((a, b) => {
        return parseInt(a) - parseInt(b);
      });
      
      console.log('Available numbers:', uniqueNumbers);
      setAvailableNumbers(uniqueNumbers);
    } catch (error) {
      console.error('Error fetching street numbers:', error);
      toast.error("Failed to load available street numbers");
    }
  };

  const handleSearch = async () => {
    if (!searchQuery.trim()) return;

    setIsLoading(true);
    try {
      const { data: settingsData, error: settingsError } = await supabase
        .from('association_settings')
        .select(`
          tenant_id,
          name,
          cvr_number
        `)
        .eq('cvr_number', searchQuery);

      if (settingsError) throw settingsError;

      const formattedResults = settingsData?.map(setting => ({
        id: setting.tenant_id, // Direct access to tenant_id
        association_name: setting.name,
        association_settings: [{ cvr_number: setting.cvr_number }]
      })) || [];

      console.log('Search results:', formattedResults);
      setSearchResults(formattedResults);

      if (formattedResults.length === 0) {
        toast.info("No associations found matching your search");
      }
    } catch (error) {
      console.error('Search error:', error);
      toast.error("Failed to search for associations");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !selectedTenant || !fullName) {
      toast.error("Please fill in all required fields");
      return;
    }

    setIsLoading(true);
    try {
      const { error } = await supabase
        .from('association_join_requests')
        .insert([{
          user_id: user.id,
          tenant_id: selectedTenant.id,
          street_name: streetName,
          street_number: streetNumber,
          full_name: fullName,
          email: user.email,
          phone: phone || null
        }]);

      if (error) throw error;

      toast.success("Join request submitted successfully");
      
      // Keep loading state true during the delay
      // Will be cleaned up by React when component unmounts
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (error) {
      console.error('Submit error:', error);
      toast.error("Failed to submit join request");
      setIsLoading(false); // Only reset loading state if there's an error
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
      <Card className="w-full max-w-md p-6">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold">Join Association</h1>
          <Link to="/">
            <Button variant="ghost" size="icon" disabled={isLoading}>
              <ArrowLeft className="h-5 w-5" />
            </Button>
          </Link>
        </div>

        {!selectedTenant ? (
          <div className="space-y-4">
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="fullName">Full Name</Label>
                <Input
                  id="fullName"
                  placeholder="Enter your full name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                  disabled={isLoading}
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={user?.email || ""}
                  disabled
                  className="bg-gray-50"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="phone">Phone Number (Optional)</Label>
                <Input
                  id="phone"
                  type="tel"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  disabled={isLoading}
                />
              </div>

              <div className="border-t pt-4">
                <div className="space-y-2">
                  <h2 className="font-medium">Search for Association</h2>
                  <p className="text-sm text-muted-foreground">
                    Enter the CVR number of the association you want to join:
                  </p>
                </div>

                <div className="flex gap-2 mt-2">
                  <Input
                    placeholder="Enter CVR number"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                    disabled={isLoading}
                  />
                  <Button onClick={handleSearch} disabled={isLoading}>
                    <Search className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>

            {searchResults.length > 0 && (
              <div className="space-y-2">
                <h3 className="text-sm font-medium text-muted-foreground">Search Results:</h3>
                {searchResults.map((tenant) => (
                  <div
                    key={tenant.id}
                    className={`p-4 border rounded-lg hover:bg-accent cursor-pointer ${isLoading ? 'pointer-events-none opacity-50' : ''}`}
                    onClick={() => {
                      if (!isLoading) {
                        console.log('Setting selected tenant:', tenant);
                        setSelectedTenant(tenant);
                      }
                    }}
                  >
                    <div className="flex items-start gap-4">
                      <Building2 className="h-5 w-5 text-muted-foreground shrink-0 mt-1" />
                      <div className="space-y-1">
                        <p className="font-bold text-base">{tenant.association_name}</p>
                        <p className="text-sm text-muted-foreground">
                          CVR: {tenant.association_settings?.[0]?.cvr_number}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="p-4 bg-accent/10 rounded-lg">
              <div className="flex items-start gap-4">
                <Building2 className="h-5 w-5 text-muted-foreground shrink-0 mt-1" />
                <div className="space-y-1">
                  <p className="font-bold text-base">{selectedTenant.association_name}</p>
                  <p className="text-sm text-muted-foreground">
                    CVR: {selectedTenant.association_settings?.[0]?.cvr_number}
                  </p>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <h2 className="text-sm font-medium text-muted-foreground">Choose your address</h2>
              
              <div className="grid grid-cols-4 gap-4">
                <div className="col-span-3 space-y-2">
                  <Label>Street Name</Label>
                  <Select
                    value={streetName}
                    onValueChange={(value) => {
                      console.log('Selected street:', value);
                      setStreetName(value);
                      setStreetNumber(""); // Reset street number when street changes
                    }}
                    disabled={isLoading}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select a street" />
                    </SelectTrigger>
                    <SelectContent>
                      {availableStreets.map((street) => (
                        <SelectItem key={street} value={street}>
                          {street}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="space-y-2">
                  <Label>Number</Label>
                  <Select
                    value={streetNumber}
                    onValueChange={setStreetNumber}
                    disabled={!streetName || isLoading}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder={streetName ? "#" : "Street first"} />
                    </SelectTrigger>
                    <SelectContent>
                      {availableNumbers.map((number) => (
                        <SelectItem key={number} value={number}>
                          {number}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>

            <div className="flex gap-2">
              <Button
                type="button"
                variant="outline"
                onClick={() => setSelectedTenant(null)}
                disabled={isLoading}
              >
                Back to Search
              </Button>
              <Button 
                type="submit" 
                className="flex-1" 
                disabled={isLoading || !streetName || !streetNumber || !fullName}
              >
                Submit Request
              </Button>
            </div>
          </form>
        )}
      </Card>
    </div>
  );
}
