
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { CalendarEvent } from "@/types/calendar";
import { Button } from "@/components/ui/button";
import { CalendarIcon, Clock, Edit, Trash } from "lucide-react";

interface EventDetailsProps {
  event: CalendarEvent;
  onClose: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export function EventDetails({ event, onClose, onEdit, onDelete }: EventDetailsProps) {
  const { t } = useTranslation();
  const eventDate = new Date(event.date);
  
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-xl font-semibold">{event.title}</h3>
      </div>
      
      <div className="flex items-center gap-2 text-sm">
        <CalendarIcon className="h-4 w-4 text-muted-foreground" />
        <span>{format(eventDate, "PPP")}</span>
      </div>
      
      <div className="flex items-center gap-2 text-sm">
        <Clock className="h-4 w-4 text-muted-foreground" />
        <span>{format(eventDate, "p")}</span>
      </div>
      
      {event.description && (
        <div className="mt-4">
          <h4 className="text-sm font-medium mb-1">{t("calendar.event.description")}</h4>
          <p className="text-sm text-muted-foreground whitespace-pre-wrap">{event.description}</p>
        </div>
      )}
      
      <div className="flex justify-between space-x-2 pt-4">
        <div className="space-x-2">
          <Button 
            variant="outline" 
            size="sm"
            onClick={onEdit}
            className="flex items-center"
          >
            <Edit className="h-4 w-4 mr-1" />
            {t("common.edit")}
          </Button>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={onDelete}
            className="flex items-center text-destructive hover:bg-destructive hover:text-destructive-foreground"
          >
            <Trash className="h-4 w-4 mr-1" />
            {t("common.delete")}
          </Button>
        </div>
        <Button variant="outline" type="button" onClick={onClose}>
          {t("common.close")}
        </Button>
      </div>
    </div>
  );
}
