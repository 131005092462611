
import * as React from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { DayMonthValue } from "@/types/settings";

interface DayMonthPickerProps {
  value?: DayMonthValue;
  onChange: (value: DayMonthValue) => void;
  disabled?: boolean;
}

export function DayMonthPicker({
  value,
  onChange,
  disabled = false
}: DayMonthPickerProps) {
  const [day, setDay] = React.useState<number | undefined>(value?.day);
  const [month, setMonth] = React.useState<number | undefined>(value?.month);

  React.useEffect(() => {
    setDay(value?.day);
    setMonth(value?.month);
  }, [value]);

  const handleDayChange = (value: string) => {
    const newDay = parseInt(value, 10);
    setDay(newDay);
    onChange({ day: newDay, month: month ?? 0 });
  };

  const handleMonthChange = (value: string) => {
    const newMonth = parseInt(value, 10);
    setMonth(newMonth);
    onChange({ day: day ?? 1, month: newMonth });
  };

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "January", "February", "March", "April", 
    "May", "June", "July", "August", 
    "September", "October", "November", "December"
  ];

  return (
    <div className="flex space-x-2">
      <Select 
        value={day?.toString()} 
        onValueChange={handleDayChange}
        disabled={disabled}
      >
        <SelectTrigger className="w-[120px]">
          <SelectValue placeholder="Day" />
        </SelectTrigger>
        <SelectContent>
          {days.map((d) => (
            <SelectItem key={d} value={d.toString()}>
              {d}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      
      <Select 
        value={month?.toString()} 
        onValueChange={handleMonthChange}
        disabled={disabled}
      >
        <SelectTrigger className="w-[160px]">
          <SelectValue placeholder="Month" />
        </SelectTrigger>
        <SelectContent>
          {months.map((m, index) => (
            <SelectItem key={m} value={index.toString()}>
              {m}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
