
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useTranslation } from "react-i18next";
import { useMembers } from "@/hooks/useMembers";
import { useCadastres } from "@/contexts/CadastresContext";

export function CadastreStatistics() {
  const { t } = useTranslation();
  const { cadastres } = useCadastres();
  const { members } = useMembers();

  // Get statistics data
  const getStatistics = () => {
    // Total number of cadastres
    const totalCadastres = cadastres.length;
    
    // Group cadastres by street name
    const streetCounts: Record<string, {
      total: number;
      zeroMembers: number;
      oneMembers: number;
      multipleMembers: number;
    }> = {};
    
    // Initialize street counts with all streets that have cadastres
    cadastres.forEach(cadastre => {
      if (!streetCounts[cadastre.streetName]) {
        streetCounts[cadastre.streetName] = {
          total: 0,
          zeroMembers: 0,
          oneMembers: 0,
          multipleMembers: 0
        };
      }
    });
    
    // Process each cadastre and count members
    cadastres.forEach(cadastre => {
      const cadastreMembers = members.filter(member => 
        member.street_name === cadastre.streetName && 
        member.street_number === cadastre.streetNumber
      );
      
      const memberCount = cadastreMembers.length;
      streetCounts[cadastre.streetName].total++;
      
      if (memberCount === 0) {
        streetCounts[cadastre.streetName].zeroMembers++;
      } else if (memberCount === 1) {
        streetCounts[cadastre.streetName].oneMembers++;
      } else {
        streetCounts[cadastre.streetName].multipleMembers++;
      }
    });
    
    // Calculate totals for each column
    const totalZeroMembers = Object.values(streetCounts).reduce((sum, street) => sum + street.zeroMembers, 0);
    const totalOneMembers = Object.values(streetCounts).reduce((sum, street) => sum + street.oneMembers, 0);
    const totalMultipleMembers = Object.values(streetCounts).reduce((sum, street) => sum + street.multipleMembers, 0);
    
    return {
      totalCadastres,
      streetCounts,
      totalZeroMembers,
      totalOneMembers,
      totalMultipleMembers
    };
  };

  const { totalCadastres, streetCounts, totalZeroMembers, totalOneMembers, totalMultipleMembers } = getStatistics();

  return (
    <div className="space-y-4">
      <div className="border rounded-md p-4 bg-muted/50">
        <p className="font-medium text-lg">{t('cadastres.totalCadastres')}: {totalCadastres}</p>
      </div>
      
      <div className="border rounded-md p-4">
        <h3 className="font-medium mb-2">{t('cadastres.byStreet')}:</h3>
        
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="font-medium">{t('cadastres.street')}</TableHead>
              <TableHead className="text-right font-medium">{t('cadastres.zeroMembers')}</TableHead>
              <TableHead className="text-right font-medium">{t('cadastres.oneMember')}</TableHead>
              <TableHead className="text-right font-medium">{t('cadastres.multipleMembers')}</TableHead>
              <TableHead className="text-right font-medium">{t('cadastres.totalCount')}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Object.entries(streetCounts).sort(([a], [b]) => a.localeCompare(b)).map(([street, counts]) => (
              <TableRow key={street}>
                <TableCell className="font-medium">{street}</TableCell>
                <TableCell className="text-right">{counts.zeroMembers}</TableCell>
                <TableCell className="text-right">{counts.oneMembers}</TableCell>
                <TableCell className="text-right">{counts.multipleMembers}</TableCell>
                <TableCell className="text-right">{counts.total}</TableCell>
              </TableRow>
            ))}
            
            {Object.keys(streetCounts).length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} className="text-center text-muted-foreground italic">
                  {t('common.noData')}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow className="border-t-2">
                <TableCell className="font-bold">{t('common.totals')}</TableCell>
                <TableCell className="text-right font-bold">{totalZeroMembers}</TableCell>
                <TableCell className="text-right font-bold">{totalOneMembers}</TableCell>
                <TableCell className="text-right font-bold">{totalMultipleMembers}</TableCell>
                <TableCell className="text-right font-bold">{totalCadastres}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
