
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Loader2, ShieldCheck, AlertCircle, UserCircle, ArrowLeft } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/contexts/AuthContext";
import { toast } from "sonner";

export default function Create() {
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const checkAuthorization = async () => {
      if (!user) return;
      
      const { data: profile } = await supabase
        .from('profiles')
        .select()
        .eq('id', user.id)
        .maybeSingle();
      
      setIsAuthorized(!!profile?.is_super_admin);
    };

    checkAuthorization();
  }, [user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      toast.error("You must be logged in to create an association");
      return;
    }

    if (!isAuthorized) {
      toast.error("You are not authorized to create associations");
      return;
    }

    setIsLoading(true);
    try {
      // Create tenant
      const { data: tenant, error: tenantError } = await supabase
        .from('tenants')
        .insert([{ name }])
        .select()
        .single();

      if (tenantError) {
        console.error('Error creating tenant:', tenantError);
        throw new Error("Failed to create association");
      }

      if (!tenant) {
        throw new Error("No tenant data returned after creation");
      }

      // Create tenant membership
      const { error: membershipError } = await supabase
        .from('tenant_memberships')
        .insert([{
          tenant_id: tenant.id,
          user_id: user.id,
          role: 'admin'
        }]);

      if (membershipError) {
        console.error('Error creating membership:', membershipError);
        throw new Error("Failed to create association membership");
      }

      // Create initial association settings
      const { error: settingsError } = await supabase
        .from('association_settings')
        .insert([{
          tenant_id: tenant.id,
          name: name,
          cvr_number: '00000000', // Default CVR number
          street_address: 'Not set',
          street_number: '0',
          post_number: '0000'
        }]);

      if (settingsError) {
        console.error('Error creating settings:', settingsError);
        // Don't throw here as the main association is already created
        toast.warning("Association created, but settings could not be initialized");
      }

      toast.success("Association created successfully");
      navigate('/');
    } catch (error) {
      console.error('Creation error:', error);
      toast.error(error instanceof Error ? error.message : "Failed to create association");
    } finally {
      setIsLoading(false);
    }
  };

  if (isAuthorized === false) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
        <Card className="w-full max-w-md p-6">
          <div className="flex items-center space-x-2 text-red-600 mb-4">
            <AlertCircle className="h-5 w-5" />
            <h1 className="text-xl font-semibold">Not Authorized</h1>
          </div>
          <p className="text-gray-600 mb-6">
            Only super administrators can create new associations. Please contact an existing administrator to request access or join an existing association.
          </p>
          <div className="space-y-3">
            <Link to="/">
              <Button variant="outline" className="w-full">
                <ArrowLeft className="h-4 w-4 mr-2" />
                Return to Dashboard
              </Button>
            </Link>
            <Button 
              className="w-full"
              onClick={() => window.location.href = "mailto:support@yourplatform.com"}
            >
              Request Super Admin Access
            </Button>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
      <Card className="w-full max-w-md p-6">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold">Create New Association</h1>
          <Link to="/">
            <Button variant="ghost" size="icon">
              <ArrowLeft className="h-5 w-5" />
            </Button>
          </Link>
        </div>
        
        <div className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg mb-6">
          <UserCircle className="h-5 w-5 text-gray-500" />
          <div className="flex flex-col flex-grow">
            <span className="text-sm font-medium text-gray-900">Logged in as:</span>
            <span className="text-sm text-gray-600">{user?.email}</span>
          </div>
          {isAuthorized && (
            <div className="flex items-center gap-1 bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs">
              <ShieldCheck className="h-3 w-3" />
              App Admin
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-medium">
              Association Name
            </label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter association name"
              required
            />
          </div>
          <Button type="submit" className="w-full" disabled={isLoading}>
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Creating...
              </>
            ) : (
              'Create Association'
            )}
          </Button>
        </form>
      </Card>
    </div>
  );
}
