
import { format } from "date-fns";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { CalendarEvent } from "@/types/calendar";

interface MonthCardProps {
  month: string;
  date: Date;
  events: CalendarEvent[];
  onEventClick: (event: CalendarEvent) => void;
  onDayClick: (date: Date, events: CalendarEvent[], element: HTMLElement) => void;
}

export function MonthCard({ month, date, events, onEventClick, onDayClick }: MonthCardProps) {
  // Get the number of days in the month
  const daysInMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();
  
  // Get the day of the week for the first day of the month (0 = Sunday, 1 = Monday, etc.)
  const firstDayOfMonth = new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  ).getDay();
  
  // Create an array of day numbers for the month
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  
  // Create blank spaces for the days before the first day of the month
  const blanks = Array.from({ length: firstDayOfMonth }, (_, i) => null);
  
  // Combine blanks and days
  const calendarDays = [...blanks, ...days];
  
  // Group events by date
  const eventsByDate = events.reduce((acc, event) => {
    const eventDate = new Date(event.date);
    const day = eventDate.getDate();
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(event);
    return acc;
  }, {} as Record<number, CalendarEvent[]>);
  
  const handleDayClick = (day: number | null, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event bubbling
    if (day === null) return;
    
    const clickedDate = new Date(date.getFullYear(), date.getMonth(), day);
    const dayEvents = eventsByDate[day] || [];
    
    // Pass the clicked element to the parent component
    onDayClick(clickedDate, dayEvents, e.currentTarget as HTMLElement);
  };
  
  return (
    <Card>
      <CardHeader className="pb-2">
        <CardTitle className="text-lg font-medium">{month}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-7 gap-1 text-xs mb-2">
          {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day, i) => (
            <div key={i} className="text-center font-medium text-muted-foreground">
              {day}
            </div>
          ))}
        </div>
        
        <div className="grid grid-cols-7 gap-1 text-xs">
          {calendarDays.map((day, i) => {
            const hasEvents = day && eventsByDate[day] && eventsByDate[day].length > 0;
            const eventCount = day && eventsByDate[day] ? eventsByDate[day].length : 0;
            
            return (
              <div
                key={i}
                className={`
                  aspect-square flex flex-col items-center justify-center rounded-sm 
                  ${day ? "hover:bg-accent cursor-pointer" : ""}
                  ${hasEvents ? "bg-accent/50" : ""}
                  relative
                `}
                onClick={(e) => day !== null && handleDayClick(day, e)}
              >
                <span className={`${hasEvents ? "font-bold" : ""}`}>
                  {day}
                </span>
                {hasEvents && (
                  <div className="flex gap-0.5 mt-0.5 items-center">
                    {Array.from({ length: Math.min(eventCount, 3) }).map((_, idx) => (
                      <span 
                        key={idx}
                        className="w-1 h-1 rounded-full bg-primary"
                        aria-hidden="true"
                      />
                    ))}
                    {eventCount > 3 && (
                      <span className="text-[8px] text-primary">+{eventCount - 3}</span>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
}
