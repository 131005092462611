
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { JoinRequestCard } from "@/components/home/JoinRequestCard";
import { Member } from "@/types/member";

interface JoinRequestsDialogProps {
  pendingRequests: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  joinRequests: any[];
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onDecline: (requestId: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onApprove: (request: any) => void;
}

export function JoinRequestsDialog({
  pendingRequests,
  joinRequests,
  isOpen,
  onOpenChange,
  onDecline,
  onApprove
}: JoinRequestsDialogProps) {
  const { t } = useTranslation();

  if (pendingRequests === 0) {
    return null;
  }

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button 
          style={{ backgroundColor: '#ffe100de' }}
          className="text-black hover:text-black hover:bg-yellow-400"
        >
          {pendingRequests} {t("members.pendingJoinRequests")}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>
            {pendingRequests} {t("members.pendingJoinRequests")}
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          {joinRequests.map((request) => (
            <div key={request.id} className="relative">
              <JoinRequestCard joinRequest={request} />
              { request.status === 'pending' && 
              <div className="flex justify-between gap-6 mt-4">
                <Button 
                  variant="destructive"
                  onClick={() => onDecline(request.id)}
                  >
                  {t("common.decline")}
                </Button>
                <Button 
                  onClick={() => onApprove(request)}
                  >
                  {t("common.approve")}
                </Button>
              </div>
                }
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}
