import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { da, enUS } from "date-fns/locale";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { MonthCard } from "./MonthCard";
import { EventForm } from "./EventForm";
import { EventDetails } from "./EventDetails";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { CalendarEvent } from "@/types/calendar";
import { Button } from "@/components/ui/button";
import { Plus, ChevronLeft, ChevronRight } from "lucide-react";

interface YearCalendarProps {
  events: CalendarEvent[];
  onAddEvent: (event: CalendarEvent) => void;
  onUpdateEvent: (event: CalendarEvent) => void;
  onDeleteEvent: (eventId: string) => void;
}

interface EventListItemProps {
  event: CalendarEvent;
  onClick: (event: CalendarEvent) => void;
}

function EventListItem({ event, onClick }: EventListItemProps) {
  return (
    <div 
      key={event.id} 
      className="p-2 border rounded cursor-pointer hover:bg-accent"
      onClick={() => onClick(event)}
    >
      <div className="flex items-center gap-2">
        <div className="text-xs text-muted-foreground">
          {format(new Date(event.date), "p", {locale: da})}
        </div>
        <div className="font-medium">{event.title}</div>
      </div>
    </div>
  );
}

interface DayEventsListProps {
  date: Date;
  events: CalendarEvent[];
  onEventClick: (event: CalendarEvent) => void;
  onCreateNew: () => void;
}

function DayEventsList({ date, events, onEventClick, onCreateNew }: DayEventsListProps) {
  const { t } = useTranslation();
  
  return (
    <div className="p-4">
      <h3 className="font-medium mb-2">
        {format(date, "MMMM d, yyyy")}
      </h3>
      <div className="space-y-2 mb-4">
        {events.map((event) => (
          <EventListItem
            key={event.id}
            event={event}
            onClick={onEventClick}
          />
        ))}
      </div>
      <Button 
        size="sm" 
        className="w-full" 
        onClick={onCreateNew}
      >
        <Plus className="h-4 w-4 mr-1" />
        {t("calendar.createNew")}
      </Button>
    </div>
  );
}

export function YearCalendar({ events, onAddEvent, onUpdateEvent, onDeleteEvent }: YearCalendarProps) {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [dayEventsData, setDayEventsData] = useState<{
    events: CalendarEvent[],
    date: Date
  } | null>(null);
  const [dayPopoverOpen, setDayPopoverOpen] = useState(false);
  const popoverTriggerRef = useRef<HTMLDivElement>(null);
  const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  
  // Generate months for the current year
  const MONTHS = Array.from({ length: 12 }, (_, i) => {
    const date = new Date(currentYear, i, 1);
    return {
      index: i,
      name: format(date, "MMMM"),
      date
    };
  });
  
  // Update position of popover trigger element when position changes
  useEffect(() => {
    if (popoverTriggerRef.current && popoverPosition) {
      const { x, y } = popoverPosition;
      popoverTriggerRef.current.style.position = 'absolute';
      popoverTriggerRef.current.style.left = `${x}px`;
      popoverTriggerRef.current.style.top = `${y}px`;
      // Make it visible for the popover to position correctly, but transparent
      popoverTriggerRef.current.style.width = '1px';
      popoverTriggerRef.current.style.height = '1px';
      popoverTriggerRef.current.style.opacity = '0';
      popoverTriggerRef.current.style.pointerEvents = 'none';
    }
  }, [popoverPosition]);
  
  const handlePreviousYear = () => {
    setCurrentYear(currentYear - 1);
  };
  
  const handleNextYear = () => {
    setCurrentYear(currentYear + 1);
  };
  
  const handleDayClick = (date: Date, dayEvents: CalendarEvent[], element: HTMLElement) => {
    // Calculate the position for the popover
    const rect = element.getBoundingClientRect();
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    
    // Set position at the bottom center of the clicked day
    setPopoverPosition({
      x: rect.left + rect.width / 2,
      y: rect.bottom + scrollTop
    });
    
    // Set the data first
    setDayEventsData({
      date,
      events: dayEvents
    });
    
    // Then open the popover if there are events, or go directly to create form if not
    if (dayEvents.length === 0) {
      // No events, open create form directly
      setSelectedDate(date);
      setIsEditMode(false);
      setIsFormOpen(true);
    } else {
      // Has events, show popover with list
      setDayPopoverOpen(true);
    }
  };
  
  const handleCreateNewFromDay = () => {
    if (dayEventsData) {
      setSelectedDate(dayEventsData.date);
      setIsEditMode(false);
      setIsFormOpen(true);
      setDayPopoverOpen(false);
    }
  };
  
  const handleEventClick = (event: CalendarEvent) => {
    setSelectedEvent(event);
    setIsDetailsOpen(true);
  };
  
  const handleDayEventClick = (event: CalendarEvent) => {
    setSelectedEvent(event);
    setSelectedDate(new Date(event.date));
    setIsEditMode(true);
    setIsFormOpen(true);
    setDayPopoverOpen(false);
  };
  
  const handleEditEvent = () => {
    if (selectedEvent) {
      setSelectedDate(new Date(selectedEvent.date));
      setIsEditMode(true);
      setIsFormOpen(true);
      setIsDetailsOpen(false);
    }
  };
  
  const handleDeleteEvent = () => {
    if (selectedEvent && selectedEvent.id) {
      onDeleteEvent(selectedEvent.id);
      setIsDetailsOpen(false);
    }
  };
  
  const handleCloseForm = () => {
    setIsFormOpen(false);
    setSelectedDate(null);
    setIsEditMode(false);
  };
  
  const handleCloseDetails = () => {
    setIsDetailsOpen(false);
    setSelectedEvent(null);
  };
  
  const handleAddEvent = (event: CalendarEvent) => {
    onAddEvent(event);
    handleCloseForm();
  };
  
  const handleUpdateEvent = (event: CalendarEvent) => {
    onUpdateEvent({
      ...event,
      id: selectedEvent?.id
    });
    handleCloseForm();
  };
  
  const getEventsForMonth = (monthIndex: number) => {
    return events.filter(event => {
      const eventDate = new Date(event.date);
      return eventDate.getMonth() === monthIndex && eventDate.getFullYear() === currentYear;
    });
  };
  
  return (
    <>
      {/* Year Navigation */}
      <div className="flex items-center justify-center mb-6 gap-4">
        <Button
          variant="outline"
          size="icon"
          onClick={handlePreviousYear}
          aria-label="Previous Year"
        >
          <ChevronLeft className="h-5 w-5" />
        </Button>
        <h2 className="text-2xl font-semibold">{currentYear}</h2>
        <Button
          variant="outline"
          size="icon"
          onClick={handleNextYear}
          aria-label="Next Year"
        >
          <ChevronRight className="h-5 w-5" />
        </Button>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {MONTHS.map((month) => (
          <MonthCard
            key={month.index}
            month={month.name}
            date={month.date}
            events={getEventsForMonth(month.index)}
            onEventClick={handleEventClick}
            onDayClick={handleDayClick}
          />
        ))}
      </div>
      
      {/* Day Events Popover - Using a hidden div as trigger */}
      <div ref={popoverTriggerRef} className="absolute" />
      
      <Popover open={dayPopoverOpen} onOpenChange={setDayPopoverOpen}>
        <PopoverTrigger asChild>
          <div ref={popoverTriggerRef} />
        </PopoverTrigger>
        <PopoverContent className="w-80 p-0" align="center" side="bottom" sideOffset={5}>
          {dayEventsData && (
            <DayEventsList
              date={dayEventsData.date}
              events={dayEventsData.events.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())}
              onEventClick={handleDayEventClick}
              onCreateNew={handleCreateNewFromDay}
            />
          )}
        </PopoverContent>
      </Popover>
      
      {/* Event Form Sheet */}
      <Sheet open={isFormOpen} onOpenChange={setIsFormOpen}>
        <SheetContent className="sm:max-w-md">
          <SheetHeader>
            <SheetTitle>
              {isEditMode 
                ? t("calendar.editEvent") 
                : selectedDate && format(selectedDate, "MMMM d, yyyy")}
            </SheetTitle>
          </SheetHeader>
          <div className="mt-6">
            {selectedDate && (
              <EventForm 
                initialDate={selectedDate} 
                initialEvent={isEditMode ? selectedEvent : undefined}
                onSubmit={isEditMode ? handleUpdateEvent : handleAddEvent} 
                onCancel={handleCloseForm} 
              />
            )}
          </div>
        </SheetContent>
      </Sheet>
      
      {/* Event Details Sheet */}
      <Sheet open={isDetailsOpen} onOpenChange={setIsDetailsOpen}>
        <SheetContent className="sm:max-w-md">
          <SheetHeader>
            <SheetTitle>
              {t("calendar.event.details")}
            </SheetTitle>
          </SheetHeader>
          <div className="mt-6">
            {selectedEvent && (
              <EventDetails 
                event={selectedEvent} 
                onClose={handleCloseDetails}
                onEdit={handleEditEvent}
                onDelete={handleDeleteEvent}
              />
            )}
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
}
