
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useTenant } from "@/contexts/TenantContext";

// Define an interface for the association settings
interface AssociationSettings {
  id?: string;
  name?: string;
  logo_url?: string;
  url_name?: string;
  fiscal_year_start_month?: number;
  fiscal_year_start_day?: number;
  annual_report_delivery_day?: number;
  annual_report_approval_day?: number;
  votes_per_cadastre?: number;
  allow_proxy_votes?: boolean;
  max_proxy_votes?: number;
  statutes_file_path?: string;
}

export function useAssociationSettings() {
  const { currentTenant } = useTenant();
  const queryClient = useQueryClient();
  
  const queryKey = ['association-settings', currentTenant?.id];

  // Add a function to invalidate the cache
  const invalidateSettings = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  const query = useQuery<AssociationSettings>({
    queryKey,
    queryFn: async () => {
      if (!currentTenant) return { 
        id: undefined, 
        name: undefined, 
        logo_url: undefined, 
        url_name: undefined,
        fiscal_year_start_month: undefined,
        fiscal_year_start_day: undefined,
        annual_report_delivery_day: undefined,
        annual_report_approval_day: undefined,
        votes_per_cadastre: 1,
        allow_proxy_votes: false,
        max_proxy_votes: undefined,
        statutes_file_path: undefined
      };

      const { data, error } = await supabase
        .from('association_settings')
        .select('id, name, logo_url, fiscal_year_start_month, fiscal_year_start_day, annual_report_delivery_day, annual_report_approval_day, votes_per_cadastre, allow_proxy_votes, max_proxy_votes, statutes_file_path')
        .eq('tenant_id', currentTenant.id)
        .single();

      if (error) {
        console.error('Error loading association settings:', error);
        return { 
          id: undefined, 
          name: undefined, 
          logo_url: undefined, 
          url_name: undefined,
          fiscal_year_start_month: undefined,
          fiscal_year_start_day: undefined,
          annual_report_delivery_day: undefined,
          annual_report_approval_day: undefined,
          votes_per_cadastre: 1,
          allow_proxy_votes: false,
          max_proxy_votes: undefined,
          statutes_file_path: undefined
        };
      }
      
      // Get the url_name from the tenant
      const { data: tenantData, error: tenantError } = await supabase
        .from('tenants')
        .select('url_name')
        .eq('id', currentTenant.id)
        .single();
        
      if (tenantError) {
        console.error('Error loading tenant details:', tenantError);
        return {
          ...data,
          url_name: undefined
        };
      }
      
      return { 
        ...data, 
        url_name: tenantData?.url_name || undefined 
      };
    },
    enabled: !!currentTenant?.id,
  });

  return {
    ...query,
    refetch: query.refetch,
    invalidateSettings
  };
}
