
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useTenant } from "@/contexts/TenantContext";
import { useEffect } from "react";

export function usePendingRequests() {
  const { currentTenant } = useTenant();
  const queryClient = useQueryClient();

  // Query for fetching pending requests count
  const pendingRequestsQuery = useQuery({
    queryKey: ['pending-requests', currentTenant?.id],
    queryFn: async () => {
      if (!currentTenant) return 0;

      const { count } = await supabase
        .from('association_join_requests')
        .select('*', { count: 'exact', head: true })
        .eq('tenant_id', currentTenant.id)
        .eq('status', 'pending')
        .eq('dismissed', false);

      return count || 0;
    },
    enabled: !!currentTenant?.id,
  });

  // Set up real-time subscription for join requests
  useEffect(() => {
    if (!currentTenant) return;
    
    const channel = supabase
      .channel('schema-db-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'association_join_requests',
          filter: `tenant_id=eq.${currentTenant.id}`
        },
        () => {
          // Invalidate the query when data changes
          queryClient.invalidateQueries({ queryKey: ['pending-requests'] });
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [currentTenant, queryClient]);

  return pendingRequestsQuery;
}
