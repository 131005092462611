
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { UserRound } from "lucide-react";
import { Member } from "@/types/member";
import md5 from "md5";

interface BoardMemberItemProps {
  position: string;
  members: Member[];
  selectedMemberId: string | null;
  onMemberSelect: (memberId: string) => void;
  assignedMemberIds: string[];
  isLoading?: boolean;
  readOnly?: boolean;
}

export const BoardMemberItem = ({
  position,
  members,
  selectedMemberId,
  onMemberSelect,
  assignedMemberIds,
  isLoading,
  readOnly = false
}: BoardMemberItemProps) => {
  const selectedMember = members.find(m => m.id === selectedMemberId);

  const getGravatarUrl = (email: string) => {
    const hash = md5(email.toLowerCase().trim());
    return `https://www.gravatar.com/avatar/${hash}?d=mp`;
  };

  return (
    <div className="flex items-center gap-4 p-4 border rounded-lg bg-muted/10">
      <div className="p-2 rounded-full bg-primary/10">
        {selectedMember ? (
          <Avatar>
            <AvatarImage src={getGravatarUrl(selectedMember.email)} alt={selectedMember.name} />
            <AvatarFallback>{selectedMember.name.charAt(0)}</AvatarFallback>
          </Avatar>
        ) : (
          <UserRound className="w-6 h-6 text-primary" />
        )}
      </div>
      <div className="flex-1">
        <h3 className="font-medium">{position}</h3>
        {readOnly && selectedMember ? (
          <div className="mt-2 text-sm text-muted-foreground">
            {selectedMember.name}
          </div>
        ) : (
          <Select
            value={selectedMemberId || ""}
            onValueChange={onMemberSelect}
            disabled={readOnly}
          >
            <SelectTrigger className="mt-2">
              <SelectValue placeholder="Select a member" />
            </SelectTrigger>
            <SelectContent>
              {isLoading ? (
                <SelectItem value="loading" disabled>
                  Loading members...
                </SelectItem>
              ) : members.length === 0 ? (
                <SelectItem value="no-members" disabled>
                  No members available
                </SelectItem>
              ) : (
                members.map((member) => (
                  <SelectItem 
                    key={member.id} 
                    value={member.id}
                    disabled={assignedMemberIds.includes(member.id) && selectedMemberId !== member.id}
                  >
                    {member.name}
                  </SelectItem>
                ))
              )}
            </SelectContent>
          </Select>
        )}
      </div>
    </div>
  );
};
