
import { useState, useEffect } from "react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";

export interface BoardMember {
  position: string;
  positionKey: string;
  memberId: string | null;
  type: 'board' | 'deputy' | 'accountant' | 'accountantDeputy';
}

export interface BoardSettings {
  boardMemberCount: number;
  hasDeputyMembers: boolean;
  deputyMemberCount: number;
  hasVerifyingAccountant: boolean;
  hasAccountantDeputy: boolean;
}

// Define position keys that are language-independent
export const POSITION_KEYS = {
  chairman: "chairman",
  cashier: "cashier",
  boardMember: "boardMember",
  deputyMember: "deputyMember",
  accountant: "accountant",
  accountantDeputy: "accountantDeputy",
};

export function useBoard(currentTenant: any) {
  const { toast } = useToast();
  const { t } = useTranslation();
  const [boardMembers, setBoardMembers] = useState<BoardMember[]>([]);
  const [settingsLoading, setSettingsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [settings, setSettings] = useState<BoardSettings>({
    boardMemberCount: 3,
    hasDeputyMembers: false,
    deputyMemberCount: 2,
    hasVerifyingAccountant: true,
    hasAccountantDeputy: false,
  });

  useEffect(() => {
    const loadBoardSettings = async () => {
      if (!currentTenant) return;

      try {
        // Load board settings first
        const { data: settingsData, error: settingsError } = await supabase
          .from('association_settings')
          .select('board_member_count, has_deputy_members, deputy_member_count, has_verifying_accountant, has_accountant_deputy')
          .eq('tenant_id', currentTenant.id)
          .single();

        if (settingsError) throw settingsError;

        if (settingsData) {
          setSettings({
            boardMemberCount: Math.max(settingsData.board_member_count ?? 3, 3),
            hasDeputyMembers: settingsData.has_deputy_members ?? false,
            deputyMemberCount: settingsData.deputy_member_count ?? 2,
            hasVerifyingAccountant: settingsData.has_verifying_accountant ?? true,
            hasAccountantDeputy: settingsData.has_accountant_deputy ?? false,
          });
        }

        // Load existing board assignments
        const { data: assignmentsData, error: assignmentsError } = await supabase
          .from('board_assignments')
          .select('position, member_id')
          .eq('tenant_id', currentTenant.id);

        if (assignmentsError) throw assignmentsError;

        generateBoardPositions(settingsData, assignmentsData || []);
      } catch (error) {
        console.error('Error loading board settings:', error);
        toast({
          title: "Error",
          description: "Failed to load board settings",
          variant: "destructive",
        });
      } finally {
        setSettingsLoading(false);
      }
    };

    loadBoardSettings();
  }, [currentTenant, t]);

  const generateBoardPositions = (settingsData: any, assignmentsData: any[]) => {
    if (!settingsData) return;

    const boardMemberCount = Math.max(settingsData.board_member_count ?? 3, 3);
    const hasDeputyMembers = settingsData.has_deputy_members ?? false;
    const deputyMemberCount = settingsData.deputy_member_count ?? 2;
    const hasVerifyingAccountant = settingsData.has_verifying_accountant ?? true;
    const hasAccountantDeputy = settingsData.has_accountant_deputy ?? false;

    const newBoardMembers: BoardMember[] = [];

    // Add main board positions with both display position and positionKey
    newBoardMembers.push({ 
      position: t("board.positions.chairman"), 
      positionKey: POSITION_KEYS.chairman, 
      memberId: null, 
      type: 'board' 
    });
    
    newBoardMembers.push({ 
      position: t("board.positions.cashier"), 
      positionKey: POSITION_KEYS.cashier, 
      memberId: null, 
      type: 'board' 
    });
    
    // Add additional board members
    for (let i = 1; i <= boardMemberCount - 2; i++) {
      newBoardMembers.push({ 
        position: `${t("board.positions.boardMember")} ${i}`, 
        positionKey: `${POSITION_KEYS.boardMember}_${i}`, 
        memberId: null,
        type: 'board'
      });
    }

    // Add deputy members if enabled
    if (hasDeputyMembers) {
      for (let i = 1; i <= deputyMemberCount; i++) {
        newBoardMembers.push({ 
          position: `${t("board.positions.deputyMember")} ${i}`, 
          positionKey: `${POSITION_KEYS.deputyMember}_${i}`, 
          memberId: null,
          type: 'deputy'
        });
      }
    }

    // Add accountant if enabled
    if (hasVerifyingAccountant) {
      newBoardMembers.push({ 
        position: t("board.positions.accountant"), 
        positionKey: POSITION_KEYS.accountant, 
        memberId: null,
        type: 'accountant'
      });

      // Add accountant deputy if enabled
      if (hasAccountantDeputy) {
        newBoardMembers.push({ 
          position: t("board.positions.accountantDeputy"), 
          positionKey: POSITION_KEYS.accountantDeputy, 
          memberId: null,
          type: 'accountantDeputy'
        });
      }
    }

    // Fill in existing assignments - match by positionKey 
    // This is a temporary solution to handle existing data that might only have position and not positionKey
    assignmentsData?.forEach(assignment => {
      // Try to find position by exact match first (for existing data)
      let boardMember = newBoardMembers.find(bm => 
        bm.position === assignment.position || bm.positionKey === assignment.position
      );

      if (boardMember) {
        boardMember.memberId = assignment.member_id;
      }
    });

    setBoardMembers(newBoardMembers);
  };

  const handleBoardMemberChange = (positionKey: string, memberId: string) => {
    setBoardMembers(prev => prev.map(bm => 
      bm.positionKey === positionKey ? { ...bm, memberId } : bm
    ));
  };

  const handleSaveAssignments = async () => {
    if (!currentTenant) return;

    try {
      setIsSaving(true);

      // Delete existing assignments
      await supabase
        .from('board_assignments')
        .delete()
        .eq('tenant_id', currentTenant.id);

      // Insert new assignments using positionKey
      const assignments = boardMembers
        .filter(bm => bm.memberId) // Only save positions that have an assigned member
        .map(bm => ({
          tenant_id: currentTenant.id,
          position: bm.positionKey, // Store the language-independent key
          member_id: bm.memberId
        }));

      const { error } = await supabase
        .from('board_assignments')
        .insert(assignments);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Board assignments have been saved",
      });
    } catch (error) {
      console.error('Error saving board assignments:', error);
      toast({
        title: "Error",
        description: "Failed to save board assignments",
        variant: "destructive",
      });
    } finally {
      setIsSaving(false);
    }
  };

  // Get array of member IDs that are already assigned to positions
  const assignedMemberIds = boardMembers.map(bm => bm.memberId).filter(Boolean) as string[];

  return {
    boardMembers,
    settings,
    settingsLoading,
    isSaving,
    assignedMemberIds,
    handleBoardMemberChange,
    handleSaveAssignments
  };
}
