
import { Search, Filter, X } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";

interface MemberSearchProps {
  searchQuery: string;
  onSearchChange: (query: string) => void;
}

export function MemberSearch({ searchQuery, onSearchChange }: MemberSearchProps) {
  const { t } = useTranslation();

  const handleClearSearch = () => {
    onSearchChange("");
  };

  return (
    <div className="flex gap-4 mb-6 m-3">
      <div className="relative flex-1">
        <Search className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
        <Input
          placeholder={t("members.searchPlaceholder") + " (name, email, or address)"}
          value={searchQuery}
          onChange={(e) => onSearchChange(e.target.value)}
          className="pl-9 pr-8"
        />
        {searchQuery && (
          <button
            onClick={handleClearSearch}
            className="absolute right-3 top-3 text-muted-foreground hover:text-foreground"
            type="button"
          >
            <X className="h-4 w-4" />
          </button>
        )}
      </div>
    </div>
  );
}
