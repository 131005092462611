
import { Loader2 } from "lucide-react";
import { Card } from "@/components/ui/card";
import { useTranslation } from "react-i18next";
import { StreetList } from "./streets/StreetList";
import { StreetManagementHeader } from "./streets/StreetManagementHeader";
import { useStreetManagement } from "./streets/hooks/useStreetManagement";

export const StreetManagement = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    streets,
    selectedStreet,
    setSelectedStreet,
    handleAddStreet,
    handleRemoveStreet
  } = useStreetManagement();

  if (isLoading) {
    return (
      <Card className="p-6 flex items-center justify-center">
        <Loader2 className="h-6 w-6 animate-spin" />
      </Card>
    );
  }

  return (
    <Card className="p-6">
      <h2 className="text-xl font-semibold mb-4">{t("settings.streets.title")}</h2>
      <p className="text-muted-foreground mb-4">{t("settings.streets.subtitle")}</p>
      <div className="space-y-4">
        <StreetManagementHeader
          selectedStreet={selectedStreet}
          setSelectedStreet={setSelectedStreet}
          onAddStreet={handleAddStreet}
        />

        <StreetList 
          streets={streets} 
          onRemoveStreet={handleRemoveStreet} 
        />
      </div>
    </Card>
  );
};
