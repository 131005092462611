
import { PageContainer } from "@/components/PageContainer";
import { PageHeader } from "@/components/PageHeader";
import { useMembers } from "@/hooks/useMembers";
import { useTenant } from "@/contexts/TenantContext";
import { useTranslation } from "react-i18next";
import { BoardContent } from "@/components/board/BoardContent";
import { BoardLoadingState } from "@/components/board/BoardLoadingState";
import { useBoard } from "@/components/board/useBoard";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";
import { useRole } from "@/hooks/useRole";

const Board = () => {
  const { t } = useTranslation();
  const { currentTenant } = useTenant();
  const { members, isLoading } = useMembers();
  const { isAdmin } = useRole();
  const {
    boardMembers,
    settings,
    settingsLoading,
    isSaving,
    assignedMemberIds,
    handleBoardMemberChange,
    handleSaveAssignments
  } = useBoard(currentTenant);

  return (
    <PageContainer>
      <PageHeader
        title={t("board.title")}
        subtitle={t("board.subtitle")}
      />
      
      {!isAdmin && (
        <Alert className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Read-only mode</AlertTitle>
          <AlertDescription>
            You have read-only access to the board information. Only administrators can make changes.
          </AlertDescription>
        </Alert>
      )}

      {settingsLoading ? (
        <BoardLoadingState />
      ) : (
        <BoardContent
          boardMembers={boardMembers}
          settings={settings}
          members={members}
          isLoading={isLoading}
          assignedMemberIds={assignedMemberIds}
          isSaving={isSaving}
          onMemberSelect={handleBoardMemberChange}
          onSave={handleSaveAssignments}
          readOnly={!isAdmin}
        />
      )}
    </PageContainer>
  );
};

export default Board;
