
import { useTranslation } from "react-i18next";
import { Member } from "@/types/member";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { useMemo } from "react";

interface MemberStatisticsProps {
  members: Member[];
}

export function MemberStatistics({ members }: MemberStatisticsProps) {
  const { t } = useTranslation();
  
  const statistics = useMemo(() => {
    // Total number of members
    const totalMembers = members.length;
    
    // Group members by street name and count them
    const streetCounts: Record<string, number> = {};
    members.forEach(member => {
      if (streetCounts[member.street_name]) {
        streetCounts[member.street_name]++;
      } else {
        streetCounts[member.street_name] = 1;
      }
    });
    
    // Find the street with the most members (for progress bar max value)
    const maxStreetCount = Math.max(...Object.values(streetCounts), 0);
    
    // Count active and inactive members
    const activeMembers = members.filter(member => member.status === 'active').length;
    const inactiveMembers = totalMembers - activeMembers;
    
    // Calculate percentages for active/inactive
    const activePercentage = totalMembers ? Math.round((activeMembers / totalMembers) * 100) : 0;
    
    return {
      totalMembers,
      streetCounts,
      maxStreetCount,
      activeMembers,
      inactiveMembers,
      activePercentage
    };
  }, [members]);
  
  const sortedStreets = useMemo(() => {
    return Object.entries(statistics.streetCounts)
      .sort(([a], [b]) => a.localeCompare(b));
  }, [statistics.streetCounts]);
  
  return (
    <div className="space-y-4">
      <Card>
        <CardHeader className="pb-2">
          <CardTitle>{t('members.statistics')}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid gap-4">
            <div className="flex items-center justify-between">
              <div className="text-sm font-medium">{t('members.totalMembers')}</div>
              <div className="font-bold text-lg">{statistics.totalMembers}</div>
            </div>
            
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <div className="text-sm font-medium">{t('members.activeMembers')}</div>
                <div className="text-sm">{statistics.activeMembers}</div>
              </div>
              <Progress value={statistics.activePercentage} className="h-2" />
            </div>
          </div>
        </CardContent>
      </Card>
      
      <Card>
        <CardHeader className="pb-2">
          <CardTitle>{t('members.byStreet')}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {sortedStreets.map(([street, count]) => (
              <div key={street} className="space-y-1">
                <div className="flex justify-between text-sm">
                  <span>{street}</span>
                  <span className="font-medium">{count}</span>
                </div>
                <Progress 
                  value={(count / statistics.maxStreetCount) * 100} 
                  className="h-2" 
                />
              </div>
            ))}
            
            {sortedStreets.length === 0 && (
              <p className="text-muted-foreground italic">{t('common.noData')}</p>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
