
import { FC } from "react";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { useTranslation } from "react-i18next";
import { StreetData } from "@/types/street";
import { StreetSearchAutocomplete } from "./StreetSearchAutocomplete";

interface StreetManagementHeaderProps {
  selectedStreet: StreetData | null;
  setSelectedStreet: (street: StreetData | null) => void;
  onAddStreet: () => void;
}

export const StreetManagementHeader: FC<StreetManagementHeaderProps> = ({
  selectedStreet,
  setSelectedStreet,
  onAddStreet
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-2">
      <StreetSearchAutocomplete
        selectedStreet={selectedStreet}
        setSelectedStreet={setSelectedStreet}
      />
      <Button 
        onClick={onAddStreet}
        disabled={!selectedStreet}
      >
        <Plus className="w-4 h-4 mr-2" />
        {t("settings.streets.addStreet")}
      </Button>
    </div>
  );
};
