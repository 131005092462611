
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { Info } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface BoardSettingItemProps {
  label: string;
  description: string;
  type: "number" | "switch";
  value: number | boolean;
  onChange: (value: number | boolean) => void;
  inputProps?: {
    min?: number;
    max?: number;
  };
  tooltip?: string;
}

export const BoardSettingItem = ({
  label,
  description,
  type,
  value,
  onChange,
  inputProps,
  tooltip
}: BoardSettingItemProps) => {
  return (
    <div className="flex items-start justify-between gap-4">
      <div className="space-y-0.5 text-left flex-1">
        <div className="flex items-center gap-2">
          <Label className="text-left" htmlFor={label.toLowerCase().replace(/\s+/g, '-')}>
            {label}
          </Label>
          {tooltip && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Info className="h-4 w-4 text-muted-foreground" />
                </TooltipTrigger>
                <TooltipContent>
                  <p className="max-w-[250px]">{tooltip}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
        <p className="text-sm text-muted-foreground text-left">{description}</p>
      </div>
      {type === "number" ? (
        <Input
          id={label.toLowerCase().replace(/\s+/g, '-')}
          type="number"
          min={inputProps?.min ?? 1}
          max={inputProps?.max ?? 10}
          value={value as number}
          onChange={(e) => onChange(parseInt(e.target.value) || inputProps?.min || 1)}
          className="w-20"
        />
      ) : (
        <Switch
          id={label.toLowerCase().replace(/\s+/g, '-')}
          checked={value as boolean}
          onCheckedChange={onChange}
        />
      )}
    </div>
  );
};
