import { useState, useEffect } from "react";
import { X, Info, PlusSquare, QrCode } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Checkbox } from "@/components/ui/checkbox";
import { StoredStreetData } from "@/types/street";
import { CadastreDialog } from "./CadastreDialog";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import { OnboardingCodeDialog } from "./OnboardingCodeDialog";

interface StreetListProps {
  streets: StoredStreetData[];
  onRemoveStreet: (streetName: string, removeAssociatedCadastres: boolean) => void;
}

export const StreetList = ({ streets, onRemoveStreet }: StreetListProps) => {
  const { t } = useTranslation();
  const [streetToDelete, setStreetToDelete] = useState<string | null>(null);
  const [selectedStreet, setSelectedStreet] = useState<StoredStreetData | null>(null);
  const [isCadastreDialogOpen, setIsCadastreDialogOpen] = useState(false);
  const [isOnboardingCodeDialogOpen, setIsOnboardingCodeDialogOpen] = useState(false);
  const [removeAssociatedCadastres, setRemoveAssociatedCadastres] = useState(true);
  const [deletionStats, setDeletionStats] = useState({ cadastreCount: 0 });
  const [memberCount, setMemberCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const openCadastreDialog = (street: StoredStreetData) => {
    setSelectedStreet(street);
    setIsCadastreDialogOpen(true);
  };

  const closeCadastreDialog = () => {
    setIsCadastreDialogOpen(false);
    setSelectedStreet(null);
  };

  const handleInitiateDelete = (streetName: string) => {
    setStreetToDelete(streetName);
    // Reset the checkbox state
    setRemoveAssociatedCadastres(true);
    setDeletionStats({ cadastreCount: 0 });
    setMemberCount(0);
  };

  const openOnboardingCodeDialog = (street: StoredStreetData) => {
    setSelectedStreet(street);
    setIsOnboardingCodeDialogOpen(true);
  };

  const closeOnboardingCodeDialog = () => {
    setIsOnboardingCodeDialogOpen(false);
    setSelectedStreet(null);
  };

  useEffect(() => {
    const fetchDeletionStats = async () => {
      if (streetToDelete && removeAssociatedCadastres) {
        setIsLoading(true);
        try {
          // Count affected cadastres
          const { data: cadastres, error: cadastreError } = await supabase
            .from('cadastres')
            .select('id')
            .eq('street_name', streetToDelete);
            
          if (cadastreError) throw cadastreError;
          
          // Count affected members
          const cadastreIds = cadastres?.map(c => c.id) || [];
          if (cadastreIds.length > 0) {
            const { count, error: memberError } = await supabase
              .from('members')
              .select('id', { count: 'exact', head: true })
              .in('cadastre_id', cadastreIds);
              
            if (memberError) throw memberError;
            
            setMemberCount(count || 0);
          } else {
            setMemberCount(0);
          }
          
          setDeletionStats({
            cadastreCount: cadastres?.length || 0
          });
        } catch (error) {
          console.error('Error getting deletion stats:', error);
          toast.error("Failed to get deletion information");
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchDeletionStats();
  }, [streetToDelete, removeAssociatedCadastres]);

  const handleDeleteConfirm = async () => {
    if (streetToDelete) {
      setIsLoading(true);
      try {
        await onRemoveStreet(streetToDelete, removeAssociatedCadastres);
        toast.success(t("settings.streets.deleteSuccess", "Street deleted successfully"));
        
        // Invalidate relevant cache queries to ensure lists are updated
        queryClient.invalidateQueries({ queryKey: ['cadastres'] });
        queryClient.invalidateQueries({ queryKey: ['members'] });
        
      } catch (error) {
        console.error("Error deleting street:", error);
        toast.error(t("settings.streets.deleteError", "Failed to delete street"));
      } finally {
        // Reset state
        setStreetToDelete(null);
        setRemoveAssociatedCadastres(true);
        setIsLoading(false);
      }
    }
  };

  const handleDeleteCancel = () => {
    // Close all dialogs and reset state
    setStreetToDelete(null);
    setRemoveAssociatedCadastres(true);
  };

  return (
    <div className="space-y-2">
      {streets.map((street) => (
        <div
          key={street.street_name}
          className="flex items-center justify-between p-2 bg-muted rounded-md"
        >
          <div className="flex items-center gap-2">
            <span>{street.street_name}</span>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="ghost" size="icon" className="h-6 w-6 p-0">
                    <Info className="h-4 w-4 text-muted-foreground" />
                    <span className="sr-only">Street Info</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent className="space-y-1">
                  <p className="font-semibold">{street.street_name}</p>
                  {street.postnummer && street.postnavn && (
                    <p>{`${street.postnummer}, ${street.postnavn}`}</p>
                  )}
                  {street.address_count !== undefined && (
                    <p className="text-xs">
                      {t("settings.streets.addressCount")}: {street.address_count}
                    </p>
                  )}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          <div className="flex items-center gap-1">
            {/* QR Code button for generating onboarding codes */}
            <Button 
              variant="ghost" 
              size="sm" 
              onClick={() => openOnboardingCodeDialog(street)}
              title={t("settings.streets.generateOnboardingCodes", "Generate Onboarding Codes")}
            >
              <QrCode className="w-4 h-4" />
            </Button>

            <Button 
              variant="ghost" 
              size="sm" 
              onClick={() => openCadastreDialog(street)}
              title={t("settings.streets.createCadastres")}
            >
              <PlusSquare className="w-4 h-4" />
            </Button>

            <AlertDialog open={streetToDelete === street.street_name}>
              <AlertDialogTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => handleInitiateDelete(street.street_name)}
                >
                  <X className="w-4 h-4" />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>{t("common.delete")}</AlertDialogTitle>
                  <AlertDialogDescription>
                    {t("settings.streets.deleteConfirm")} {street.street_name}?
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <Checkbox 
                      id="remove-cadastres"
                      checked={removeAssociatedCadastres}
                      onCheckedChange={(checked) => setRemoveAssociatedCadastres(checked === true)}
                    />
                    <label
                      htmlFor="remove-cadastres"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      {t("settings.streets.removeAssociatedCadastres")}
                    </label>
                  </div>
                  
                  {/* Expandable details section */}
                  {removeAssociatedCadastres && (
                    <div className="border rounded-md p-3 bg-muted/50 space-y-2">
                      <p className="text-sm font-medium">
                        {t("settings.streets.finalDeleteConfirmDescription")}
                      </p>
                      <p className="text-sm">
                        {t("settings.streets.cadastresToBeDeleted", { count: deletionStats.cadastreCount })}
                      </p>
                      <p className="text-sm" style={{ color: memberCount > 0 ? 'red' : 'inherit' }}>
                        {t("settings.streets.membersWithAssociation", { count: memberCount })}
                      </p>
                      {memberCount > 0 && <p className="text-sm italic" >
                        {t("settings.streets.membersWithAssociationNotice")}
                      </p>}
                    </div>
                  )}
                </div>
                <AlertDialogFooter>
                  <AlertDialogCancel onClick={handleDeleteCancel}>
                    {t("common.cancel")}
                  </AlertDialogCancel>
                  <AlertDialogAction 
                    onClick={handleDeleteConfirm}
                    disabled={isLoading || (removeAssociatedCadastres && memberCount > 0)}
                  >
                    {t("common.delete")}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        </div>
      ))}
      {streets.length === 0 && (
        <p className="text-sm text-muted-foreground">
          {t("settings.streets.noStreets")}
        </p>
      )}

      <CadastreDialog 
        street={selectedStreet}
        isOpen={isCadastreDialogOpen}
        onClose={closeCadastreDialog}
      />

      <OnboardingCodeDialog
        street={selectedStreet}
        isOpen={isOnboardingCodeDialogOpen}
        onClose={closeOnboardingCodeDialog}
      />
    </div>
  );
};
