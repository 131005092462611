
import { useTranslation } from "react-i18next";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { Member } from "@/types/member";
import { BoardPositionsSection } from "./BoardPositionsSection";
import { BoardMember, BoardSettings } from "./useBoard";

interface BoardContentProps {
  boardMembers: BoardMember[];
  settings: BoardSettings;
  members: Member[];
  isLoading: boolean;
  assignedMemberIds: string[];
  isSaving: boolean;
  onMemberSelect: (positionKey: string, memberId: string) => void;
  onSave: () => void;
  readOnly?: boolean;
}

export const BoardContent = ({
  boardMembers,
  settings,
  members,
  isLoading,
  assignedMemberIds,
  isSaving,
  onMemberSelect,
  onSave,
  readOnly = false
}: BoardContentProps) => {
  const { t } = useTranslation();

  // Group board members by type
  const boardPositions = boardMembers.filter(bm => bm.type === 'board');
  const deputyPositions = boardMembers.filter(bm => bm.type === 'deputy');
  const accountantPositions = boardMembers.filter(bm => 
    bm.type === 'accountant' || bm.type === 'accountantDeputy'
  );

  return (
    <Card className="p-6">
      <div className="space-y-6">
        {/* Board Positions */}
        <BoardPositionsSection
          title={t("board.sections.boardMembers")}
          boardMembers={boardMembers}
          type="board"
          members={members}
          isLoading={isLoading}
          assignedMemberIds={assignedMemberIds}
          onMemberSelect={onMemberSelect}
          readOnly={readOnly}
        />

        {/* Deputy Members */}
        {settings.hasDeputyMembers && deputyPositions.length > 0 && (
          <BoardPositionsSection
            title={t("board.sections.deputyMembers")}
            boardMembers={boardMembers}
            type="deputy"
            members={members}
            isLoading={isLoading}
            assignedMemberIds={assignedMemberIds}
            onMemberSelect={onMemberSelect}
            readOnly={readOnly}
          />
        )}

        {/* Accountants */}
        {settings.hasVerifyingAccountant && accountantPositions.length > 0 && (
          <BoardPositionsSection
            title={t("board.sections.accountants")}
            boardMembers={boardMembers}
            type="accountant"
            members={members}
            isLoading={isLoading}
            assignedMemberIds={assignedMemberIds}
            onMemberSelect={onMemberSelect}
            readOnly={readOnly}
          />
        )}
        
        <div className="flex justify-end">
          {!readOnly && (
            <Button 
              onClick={onSave} 
              disabled={isSaving}
            >
              {isSaving ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  {t("board.saving")}
                </>
              ) : (
                t("board.saveAssignments")
              )}
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};
