
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { useStatuteDocuments } from "@/hooks/useStatuteDocuments";

export function useStatuteFile() {
  const { t } = useTranslation();
  const { data: statuteDocuments } = useStatuteDocuments();
  
  const currentStatute = statuteDocuments && statuteDocuments.length > 0 ? statuteDocuments[0] : null;
  const statuteFile = currentStatute ? {
    path: currentStatute.file_path,
    name: currentStatute.file_name
  } : null;
  
  const handleDownload = async () => {
    if (!statuteFile) return;
    
    try {
      const { data, error } = await supabase.storage
        .from('documents')
        .download(statuteFile.path);
        
      if (error) throw error;
      
      const url = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = statuteFile.name;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading statute document:", error);
      toast.error(t("settings.statutes.downloadError"));
    }
  };
  
  return {
    currentStatute,
    statuteFile,
    handleDownload
  };
}
