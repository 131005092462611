
import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useTenant } from "@/contexts/TenantContext";
import { toast } from "sonner";

export interface BankSettings {
  id?: string;
  redirect_uri: string;
  market: string;
}

export interface BankAccessToken {
  id?: string;
  tenant_id: string;
  access_token: string;
  access_expires: string;
  refresh_token: string;
  refresh_expires: string;
}

export function useBankSettings() {
  const { currentTenant } = useTenant();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  
  const queryKey = ['bank-settings', currentTenant?.id];
  const accessQueryKey = ['bank-access', currentTenant?.id];

  // Query to fetch bank settings
  const { data: bankSettings, isLoading: isLoadingSettings } = useQuery({
    queryKey,
    queryFn: async (): Promise<BankSettings> => {
      if (!currentTenant?.id) {
        return {
          redirect_uri: window.location.origin + "/callback",
          market: "DK"
        };
      }

      const { data, error } = await supabase
        .from('bank_settings')
        .select('*')
        .eq('tenant_id', currentTenant.id)
        .single();

      if (error) {
        console.error("Error fetching bank settings:", error);
        // Return default values if no settings found
        return {
          redirect_uri: window.location.origin + "/callback",
          market: "DK"
        };
      }
      
      return {
        redirect_uri: data.redirect_uri,
        market: data.market
      };
    },
    enabled: !!currentTenant?.id,
  });

  // Query to check if bank is connected
  const { data: bankAccess, isLoading: isLoadingAccess } = useQuery({
    queryKey: accessQueryKey,
    queryFn: async (): Promise<BankAccessToken | null> => {
      if (!currentTenant?.id) {
        return null;
      }

      const { data, error } = await supabase
        .from('bank_access')
        .select('*')
        .eq('tenant_id', currentTenant.id)
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      if (error) {
        if (error.code === 'PGRST116') {
          // No records found
          return null;
        }
        console.error("Error fetching bank access token:", error);
        return null;
      }
      
      return data as BankAccessToken;
    },
    enabled: !!currentTenant?.id,
  });

  // Determine if the bank is connected
  const isConnected = !!bankAccess && new Date(bankAccess.access_expires) > new Date();

  // Function to initiate bank connection
  const connectToBank = async (): Promise<string> => {
    if (!currentTenant?.id) {
      throw new Error("No tenant selected");
    }
    
    setIsConnecting(true);
    
    try {
      const response = await supabase.functions.invoke('gocardless-bank?method=auth-url', {
        method: 'POST',
        body: {
          tenant_id: currentTenant.id,
          redirect_url: window.location.href
        },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      
      if (response.error) {
        throw new Error(response.error);
      }

      // Return the authentication URL to redirect to
      return response.data.auth_url;
    } catch (error) {
      console.error("Error connecting to bank:", error);
      throw error;
    } finally {
      setIsConnecting(false);
    }
  };

  // Mutation to save bank settings
  const saveBankSettingsMutation = useMutation({
    mutationFn: async (settings: BankSettings) => {
      if (!currentTenant?.id) {
        throw new Error("No tenant selected");
      }

      setIsLoading(true);
      
      // Check if settings already exist for this tenant
      const { data: existingSettings, error: fetchError } = await supabase
        .from('bank_settings')
        .select('*')
        .eq('tenant_id', currentTenant.id)
        .maybeSingle();

      if (fetchError && fetchError.code !== 'PGRST116') {
        throw fetchError;
      }

      // We need to include empty placeholders for client_id and client_secret
      // if creating a new record, as they're required by the database schema
      // but are actually stored as environment variables on the server
      if (!existingSettings) {
        // Insert new record with placeholder values for client_id and client_secret
        const { error } = await supabase
          .from('bank_settings')
          .insert({
            tenant_id: currentTenant.id,
            redirect_uri: settings.redirect_uri,
            market: settings.market,
            client_id: 'stored_in_env',  // Placeholder value
            client_secret: 'stored_in_env'  // Placeholder value
          });

        if (error) {
          throw error;
        }
      } else {
        // Update existing record, only updating the fields we manage in the UI
        const { error } = await supabase
          .from('bank_settings')
          .update({
            redirect_uri: settings.redirect_uri,
            market: settings.market
          })
          .eq('tenant_id', currentTenant.id);

        if (error) {
          throw error;
        }
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      toast.success("Bank settings saved successfully");
    },
    onError: (error) => {
      console.error("Error saving bank settings:", error);
      toast.error("Failed to save bank settings");
    },
    onSettled: () => {
      setIsLoading(false);
    }
  });

  return {
    bankSettings: bankSettings || {
      redirect_uri: window.location.origin + "/callback",
      market: "DK"
    },
    isConnected,
    bankAccess,
    isLoading: isLoadingSettings || isLoading,
    isConnecting,
    connectToBank,
    saveBankSettings: saveBankSettingsMutation.mutate
  };
}
