
import { Button } from "@/components/ui/button";
import { LogIn, Plus, Ticket } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ActionButtonsProps {
  hasCreationRequest: boolean;
}

export const ActionButtons = ({ hasCreationRequest }: ActionButtonsProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 sm:flex-row justify-center items-center">
      <Button size="lg" onClick={() => navigate('/tenants/join')} className="w-full sm:w-auto">
        <LogIn className="w-4 h-4 mr-2" />
        {t("home.actions.joinExisting")}
      </Button>
      <Button 
        size="lg" 
        variant="outline" 
        onClick={() => navigate('/tenants/request-create')} 
        className="w-full sm:w-auto"
        disabled={hasCreationRequest}
      >
        <Plus className="w-4 h-4 mr-2" />
        {hasCreationRequest ? t("home.actions.requestNewPending") : t("home.actions.requestNew")}
      </Button>
      <Button 
        size="lg"
        variant="secondary"
        onClick={() => navigate('/tenants/onboarding-code')}
        className="w-full sm:w-auto"
      >
        <Ticket className="w-4 h-4 mr-2" />
        {t("home.actions.enterCode", "Enter Code")}
      </Button>
    </div>
  );
};
