
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useRole } from "@/hooks/useRole";
import { useTenant } from "@/contexts/TenantContext";
import { XCircle, Zap } from "lucide-react";
import { toast } from "sonner";
import { generateAndSaveOnboardingCode } from "@/utils/onboardingCodes";
import { useAssociationSettings } from "@/hooks/useAssociationSettings";

interface CadastreFormProps {
  cadastre: {
    id?: string;
    streetName: string;
    streetNumber: string;
    plotNumber: string;
    onboarding_code?: string;
  };
  streets: string[];
  onSubmit: () => void;
  onChange: (values: { streetName?: string; streetNumber?: string; plotNumber?: string; onboarding_code?: string }) => void;
  onCancel?: () => void;
  submitLabel?: string;
}

export function CadastreForm({
  cadastre,
  streets,
  onSubmit,
  onChange,
  onCancel,
  submitLabel = "Save"
}: CadastreFormProps) {
  const { t } = useTranslation();
  const { currentTenant } = useTenant();
  const { isAdmin, isSuperAdmin } = useRole();
  const { data: associationSettings } = useAssociationSettings();
  const [isGenerating, setIsGenerating] = useState(false);
  const isSaveDisabled = !cadastre.streetName || !cadastre.streetNumber.trim();
  const canViewOnboardingCode = isAdmin || isSuperAdmin;
  
  const clearOnboardingCode = () => {
    onChange({ onboarding_code: null });
  };

  const generateOnboardingCode = async () => {
    if (!cadastre.id || !currentTenant) return;
    
    try {
      setIsGenerating(true);
      
      const result = await generateAndSaveOnboardingCode({
        cadastreId: cadastre.id,
        streetName: cadastre.streetName,
        streetNumber: cadastre.streetNumber,
        tenantId: currentTenant.id,
        associationId: associationSettings?.id,
        associationName: associationSettings?.name
      });
      
      if (result.success && result.code) {
        onChange({ onboarding_code: result.code });
        toast.success(t('cadastres.onboardingCodeGenerated', 'Onboarding code generated successfully'));
      } else {
        toast.error(t('cadastres.onboardingCodeGenerationFailed', 'Failed to generate onboarding code'));
      }
    } catch (error) {
      console.error('Error generating onboarding code:', error);
      toast.error(t('cadastres.onboardingCodeGenerationFailed', 'Failed to generate onboarding code'));
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <label className="text-sm font-medium">{t('cadastres.street')}</label>
          <Select 
            value={cadastre.streetName} 
            onValueChange={value => onChange({ streetName: value })}
          >
            <SelectTrigger>
              <SelectValue placeholder={t('cadastres.selectStreet')} />
            </SelectTrigger>
            <SelectContent>
              {streets.map(street => (
                <SelectItem key={street} value={street}>
                  {street}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="space-y-2">
          <label className="text-sm font-medium">{t('cadastres.street')} #</label>
          <Input 
            value={cadastre.streetNumber} 
            onChange={e => onChange({ streetNumber: e.target.value })} 
            placeholder={t('cadastres.enterStreetNumber')}
          />
        </div>
      </div>
      <div className="space-y-2">
        <label className="text-sm font-medium">{t('cadastres.plotNumber')}</label>
        <Input 
          value={cadastre.plotNumber} 
          onChange={e => onChange({ plotNumber: e.target.value })} 
          placeholder={t('cadastres.enterPlotNumber')}
        />
      </div>
      
      {/* Onboarding Code section - only visible to admins */}
      {canViewOnboardingCode && cadastre.id && (
        <div className="space-y-2 pt-4 border-t">
          <div className="flex justify-between items-center">
            <label className="text-sm font-medium">{t('cadastres.onboardingCode', 'Onboarding Code')}</label>
            {cadastre.onboarding_code ? (
              <Button 
                variant="ghost" 
                size="sm" 
                onClick={clearOnboardingCode}
                className="h-8 px-2"
              >
                <XCircle className="h-4 w-4 mr-1" />
                {t('common.clear', 'Clear Code')}
              </Button>
            ) : (
              <Button 
                variant="ghost" 
                size="sm" 
                onClick={generateOnboardingCode}
                disabled={isGenerating}
                className="h-8 px-2"
              >
                <Zap className="h-4 w-4 mr-1" />
                {t('cadastres.generateCode', 'Generate')}
              </Button>
            )}
          </div>
          <Input 
            value={cadastre.onboarding_code || t('cadastres.noCodeGenerated', 'No code generated')} 
            readOnly 
            className="font-mono bg-muted"
          />
          <p className="text-xs text-muted-foreground">
            {t('cadastres.onboardingCodeDescription', 'This code can be used to identify this cadastre during onboarding.')}
          </p>
        </div>
      )}
      
      <div className={onCancel ? "flex justify-end gap-2" : ""}>
        {onCancel && (
          <Button variant="outline" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
        )}
        <Button 
          onClick={onSubmit}
          disabled={isSaveDisabled}
          className={onCancel ? "" : "w-full"}
        >
          {submitLabel}
        </Button>
      </div>
    </div>
  );
}
