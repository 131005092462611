
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useAssociationDetailsForm } from "@/hooks/useAssociationDetailsForm";
import { LogoUploader } from "./association/LogoUploader";
import { CVRLookup } from "./association/CVRLookup";
import { AddressFields } from "./association/AddressFields";
import { UrlNameField } from "./association/UrlNameField";
import { useTenant } from "@/contexts/TenantContext";

export function AssociationDetailsForm() {
  const { currentTenant } = useTenant();
  const { t } = useTranslation();
  const {
    isLoading,
    isSaving,
    formData,
    settings,
    urlNameChanged,
    handleCVRChange,
    handleAddressFieldChange,
    handleLogoChange,
    handleUrlNameChange,
    handleSubmit
  } = useAssociationDetailsForm();

  if (isLoading) {
    return (
      <Card>
        <CardContent className="flex items-center justify-center py-6">
          <Loader2 className="h-6 w-6 animate-spin" />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <div>
            <CardTitle>{t("settings.association.title")}</CardTitle>
            <CardDescription>{t("settings.association.subtitle")}</CardDescription>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <LogoUploader 
            tenantId={currentTenant?.id} 
            logoUrl={formData.logoUrl} 
            onLogoChange={handleLogoChange} 
          />

          <CVRLookup 
            value={formData.cvrNumber} 
            onChange={handleCVRChange} 
          />

          <div className="space-y-2">
            <Label className="text-left block" htmlFor="name">{t("settings.association.name")}</Label>
            <Input
              id="name"
              value={formData.name}
              onChange={(e) => handleAddressFieldChange('name', e.target.value)}
            />
          </div>

          <AddressFields 
            streetAddress={formData.streetAddress}
            streetNumber={formData.streetNumber}
            postNumber={formData.postNumber}
            country={formData.country}
            onChange={handleAddressFieldChange}
          />

          <UrlNameField
            urlName={formData.urlName}
            onChange={handleUrlNameChange}
            tenantId={currentTenant?.id}
            existingUrlName={settings?.url_name}
          />

          <Button 
            type="submit" 
            disabled={isSaving || (urlNameChanged && settings?.url_name !== formData.urlName)}
          >
            {isSaving ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                {t("common.saving")}
              </>
            ) : (
              t("common.save")
            )}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}
