
import { useEffect, useState } from "react";
import { 
  FileIcon, FileSpreadsheet, FileText, 
  Image, FileArchive, Video, Music, 
  FileCode, File, Download, Trash, Eye, 
  ChevronUp, ChevronDown, FolderIcon,
  Edit,
  Trash2
} from "lucide-react";
import { FaRegFilePdf } from "react-icons/fa6";
import { DocumentFile, DocumentFolder } from "@/types/document";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { useTranslation } from "react-i18next";
import { useRole } from "@/hooks/useRole";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { toast } from "sonner";

interface CompactFileListProps {
  folders: DocumentFolder[];
  files: DocumentFile[];
  onOpenFolder: (folderId: string) => void;
  onEditFolder: (folder: DocumentFolder) => void;
  onDeleteFolder: (folderId: string, folderName: string) => void;
  onViewFile: (file: DocumentFile) => void;
  onDownloadFile: (file: DocumentFile) => void;
  onDeleteFile: (fileId: string, fileName: string, path: string) => void;
  onSelectionChange: (selectedItems: {
    folders: DocumentFolder[];
    files: DocumentFile[];
  }) => void;
  onUploadFile?: (file: File) => Promise<void>;
  currentFolderId: string | null;
}

type SortField = "name" | "type" | "size";
type SortDirection = "asc" | "desc";

type ListItem = {
  id: string;
  isFolder: boolean;
  name: string;
  type?: string;
  size?: number;
  item: DocumentFolder | DocumentFile;
}

export function CompactFileList({
  folders,
  files,
  onOpenFolder,
  onEditFolder,
  onDeleteFolder,
  onViewFile,
  onDownloadFile,
  onDeleteFile,
  onSelectionChange,
  onUploadFile,
  currentFolderId
}: CompactFileListProps) {
  const { t } = useTranslation();
  const { canEdit, canDelete } = useRole();
  
  // Sorting state
  const [sortField, setSortField] = useState<SortField>("name");
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc");
  
  // Selection state
  const [selectedFolders, setSelectedFolders] = useState<Set<string>>(new Set());
  const [selectedFiles, setSelectedFiles] = useState<Set<string>>(new Set());
  
  // Drag and drop states
  const [dragActive, setDragActive] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  
  // Format file size for display
  const formatFileSize = (bytes: number) => {
    if (bytes < 1024) return bytes + ' B';
    if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + ' KB';
    return (bytes / (1024 * 1024)).toFixed(1) + ' MB';
  };

  const iconSizeStyle = "h-6 w-6";
  
  // Get file type icon
  const getFileIcon = (file: DocumentFile) => {
    const type = file.content_type.split('/')[0];
    const subtype = file.content_type.split('/')[1];
    switch (type) {
      case 'image':
        return <Image className={iconSizeStyle+" text-blue-500"} />;
      case 'application':
        if (subtype.includes('pdf')) return <FaRegFilePdf className={iconSizeStyle+" text-red-500"} />;
        if (subtype.includes('spreadsheet') || subtype.includes('excel')) return <FileSpreadsheet className={iconSizeStyle+" text-green-500"} />;
        if (subtype.includes('document') || subtype.includes('word')) return <FileText className={iconSizeStyle+" text-blue-500"} />;
        if (subtype.includes('zip') || subtype.includes('rar') || subtype.includes('compressed')) return <FileArchive className={iconSizeStyle+" text-orange-500"} />;
        if (subtype.includes('json') || subtype.includes('xml') || subtype.includes('javascript') || subtype.includes('html')) return <FileCode className={iconSizeStyle+" text-purple-500"} />;
        return <FileIcon className={iconSizeStyle+" text-gray-500"} />;
      case 'text':
        return <FileText className={iconSizeStyle+" text-blue-500"} />;
      case 'video':
        return <Video className={iconSizeStyle+" text-red-500"} />;
      case 'audio':
        return <Music className={iconSizeStyle+" text-purple-500"} />;
      default:
        return <File className={iconSizeStyle+" text-gray-500"} />;
    }
  };
  
  // Get file type name
  const getFileTypeName = (file: DocumentFile) => {
    const type = file.content_type.split('/')[0];
    const subtype = file.content_type.split('/')[1];
    
    return subtype ? subtype.toUpperCase() : type.toUpperCase();
  };
  
  // Handle sort change
  const handleSort = (field: SortField) => {
    if (field === sortField) {
      // Toggle direction if same field
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // New field, default to ascending
      setSortField(field);
      setSortDirection("asc");
    }
  };
  
  // Sort chevron display
  const SortChevron = ({ field }: { field: SortField }) => {
    if (field !== sortField) return null;
    return sortDirection === "asc" ? (
      <ChevronUp className="h-4 w-4 inline ml-1" />
    ) : (
      <ChevronDown className="h-4 w-4 inline ml-1" />
    );
  };
  
  // Combine and sort folders and files
  const getSortedItems = (): ListItem[] => {
    // Convert folders and files to a common structure
    const folderItems: ListItem[] = folders.map(folder => ({
      id: folder.id,
      isFolder: true,
      name: folder.name,
      type: t("documents.folder"),
      item: folder
    }));
    
    const fileItems: ListItem[] = files.map(file => ({
      id: file.id,
      isFolder: false,
      name: file.name,
      type: file.content_type,
      size: file.size,
      item: file
    }));
    
    // If no sort is applied or sorting by type, show folders first
    if ((sortField === "name" && sortDirection === "asc") || sortField === "type") {
      // When sorting by name (asc) or type, show folders first
      const sortedFolders = [...folderItems].sort((a, b) => 
        sortDirection === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
      );
      
      let sortedFiles = [...fileItems];
      
      if (sortField === "name") {
        sortedFiles = sortedFiles.sort((a, b) => 
          sortDirection === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
        );
      } else if (sortField === "type") {
        sortedFiles = sortedFiles.sort((a, b) => {
          const typeA = (a.item as DocumentFile).content_type;
          const typeB = (b.item as DocumentFile).content_type;
          return sortDirection === "asc" ? typeA.localeCompare(typeB) : typeB.localeCompare(typeA);
        });
      }
      
      return [...sortedFolders, ...sortedFiles];
    }
    
    // For other sorts, or when sorting by name in desc order, mix folders and files
    const allItems = [...folderItems, ...fileItems];
    
    return allItems.sort((a, b) => {
      switch (sortField) {
        case "name":
          return sortDirection === "asc" 
            ? a.name.localeCompare(b.name) 
            : b.name.localeCompare(a.name);
        case "size":
          // Folders don't have size, so they should be handled specially
          if (a.isFolder && b.isFolder) return 0;
          if (a.isFolder) return sortDirection === "asc" ? -1 : 1;
          if (b.isFolder) return sortDirection === "asc" ? 1 : -1;
          return sortDirection === "asc" 
            ? (a.size || 0) - (b.size || 0) 
            : (b.size || 0) - (a.size || 0);
        default:
          return 0;
      }
    });
  };
  
  // Selection handlers
  const toggleSelectAllFolders = (checked: boolean) => {
    if (checked) {
      const allFolderIds = folders.map(folder => folder.id);
      setSelectedFolders(new Set(allFolderIds));
    } else {
      setSelectedFolders(new Set());
    }
  };
  
  const toggleSelectAllFiles = (checked: boolean) => {
    if (checked) {
      const allFileIds = files.map(file => file.id);
      setSelectedFiles(new Set(allFileIds));
    } else {
      setSelectedFiles(new Set());
    }
  };
  
  const toggleSelectAll = (checked: boolean) => {
    toggleSelectAllFolders(checked);
    toggleSelectAllFiles(checked);
  };
  
  const toggleFolderSelection = (folderId: string, checked: boolean) => {
    const newSelection = new Set(selectedFolders);
    if (checked) {
      newSelection.add(folderId);
    } else {
      newSelection.delete(folderId);
    }
    setSelectedFolders(newSelection);
  };
  
  const toggleFileSelection = (fileId: string, checked: boolean) => {
    const newSelection = new Set(selectedFiles);
    if (checked) {
      newSelection.add(fileId);
    } else {
      newSelection.delete(fileId);
    }
    setSelectedFiles(newSelection);
  };
  
  useEffect(() => {
    const selectedFolderObjects = folders.filter(folder => 
      selectedFolders.has(folder.id)
    );
    const selectedFileObjects = files.filter(file => 
      selectedFiles.has(file.id)
    );
    
    onSelectionChange({
      folders: selectedFolderObjects,
      files: selectedFileObjects
    });
  }, [files, folders, onSelectionChange, selectedFiles, selectedFolders]);

  // Calculate if all items are selected
  const allFoldersSelected = folders.length > 0 && selectedFolders.size === folders.length;
  const allFilesSelected = files.length > 0 && selectedFiles.size === files.length;
  const allSelected = 
    (folders.length + files.length > 0) && 
    (selectedFolders.size + selectedFiles.size === folders.length + files.length);
  
  const hasAnySelection = selectedFolders.size > 0 || selectedFiles.size > 0;
  
  // Drag and drop handlers
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!dragActive) setDragActive(true);
  };
  
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };
  
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };
  
  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (!onUploadFile || !currentFolderId) {
      toast.error(t("documents.cannotUploadHere"));
      return;
    }
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      try {
        setIsUploading(true);
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          const file = e.dataTransfer.files[i];
          await onUploadFile(file);
        }
        toast.success(t("documents.uploadComplete"));
      } catch (error) {
        console.error('Error uploading file:', error);
        toast.error(t("documents.uploadError"));
      } finally {
        setIsUploading(false);
      }
    }
  };
  
  const sortedItems = getSortedItems();
  
  return (
    <div 
      className={`w-full relative ${dragActive && !isUploading ? 'bg-primary/5 border-2 border-dashed border-primary rounded-lg' : ''}`}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {dragActive && !isUploading && (
        <div className="absolute inset-0 flex items-center justify-center z-10 pointer-events-none">
          <p className="text-primary font-medium">{t("documents.dropFilesHere")}</p>
        </div>
      )}
      
      {isUploading && (
        <div className="absolute inset-0 flex items-center justify-center z-10 bg-background/80">
          <p className="text-primary font-medium">{t("documents.uploading")}</p>
        </div>
      )}
      
      <Table className={dragActive ? 'opacity-30' : ''}>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[40px]">
              <Checkbox 
                checked={allSelected} 
                onCheckedChange={toggleSelectAll}
                aria-label={t("documents.selectAll")}
              />
            </TableHead>
            <TableHead 
              className="w-[70%] cursor-pointer"
              onClick={() => handleSort("name")}
            >
              {t("documents.name")} <SortChevron field="name" />
            </TableHead>
            <TableHead 
              className="cursor-pointer"
              onClick={() => handleSort("type")}
            >
              {t("documents.type")} <SortChevron field="type" />
            </TableHead>
            <TableHead 
              className="cursor-pointer"
              onClick={() => handleSort("size")}
            >
              {t("documents.size")} <SortChevron field="size" />
            </TableHead>
            <TableHead className="text-right">{t("common.actions")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedItems.map(item => (
            <TableRow key={`${item.isFolder ? 'folder' : 'file'}-${item.id}`} className="group">
              <TableCell>
                <Checkbox 
                  checked={item.isFolder ? selectedFolders.has(item.id) : selectedFiles.has(item.id)}
                  onCheckedChange={(checked) => 
                    item.isFolder 
                      ? toggleFolderSelection(item.id, !!checked)
                      : toggleFileSelection(item.id, !!checked)
                  }
                  aria-label={`Select ${item.name}`}
                  onClick={(e) => e.stopPropagation()}
                />
              </TableCell>
              <TableCell 
                className="font-medium cursor-pointer"
                onClick={() => {
                  if (item.isFolder) {
                    onOpenFolder(item.id);
                  } else {
                    onViewFile(item.item as DocumentFile);
                  }
                }}
              >
                <div className="flex items-center text-left gap-2">
                  {item.isFolder 
                    ? <FolderIcon className={iconSizeStyle+" text-primary"} />
                    : getFileIcon(item.item as DocumentFile)
                  }
                  {item.name}
                </div>
              </TableCell>
              <TableCell className="text-left">
                {item.isFolder 
                  ? t("documents.folder")
                  : getFileTypeName(item.item as DocumentFile)
                }
              </TableCell>
              <TableCell className="text-left">
                {item.isFolder 
                  ? "-" 
                  : formatFileSize((item.item as DocumentFile).size)
                }
              </TableCell>
              <TableCell className="text-right">
                <div className="flex justify-end gap-1">
                  {item.isFolder ? (
                    // Folder actions
                    <>
                      {canEdit('documents') && (
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            onEditFolder(item.item as DocumentFolder);
                          }}
                        >
                          <Edit className="h-4 w-4" />
                        </Button>
                      )}
                      {canDelete('documents') && (
                        <Button
                          variant="ghost"
                          size="sm"
                          className="text-destructive hover:text-destructive"
                          onClick={(e) => {
                            e.stopPropagation();
                            onDeleteFolder(item.id, (item.item as DocumentFolder).name);
                          }}
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      )}
                    </>
                  ) : (
                    // File actions
                    <>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDownloadFile(item.item as DocumentFile);
                        }}
                      >
                        <Download className="h-4 w-4" />
                      </Button>
                      {canDelete('documents') && (
                        <Button
                          variant="ghost"
                          size="sm"
                          className="text-destructive hover:text-destructive"
                          onClick={(e) => {
                            e.stopPropagation();
                            const file = item.item as DocumentFile;
                            onDeleteFile(file.id, file.name, file.storage_path);
                          }}
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
          
          {folders.length === 0 && files.length === 0 && (
            <TableRow>
              <TableCell colSpan={5} className="text-center py-8">
                {t("documents.emptyFolder")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
