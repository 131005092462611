
import { PageContainer } from "@/components/PageContainer";
import { PageHeader } from "@/components/PageHeader";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { AssociationDetailsForm } from "@/components/settings/AssociationDetailsForm";
import { StreetManagement } from "@/components/settings/StreetManagement";
import { BoardSettings } from "@/components/settings/BoardSettings";
import { BankSettings } from "@/components/settings/BankSettings";
import { StatutesSettings } from "@/components/settings/StatutesSettings";
import { useTranslation } from "react-i18next";
import { PermissionGuard } from "@/components/auth/PermissionGuard";
import { useRole } from "@/hooks/useRole";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";
import { useEffect } from "react";

// Import local translations
import enTranslations from "./locales/en.json";
import daTranslations from "./locales/da.json";

export default function Settings() {
  const { t, i18n } = useTranslation();
  const { isAdmin } = useRole();

  // Register local translations
  useEffect(() => {
    i18n.addResourceBundle('en', 'local', enTranslations, true, true);
    i18n.addResourceBundle('da', 'local', daTranslations, true, true);
    
    return () => {
      // Clean up when component unmounts (optional)
      i18n.removeResourceBundle('en', 'local');
      i18n.removeResourceBundle('da', 'local');
    };
  }, [i18n]);

  // Helper function to get local translations
  const tLocal = (key: string) => t(`local:${key}`);

  return (
    <PageContainer>
      <PageHeader title={tLocal("settings.title")} subtitle={tLocal("settings.subtitle")} />
      
      {!isAdmin && (
        <Alert className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>{tLocal("settings.readOnlyMode.title")}</AlertTitle>
          <AlertDescription>
            {tLocal("settings.readOnlyMode.description")}
          </AlertDescription>
        </Alert>
      )}
      
      <Tabs defaultValue="association">
        <TabsList>
          <TabsTrigger value="association">{tLocal("settings.tabs.association")}</TabsTrigger>
          <TabsTrigger value="streets">{tLocal("settings.tabs.streets")}</TabsTrigger>
          <TabsTrigger value="board">{tLocal("settings.tabs.board")}</TabsTrigger>
          <TabsTrigger value="bank">{tLocal("settings.tabs.bank")}</TabsTrigger>
          <TabsTrigger value="statutes">{tLocal("settings.tabs.statutes")}</TabsTrigger>
        </TabsList>

        <TabsContent value="association" className="mt-6">
          <PermissionGuard 
            permission="edit" 
            resource="association_settings"
            fallback={<AssociationDetailsForm />}
          >
            <AssociationDetailsForm />
          </PermissionGuard>
        </TabsContent>

        <TabsContent value="streets" className="mt-6">
          <PermissionGuard 
            permission="edit" 
            resource="streets"
            fallback={<StreetManagement />}
          >
            <StreetManagement />
          </PermissionGuard>
        </TabsContent>

        <TabsContent value="board" className="mt-6">
          <PermissionGuard 
            permission="edit" 
            resource="board"
            fallback={<BoardSettings />}
          >
            <BoardSettings />
          </PermissionGuard>
        </TabsContent>

        <TabsContent value="bank" className="mt-6">
          <PermissionGuard 
            permission="edit" 
            resource="bank"
            fallback={<BankSettings />}
          >
            <BankSettings />
          </PermissionGuard>
        </TabsContent>

        <TabsContent value="statutes" className="mt-6">
          <PermissionGuard 
            permission="edit" 
            resource="association_settings"
            fallback={<StatutesSettings />}
          >
            <StatutesSettings />
          </PermissionGuard>
        </TabsContent>
      </Tabs>
    </PageContainer>
  );
};
