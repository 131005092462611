
import { Card } from "@/components/ui/card";
import { LucideIcon } from "lucide-react";

interface DashboardCardProps {
  title: string;
  value: string;
  icon: LucideIcon;
  change?: string;
  className?: string;
}

export const DashboardCard = ({ title, value, icon: Icon, change, className }: DashboardCardProps) => {
  return (
    <Card className={`p-6 hover:shadow-lg transition-shadow ${className}`}>
      <div className="flex items-center justify-between">
        <Icon className="w-8 h-8 text-muted-foreground" />
        {change && <p className="text-sm text-muted-foreground">{change}</p>}
      </div>
      <h3 className="text-2xl font-bold mt-4">{value}</h3>
      <p className="text-sm text-muted-foreground">{title}</p>
    </Card>
  );
};
