
import { FolderPlus, Upload } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";

interface EmptyStateProps {
  title: string;
  description: string;
  onCreateFolder?: () => void;
  onUploadFile?: () => void;
  showFolderButton?: boolean;
  showUploadButton?: boolean;
}

export function EmptyState({
  title,
  description,
  onCreateFolder,
  onUploadFile,
  showFolderButton = true,
  showUploadButton = true,
}: EmptyStateProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center p-8 text-center">
      <div className="rounded-full bg-muted p-3 mb-4">
        <FolderPlus className="h-8 w-8 text-muted-foreground" />
      </div>
      <h3 className="text-lg font-medium mb-2">{title}</h3>
      <p className="text-sm text-muted-foreground max-w-sm mb-6">
        {description}
      </p>
      <div className="flex flex-wrap gap-4 justify-center">
        {showFolderButton && onCreateFolder && (
          <Button onClick={onCreateFolder}>
            <FolderPlus className="mr-2 h-4 w-4" />
            {t("documents.createFolder")}
          </Button>
        )}
        {showUploadButton && onUploadFile && (
          <Button variant="outline" onClick={onUploadFile}>
            <Upload className="mr-2 h-4 w-4" />
            {t("documents.uploadFile")}
          </Button>
        )}
      </div>
    </div>
  );
}
