
import { createContext, useContext, useEffect, useState } from "react";
import { Session, User } from "@supabase/supabase-js";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "sonner";

interface AuthContextType {
  session: Session | null;
  user: User | null;
  signIn: (email: string, password: string, redirectPath?: string) => Promise<void>;
  signUp: (email: string, password: string, firstName: string, lastName: string, redirectPath?: string) => Promise<void>;
  signOut: () => Promise<void>;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [redirectAfterAuth, setRedirectAfterAuth] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setUser(session?.user ?? null);
      setLoading(false);
      
      // Handle redirect after authentication if needed
      if (session && redirectAfterAuth) {
        navigate(redirectAfterAuth);
        setRedirectAfterAuth(null);
      }
    });

    // Listen for auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setUser(session?.user ?? null);
      setLoading(false);
      
      // Handle redirect after authentication if needed
      if (session && redirectAfterAuth) {
        navigate(redirectAfterAuth);
        setRedirectAfterAuth(null);
      }
    });

    return () => subscription.unsubscribe();
  }, [navigate, redirectAfterAuth]);

  const signIn = async (email: string, password: string, redirectPath?: string) => {
    if (redirectPath) {
      setRedirectAfterAuth(redirectPath);
    }
    
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    
    if (error) throw error;
    
    // Navigation happens in the effect when session changes
    if (!redirectPath) {
      navigate("/");
    }
  };

  const signUp = async (email: string, password: string, firstName: string, lastName: string, redirectPath?: string) => {
    if (redirectPath) {
      setRedirectAfterAuth(redirectPath);
    }
    
    const { error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          first_name: firstName,
          last_name: lastName,
        },
      },
    });
    
    if (error) throw error;
    
    if (!redirectPath) {
      navigate("/auth?message=Check your email to confirm your account");
    } else {
      toast.success("Account created successfully");
    }
  };

  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      
      if (error) {
        console.error("Error signing out:", error.message);
        toast.error(`Sign out failed: ${error.message}`);
      } else {
        // Clear local state even if there's an error from Supabase
        setSession(null);
        setUser(null);
        navigate("/auth");
        toast.success("Successfully signed out");
      }
    } catch (error) {
      console.error("Exception during sign out:", error);
      // Force clean state and redirect even if API call fails
      setSession(null);
      setUser(null);
      navigate("/auth");
    }
  };

  return (
    <AuthContext.Provider value={{ session, user, signIn, signUp, signOut, loading }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
