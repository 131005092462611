
import { ReactNode } from "react";
import { useRole } from "@/hooks/useRole";

interface PermissionGuardProps {
  children: ReactNode;
  fallback?: ReactNode;
  requiredRole?: "admin" | "super-admin";
  resource?: string;
  resourceOwnerId?: string;
  permission: "view" | "edit" | "create" | "delete";
}

export function PermissionGuard({ 
  children, 
  fallback = null, 
  requiredRole,
  resource,
  resourceOwnerId,
  permission 
}: PermissionGuardProps) {
  const { role, canEdit, canCreate, canDelete, isLoading } = useRole();
  
  // Skip permission checks if still loading
  if (isLoading) return null;
  
  // Check for specific role requirement
  if (requiredRole) {
    if (requiredRole === "super-admin" && role !== "super-admin") return <>{fallback}</>;
    if (requiredRole === "admin" && role !== "admin" && role !== "super-admin") return <>{fallback}</>;
  }
  
  // Check for specific permissions
  if (resource && permission) {
    if (permission === "edit" && !canEdit(resource, resourceOwnerId)) return <>{fallback}</>;
    if (permission === "create" && !canCreate(resource)) return <>{fallback}</>;
    if (permission === "delete" && !canDelete(resource, resourceOwnerId)) return <>{fallback}</>;
  }
  
  return <>{children}</>;
}

// Convenience components
export function IfCanEdit({ 
  children, 
  resource, 
  resourceOwnerId, 
  fallback = null 
}: Omit<PermissionGuardProps, "permission"> & { 
  resource?: string; 
  resourceOwnerId?: string; 
  fallback?: ReactNode 
}) {
  return (
    <PermissionGuard 
      permission="edit" 
      resource={resource} 
      resourceOwnerId={resourceOwnerId}
      fallback={fallback}
    >
      {children}
    </PermissionGuard>
  );
}

export function IfCanCreate({ 
  children, 
  resource, 
  fallback = null 
}: Omit<PermissionGuardProps, "permission" | "resourceOwnerId"> & { 
  resource?: string; 
  fallback?: ReactNode 
}) {
  return (
    <PermissionGuard 
      permission="create" 
      resource={resource}
      fallback={fallback}
    >
      {children}
    </PermissionGuard>
  );
}

export function IfCanDelete({ 
  children, 
  resource, 
  resourceOwnerId, 
  fallback = null 
}: Omit<PermissionGuardProps, "permission"> & { 
  resource?: string; 
  resourceOwnerId?: string; 
  fallback?: ReactNode 
}) {
  return (
    <PermissionGuard 
      permission="delete" 
      resource={resource}
      resourceOwnerId={resourceOwnerId}
      fallback={fallback}
    >
      {children}
    </PermissionGuard>
  );
}
