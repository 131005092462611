
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { useTranslation } from "react-i18next";
import type { CVRResponse } from "@/types/settings";

interface CVRLookupProps {
  value: string;
  onChange: (value: string, cvrData?: Partial<CVRResponse>) => void;
}

export function CVRLookup({ value, onChange }: CVRLookupProps) {
  const { toast } = useToast();
  const { t } = useTranslation();

  const handleCVRLookup = async (value: string) => {
    if (value.length === 8) {
      try {
        const response = await fetch(`https://cvrapi.dk/api?search=${value}&country=dk`);
        const data: CVRResponse = await response.json();

        if (data.error) {
          toast({
            title: "Error",
            description: "Could not find company with this CVR number",
            variant: "destructive"
          });
          return;
        }

        // Split address into street name and number
        const addressParts = data.address.split(" ");
        const streetNumber = addressParts.pop() || "";
        const streetAddress = addressParts.join(" ");

        // Pass the CVR data to parent component
        onChange(value, {
          name: data.name,
          address: data.address,
          zipcode: data.zipcode,
          city: data.city,
          country: "Denmark",
          // Add derived data
          streetAddress,
          streetNumber
        });

        toast({
          title: "Success",
          description: "Company details have been automatically filled"
        });
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to fetch company details",
          variant: "destructive"
        });
      }
    } else {
      // Just update the CVR number without lookup
      onChange(value);
    }
  };

  return (
    <div className="space-y-2">
      <Label className="text-left block" htmlFor="cvrNumber">{t("settings.association.cvrNumber")}</Label>
      <Input
        id="cvrNumber"
        value={value}
        onChange={(e) => handleCVRLookup(e.target.value)}
      />
    </div>
  );
}
