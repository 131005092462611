import { useState, useEffect } from "react";
import { CheckCircle, Loader2, QrCode, Trash2 } from "lucide-react";
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogDescription 
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { useTranslation } from "react-i18next";
import { StoredStreetData } from "@/types/street";
import { supabase } from "@/integrations/supabase/client";
import { useTenant } from "@/contexts/TenantContext";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import { Switch } from "@/components/ui/switch";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { 
  generateOnboardingCodesForStreet,
  clearOnboardingCodesForStreet
} from "@/utils/onboardingCodes";

interface OnboardingCodeDialogProps {
  street: StoredStreetData | null;
  isOpen: boolean;
  onClose: () => void;
}

export function OnboardingCodeDialog({ street, isOpen, onClose }: OnboardingCodeDialogProps) {
  const { t } = useTranslation();
  const { currentTenant } = useTenant();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isClearing, setIsClearing] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [progress, setProgress] = useState(0);
  const [regenerateAll, setRegenerateAll] = useState(false);
  const [stats, setStats] = useState({
    totalCadastres: 0,
    pendingCadastres: 0,
    completedCadastres: 0,
  });
  
  useEffect(() => {
    if (isOpen && street) {
      loadStats();
    } else {
      // Reset state when dialog closes
      setIsComplete(false);
      setProgress(0);
      setRegenerateAll(false);
      setStats({
        totalCadastres: 0,
        pendingCadastres: 0,
        completedCadastres: 0,
      });
    }
  }, [isOpen, street]);

  const loadStats = async () => {
    if (!street || !currentTenant) return;
    
    setIsLoading(true);
    try {
      // Get total cadastres for this street
      const { data: allCadastres, error: countError } = await supabase
        .from('cadastres')
        .select('id, onboarding_code')
        .eq('tenant_id', currentTenant.id)
        .eq('street_name', street.street_name);
      
      if (countError) throw countError;
      
      const totalCadastres = allCadastres.length;
      const pendingCadastres = allCadastres.filter(c => !c.onboarding_code).length;
      
      setStats({
        totalCadastres,
        pendingCadastres,
        completedCadastres: totalCadastres - pendingCadastres,
      });
    } catch (error) {
      console.error('Error loading stats:', error);
      toast.error(t('common.error', 'Error loading data'));
    } finally {
      setIsLoading(false);
    }
  };

  const generateOnboardingCodes = async () => {
    if (!street || !currentTenant) return;
    
    setIsGenerating(true);
    setProgress(0);
    setIsFailure(false);
    
    try {
      // Use the new utility function for generating codes
      const result = await generateOnboardingCodesForStreet(
        street.street_name,
        currentTenant.id,
        regenerateAll,
        (completed, total) => {
          const newProgress = Math.round((completed / total) * 100);
          setProgress(newProgress);
          
          // Update stats
          setStats(prev => ({
            ...prev,
            pendingCadastres: regenerateAll ? prev.pendingCadastres : Math.max(0, prev.pendingCadastres - 1),
            completedCadastres: regenerateAll ? prev.completedCadastres : prev.completedCadastres + 1,
          }));
        }
      );
      
      // Indicate completion
      if (result.completed > 0 && result.failed === 0) {
        setIsComplete(true);
        if (regenerateAll) {
          toast.success(t('settings.streets.codesRegenerated', 'All onboarding codes successfully regenerated'));
        } else {
          toast.success(t('settings.streets.codesGenerated', 'Onboarding codes generated successfully'));
        }
      } else if (result.failed > 0) {
        setIsFailure(true);
        toast.error(t('settings.streets.codesGenerationError', 'Some onboarding codes failed to generate'));
      } else if (result.total === 0) {
        if (regenerateAll) {
          toast.info(t('settings.streets.noCodesFound', 'No cadastres found for this street'));
        } else {
          toast.info(t('settings.streets.noCodesNeeded', 'All cadastres already have onboarding codes'));
        }
        setIsComplete(true);
      }
      
      // Invalidate cache to update views
      queryClient.invalidateQueries({ queryKey: ['cadastres'] });
      queryClient.invalidateQueries({ queryKey: ['onboarding_codes'] });
      
    } catch (error) {
      console.error('Error generating codes:', error);
      setIsFailure(true);
      toast.error(t('common.error', 'Failed to generate onboarding codes'));
    } finally {
      setIsGenerating(false);
    }
  };
  
  const clearAllOnboardingCodes = async () => {
    if (!street || !currentTenant) return;
    
    const confirmMessage = t(
      'settings.streets.confirmClearCodes',
      'Are you sure you want to clear all onboarding codes for this street? This action cannot be undone.'
    );
    
    if (!confirm(confirmMessage)) return;
    
    setIsClearing(true);
    try {
      // Use the refactored utility function
      const result = await clearOnboardingCodesForStreet(
        street.street_name,
        currentTenant.id
      );
      
      if (result.success) {
        // Success - update stats
        setStats(prev => ({
          ...prev,
          pendingCadastres: prev.totalCadastres,
          completedCadastres: 0
        }));
        
        // Invalidate queries to refresh data
        queryClient.invalidateQueries({ queryKey: ['cadastres'] });
        queryClient.invalidateQueries({ queryKey: ['onboarding_codes'] });
        
        toast.success(t('settings.streets.codesCleared', 'All onboarding codes have been cleared'));
      } else {
        throw result.error;
      }
    } catch (error) {
      console.error('Error clearing codes:', error);
      toast.error(t('common.error', 'Failed to clear onboarding codes'));
    } finally {
      setIsClearing(false);
    }
  };
  
  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <QrCode className="w-5 h-5" />
            {t('settings.streets.generateOnboardingCodes', 'Generate Onboarding Codes')}
          </DialogTitle>
          <DialogDescription>
            {t('settings.streets.onboardingCodesDescription', 
              'Onboarding codes help new members easily join the right cadastre. When new members sign up, they can enter this code to automatically associate with the correct cadastre.')}
          </DialogDescription>
        </DialogHeader>

        {isLoading ? (
          <div className="flex justify-center py-8">
            <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
          </div>
        ) : isComplete ? (
          <div className="space-y-4 py-4">
            <div className="flex flex-col items-center justify-center space-y-2">
              <CheckCircle className="h-16 w-16 text-green-500" />
              <h3 className="text-xl font-medium">
                {t('settings.streets.generationComplete', 'Generation Complete')}
              </h3>
              <p className="text-center text-muted-foreground">
                {regenerateAll 
                  ? t('settings.streets.allCodesRegenerated', 'All onboarding codes have been regenerated successfully.')
                  : t('settings.streets.allCodesGenerated', 'All onboarding codes have been generated successfully.')}
              </p>
            </div>
            <Alert>
              <AlertDescription>
                {t('settings.streets.onboardingCodeAlert', 
                  'These codes can now be used for new member registration. Share them with potential members to allow them to join without administrative approval.')}
              </AlertDescription>
            </Alert>
            <div className="flex justify-center pt-4">
              <Button onClick={onClose}>
                {t('common.done', 'Done')}
              </Button>
            </div>
          </div>
        ) : (
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span>{t('settings.streets.totalCadastres', 'Total Cadastres')}:</span>
                <span className="font-medium">{stats.totalCadastres}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span>{t('settings.streets.cadastresWithoutCodes', 'Cadastres Without Codes')}:</span>
                <span className="font-medium">{stats.pendingCadastres}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span>{t('settings.streets.cadastresWithCodes', 'Cadastres With Codes')}:</span>
                <span className="font-medium">{stats.completedCadastres}</span>
              </div>
            </div>
            
            <Alert>
              <AlertDescription>
                {t('settings.streets.onboardingCodeInfo', 
                  'Generated codes will be linked to your association and specific address details. Members using these codes can bypass the typical approval process.')}
              </AlertDescription>
            </Alert>
            
            <div className="flex items-center space-x-2 py-2">
              <Switch
                id="regenerate-all"
                checked={regenerateAll}
                onCheckedChange={setRegenerateAll}
              />
              <label
                htmlFor="regenerate-all"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {t('settings.streets.regenerateAllCodes', 'Regenerate all codes (including existing ones)')}
              </label>
            </div>
            
            {isGenerating && (
              <div className="space-y-2">
                <Progress value={progress} className="h-2" />
                <p className="text-xs text-center text-muted-foreground">
                  {regenerateAll 
                    ? t('settings.streets.regeneratingCodes', 'Regenerating codes... {{percent}}% completed', {
                        percent: progress
                      })
                    : t('settings.streets.generatingCodes', 'Generating codes... {{completed}} of {{total}} completed', {
                        completed: stats.completedCadastres,
                        total: stats.totalCadastres
                      })}
                </p>
              </div>
            )}
            
            {isFailure && (
              <div className="text-center text-red-500 text-sm">
                {t('settings.streets.generationError', 'There was an error generating some codes. Please try again.')}
              </div>
            )}
            
            <div className="flex justify-between">
              <Button
                variant="outline"
                onClick={clearAllOnboardingCodes}
                disabled={stats.completedCadastres === 0 || regenerateAll || isClearing || isGenerating}
                className="gap-2"
              >
                {isClearing && <Loader2 className="h-4 w-4 animate-spin" />}
                <Trash2 className="h-4 w-4" />
                {t('settings.streets.clearAllCodes', 'Clear All Codes')}
              </Button>
              
              <Button
                onClick={generateOnboardingCodes}
                disabled={isGenerating || isClearing || (!regenerateAll && stats.pendingCadastres === 0)}
              >
                {isGenerating && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                {regenerateAll 
                  ? t('settings.streets.regenerateCodes', 'Regenerate All Codes') 
                  : t('settings.streets.generateCodes', 'Generate Codes')}
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
