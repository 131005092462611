
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { useTenant } from "@/contexts/TenantContext";
import { useAssociationSettings } from "@/hooks/useAssociationSettings";
import { useRole } from "@/hooks/useRole";

const statutesFormSchema = z.object({
  fiscalYearStart: z.object({
    day: z.number().min(1).max(31).optional(),
    month: z.number().min(0).max(11).optional()
  }).optional(),
  annualReportDelivery: z.object({
    day: z.number().min(1).max(31).optional(),
    month: z.number().min(0).max(11).optional()
  }).optional(),
  annualReportApproval: z.object({
    day: z.number().min(1).max(31).optional(),
    month: z.number().min(0).max(11).optional()
  }).optional(),
  votesPerCadastre: z.number().min(1).max(10).optional(),
  allowProxyVotes: z.boolean().optional(),
  maxProxyVotes: z.number().min(0).max(100).optional()
});

export function useStatuteForm() {
  const { t } = useTranslation();
  const { currentTenant } = useTenant();
  const { isAdmin } = useRole();
  const [isSaving, setIsSaving] = useState(false);
  
  const {
    data: settings,
    isLoading,
    refetch: refetchSettings
  } = useAssociationSettings();
  
  const form = useForm<z.infer<typeof statutesFormSchema>>({
    resolver: zodResolver(statutesFormSchema),
    defaultValues: {
      fiscalYearStart: {
        day: settings?.fiscal_year_start_day,
        month: settings?.fiscal_year_start_month
      },
      annualReportDelivery: {
        day: settings?.annual_report_delivery_day,
        month: settings?.annual_report_delivery_day
      },
      annualReportApproval: {
        day: settings?.annual_report_approval_day,
        month: settings?.annual_report_approval_day
      },
      votesPerCadastre: settings?.votes_per_cadastre || 1,
      allowProxyVotes: settings?.allow_proxy_votes || false,
      maxProxyVotes: settings?.max_proxy_votes || 0
    }
  });
  
  useEffect(() => {
    if (settings) {
      form.reset({
        fiscalYearStart: {
          day: settings.fiscal_year_start_day,
          month: settings.fiscal_year_start_month
        },
        annualReportDelivery: {
          day: settings.annual_report_delivery_day,
          month: settings.annual_report_delivery_day
        },
        annualReportApproval: {
          day: settings.annual_report_approval_day,
          month: settings.annual_report_approval_day
        },
        votesPerCadastre: settings.votes_per_cadastre || 1,
        allowProxyVotes: settings.allow_proxy_votes || false,
        maxProxyVotes: settings.max_proxy_votes || 0
      });
    }
  }, [settings, form]);
  
  const isDirty = form.formState.isDirty;
  
  const onSubmit = async (values: z.infer<typeof statutesFormSchema>) => {
    if (!currentTenant) return;
    setIsSaving(true);
    
    try {
      const updateData = {
        fiscal_year_start_day: values.fiscalYearStart?.day,
        fiscal_year_start_month: values.fiscalYearStart?.month,
        annual_report_delivery_day: values.annualReportDelivery?.day,
        annual_report_approval_day: values.annualReportApproval?.day,
        votes_per_cadastre: values.votesPerCadastre,
        allow_proxy_votes: values.allowProxyVotes,
        max_proxy_votes: values.maxProxyVotes
      };
      
      const { error } = await supabase
        .from('association_settings')
        .update(updateData)
        .eq('tenant_id', currentTenant.id);
        
      if (error) throw error;
      
      toast.success(t("settings.statutes.saveSuccess"));
      refetchSettings();
    } catch (error) {
      console.error("Error saving statute settings:", error);
      toast.error(t("settings.statutes.saveError"));
    } finally {
      setIsSaving(false);
    }
  };
  
  return {
    form,
    isLoading,
    isSaving,
    isDirty,
    isAdmin,
    onSubmit
  };
}
