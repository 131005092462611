import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

// Crockford Base32 alphabet (excluding I, L, O, U for readability)
const BASE32_ALPHABET = "0123456789ABCDEFGHJKMNPQRSTVWXYZ";

/**
 * Converts a number to a Base32 (Crockford) string.
 * @param num - The number to encode
 * @param length - Desired length of the output string
 * @returns Base32 encoded string
 */
export function encodeBase32(num: number, length: number = 6): string {
  let encoded = "";
  while (num > 0) {
    encoded = BASE32_ALPHABET[num % 32] + encoded;
    num = Math.floor(num / 32);
  }
  return encoded.padStart(length, BASE32_ALPHABET[0]); // Ensure fixed length
}

/**
 * Generates a 6-character unique Base32 code from an input string
 * Using a simple hash function
 * @param input - The input string
 * @returns 6-character unique Base32 code
 */
export function generateUniqueCode(input: string): string {
  // Create a deterministic numeric value from the input string
  // This is a simple hash function
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  
  // Make hash positive
  hash = Math.abs(hash);
  
  // Encode to Base32 with 6 characters
  return encodeBase32(hash, 6);
}

export interface OnboardingCodeParams {
  cadastreId: string;
  streetName: string;
  streetNumber: string;
  tenantId: string;
  associationId?: string;
  associationName?: string;
}

/**
 * Generates and saves an onboarding code for a specific cadastre
 * 
 * @param params - Parameters containing cadastre data
 * @returns Promise with the generated code or error
 */
export async function generateAndSaveOnboardingCode(params: OnboardingCodeParams): Promise<{ code: string; success: boolean; error?: any }> {
  try {
    // Get association info if not provided
    let associationId = params.associationId;
    let associationName = params.associationName;
    
    if (!associationId || !associationName) {
      const { data: associationData, error: associationError } = await supabase
        .from('association_settings')
        .select('id, name')
        .eq('tenant_id', params.tenantId)
        .single();
      
      if (associationError) {
        throw new Error("Could not fetch association data");
      }
      
      associationId = associationData.id;
      associationName = associationData.name;
    }

    const invalidator = 1;

    // Generate input string for the hash. We use the invalidator to make sure we can invalidate existing codes
    const inputString = `${params.streetName}` + 
                        `-` + 
                        `${params.streetNumber}` +
                        `-` + 
                        `${invalidator}` +
                        `-` + 
                        `${params.associationId}` + 
                        `-` + 
                        `${params.tenantId}`;
    
    // Generate the code using our function
    const code = generateUniqueCode(inputString);
    
    // Update the cadastre with the onboarding code
    const { error: updateError } = await supabase
      .from('cadastres')
      .update({ onboarding_code: code })
      .eq('id', params.cadastreId);
    
    if (updateError) {
      throw updateError;
    }
    
    // Also add the code to the onboarding_codes table
    const { error: insertError } = await supabase
      .from('onboarding_codes')
      .upsert([{
        code: code,
        street_name: params.streetName,
        street_number: params.streetNumber,
        association_name: associationName,
        association_id: associationId,
        tenant_id: params.tenantId,
        use_count: 0,
        expires_at: null // No expiration by default
      }], {
        onConflict: 'code'
      });
      
    if (insertError) {
      throw insertError;
    }
    
    return { code, success: true };
  } catch (error) {
    console.error('Error generating onboarding code:', error);
    return { code: "", success: false, error };
  }
}

/**
 * Generates onboarding codes for multiple cadastres
 * 
 * @param streetName - Street name to filter cadastres
 * @param tenantId - Tenant ID
 * @param regenerateAll - Whether to regenerate codes for cadastres that already have them
 * @param onProgress - Optional callback to report progress
 * @returns Promise with results of the operation
 */
export async function generateOnboardingCodesForStreet(
  streetName: string, 
  tenantId: string, 
  regenerateAll: boolean = false,
  onProgress?: (completed: number, total: number) => void
): Promise<{ 
  success: boolean; 
  completed: number; 
  failed: number; 
  total: number;
}> {
  try {
    // Get association data for the codes
    const { data: associationData, error: associationError } = await supabase
      .from('association_settings')
      .select('name, id')
      .eq('tenant_id', tenantId)
      .single();
    
    if (associationError) throw associationError;
    
    // Get cadastres that need codes based on the regenerateAll flag
    const query = supabase
      .from('cadastres')
      .select('id, street_name, street_number, tenant_id')
      .eq('tenant_id', tenantId)
      .eq('street_name', streetName);
    
    // If we're not regenerating all, only select those without codes
    if (!regenerateAll) {
      query.is('onboarding_code', null);
    }
    
    const { data: cadastres, error: fetchError } = await query;
    
    if (fetchError) throw fetchError;
    
    if (!cadastres || cadastres.length === 0) {
      return { success: true, completed: 0, failed: 0, total: 0 };
    }
    
    let completed = 0;
    let failed = 0;
    const total = cadastres.length;
    
    // Process cadastres one by one
    for (const cadastre of cadastres) {
      try {
        const result = await generateAndSaveOnboardingCode({
          cadastreId: cadastre.id,
          streetName: cadastre.street_name,
          streetNumber: cadastre.street_number,
          tenantId: cadastre.tenant_id,
          associationId: associationData.id,
          associationName: associationData.name
        });
        
        if (result.success) {
          completed++;
        } else {
          failed++;
        }
        
        // Report progress if callback provided
        if (onProgress) {
          onProgress(completed, total);
        }
        
        // Add small delay to avoid overwhelming the database
        await new Promise(resolve => setTimeout(resolve, 50));
        
      } catch (error) {
        console.error('Error processing cadastre:', error);
        failed++;
      }
    }
    
    return {
      success: failed === 0,
      completed,
      failed,
      total
    };
  } catch (error) {
    console.error('Error in batch generation:', error);
    return { success: false, completed: 0, failed: 0, total: 0 };
  }
}

/**
 * Clears all onboarding codes for a specific street
 * 
 * @param streetName - Street name to clear codes for
 * @param tenantId - Tenant ID
 * @returns Promise indicating success or failure
 */
export async function clearOnboardingCodesForStreet(
  streetName: string,
  tenantId: string
): Promise<{ success: boolean; error?: any }> {
  try {
    // Step 1: Get association settings to get the association ID
    const { data: associationData, error: associationError } = await supabase
      .from('association_settings')
      .select('id')
      .eq('tenant_id', tenantId)
      .single();
    
    if (associationError) throw associationError;
    
    const associationId = associationData.id;
    
    // Step 2: Set all cadastre onboarding_code fields to NULL for this street
    const { error: updateError } = await supabase
      .from('cadastres')
      .update({ onboarding_code: null })
      .eq('tenant_id', tenantId)
      .eq('street_name', streetName);
    
    if (updateError) throw updateError;
    
    // Step 3: Delete all entries from onboarding_codes table for this association and street
    const { error: deleteError } = await supabase
      .from('onboarding_codes')
      .delete()
      .eq('tenant_id', tenantId)
      .eq('association_id', associationId)
      .eq('street_name', streetName);
    
    if (deleteError) throw deleteError;
    
    return { success: true };
  } catch (error) {
    console.error('Error clearing onboarding codes:', error);
    return { success: false, error };
  }
}

/**
 * Requests an onboarding code from the Supabase Edge Function
 * 
 * @param cadastreId - Cadastre ID to generate code for
 * @param tenantId - Tenant ID
 * @returns Promise with the result of the operation
 */
export async function requestOnboardingCodeFromEdgeFunction(
  cadastreId: string,
  tenantId: string
): Promise<{ success: boolean; code?: string; error?: string }> {
  try {
    const { data, error } = await supabase.functions.invoke('generate-onboarding-codes', {
      body: { cadastre_id: cadastreId, tenant_id: tenantId }
    });
    
    if (error) {
      throw error;
    }
    
    return { 
      success: true, 
      code: data.onboarding_code 
    };
  } catch (error) {
    console.error('Error invoking edge function:', error);
    return { 
      success: false, 
      error: error.message || 'Failed to generate code' 
    };
  }
}
