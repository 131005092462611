
import { useEffect, useState } from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useTenant } from "@/contexts/TenantContext";
import { BoardSettingItem } from "./BoardSettingItem";
import { useTranslation } from "react-i18next";

interface BoardSettings {
  boardMemberCount: number;
  hasDeputyMembers: boolean;
  deputyMemberCount: number;
  hasVerifyingAccountant: boolean;
  hasAccountantDeputy: boolean;
}

export const BoardSettings = () => {
  const { currentTenant } = useTenant();
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState<BoardSettings>({
    boardMemberCount: 5,
    hasDeputyMembers: false,
    deputyMemberCount: 2,
    hasVerifyingAccountant: true,
    hasAccountantDeputy: false,
  });

  useEffect(() => {
    const loadSettings = async () => {
      if (!currentTenant) return;

      try {
        const { data, error } = await supabase
          .from('association_settings')
          .select('*')
          .eq('tenant_id', currentTenant.id)
          .single();

        if (error) throw error;

        if (data) {
          setSettings({
            boardMemberCount: Math.max(data.board_member_count ?? 3, 3), // Ensure minimum of 3
            hasDeputyMembers: data.has_deputy_members ?? false,
            deputyMemberCount: data.deputy_member_count ?? 2,
            hasVerifyingAccountant: data.has_verifying_accountant ?? true,
            hasAccountantDeputy: data.has_accountant_deputy ?? false,
          });
        }
      } catch (error) {
        console.error('Error loading board settings:', error);
        toast.error("Failed to load board settings");
      } finally {
        setIsLoading(false);
      }
    };

    loadSettings();
  }, [currentTenant]);

  const handleSaveSettings = async () => {
    if (!currentTenant) return;

    try {
      setIsSaving(true);
      
      const { data: existingData, error: fetchError } = await supabase
        .from('association_settings')
        .select('*')
        .eq('tenant_id', currentTenant.id)
        .single();

      if (fetchError) throw fetchError;

      if (!existingData) {
        toast.error("No association settings found");
        return;
      }

      const { error: updateError } = await supabase
        .from('association_settings')
        .update({
          board_member_count: Math.max(settings.boardMemberCount, 3), // Ensure minimum of 3
          has_deputy_members: settings.hasDeputyMembers,
          deputy_member_count: settings.deputyMemberCount,
          has_verifying_accountant: settings.hasVerifyingAccountant,
          has_accountant_deputy: settings.hasAccountantDeputy,
        })
        .eq('tenant_id', currentTenant.id);

      if (updateError) throw updateError;

      toast.success(t("settings.board.saveSuccess"));
    } catch (error) {
      console.error('Error saving board settings:', error);
      toast.error(t("settings.board.saveError"));
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <Card>
        <CardContent className="flex items-center justify-center py-6">
          <Loader2 className="h-6 w-6 animate-spin" />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("settings.board.title")}</CardTitle>
        <CardDescription>{t("settings.board.subtitle")}</CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-4">
          <BoardSettingItem
            label={t("settings.board.boardMemberCount.label")}
            description={t("settings.board.boardMemberCount.description")}
            type="number"
            value={settings.boardMemberCount}
            onChange={(value) => setSettings({ ...settings, boardMemberCount: Math.max(value as number, 3) })}
            inputProps={{ min: 3, max: 10 }}
            tooltip={t("settings.board.boardMemberCount.tooltip")}
          />

          <BoardSettingItem
            label={t("settings.board.deputyMembers.label")}
            description={t("settings.board.deputyMembers.description")}
            type="switch"
            value={settings.hasDeputyMembers}
            onChange={(value) => setSettings({ ...settings, hasDeputyMembers: value as boolean })}
            tooltip={t("settings.board.deputyMembers.tooltip")}
          />

          {settings.hasDeputyMembers && (
            <BoardSettingItem
              label={t("settings.board.deputyMemberCount.label")}
              description={t("settings.board.deputyMemberCount.description")}
              type="number"
              value={settings.deputyMemberCount}
              onChange={(value) => setSettings({ ...settings, deputyMemberCount: value as number })}
              inputProps={{ min: 1, max: 5 }}
              tooltip={t("settings.board.deputyMemberCount.tooltip")}
            />
          )}

          <BoardSettingItem
            label={t("settings.board.verifyingAccountant.label")}
            description={t("settings.board.verifyingAccountant.description")}
            type="switch"
            value={settings.hasVerifyingAccountant}
            onChange={(value) => setSettings({ ...settings, hasVerifyingAccountant: value as boolean })}
            tooltip={t("settings.board.verifyingAccountant.tooltip")}
          />

          {settings.hasVerifyingAccountant && (
            <BoardSettingItem
              label={t("settings.board.accountantDeputy.label")}
              description={t("settings.board.accountantDeputy.description")}
              type="switch"
              value={settings.hasAccountantDeputy}
              onChange={(value) => setSettings({ ...settings, hasAccountantDeputy: value as boolean })}
              tooltip={t("settings.board.accountantDeputy.tooltip")}
            />
          )}
        </div>

        <Button 
          onClick={handleSaveSettings} 
          disabled={isSaving}
          className="w-full sm:w-auto"
        >
          {isSaving ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {t("common.saving")}
            </>
          ) : (
            t("common.save")
          )}
        </Button>
      </CardContent>
    </Card>
  );
};
