
import { useState, useRef } from 'react';
import { Button } from "@/components/ui/button";
import { UploadCloud, X, FileText } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

interface FileUploaderProps {
  onUpload: (file: File) => Promise<void>;
  isUploading: boolean;
  accept?: string;
}

export function FileUploader({ onUpload, isUploading, accept = '*' }: FileUploaderProps) {
  const { t } = useTranslation();
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      setSelectedFile(file);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error(t("documents.noFileSelected"));
      return;
    }

    try {
      await onUpload(selectedFile);
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Upload error:', error);
    }
  };

  const formatFileSize = (bytes: number) => {
    if (bytes < 1024) return bytes + ' B';
    if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + ' KB';
    return (bytes / (1024 * 1024)).toFixed(1) + ' MB';
  };

  return (
    <div className="w-full">
      {!selectedFile ? (
        <div
          className={`
            border-2 border-dashed rounded-lg p-6 
            flex flex-col items-center justify-center 
            transition-colors ease-in-out duration-300
            ${dragActive ? 'border-primary bg-primary/5' : 'border-border'}
          `}
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}
        >
          <UploadCloud className="h-12 w-12 text-muted-foreground mb-4" />
          <p className="text-sm text-muted-foreground mb-2 text-center">
            {t("documents.dragAndDrop")}
          </p>
          <p className="text-xs text-muted-foreground mb-4 text-center">
            {t("documents.or")}
          </p>
          <Button
            onClick={() => fileInputRef.current?.click()}
            type="button"
          >
            {t("documents.browseFiles")}
          </Button>
          <input
            type="file"
            accept={accept}
            className="hidden"
            ref={fileInputRef}
            onChange={handleFileChange}
          />
        </div>
      ) : (
        <div className="border rounded-lg p-4">
          <div className="flex items-center">
            <FileText className="h-8 w-8 text-primary mr-3" />
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium truncate">
                {selectedFile.name}
              </p>
              <p className="text-xs text-muted-foreground">
                {formatFileSize(selectedFile.size)}
              </p>
            </div>
            <Button
              size="icon"
              variant="ghost"
              onClick={handleRemoveFile}
              disabled={isUploading}
              className="ml-2"
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
          <div className="mt-4 flex justify-end">
            <Button
              onClick={handleUpload}
              disabled={isUploading}
            >
              {isUploading ? t("common.uploading") : t("common.upload")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
