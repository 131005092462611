
import { useAuth } from "@/contexts/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

export type UserRole = "member" | "admin" | "super-admin";

export interface RoleData {
  role: UserRole;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isMember: boolean;
  // Check if user can perform specific actions
  canEdit: (resourceType: string, resourceOwnerId?: string) => boolean;
  canCreate: (resourceType: string) => boolean;
  canDelete: (resourceType: string, resourceOwnerId?: string) => boolean;
  // Check if user can edit their own member data
  isOwnMemberData: (memberId: string) => boolean;
  // General loading state
  isLoading: boolean;
}

export function useRole(): RoleData {
  const { user } = useAuth();
  
  const { data: roleData, isLoading } = useQuery({
    queryKey: ['user-role', user?.id],
    queryFn: async () => {
      if (!user) return { role: 'member' as UserRole, memberId: null };
      
      // Get user's tenant membership role
      const { data: membershipData } = await supabase
        .from('tenant_memberships')
        .select('role, tenant_id')
        .eq('user_id', user.id)
        .single();
      
      // Check if user is super admin
      const { data: profileData } = await supabase
        .from('profiles')
        .select('is_super_admin')
        .eq('id', user.id)
        .single();
        
      // Get member ID for the current user (if exists)
      const { data: memberData } = await supabase
        .from('members')
        .select('id')
        .eq('email', user.email)
        .single();
        
      let role: UserRole = 'member';
      
      if (profileData?.is_super_admin) {
        role = 'super-admin';
      } else if (membershipData?.role === 'admin') {
        role = 'admin';
      }
      
      return { 
        role,
        memberId: memberData?.id || null
      };
    },
    enabled: !!user?.id,
  });
  
  const role = roleData?.role || 'member';
  const isAdmin = role === 'admin' || role === 'super-admin';
  const isSuperAdmin = role === 'super-admin';
  const isMember = true; // Everyone has at least member privileges
  
  // Check if user can edit their own member data
  const isOwnMemberData = (memberId: string): boolean => {
    return !!roleData?.memberId && roleData.memberId === memberId;
  };
  
  // Permission check functions
  const canEdit = (resourceType: string, resourceOwnerId?: string): boolean => {
    if (isSuperAdmin) return true;
    if (isAdmin) return true;
    if (resourceType === 'member' && resourceOwnerId && isOwnMemberData(resourceOwnerId)) return true;
    return false;
  };
  
  const canCreate = (resourceType: string): boolean => {
    if (isSuperAdmin) return true;
    if (isAdmin) return true;
    return false;
  };
  
  const canDelete = (resourceType: string, resourceOwnerId?: string): boolean => {
    if (isSuperAdmin) return true;
    if (isAdmin) return true;
    return false;
  };
  
  return {
    role,
    isAdmin,
    isSuperAdmin,
    isMember,
    canEdit,
    canCreate,
    canDelete,
    isOwnMemberData,
    isLoading,
  };
}
