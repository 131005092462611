
import { useTranslation } from "react-i18next";
import { User, MapPin, Mail, Phone } from "lucide-react";
import { MemberListItem } from "./MemberListItem";
import { Member } from "@/types/member";

interface MemberTableProps {
  members: Member[];
  isLoading: boolean;
  searchQuery: string;
  onUpdateMember: (member: Member) => void;
  onDeleteMember: (id: string) => Promise<boolean>;
}

export function MemberTable({ 
  members, 
  isLoading, 
  searchQuery, 
  onUpdateMember, 
  onDeleteMember 
}: MemberTableProps) {
  const { t } = useTranslation();

  const filteredMembers = members.filter(member => {
    const searchLower = searchQuery.toLowerCase();
    const memberFullAddress = `${member.street_name} ${member.street_number}`.toLowerCase();
    
    return member.name.toLowerCase().includes(searchLower) ||
           member.email.toLowerCase().includes(searchLower) ||
           memberFullAddress.includes(searchLower);
  });

  return (
    <div>
      <div className="grid grid-cols-[2fr_2fr_1fr_1fr_auto] gap-4 py-2 px-4 border-b font-medium text-sm">
        <div className="flex items-center gap-2">
          <User className="w-4 h-4" />
          {t("members.name")}
        </div>
        <div className="flex items-center gap-2">
          <MapPin className="w-4 h-4" />
          {t("members.address")}
        </div>
        <div className="flex items-center gap-2">
          <Mail className="w-4 h-4" />
          {t("members.contact")}
        </div>
        <div className="flex items-center gap-2">
          <Phone className="w-4 h-4" />
          {t("members.phone")}
        </div>
        <div className="w-[88px]">{t('common.actions')}</div>
      </div>

      <div className="divide-y">
        {isLoading ? (
          <div className="flex justify-center items-center h-32">
            <p className="text-muted-foreground">{t("members.loadingMembers")}</p>
          </div>
        ) : (
          filteredMembers.map((member) => (
            <MemberListItem
              key={member.id}
              member={member}
              onEdit={onUpdateMember}
              onDelete={onDeleteMember}
            />
          ))
        )}

        {!isLoading && filteredMembers.length === 0 && (
          <div className="p-8 text-center text-muted-foreground">
            {searchQuery ? t("members.noSearchResults") : t("common.noData")}
          </div>
        )}
      </div>
    </div>
  );
}
