
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { PageContainer } from "@/components/PageContainer";
import { PageHeader } from "@/components/PageHeader";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { YearCalendar } from "@/components/calendar/YearCalendar";
import { CalendarEvent } from "@/types/calendar";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { EventForm } from "@/components/calendar/EventForm";
import { supabase } from "@/integrations/supabase/client";
import { useTenant } from "@/contexts/TenantContext";

export default function Calendar() {
  const { t } = useTranslation();
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [isAddEventOpen, setIsAddEventOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { currentTenant } = useTenant();
  
  // Fetch events on component mount
  useEffect(() => {
    if (currentTenant) {
      fetchEvents();
    }
  }, [currentTenant]);
  
  const fetchEvents = async () => {
    if (!currentTenant) return;

    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('calendar_events')
        .select('*');
      
      if (error) {
        console.error('Error fetching events:', error);
        toast.error(t("calendar.fetchError"));
        return;
      }
      
      setEvents(data || []);
    } catch (error) {
      console.error('Error:', error);
      toast.error(t("calendar.fetchError"));
    } finally {
      setLoading(false);
    }
  };
  
  const addEvent = async (event: CalendarEvent) => {
    if (!currentTenant) return;

    try {
      const { data, error } = await supabase
        .from('calendar_events')
        .insert({
          title: event.title,
          description: event.description,
          date: event.date,
          tenant_id: currentTenant.id
        })
        .select()
        .single();
      
      if (error) {
        console.error('Error adding event:', error);
        toast.error(t("calendar.addError"));
        return;
      }
      
      setEvents((prevEvents) => [...prevEvents, data]);
      setIsAddEventOpen(false);
      toast.success(t("calendar.eventAdded"));
    } catch (error) {
      console.error('Error:', error);
      toast.error(t("calendar.addError"));
    }
  };
  
  const updateEvent = async (event: CalendarEvent) => {
    if (!event.id || !currentTenant) return;
    
    try {
      const { error } = await supabase
        .from('calendar_events')
        .update({
          title: event.title,
          description: event.description,
          date: event.date
        })
        .eq('id', event.id);
      
      if (error) {
        console.error('Error updating event:', error);
        toast.error(t("calendar.updateError"));
        return;
      }
      
      setEvents((prevEvents) => 
        prevEvents.map((e) => (e.id === event.id ? event : e))
      );
      toast.success(t("calendar.eventUpdated"));
    } catch (error) {
      console.error('Error:', error);
      toast.error(t("calendar.updateError"));
    }
  };
  
  const deleteEvent = async (eventId: string) => {
    if (!currentTenant) return;
    
    try {
      const { error } = await supabase
        .from('calendar_events')
        .delete()
        .eq('id', eventId);
      
      if (error) {
        console.error('Error deleting event:', error);
        toast.error(t("calendar.deleteError"));
        return;
      }
      
      setEvents((prevEvents) => prevEvents.filter((e) => e.id !== eventId));
      toast.success(t("calendar.eventDeleted"));
    } catch (error) {
      console.error('Error:', error);
      toast.error(t("calendar.deleteError"));
    }
  };
  
  return (
    <PageContainer>
      <PageHeader 
        title={t("calendar.title")} 
        subtitle={t("calendar.subtitle")}
      >
        <Button variant="outline" onClick={() => setIsAddEventOpen(true)}>
          <Plus className="mr-2" size={16} />
          {t("calendar.addEvent")}
        </Button>
      </PageHeader>
      
      <div className="mt-6">
        {loading ? (
          <div className="flex justify-center py-8">
            <div className="animate-pulse">Loading...</div>
          </div>
        ) : (
          <YearCalendar 
            events={events} 
            onAddEvent={addEvent} 
            onUpdateEvent={updateEvent}
            onDeleteEvent={deleteEvent}
          />
        )}
      </div>
      
      {/* Add Event Sheet */}
      <Sheet open={isAddEventOpen} onOpenChange={setIsAddEventOpen}>
        <SheetContent className="sm:max-w-md">
          <SheetHeader>
            <SheetTitle>{t("calendar.addEvent")}</SheetTitle>
          </SheetHeader>
          <div className="mt-6">
            <EventForm 
              initialDate={new Date()} 
              onSubmit={addEvent} 
              onCancel={() => setIsAddEventOpen(false)} 
            />
          </div>
        </SheetContent>
      </Sheet>
    </PageContainer>
  );
}
