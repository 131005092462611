
import { Download, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { DocumentFile, DocumentFolder } from "@/types/document";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface BulkActionsProps {
  selectedItems: {
    folders: DocumentFolder[];
    files: DocumentFile[];
  };
  onDownloadSelected: () => void;
  onDeleteSelected: () => void;
}

export function BulkActions({ 
  selectedItems, 
  onDownloadSelected, 
  onDeleteSelected 
}: BulkActionsProps) {
  const { t } = useTranslation();
  const totalSelected = selectedItems.folders.length + selectedItems.files.length;
  const hasSelection = totalSelected > 0;
  
  return (
    <div className="flex items-center gap-2">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              size="icon"
              variant="outline"
              onClick={onDownloadSelected}
              disabled={!hasSelection}
              className="h-8 w-8"
            >
              <Download className="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>{t("documents.download")}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              size="icon"
              variant="outline"
              onClick={onDeleteSelected}
              disabled={!hasSelection}
              className="h-8 w-8 text-destructive hover:text-destructive-foreground hover:bg-destructive"
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>{t("common.delete")}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}
