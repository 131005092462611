
import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { Loader2 } from "lucide-react";
import { generateAndSaveOnboardingCode, generateOnboardingCodesForStreet } from "@/utils/onboardingCodes";
import { generateQRCodePDF } from "@/utils/qrCodePdfGenerator";
import { useToast } from "@/hooks/use-toast";
import { useTenant } from "@/contexts/TenantContext";
import { useTranslation } from "react-i18next";

interface QRCodeGenerationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCadastres: Array<{
    id: string;
    streetName: string;
    streetNumber: string;
    onboarding_code?: string;
  }>;
  onCodesGenerated?: () => void;
}

export function QRCodeGenerationDialog({
  isOpen,
  onClose,
  selectedCadastres,
  onCodesGenerated
}: QRCodeGenerationDialogProps) {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { currentTenant } = useTenant();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingPDFs, setIsGeneratingPDFs] = useState(false);
  const [progress, setProgress] = useState(0);
  
  // Count cadastres with onboarding codes
  const cadastresWithCodes = selectedCadastres.filter(c => !!c.onboarding_code);
  
  const handleGenerateMissingCodes = async () => {
    if (!currentTenant) return;
    
    setIsGenerating(true);
    setProgress(0);
    
    const cadastresWithoutCodes = selectedCadastres.filter(c => !c.onboarding_code);
    let completed = 0;
    
    try {
      for (const cadastre of cadastresWithoutCodes) {
        await generateAndSaveOnboardingCode({
          cadastreId: cadastre.id,
          streetName: cadastre.streetName,
          streetNumber: cadastre.streetNumber,
          tenantId: currentTenant.id
        });
        
        completed++;
        setProgress(Math.round((completed / cadastresWithoutCodes.length) * 100));
        
        // Small delay to avoid overwhelming the database
        await new Promise(resolve => setTimeout(resolve, 50));
      }
      
      toast({
        title: t("common.success"),
        description: t("cadastres.codesGenerated", { count: completed }),
      });
      
      if (onCodesGenerated) {
        onCodesGenerated();
      }
    } catch (error) {
      console.error("Error generating codes:", error);
      toast({
        title: t("common.error"),
        description: t("cadastres.generateCodesFailed"),
        variant: "destructive"
      });
    } finally {
      setIsGenerating(false);
    }
  };
  
  const handleGeneratePDFs = async () => {
    if (cadastresWithCodes.length === 0) {
      toast({
        title: t("common.error"),
        description: t("cadastres.noCodesToGeneratePDFs"),
        variant: "destructive"
      });
      return;
    }
    
    setIsGeneratingPDFs(true);
    setProgress(0);
    
    try {
      await generateQRCodePDF(cadastresWithCodes, (completed, total) => {
        setProgress(Math.round((completed / total) * 100));
      });
      
      toast({
        title: t("common.success"),
        description: t("cadastres.pdfsGenerated", { count: cadastresWithCodes.length }),
      });
    } catch (error) {
      console.error("Error generating PDFs:", error);
      toast({
        title: t("common.error"),
        description: t("cadastres.generatePDFsFailed"),
        variant: "destructive"
      });
    } finally {
      setIsGeneratingPDFs(false);
    }
  };
  
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>{t("cadastres.qrCodeGeneration")}</DialogTitle>
          <DialogDescription>
            {t("cadastres.qrCodeGenerationDescription")}
          </DialogDescription>
        </DialogHeader>
        
        <div className="space-y-4 py-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="border rounded-md p-3 text-center">
              <div className="text-2xl font-bold">{selectedCadastres.length}</div>
              <div className="text-sm text-muted-foreground">{t("cadastres.selectedCadastres")}</div>
            </div>
            
            <div className="border rounded-md p-3 text-center">
              <div className="text-2xl font-bold">{cadastresWithCodes.length}</div>
              <div className="text-sm text-muted-foreground">{t("cadastres.withOnboardingCodes")}</div>
            </div>
          </div>
          
          {(isGenerating || isGeneratingPDFs) && (
            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span>{isGenerating ? t("cadastres.generatingCodes") : t("cadastres.generatingPDFs")}</span>
                <span>{progress}%</span>
              </div>
              <Progress value={progress} />
            </div>
          )}
        </div>
        
        <DialogFooter className="sm:justify-between">
          <Button
            onClick={handleGenerateMissingCodes}
            disabled={isGenerating || isGeneratingPDFs || selectedCadastres.every(c => !!c.onboarding_code)}
          >
            {isGenerating && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            {t("cadastres.generateMissingCodes")}
          </Button>
          
          <Button
            onClick={handleGeneratePDFs}
            disabled={isGenerating || isGeneratingPDFs || cadastresWithCodes.length === 0}
          >
            {isGeneratingPDFs && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            {t("cadastres.generatePDFs")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
