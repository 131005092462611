
import React from "react";
import { useTranslation } from "react-i18next";
import { 
  FormControl, 
  FormDescription, 
  FormField, 
  FormItem, 
  FormLabel, 
  FormMessage 
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";

interface StatutesVotingSectionProps {
  form: any;
  isAdmin: boolean;
}

export function StatutesVotingSection({ form, isAdmin }: StatutesVotingSectionProps) {
  const { t } = useTranslation();

  // Calculate total maximum votes per cadastre
  const totalMaxVotes = () => {
    const votesPerCadastre = form.watch("votesPerCadastre") || 1;
    const allowProxyVotes = form.watch("allowProxyVotes");
    const maxProxyVotes = form.watch("maxProxyVotes") || 0;
    
    return votesPerCadastre + (allowProxyVotes ? maxProxyVotes : 0);
  };

  return (
    <>
      <FormField
        control={form.control}
        name="votesPerCadastre"
        render={({ field }) => (
          <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
            <div  className="text-left">
              <FormLabel>{t("settings.statutes.votesPerCadastre")}</FormLabel>
              <FormDescription>
                {t("settings.statutes.votesPerCadastreDescription")}
              </FormDescription>
            </div>
            <FormMessage />
            <FormControl>
              <Input className="w-[288px]"
                type="number"
                min={1}
                disabled={!isAdmin}
                {...field}
                value={field.value || 1}
                onChange={(e) => 
                  field.onChange(parseInt(e.target.value) || 1)
                }
              />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="allowProxyVotes"
        render={({ field }) => (
          <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
            <div  className="text-left min-w-[260px]">
              <FormLabel className="text-base">
                {t("settings.statutes.allowProxyVotes")}
              </FormLabel>
              <FormDescription>
                {t("settings.statutes.allowProxyVotesDescription")}
              </FormDescription>
            </div>
            <FormControl>
              <Switch
                checked={field.value}
                onCheckedChange={field.onChange}
                disabled={!isAdmin}
              />
            </FormControl>
          </FormItem>
        )}
      />

      {form.watch("allowProxyVotes") && (
        <FormField
          control={form.control}
          name="maxProxyVotes"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
              <div className="space-y-0.5">
                <FormLabel className="text-base">
                  {t("settings.statutes.maxProxyVotes")}
                </FormLabel>
                <FormDescription>
                  {t("settings.statutes.maxProxyVotesDescription")}
                </FormDescription>
              </div>
              <FormControl>
                <Input
                  type="number"
                  min={1}
                  disabled={!isAdmin}
                  className="w-20 text-right"
                  {...field}
                  value={field.value || 1}
                  onChange={(e) => 
                    field.onChange(parseInt(e.target.value) || 1)
                  }
                />
              </FormControl>
            </FormItem>
          )}
        />
      )}

      {form.watch("allowProxyVotes") && (
        <div className="rounded-lg border p-4 bg-muted/50">
          <div className="font-medium">
            {t("settings.statutes.totalMaxVotes", { count: totalMaxVotes() })}
          </div>
          <div className="text-sm text-muted-foreground mt-1">
            {t("settings.statutes.totalMaxVotesDescription", {
              regular: form.watch("votesPerCadastre") || 1,
              proxy: form.watch("maxProxyVotes") || 1
            })}
          </div>
        </div>
      )}
    </>
  );
}
