
import { Checkbox } from "@/components/ui/checkbox";
import { useTranslation } from "react-i18next";

interface CadastreListHeaderProps {
  sortBy: 'address' | 'residents' | 'plotNumber';
  getSortIcon: (field: 'address' | 'residents' | 'plotNumber') => React.ReactNode;
  handleSort: (field: 'address' | 'residents' | 'plotNumber') => void;
  allSelected: boolean;
  handleSelectAll: (checked: boolean) => void;
}

export function CadastreListHeader({
  sortBy,
  getSortIcon,
  handleSort,
  allSelected,
  handleSelectAll
}: CadastreListHeaderProps) {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-[auto_2fr_1fr_0.5fr_0.5fr] gap-4 py-2 px-4 border-b font-medium text-sm">
      <div className="flex items-center justify-center">
        <Checkbox 
          checked={allSelected} 
          onCheckedChange={handleSelectAll}
          aria-label="Select all cadastres"
        />
      </div>
      <button 
        onClick={() => handleSort('address')} 
        className="text-left flex items-center hover:text-primary transition-colors"
      >
        {t('cadastres.address')}
        {getSortIcon('address')}
      </button>
      <button 
        onClick={() => handleSort('residents')} 
        className="text-left flex items-center hover:text-primary transition-colors"
      >
        {t('cadastres.residents')}
        {getSortIcon('residents')}
      </button>
      <button 
        onClick={() => handleSort('plotNumber')} 
        className="text-left flex items-center hover:text-primary transition-colors"
      >
        {t('cadastres.plotNumber')}
        {getSortIcon('plotNumber')}
      </button>
      <div className="text-right min-w-[138px]">{t('common.actions')}</div>
    </div>
  );
}
