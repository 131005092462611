
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useTenant } from "@/contexts/TenantContext";
import { format, subMonths, addMonths, startOfMonth, endOfMonth, parseISO, isBefore } from "date-fns";

interface DashboardStats {
  // Members
  totalMembers: number;
  newMembersSinceLastMonth: number;
  
  // Documents
  totalDocuments: number;
  newDocumentsThisMonth: number;
  
  // Events
  upcomingEvents: number;
  daysUntilNextEvent: number | null;
  nextEventDate: string | null;
  
  // Cadastres
  totalCadastres: number;
  cadastresWithoutMembers: number;
}

export function useDashboardStats() {
  const { currentTenant } = useTenant();
  const currentDate = new Date();
  const firstDayLastMonth = startOfMonth(subMonths(currentDate, 1));
  const firstDayCurrentMonth = startOfMonth(currentDate);
  const lastDayInThreeMonths = endOfMonth(addMonths(currentDate, 3));

  const { data: stats = {
    totalMembers: 0,
    newMembersSinceLastMonth: 0,
    totalDocuments: 0,
    newDocumentsThisMonth: 0,
    upcomingEvents: 0,
    daysUntilNextEvent: null,
    nextEventDate: null,
    totalCadastres: 0,
    cadastresWithoutMembers: 0
  }, isLoading } = useQuery({
    queryKey: ['dashboardStats', currentTenant?.id],
    queryFn: async (): Promise<DashboardStats> => {
      if (!currentTenant) {
        throw new Error("No tenant selected");
      }

      // Fetch members
      const { data: members, error: membersError } = await supabase
        .from('members')
        .select('id, created_at')
        .eq('tenant_id', currentTenant.id)
        .eq('status', 'active');

      if (membersError) throw membersError;

      const totalMembers = members.length;
      const newMembersSinceLastMonth = members.filter(member => 
        parseISO(member.created_at) >= firstDayLastMonth
      ).length;

      // Fetch documents
      const { data: documents, error: documentsError } = await supabase
        .from('document_files')
        .select('id, created_at')
        .eq('tenant_id', currentTenant.id);

      if (documentsError) throw documentsError;

      const totalDocuments = documents.length;
      const newDocumentsThisMonth = documents.filter(doc => 
        parseISO(doc.created_at) >= firstDayCurrentMonth
      ).length;

      // Fetch events
      const { data: events, error: eventsError } = await supabase
        .from('calendar_events')
        .select('id, date')
        .eq('tenant_id', currentTenant.id)
        .gte('date', currentDate.toISOString())
        .lte('date', lastDayInThreeMonths.toISOString())
        .order('date');

      if (eventsError) throw eventsError;

      const upcomingEvents = events.length;
      
      // Calculate days until next event
      let daysUntilNextEvent = null;
      let nextEventDate = null;
      
      if (events.length > 0) {
        const nextEvent = events[0];
        const eventDate = parseISO(nextEvent.date);
        const diffTime = Math.abs(eventDate.getTime() - currentDate.getTime());
        daysUntilNextEvent = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        nextEventDate = nextEvent.date;
      }

      // Fetch cadastres and count those without members
      const { data: cadastres, error: cadastresError } = await supabase
        .from('cadastres')
        .select('id')
        .eq('tenant_id', currentTenant.id);

      if (cadastresError) throw cadastresError;

      const totalCadastres = cadastres.length;

      // For each cadastre, check if it has associated members
      let cadastresWithoutMembers = 0;
      for (const cadastre of cadastres) {
        const { count, error: memberCountError } = await supabase
          .from('members')
          .select('id', { count: 'exact', head: true })
          .eq('cadastre_id', cadastre.id)
          .eq('tenant_id', currentTenant.id);

        if (memberCountError) throw memberCountError;

        if (count === 0) {
          cadastresWithoutMembers++;
        }
      }

      return {
        totalMembers,
        newMembersSinceLastMonth,
        totalDocuments,
        newDocumentsThisMonth,
        upcomingEvents,
        daysUntilNextEvent,
        nextEventDate,
        totalCadastres,
        cadastresWithoutMembers
      };
    },
    enabled: !!currentTenant?.id,
  });

  return {
    stats,
    isLoading
  };
}
