
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { FileDown } from "lucide-react";
import { exportMembers } from "@/utils/memberExport";
import { Member } from "@/types/member";

interface MemberExportTabProps {
  members: Member[];
}

export function MemberExportTab({ members }: MemberExportTabProps) {
  const { t } = useTranslation();

  const handleExport = async (format: 'xlsx' | 'csv') => {
    await exportMembers(members, format, t);
  };

  return (
    <div>
      <h3 className="text-lg font-medium mb-2">{t('members.exportTitle')}</h3>
      <p className="text-muted-foreground mb-6">
        {t('members.exportDescription')}
      </p>
      <div className="flex gap-4 justify-center">
        <Button onClick={() => handleExport('xlsx')}>
          <FileDown className="mr-2 h-4 w-4" />
          {t('members.exportAsXLSX')}
        </Button>
        <Button onClick={() => handleExport('csv')}>
          <FileDown className="mr-2 h-4 w-4" />
          {t('members.exportAsCSV')}
        </Button>
      </div>
    </div>
  );
}
