import {
  Users,
  Home,
  FileText,
  Calendar,
  MessageSquare,
  ClipboardList,
  Settings,
  Bell,
  Building2,
  UserRound,
  LogOut,
  Shield,
} from "lucide-react";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuBadge,
} from "@/components/ui/sidebar";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { useTenant } from "@/contexts/TenantContext";
import { Button } from "./ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useEffect, useState, memo, useMemo } from "react";
import md5 from "md5";
import { useTranslation } from "react-i18next";
import { Sheet, SheetContent, SheetTrigger } from "./ui/sheet";
import { ProfileSettings } from "./ProfileSettings";
import { useAssociationSettings } from "@/hooks/useAssociationSettings";
import { useAdminStatus } from "@/hooks/useAdminStatus";
import { usePendingRequests } from "@/hooks/usePendingRequests";
import { toast } from "sonner";

interface MenuItem {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  path: string;
  requiresTenant?: boolean;
  requiresAdmin?: boolean;
}

const menuItems: MenuItem[] = [
  { title: "menu.home", icon: Home, path: "/", requiresTenant: false },
  { title: "menu.members", icon: Users, path: "/members", requiresTenant: true },
  { title: "menu.cadastres", icon: Building2, path: "/cadastres", requiresTenant: true },
  { title: "menu.board", icon: UserRound, path: "/board", requiresTenant: true },
  { title: "menu.documents", icon: FileText, path: "/documents", requiresTenant: true },
  { title: "menu.calendar", icon: Calendar, path: "/calendar", requiresTenant: true },
  { title: "menu.meetings", icon: Calendar, path: "/meetings", requiresTenant: true },
  { title: "menu.forum", icon: MessageSquare, path: "/forum", requiresTenant: true },
  { title: "menu.tasks", icon: ClipboardList, path: "/tasks", requiresTenant: true },
];

const bottomMenuItems: MenuItem[] = [
  { title: "menu.notifications", icon: Bell, path: "/notifications", requiresTenant: true },
  { title: "menu.settings", icon: Settings, path: "/settings", requiresTenant: true },
  { title: "menu.admin", icon: Shield, path: "/admin", requiresAdmin: true }
];

// Updated reusable SidebarTitle component - now memoized
interface SidebarTitleProps {
  associationName?: string;
  logoUrl?: string;
}

const SidebarTitle = memo(({ associationName, logoUrl }: SidebarTitleProps) => {
  const { t } = useTranslation();
  
  if (logoUrl) {
    return (
      <div className="w-full flex items-center justify-center h-20">
        <div className="relative w-full h-full flex items-center justify-center overflow-hidden">
          <img 
            src={logoUrl} 
            alt="Association Logo" 
            className="max-w-full max-h-full object-contain" 
          />
        </div>
      </div>
    );
  }
  
  if (associationName) {
    return <h1 className="text-xl font-semibold truncate">{associationName}</h1>;
  }
  
  return <h1 className="text-xl font-semibold">{t("app.title", "HOA Manager")}</h1>;
});

// Add display name for better debugging
SidebarTitle.displayName = "SidebarTitle";

export function AppSidebar() {
  const { t } = useTranslation();
  const { signOut, user } = useAuth();
  const { currentTenant } = useTenant();
  const location = useLocation();
  
  // Use React Query hooks instead of useState and useEffect
  const { data: isSuperAdmin = false } = useAdminStatus();
  const { data: pendingRequests = 0 } = usePendingRequests();
  
  // Important: Change the staleTime and cacheTime to ensure we get fresh data
  const { data: associationSettings = { name: undefined, logo_url: undefined } } = useAssociationSettings();

  // Get initials from email
  const getInitials = (email: string) => {
    return email?.substring(0, 2).toUpperCase() || "??";
  };

  // Get Gravatar URL
  const getGravatarUrl = (email: string) => {
    const hash = md5(email.toLowerCase().trim());
    return `https://www.gravatar.com/avatar/${hash}?d=mp&s=80`;
  };

  const shouldShowMenuItem = (item: MenuItem) => {
    if (item.requiresAdmin && !isSuperAdmin) return false;
    if (item.requiresTenant && !currentTenant) return false;
    return true;
  };

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  // Use useMemo for filtered menu items to prevent unnecessary re-calculations
  const filteredMainMenuItems = useMemo(() => 
    menuItems.filter(shouldShowMenuItem), 
    [menuItems, isSuperAdmin, currentTenant]
  );

  const filteredBottomMenuItems = useMemo(() => 
    bottomMenuItems.filter(shouldShowMenuItem), 
    [bottomMenuItems, isSuperAdmin, currentTenant]
  );

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error("Error during sign out:", error);
      toast.error("Failed to sign out. Please try again.");
    }
  };

  return (
    <Sidebar>
      <SidebarContent>
        <div className="flex flex-col h-full">
          <div className="p-4">
            <SidebarTitle 
              associationName={associationSettings.name} 
              logoUrl={associationSettings.logo_url} 
            />
          </div>
          
          <SidebarGroup>
            <SidebarGroupLabel>{t("menu.mainMenu")}</SidebarGroupLabel>
            <SidebarGroupContent>
              <SidebarMenu>
                {filteredMainMenuItems.map((item) => (
                  <SidebarMenuItem key={item.title}>
                    <SidebarMenuButton asChild>
                      <Link 
                        to={item.path} 
                        className={`flex items-center gap-3 ${isActive(item.path) ? 'font-medium' : ''}`}
                      >
                        <item.icon className="w-4 h-4" />
                        <span>{t(item.title)}</span>
                        {item.path === '/members' && pendingRequests > 0 && (
                          <SidebarMenuBadge>
                            {pendingRequests}
                          </SidebarMenuBadge>
                        )}
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>

          <div className="mt-auto">
            <SidebarGroup>
              <SidebarGroupContent>
                <SidebarMenu>
                  {filteredBottomMenuItems.map((item) => (
                    <SidebarMenuItem key={item.title}>
                      <SidebarMenuButton asChild>
                        <Link 
                          to={item.path} 
                          className={`flex items-center gap-3 ${isActive(item.path) ? 'font-medium' : ''}`}
                        >
                          <item.icon className="w-4 h-4" />
                          <span>{t(item.title)}</span>
                        </Link>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  ))}
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>

            <div className="p-4 border-t border-sidebar-border mt-2">
              <Sheet>
                <SheetTrigger asChild>
                  <div className="flex items-center gap-3 cursor-pointer hover:bg-accent rounded-md p-2 transition-colors">
                    <Avatar>
                      <AvatarImage 
                        src={user?.user_metadata?.avatar_url || (user?.email ? getGravatarUrl(user.email) : undefined)} 
                      />
                      <AvatarFallback>{user?.email ? getInitials(user.email) : "??"}</AvatarFallback>
                    </Avatar>
                    <div className="flex flex-col">
                      <span className="text-sm font-medium truncate">
                        {user?.email}
                      </span>
                      <span className="text-xs text-muted-foreground truncate">
                        {t("common.loggedIn")}
                      </span>
                    </div>
                  </div>
                </SheetTrigger>
                <SheetContent>
                  <ProfileSettings />
                </SheetContent>
              </Sheet>

              <Button
                variant="ghost"
                className="w-full justify-start mt-2"
                onClick={handleSignOut}
              >
                <LogOut className="w-4 h-4 mr-3" />
                <span>{t("auth.signOut")}</span>
              </Button>
            </div>
          </div>
        </div>
      </SidebarContent>
    </Sidebar>
  );
}
