
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { Label } from "@/components/ui/label";

interface FileUploadDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (file: File) => void;
  title?: string;
  description?: string;
  acceptedFileTypes?: string;
  showAdoptionDate?: boolean;
  adoptionDate?: Date | null;
  onAdoptionDateChange?: (date: Date | null) => void;
  adoptionDateRequired?: boolean;
}

export function FileUploadDialog({
  isOpen,
  onClose,
  onUpload,
  title = "Upload File",
  description = "Select a file to upload",
  acceptedFileTypes = ".pdf,.doc,.docx,.txt,.xls,.xlsx",
  showAdoptionDate = false,
  adoptionDate = null,
  onAdoptionDateChange,
  adoptionDateRequired = false
}: FileUploadDialogProps) {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (!selectedFile) {
      toast.error(t("documents.noFileSelected"));
      return;
    }

    if (showAdoptionDate && adoptionDateRequired && !adoptionDate) {
      toast.error(t("settings.statutes.adoptionDateRequired"));
      return;
    }

    onUpload(selectedFile);
    setSelectedFile(null);
  };

  const handleClose = () => {
    setSelectedFile(null);
    onClose();
  };

  const handleDateSelect = (date: Date | undefined) => {
    if (onAdoptionDateChange) {
      onAdoptionDateChange(date || null);
    }
    setPopoverOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <input
              type="file"
              accept={acceptedFileTypes}
              className="cursor-pointer file:cursor-pointer file:border-0 file:bg-transparent file:text-sm file:font-medium"
              onChange={handleFileChange}
            />
            {selectedFile && (
              <p className="text-xs text-muted-foreground">
                {selectedFile.name} ({(selectedFile.size / 1024).toFixed(1)} KB)
              </p>
            )}
          </div>

          {showAdoptionDate && (
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="adoption-date">{t("settings.statutes.adoptionDate")}</Label>
              <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
                <PopoverTrigger asChild>
                  <Button
                    id="adoption-date"
                    variant="outline"
                    className={cn(
                      "w-full justify-start text-left font-normal",
                      !adoptionDate && "text-muted-foreground"
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {adoptionDate ? (
                      format(adoptionDate, "PPP")
                    ) : (
                      <span>{t("settings.statutes.selectDate")}</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={adoptionDate || undefined}
                    onSelect={handleDateSelect}
                    initialFocus
                    className={cn("p-3 pointer-events-auto")}
                  />
                </PopoverContent>
              </Popover>
              {adoptionDateRequired && !adoptionDate && (
                <p className="text-xs text-destructive">
                  {t("settings.statutes.adoptionDateRequired")}
                </p>
              )}
            </div>
          )}
        </div>
        <DialogFooter>
          <Button
            type="button"
            variant="outline"
            onClick={handleClose}
          >
            {t("common.cancel")}
          </Button>
          <Button
            type="button"
            onClick={handleUpload}
            disabled={!selectedFile || (showAdoptionDate && adoptionDateRequired && !adoptionDate)}
          >
            {t("common.upload")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
