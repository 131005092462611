import { Bell } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { DashboardCards } from "./DashboardCards";
import { Card } from "@/components/ui/card";

const announcements = [{
  title: "Årlig General Forsamling",
  description: "The annual general meeting will be held on July 15th at 6 PM in the main hall.",
  date: "2025-07-15"
}, {
  title: "Vej arbejde",
  description: "Der vil være vejarbejde i perioden 1. juni til 1. juli. Der vil være begrænset adgang til parkering.",
  date: "2025-06-01"
}, {
  title: "Nye parkeringsregler",
  description: "Parkeringsreglerne er blevet opdateret. Læs mere på vores hjemmeside.",
  date: "2024-06-01"
}];

export const DashboardView = () => {
  const { t } = useTranslation();

  return (
    <div className="space-y-8 animate-fadeIn">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold text-left">{t('dashboard.welcomeBack')}</h1>
          <p className="text-muted-foreground">{t('dashboard.whatsHappening')}</p>
        </div>
        <Button variant="outline" className="flex items-center gap-2">
          <Bell className="w-4 h-4" />
          {t('dashboard.notifications')}
        </Button>
      </div>

      <DashboardCards />

      <div>
        <h2 className="text-xl font-semibold mb-4">{t('dashboard.recentAnnouncements')}</h2>
        <div className="grid gap-4">
          {announcements.map(announcement => (
            <Card key={announcement.title} className="p-6 hover:bg-accent/5 transition-colors">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="font-medium text-left">{announcement.title}</h3>
                  <p className="text-sm text-muted-foreground mt-1">
                    {announcement.description}
                  </p>
                </div>
                <span className="text-sm text-muted-foreground">
                  {new Date(announcement.date).toLocaleDateString()}
                </span>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};
