
import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "./AuthContext";
import { toast } from "sonner";

interface Tenant {
  id: string;
  name: string;
}

interface TenantContextType {
  currentTenant: Tenant | null;
  tenants: Tenant[];
  setCurrentTenant: (tenant: Tenant) => void;
  isLoading: boolean;
}

const TenantContext = createContext<TenantContextType | undefined>(undefined);

export function TenantProvider({ children }: { children: React.ReactNode }) {
  const [currentTenant, setCurrentTenant] = useState<Tenant | null>(() => {
    // Try to restore from localStorage
    const savedTenant = localStorage.getItem('currentTenant');
    return savedTenant ? JSON.parse(savedTenant) : null;
  });
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      loadTenants();
    } else {
      setTenants([]);
      setCurrentTenant(null);
      localStorage.removeItem('currentTenant');
      setIsLoading(false);
    }
  }, [user]);

  // Save currentTenant to localStorage whenever it changes
  useEffect(() => {
    if (currentTenant) {
      localStorage.setItem('currentTenant', JSON.stringify(currentTenant));
    } else {
      localStorage.removeItem('currentTenant');
    }
  }, [currentTenant]);

  const loadTenants = async () => {
    try {
      console.log("Loading tenants for user:", user?.id);
      setIsLoading(true);
      
      // First check if the user is a super-admin
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('is_super_admin')
        .eq('id', user?.id)
        .single();

      if (profileError) {
        console.error("Error fetching profile:", profileError);
        throw profileError;
      }
      
      const isSuperAdmin = profileData?.is_super_admin;
      console.log("User is super admin:", isSuperAdmin);
      
      // If super-admin, can see all tenants
      let userTenants: Tenant[] = [];
      
      if (isSuperAdmin) {
        const { data: allTenants, error: allTenantsError } = await supabase
          .from('tenants')
          .select('id, name');
          
        if (allTenantsError) {
          console.error("Error fetching all tenants:", allTenantsError);
          throw allTenantsError;
        }
        
        console.log("Fetched tenants for super admin:", allTenants?.length);
        userTenants = allTenants;
      } else {
        // Regular user - only show tenants they're members of
        const { data: tenantMemberships, error: membershipError } = await supabase
          .from('tenant_memberships')
          .select('tenant:tenants(id, name)')
          .eq('user_id', user?.id);

        if (membershipError) {
          console.error("Error fetching tenant memberships:", membershipError);
          throw membershipError;
        }
        
        console.log("Fetched tenant memberships:", tenantMemberships?.length);
        userTenants = tenantMemberships.map(tm => tm.tenant as Tenant);
      }

      console.log("User tenants:", userTenants);
      setTenants(userTenants);

      // Check if current tenant is still valid
      const currentTenantId = currentTenant?.id;
      const tenantStillExists = currentTenantId && userTenants.some(t => t.id === currentTenantId);
      
      if (userTenants.length > 0) {
        if (!tenantStillExists) {
          // If current tenant is no longer valid, set the first available tenant
          console.log("Setting first tenant as current:", userTenants[0]);
          setCurrentTenant(userTenants[0]);
        } else {
          // If current tenant exists but might have updated data, refresh it
          const updatedCurrentTenant = userTenants.find(t => t.id === currentTenantId);
          if (updatedCurrentTenant && 
              (updatedCurrentTenant.name !== currentTenant?.name)) {
            console.log("Updating current tenant data:", updatedCurrentTenant);
            setCurrentTenant(updatedCurrentTenant);
          }
        }
      } else if (currentTenant) {
        // If user no longer has any tenants but still has a currentTenant, clear it
        console.log("User has no tenants, clearing current tenant");
        setCurrentTenant(null);
      }
    } catch (error) {
      console.error('Error loading tenants:', error);
      toast.error('Failed to load tenants');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetCurrentTenant = (tenant: Tenant) => {
    console.log("Setting current tenant to:", tenant.name, tenant.id);
    setCurrentTenant(tenant);
  };

  return (
    <TenantContext.Provider value={{ 
      currentTenant, 
      tenants, 
      setCurrentTenant: handleSetCurrentTenant, 
      isLoading 
    }}>
      {children}
    </TenantContext.Provider>
  );
}

export function useTenant() {
  const context = useContext(TenantContext);
  if (context === undefined) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
}
