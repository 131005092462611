import * as React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { DayPicker, useNavigation } from "react-day-picker";

import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function CustomCaption({ 
  displayMonth, 
  displayYear,
  onMonthSelect,
  onYearSelect 
}: { 
  displayMonth: number; 
  displayYear: number;
  onMonthSelect: (month: number) => void;
  onYearSelect: (year: number) => void;
}) {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  
  const years = React.useMemo(() => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 31 }, (_, i) => currentYear - 20 + i);
  }, []);

  const months = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ];
  
  const handleMonthChange = (value: string) => {
    const monthIndex = parseInt(value);
    onMonthSelect(monthIndex);
    
    const newDate = new Date();
    newDate.setMonth(monthIndex);
    newDate.setFullYear(displayYear);
    goToMonth(newDate);
  };
  
  const handleYearChange = (value: string) => {
    const year = parseInt(value);
    onYearSelect(year);
    
    const newDate = new Date();
    newDate.setMonth(displayMonth);
    newDate.setFullYear(year);
    goToMonth(newDate);
  };

  return (
    <div className="flex justify-between items-center px-2">
      <button
        onClick={() => previousMonth && goToMonth(previousMonth)}
        disabled={!previousMonth}
        className={cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        )}
      >
        <ChevronLeft className="h-4 w-4" />
      </button>
      
      <div className="flex space-x-2">
        <Select value={displayMonth.toString()} onValueChange={handleMonthChange}>
          <SelectTrigger className="h-7 w-[110px]">
            <SelectValue placeholder="Select month" />
          </SelectTrigger>
          <SelectContent>
            {months.map((month, index) => (
              <SelectItem key={month} value={index.toString()}>
                {month}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        
        <Select value={displayYear.toString()} onValueChange={handleYearChange}>
          <SelectTrigger className="h-7 w-[80px]">
            <SelectValue placeholder="Select year" />
          </SelectTrigger>
          <SelectContent>
            {years.map((year) => (
              <SelectItem key={year} value={year.toString()}>
                {year}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      
      <button
        onClick={() => nextMonth && goToMonth(nextMonth)}
        disabled={!nextMonth}
        className={cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        )}
      >
        <ChevronRight className="h-4 w-4" />
      </button>
    </div>
  );
}

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  const [month, setMonth] = React.useState<Date>(props.defaultMonth || new Date());
  
  const [displayMonth, setDisplayMonth] = React.useState(month.getMonth());
  const [displayYear, setDisplayYear] = React.useState(month.getFullYear());
  
  React.useEffect(() => {
    setDisplayMonth(month.getMonth());
    setDisplayYear(month.getFullYear());
  }, [month]);

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3 pointer-events-auto", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "hidden",
        nav: "hidden",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell:
          "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
        ),
        day_range_end: "day-range-end",
        day_selected:
          "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        day_today: "bg-accent text-accent-foreground",
        day_outside:
          "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle:
          "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        ...classNames,
      }}
      month={month}
      onMonthChange={setMonth}
      components={{
        Caption: ({ displayMonth }) => (
          <CustomCaption 
            displayMonth={displayMonth.getMonth()} 
            displayYear={displayMonth.getFullYear()} 
            onMonthSelect={setDisplayMonth}
            onYearSelect={setDisplayYear}
          />
        ),
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
