
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ArrowLeft, Info, Loader2 } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/contexts/AuthContext";
import { toast } from "sonner";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import type { CVRResponse } from "@/types/settings";

export default function RequestCreate() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    cvr_number: "",
    street_address: "",
    street_number: "",
    post_number: "",
    contact_email: "",
    city: "",
  });
  const [existingError, setExistingError] = useState<string | null>(null);
  const { user } = useAuth();

  const checkExistingAssociation = async (cvrNumber: string) => {
    // Check existing association settings
    const { data: existingAssociation } = await supabase
      .from('association_settings')
      .select('id')
      .eq('cvr_number', cvrNumber)
      .maybeSingle();

    if (existingAssociation) {
      return "An association with this CVR number already exists in the system.";
    }

    // Check pending creation requests
    const { data: existingRequest } = await supabase
      .from('association_creation_requests')
      .select('id')
      .eq('cvr_number', cvrNumber)
      .eq('status', 'pending')
      .maybeSingle();

    if (existingRequest) {
      return "There is already a pending creation request for this CVR number.";
    }

    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    setIsLoading(true);
    try {
      const { error } = await supabase
        .from('association_creation_requests')
        .insert([{
          user_id: user.id,
          ...formData,
          contact_email_verified: false
        }]);

      if (error) throw error;

      toast.success("Association creation request submitted successfully");
      navigate('/');
    } catch (error) {
      console.error('Submit error:', error);
      toast.error("Failed to submit creation request");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCVRLookup = async (value: string) => {
    if (value.length === 8) {
      try {
        // First check if CVR exists in our system
        const existingError = await checkExistingAssociation(value);
        setExistingError(existingError);

        if (existingError) {
          return;
        }

        const response = await fetch(`https://cvrapi.dk/api?search=${value}&country=dk`);
        const data: CVRResponse = await response.json();

        if (data.error) {
          toast.error("Could not find company with this CVR number");
          return;
        }

        // Split address into street name and number
        const addressParts = data.address.split(" ");
        const streetNumber = addressParts.pop() || "";
        const streetAddress = addressParts.join(" ");

        setFormData({
          name: data.name,
          cvr_number: value,
          street_address: streetAddress,
          street_number: streetNumber,
          post_number: data.zipcode,
          contact_email: data.email || "",
          city: data.city,
        });

        toast.success("Company details have been automatically filled");
      } catch (error) {
        toast.error("Failed to fetch company details");
      }
    } else {
      setExistingError(null);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    // Only allow direct input for CVR number
    if (name === 'cvr_number') {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
      handleCVRLookup(value);
    }
  };

  const isFormValid = formData.cvr_number && formData.name && formData.contact_email && !existingError;

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
      <Card className="w-full max-w-md p-6">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold">Request New Association</h1>
          <Link to="/">
            <Button variant="ghost" size="icon">
              <ArrowLeft className="h-5 w-5" />
            </Button>
          </Link>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <label className="text-sm font-medium block text-left">CVR Number</label>
            <Input
              name="cvr_number"
              value={formData.cvr_number}
              onChange={handleInputChange}
              placeholder="Enter CVR number"
              required
            />
            {existingError ? (
              <p className="text-sm text-red-500 mt-1">{existingError}</p>
            ) : (
              <p className="text-sm text-muted-foreground">
                Enter an 8-digit CVR number to automatically fill the form
              </p>
            )}
          </div>

          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <label className="text-sm font-medium block text-left">Contact Email</label>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Info className="h-4 w-4 text-muted-foreground hover:text-foreground transition-colors" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="max-w-xs">This email is retrieved from official records and will be used to verify your association's request. Only authorized representatives can submit requests.</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <Input
              name="contact_email"
              value={formData.contact_email}
              onChange={handleInputChange}
              placeholder="Contact email will appear here"
              required
              readOnly
              className="bg-gray-50"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium block text-left">Association Name</label>
            <Input
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Association name will appear here"
              required
              readOnly
              className="bg-gray-50"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium block text-left">Street Address</label>
            <Input
              name="street_address"
              value={formData.street_address}
              onChange={handleInputChange}
              placeholder="Street address will appear here"
              required
              readOnly
              className="bg-gray-50"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium block text-left">Street Number</label>
            <Input
              name="street_number"
              value={formData.street_number}
              onChange={handleInputChange}
              placeholder="Street number will appear here"
              required
              readOnly
              className="bg-gray-50"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium block text-left">Post Number</label>
            <Input
              name="post_number"
              value={formData.post_number}
              onChange={handleInputChange}
              placeholder="Post number will appear here"
              required
              readOnly
              className="bg-gray-50"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium block text-left">City</label>
            <Input
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              placeholder="City will appear here"
              required
              readOnly
              className="bg-gray-50"
            />
          </div>

          <Button 
            type="submit" 
            className="w-full" 
            disabled={isLoading || !isFormValid}
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Submitting...
              </>
            ) : (
              'Submit Request'
            )}
          </Button>
        </form>
      </Card>
    </div>
  );
}
