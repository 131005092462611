
import React from "react";
import { useTranslation } from "react-i18next";
import { StatutesFileCard } from "./statutes/StatutesFileCard";
import { StatutesFormCard } from "./statutes/StatutesFormCard";
import { useStatuteFileUpload } from "./statutes/hooks/useStatuteFileUpload";
import { useStatuteFile } from "./statutes/hooks/useStatuteFile";
import { useStatuteForm } from "./statutes/hooks/useStatuteForm";
import { FileUploadDialog } from "@/components/documents/FileUploadDialog";

export function StatutesSettings() {
  const { t } = useTranslation();
  
  // Hook for statute file upload functionality
  const {
    isUploading,
    showUploadDialog,
    adoptionDate,
    setAdoptionDate,
    handleUploadClick,
    handleFileUpload,
    setShowUploadDialog
  } = useStatuteFileUpload();
  
  // Hook for statute file data and operations
  const {
    currentStatute,
    statuteFile,
    handleDownload
  } = useStatuteFile();
  
  // Hook for form state and operations
  const {
    form,
    isLoading,
    isSaving,
    isDirty,
    isAdmin,
    onSubmit
  } = useStatuteForm();
  
  if (isLoading) {
    return <div className="text-center p-8">{t("common.loading")}...</div>;
  }
  
  return (
    <div className="space-y-6">
      <StatutesFileCard 
        statuteFile={statuteFile} 
        isAdmin={isAdmin} 
        isUploading={isUploading} 
        onUploadClick={handleUploadClick} 
        onDownloadClick={handleDownload}
        adoptionDate={currentStatute?.adoption_date}
      />
      
      <StatutesFormCard 
        form={form} 
        onSubmit={onSubmit} 
        isAdmin={isAdmin} 
        isSaving={isSaving} 
        isDirty={isDirty} 
      />
    
      <FileUploadDialog 
        isOpen={showUploadDialog} 
        onClose={() => setShowUploadDialog(false)} 
        onUpload={handleFileUpload} 
        title={t("settings.statutes.uploadTitle")} 
        description={t("settings.statutes.uploadDescription")} 
        acceptedFileTypes=".pdf,.doc,.docx" 
        showAdoptionDate={true} 
        adoptionDate={adoptionDate} 
        onAdoptionDateChange={setAdoptionDate} 
        adoptionDateRequired={true} 
      />
    </div>
  );
}
