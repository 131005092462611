
import { Folder, MoreVertical, Edit, Trash } from "lucide-react";
import { DocumentFolder } from "@/types/document";
import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuItem, 
  DropdownMenuTrigger 
} from "@/components/ui/dropdown-menu";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { useRole } from "@/hooks/useRole";

interface FolderCardProps {
  folder: DocumentFolder;
  onOpen: (folderId: string) => void;
  onEdit: (folder: DocumentFolder) => void;
  onDelete: (folderId: string) => void;
}

export function FolderCard({ folder, onOpen, onEdit, onDelete }: FolderCardProps) {
  const { t } = useTranslation();
  const { canEdit, canDelete } = useRole();

  return (
    <div 
      className="group relative border rounded-md p-3 hover:border-primary transition-colors cursor-pointer flex items-center"
      onClick={() => onOpen(folder.id)}
    >
      <Folder className="h-12 w-12 text-primary mr-3" />
      <div className="flex-1 min-w-0">
        <h3 className="font-medium truncate">{folder.name}</h3>
        {folder.description && (
          <p className="text-xs text-muted-foreground truncate mt-1">{folder.description}</p>
        )}
      </div>
      
      {(canEdit('documents') || canDelete('documents')) && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild onClick={(e) => e.stopPropagation()}>
            <Button variant="ghost" size="icon" className="opacity-0 group-hover:opacity-100 absolute top-2 right-2">
              <MoreVertical className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {canEdit('documents') && (
              <DropdownMenuItem onClick={(e) => {
                e.stopPropagation();
                onEdit(folder);
              }}>
                <Edit className="h-4 w-4 mr-2" />
                {t("common.edit")}
              </DropdownMenuItem>
            )}
            {canDelete('documents') && (
              <DropdownMenuItem 
                className="text-destructive"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(folder.id);
                }}
              >
                <Trash className="h-4 w-4 mr-2" />
                {t("common.delete")}
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
}
