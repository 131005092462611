
import { ReactNode } from "react";
import { Layout } from "@/components/Layout";

interface PageContainerProps {
  children?: ReactNode;
}

export function PageContainer({ children }: PageContainerProps) {
  return (
    <Layout>
      <div className=" py-6 space-y-6 animate-fadeIn">
        {children}
      </div>
    </Layout>
  );
}
