
import { useState, useEffect } from "react";
import { 
  X, 
  Download,
  Maximize,
  Minimize,
  ZoomIn,
  ZoomOut,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { DocumentFile } from "@/types/document";
import { useDocuments } from "@/hooks/useDocuments";
import { Dialog, DialogContent } from "@/components/ui/dialog";

interface FileViewerProps {
  file: DocumentFile | null;
  url: string | null;
  isOpen: boolean;
  onClose: () => void;
  onDownload: () => void;
  fullscreen?: boolean;
  onToggleFullscreen: () => void;
}

export function FileViewer({
  file,
  url,
  isOpen,
  onClose,
  onDownload,
  fullscreen = false,
  onToggleFullscreen,
}: FileViewerProps) {
  const [zoom, setZoom] = useState(100);
  
  // Reset zoom when file changes
  useEffect(() => {
    setZoom(100);
  }, [file]);

  const handleZoomIn = () => {
    setZoom(prev => Math.min(prev + 25, 200));
  };

  const handleZoomOut = () => {
    setZoom(prev => Math.max(prev - 25, 50));
  };

  if (!file || !url) return null;

  const renderContent = () => {
    const type = file.content_type.split('/')[0];
    const subtype = file.content_type.split('/')[1];
    
    // Style for zoom
    const contentStyle = {
      transform: `scale(${zoom / 100})`,
      transformOrigin: 'top left',
      transition: 'transform 0.2s ease-in-out',
    };

    switch (type) {
      case 'image':
        return (
          <div className="overflow-auto h-full flex items-center justify-center">
            <img 
              src={url} 
              alt={file.name} 
              className="max-w-full object-contain"
              style={contentStyle}
            />
          </div>
        );
      case 'application':
        if (subtype.includes('pdf')) {
          return (
            <div className="h-full">
              <iframe 
                src={`${url}#view=FitH`} 
                className="w-full h-full border-none" 
                title={file.name}
              />
            </div>
          );
        }
        return (
          <div className="flex flex-col items-center justify-center h-full">
            <p className="text-center mb-4">This file type ({file.content_type}) cannot be previewed directly.</p>
            <Button onClick={onDownload}>Download to view</Button>
          </div>
        );
      case 'text':
        return (
          <div className="overflow-auto h-full p-4 bg-white">
            <iframe src={url} className="w-full h-full border-none" title={file.name} />
          </div>
        );
      case 'video':
        return (
          <div className="h-full flex items-center justify-center">
            <video controls className="max-w-full max-h-full">
              <source src={url} type={file.content_type} />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      case 'audio':
        return (
          <div className="h-full flex items-center justify-center">
            <audio controls className="w-full">
              <source src={url} type={file.content_type} />
              Your browser does not support the audio tag.
            </audio>
          </div>
        );
      default:
        return (
          <div className="flex flex-col items-center justify-center h-full">
            <p className="text-center mb-4">This file type ({file.content_type}) cannot be previewed directly.</p>
            <Button onClick={onDownload}>Download to view</Button>
          </div>
        );
    }
  };

  const viewerContent = (
    <div className={`flex flex-col ${fullscreen ? 'fixed inset-0 z-50 bg-background' : 'h-[60vh]'}`}>
      {/* Toolbar */}
      <div className="flex justify-between items-center p-2 border-b">
        <div className="font-medium truncate max-w-[200px] sm:max-w-md">{file.name}</div>
        <div className="flex items-center gap-1">
          {(file.content_type.startsWith('image/')) && (
            <>
              <Button variant="ghost" size="icon" onClick={handleZoomIn} title="Zoom In">
                <ZoomIn className="h-4 w-4" />
              </Button>
              <Button variant="ghost" size="icon" onClick={handleZoomOut} title="Zoom Out">
                <ZoomOut className="h-4 w-4" />
              </Button>
              <div className="text-xs text-muted-foreground mx-1">{zoom}%</div>
            </>
          )}
          <Button variant="ghost" size="icon" onClick={onDownload} title="Download">
            <Download className="h-4 w-4" />
          </Button>
          <Button variant="ghost" size="icon" onClick={onToggleFullscreen} title={fullscreen ? "Exit Fullscreen" : "Fullscreen"}>
            {fullscreen ? <Minimize className="h-4 w-4" /> : <Maximize className="h-4 w-4" />}
          </Button>
          <Button variant="ghost" size="icon" onClick={onClose} title="Close">
            <X className="h-4 w-4" />
          </Button>
        </div>
      </div>
      
      {/* Content */}
      <div className="flex-1 overflow-hidden">
        {renderContent()}
      </div>
    </div>
  );

  if (fullscreen) {
    return viewerContent;
  }

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent hideCloseButton={true} className="max-w-4xl w-[90vw] p-0">
        {viewerContent}
      </DialogContent>
    </Dialog>
  );
}
