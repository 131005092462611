
import { Member } from "@/types/member";

/**
 * Parses a file (XLSX or CSV) into member objects
 */
export const parseImportFile = async (file: File): Promise<{
  parsedMembers: Partial<Member>[];
  errors: Array<{ line: number; message: string }>;
}> => {
  const fileExtension = file.name.split('.').pop()?.toLowerCase();
  let parsedMembers: Partial<Member>[] = [];
  const errors: Array<{ line: number; message: string }> = [];
  
  try {
    if (fileExtension === 'xlsx') {
      const XLSX = await import('xlsx');
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      
      parsedMembers = jsonData.map((row: any, index: number) => {
        try {
          return {
            name: row.name,
            email: row.email,
            phone: row.phone,
            street_name: row.street_name,
            street_number: row.street_number,
            join_date: row.join_date,
            start_date: row.start_date,
            end_date: row.end_date || undefined,
            is_permanent_resident: row.is_permanent_resident === 'Yes',
            status: row.status === 'active' ? 'active' : 'inactive'
          };
        } catch (err) {
          errors.push({ line: index + 2, message: `Error parsing row: ${err}` });
          return {};
        }
      });
    } else if (fileExtension === 'csv') {
      const text = await file.text();
      const rows = text.split('\n');
      const headers = rows[0].split(',').map(h => h.trim());
      
      parsedMembers = rows.slice(1).filter(row => row.trim() !== '').map((row, index) => {
        try {
          const values = row.split(',').map(v => v.trim());
          const rowData: Record<string, any> = {};
          
          headers.forEach((header, i) => {
            rowData[header] = values[i];
          });
          
          return {
            name: rowData.name,
            email: rowData.email,
            phone: rowData.phone,
            street_name: rowData.street_name,
            street_number: rowData.street_number,
            join_date: rowData.join_date,
            start_date: rowData.start_date,
            end_date: rowData.end_date || undefined,
            is_permanent_resident: rowData.is_permanent_resident === 'Yes',
            status: rowData.status === 'active' ? 'active' : 'inactive'
          };
        } catch (err) {
          errors.push({ line: index + 2, message: `Error parsing row: ${err}` });
          return {};
        }
      });
    } else {
      throw new Error('Unsupported file format');
    }
  } catch (error) {
    errors.push({ line: 0, message: `File parsing error: ${error}` });
  }
  
  if (parsedMembers.length === 0 && errors.length === 0) {
    errors.push({ line: 0, message: 'No data found in file' });
  }

  return { parsedMembers, errors };
};

/**
 * Validates members have all required fields
 */
export const validateMembers = (members: Partial<Member>[]): {
  validMembers: Partial<Member>[];
  invalidEntries: Array<{ line: number; errors: string[] }>;
} => {
  const validMembers: Partial<Member>[] = [];
  const invalidEntries: Array<{ line: number; errors: string[] }> = [];

  members.forEach((member, index) => {
    const errors: string[] = [];
    
    if (!member.name) errors.push('Missing name');
    if (!member.email) errors.push('Missing email');
    if (!member.street_name) errors.push('Missing street name');
    if (!member.street_number) errors.push('Missing street number');
    if (!member.join_date) errors.push('Missing join date');
    if (!member.start_date) errors.push('Missing start date');
    
    if (errors.length > 0) {
      invalidEntries.push({ line: index + 2, errors });
    } else {
      validMembers.push(member);
    }
  });

  return { validMembers, invalidEntries };
};

/**
 * Filters out members that already exist in the database
 */
export const filterExistingMembers = (
  parsedMembers: Partial<Member>[], 
  existingMembers: Member[]
): { newMembers: Partial<Member>[], duplicateCount: number } => {
  const existingEmails = new Set(existingMembers.map(m => m.email));
  const newMembers = parsedMembers.filter(m => !existingEmails.has(m.email!));
  const duplicateCount = parsedMembers.length - newMembers.length;
  
  return { newMembers, duplicateCount };
};
