
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { useTenant } from "@/contexts/TenantContext";
import { supabase } from "@/integrations/supabase/client";
import { useStatuteDocuments } from "@/hooks/useStatuteDocuments";

export function useStatuteFileUpload() {
  const { t } = useTranslation();
  const { currentTenant } = useTenant();
  const [isUploading, setIsUploading] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [adoptionDate, setAdoptionDate] = useState<Date | null>(null);
  const { refetch: refetchDocuments } = useStatuteDocuments();

  const handleUploadClick = () => {
    setShowUploadDialog(true);
  };

  const handleFileUpload = async (file: File) => {
    if (!currentTenant || !adoptionDate) {
      toast.error(t("settings.statutes.missingData"));
      return;
    }
    
    setIsUploading(true);
    try {
      const timestamp = new Date().getTime();
      const filePath = `statutes/${currentTenant.id}/${timestamp}_${file.name}`;
      
      const { error: uploadError } = await supabase.storage
        .from('documents')
        .upload(filePath, file);
        
      if (uploadError) throw uploadError;

      const { error: dbError } = await supabase.from('statute_documents').insert({
        tenant_id: currentTenant.id,
        file_path: filePath,
        file_name: file.name,
        adoption_date: adoptionDate.toISOString().split('T')[0]
      });
      
      if (dbError) throw dbError;
      
      toast.success(t("settings.statutes.uploadSuccess"));
      setShowUploadDialog(false);
      setAdoptionDate(null);
      refetchDocuments();
    } catch (error) {
      console.error("Error uploading statute document:", error);
      toast.error(t("settings.statutes.uploadError"));
    } finally {
      setIsUploading(false);
    }
  };

  return {
    isUploading,
    showUploadDialog,
    adoptionDate,
    setAdoptionDate,
    handleUploadClick,
    handleFileUpload,
    setShowUploadDialog
  };
}
