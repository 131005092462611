
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Edit, Trash2, Users, QrCode } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { IfCanEdit, IfCanDelete } from "@/components/auth/PermissionGuard";
import { Checkbox } from "@/components/ui/checkbox";

interface CadastreListItemProps {
  id: string;
  streetName: string;
  streetNumber: string;
  plotNumber: string;
  residentCount: number;
  onboarding_code?: string;
  onEdit: () => void;
  onDelete: () => void;
  canEdit?: boolean;
  isSelected?: boolean;
  onSelect?: (id: string, selected: boolean) => void;
}

export function CadastreListItem({
  id,
  streetName,
  streetNumber,
  plotNumber,
  residentCount,
  onboarding_code,
  onEdit,
  onDelete,
  canEdit,
  isSelected = false,
  onSelect
}: CadastreListItemProps) {
  const navigate = useNavigate();

  const handleResidentClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (residentCount > 0) {
      const searchQuery = `${streetName} ${streetNumber}`;
      navigate(`/members?search=${encodeURIComponent(searchQuery)}`);
    }
  };

  const handleCheckboxChange = (checked: boolean) => {
    if (onSelect) {
      onSelect(id, checked);
    }
  };

  return (
    <div className="grid grid-cols-[auto_2fr_1fr_0.5fr_0.5fr] items-center gap-4 py-2 px-4 hover:bg-accent/5">
      <div className="flex items-center justify-center">
        <Checkbox 
          checked={isSelected} 
          onCheckedChange={handleCheckboxChange}
          aria-label={`Select cadastre ${streetName} ${streetNumber}`}
        />
      </div>
      <div className="text-sm text-left">
        {streetName} {streetNumber}
      </div>
      <div className="flex items-center gap-2">
        <Users className="h-4 w-4 text-muted-foreground" />
        <Badge 
          variant="secondary" 
          className={`text-xs ${residentCount > 0 ? 'cursor-pointer hover:bg-secondary/70' : ''}`}
          onClick={handleResidentClick}
        >
          {residentCount}
        </Badge>
      </div>
      <div className="text-sm text-muted-foreground text-left">
        {plotNumber}
      </div>
      <div className="flex gap-2 justify-end min-w-[138px]">
        {onboarding_code && (
          <Button
            variant="ghost"
            size="icon"
            disabled
            title="This cadastre has an onboarding code"
          >
            <QrCode className="h-4 w-4 text-muted-foreground" />
          </Button>
        )}
        
        <IfCanEdit 
          resource="cadastre" 
          resourceOwnerId={id}
          fallback={<Button variant="ghost" size="icon" disabled><Edit className="h-4 w-4" /></Button>}
        >
          <Button
            variant="ghost"
            size="icon"
            onClick={onEdit}
          >
            <Edit className="h-4 w-4" />
          </Button>
        </IfCanEdit>
        
        <IfCanDelete 
          resource="cadastre" 
          resourceOwnerId={id}
          fallback={<Button variant="ghost" size="icon" disabled><Trash2 className="h-4 w-4 text-destructive" /></Button>}
        >
          <Button
            variant="ghost"
            size="icon"
            onClick={onDelete}
          >
            <Trash2 className="h-4 w-4 text-destructive" />
          </Button>
        </IfCanDelete>
      </div>
    </div>
  );
}
