
import { format } from "date-fns";
import { AlertCircle, CheckCircle2, XCircle, User, MapPin, Phone, Key } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

interface JoinRequest {
  id: string;
  status: 'pending' | 'approved' | 'rejected';
  review_notes?: string | null;
  created_at: string;
  updated_at: string;
  tenant_id: string;
  full_name: string;
  street_name: string;
  street_number: string;
  phone?: string | null;
  dismissed: boolean;
  onboarding_code?: string | null;
  association_settings: {
    name: string;
  } | null;
}

interface JoinRequestCardProps {
  joinRequest: JoinRequest;
  onDismiss?: () => void;
}

const getStatusIcon = (status: string) => {
  switch (status) {
    case 'pending':
      return <AlertCircle className="w-5 h-5 text-yellow-500" />;
    case 'approved':
      return <CheckCircle2 className="w-5 h-5 text-green-500" />;
    case 'rejected':
      return <XCircle className="w-5 h-5 text-red-500" />;
    default:
      return null;
  }
};

const getStatusColor = (status: string) => {
  switch (status) {
    case 'pending':
      return 'bg-yellow-50 border-yellow-200';
    case 'approved':
      return 'bg-green-50 border-green-200';
    case 'rejected':
      return 'bg-red-50 border-red-200';
    default:
      return '';
  }
};

const getStatusBadgeColor = (status: string) => {
  switch (status) {
    case 'pending':
      return 'bg-yellow-100 text-yellow-800';
    case 'approved':
      return 'bg-green-100 text-green-800';
    case 'rejected':
      return 'bg-red-100 text-red-800';
    default:
      return '';
  }
};

export const JoinRequestCard = ({ joinRequest, onDismiss }: JoinRequestCardProps) => {
  if (!joinRequest.association_settings) return null;

  const handleDismiss = async () => {
    try {
      const { error } = await supabase
        .from('association_join_requests')
        .update({ dismissed: true })
        .eq('id', joinRequest.id);

      if (error) throw error;

      toast.success("Request dismissed");
      if (onDismiss) {
        onDismiss();
      }
    } catch (error) {
      console.error('Error dismissing request:', error);
      toast.error("Failed to dismiss request");
    }
  };

  return (
    <Card className={getStatusColor(joinRequest.status)}>
      <CardContent className="p-6 space-y-4">
        <div className="flex items-center justify-between border-b pb-4">
          <h3 className="text-lg font-semibold flex items-center gap-2">
            <span>Active Join Request</span>
            {getStatusIcon(joinRequest.status)}
          </h3>
          <span className={`px-3 py-1 rounded-full text-sm capitalize ${getStatusBadgeColor(joinRequest.status)}`}>
            {joinRequest.status}
          </span>
        </div>
        
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <span className="text-sm text-muted-foreground">Association</span>
            <span className="font-medium">{joinRequest.association_settings.name}</span>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-sm text-muted-foreground flex items-center gap-2">
              <User className="w-4 h-4" />
              Applicant
            </span>
            <span className="font-medium">{joinRequest.full_name}</span>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-sm text-muted-foreground flex items-center gap-2">
              <MapPin className="w-4 h-4" />
              Address
            </span>
            <span className="font-medium">
              {joinRequest.street_name} {joinRequest.street_number}
            </span>
          </div>
          
          {joinRequest.phone && (
            <div className="flex justify-between items-center">
              <span className="text-sm text-muted-foreground flex items-center gap-2">
                <Phone className="w-4 h-4" />
                Phone
              </span>
              <span className="font-medium">{joinRequest.phone}</span>
            </div>
          )}
          
          {joinRequest.onboarding_code && (
            <div className="flex justify-between items-center">
              <span className="text-sm text-muted-foreground flex items-center gap-2">
                <Key className="w-4 h-4" />
                Onboarding Code
              </span>
              <span className="font-medium font-mono">{joinRequest.onboarding_code}</span>
            </div>
          )}
          
          <div className="flex justify-between items-center">
            <span className="text-sm text-muted-foreground">Requested on</span>
            <span className="font-medium">
              {format(new Date(joinRequest.created_at), "MMM d, yyyy 'at' HH:mm")}
            </span>
          </div>
          
          <div className="flex justify-between items-center">
            <span className="text-sm text-muted-foreground">Last updated</span>
            <span className="font-medium">
              {format(new Date(joinRequest.updated_at), "MMM d, yyyy 'at' HH:mm")}
            </span>
          </div>

          {joinRequest.review_notes && (
            <div className={`mt-4 p-3 rounded-md text-left ${
              joinRequest.status === 'rejected' 
              ? 'bg-red-50' 
              : joinRequest.status === 'pending'
              ? 'bg-yellow-50 border border-yellow-200'
              : 'bg-green-50 border border-green-200'
            }`}>
              <div className="text-left">
                <span className="text-sm text-muted-foreground block mb-1">Review notes</span>
                <span className={`text-sm ${
                  joinRequest.status === 'rejected'
                    ? 'font-bold text-red-700'
                    : joinRequest.status === 'pending'
                    ? 'text-yellow-800'
                    : 'text-green-800'
                }`}>
                  {joinRequest.review_notes}
                </span>
              </div>
            </div>
          )}

          {(joinRequest.status === 'rejected' || joinRequest.status === 'approved') && (
            <div className="flex justify-end mt-4">
              <Button
                variant="destructive"
                onClick={handleDismiss}
                className={joinRequest.status === 'approved' 
                  ? "bg-green-100 text-green-700 hover:bg-green-200 hover:text-green-800"
                  : "bg-red-100 text-red-700 hover:bg-red-200 hover:text-red-800"
                }
              >
                Dismiss
              </Button>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
