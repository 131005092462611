
import { useState } from "react";
import { QrCode, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { QRCodeGenerationDialog } from "./QRCodeGenerationDialog";

interface CadastreSelectionActionsProps {
  selectedCount: number;
  selectedCadastres: Array<{
    id: string;
    streetName: string;
    streetNumber: string;
    onboarding_code?: string;
  }>;
  onDeleteSelected: () => void;
  onCodesGenerated?: () => void;
}

export function CadastreSelectionActions({ 
  selectedCount, 
  selectedCadastres,
  onDeleteSelected,
  onCodesGenerated
}: CadastreSelectionActionsProps) {
  const { t } = useTranslation();
  const hasSelection = selectedCount > 0;
  const [isQRDialogOpen, setIsQRDialogOpen] = useState(false);
  
  const handleOpenQRDialog = () => {
    if (hasSelection) {
      setIsQRDialogOpen(true);
    }
  };
  
  return (
    <>
      <div className="flex items-center gap-2 mr-4 border-r pr-4">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                size="icon"
                variant="outline"
                onClick={handleOpenQRDialog}
                disabled={!hasSelection}
                className="h-8 w-8"
              >
                <QrCode className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t("cadastres.generateQRCodes")}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                size="icon"
                variant="outline"
                onClick={onDeleteSelected}
                disabled={!hasSelection}
                className="h-8 w-8 text-destructive hover:text-destructive-foreground hover:bg-destructive"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t("common.delete")}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      
      <QRCodeGenerationDialog
        isOpen={isQRDialogOpen}
        onClose={() => setIsQRDialogOpen(false)}
        selectedCadastres={selectedCadastres}
        onCodesGenerated={onCodesGenerated}
      />
    </>
  );
}
