
import { PageContainer } from "@/components/PageContainer";
import { PageHeader } from "@/components/PageHeader";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Plus, Loader2, Search, ArrowUp, ArrowDown } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { useTranslation } from "react-i18next";
import { CadastreListItem } from "@/components/cadastres/CadastreListItem";
import { CadastreForm } from "@/components/cadastres/CadastreForm";
import { IfCanCreate } from "@/components/auth/PermissionGuard";
import { useRole } from "@/hooks/useRole";
import { StatisticsButton } from "@/components/common/StatisticsButton";
import { CadastreSelectionActions } from "@/components/cadastres/CadastreSelectionActions";
import { useCadastresPage } from "./hooks/useCadastresPage";
import { CadastreStatistics } from "./components/CadastreStatistics";
import { CadastreListHeader } from "./components/CadastreListHeader";

const Cadastres = () => {
  const { t } = useTranslation();
  const { canEdit } = useRole();
  const {
    isLoading,
    sortedCadastres,
    filteredCadastres,
    selectedCadastreObjects,
    searchQuery,
    sortBy,
    sortDirection,
    allSelected,
    isSheetOpen,
    newCadastre,
    editingCadastre,
    
    setSearchQuery,
    handleSort,
    handleSelectAll,
    handleSelectCadastre,
    handleDeleteSelected,
    handleDeleteCadastre,
    handleEdit,
    handleOpenAdd,
    handleCloseSheet,
    handleAddCadastre,
    handleUpdateCadastre,
    setNewCadastre,
    setEditingCadastre
  } = useCadastresPage();

  const getSortIcon = (field: 'address' | 'residents' | 'plotNumber') => {
    if (sortBy !== field) return null;
    
    return sortDirection === 'asc' 
      ? <ArrowUp className="ml-1 h-4 w-4 inline" /> 
      : <ArrowDown className="ml-1 h-4 w-4 inline" />;
  };

  const handleCodesGenerated = () => {
    // Refresh the data to show newly generated codes
    window.location.reload();
  };

  if (isLoading) {
    return (
      <PageContainer>
        <div className="flex items-center justify-center h-[50vh]">
          <Loader2 className="h-8 w-8 animate-spin" />
        </div>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <div className="space-y-6 animate-fadeIn">
        <PageHeader
          title={t('cadastres.title')}
          subtitle={t('cadastres.subtitle')}
        >
          <div className="flex items-center gap-4">
            <CadastreSelectionActions
              selectedCount={selectedCadastreObjects.length}
              selectedCadastres={selectedCadastreObjects}
              onDeleteSelected={handleDeleteSelected}
              onCodesGenerated={handleCodesGenerated}
            />
            
            <div className="flex items-center gap-2">
              <StatisticsButton 
                dialogTitle={t('cadastres.statistics')}
                tooltipText={t('cadastres.statistics')}
                renderStatistics={() => <CadastreStatistics />}
              />
              
              <IfCanCreate resource="cadastre" fallback={null}>
                <Button onClick={handleOpenAdd}>
                  <Plus className="w-4 h-4 mr-2" />
                  {t('cadastres.addCadastre')}
                </Button>
              </IfCanCreate>
            </div>
          </div>
        </PageHeader>

        <Card>
          <div className="relative m-3">
            <Search className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder={t('cadastres.search')}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-9 mb-4"
            />
          </div>
          
          <CadastreListHeader 
            sortBy={sortBy}
            getSortIcon={getSortIcon}
            handleSort={handleSort}
            allSelected={allSelected}
            handleSelectAll={handleSelectAll}
          />
          
          <div className="divide-y">
            {sortedCadastres.map(cadastre => (
              <CadastreListItem
                key={cadastre.id}
                {...cadastre}
                onEdit={() => handleEdit(cadastre)}
                onDelete={() => handleDeleteCadastre(cadastre.id)}
                canEdit={canEdit("cadastre")}
                isSelected={selectedCadastreObjects.some(c => c.id === cadastre.id)}
                onSelect={handleSelectCadastre}
              />
            ))}
            {filteredCadastres.length === 0 && (
              <div className="p-8 text-center text-muted-foreground">
                {searchQuery ? t('cadastres.noSearchResults') : t('cadastres.noData')}
              </div>
            )}
          </div>
        </Card>

        <Sheet open={isSheetOpen} onOpenChange={handleCloseSheet}>
          <SheetContent>
            <SheetHeader>
              <SheetTitle>
                {editingCadastre ? t('cadastres.editCadastre') : t('cadastres.addCadastre')}
              </SheetTitle>
            </SheetHeader>
            <div className="mt-4">
              <CadastreForm
                cadastre={editingCadastre || newCadastre}
                streets={[]} // This will be provided by the form itself
                onSubmit={editingCadastre ? handleUpdateCadastre : handleAddCadastre}
                onChange={values => 
                  editingCadastre 
                    ? setEditingCadastre({ ...editingCadastre, ...values })
                    : setNewCadastre({ ...newCadastre, ...values })
                }
                onCancel={handleCloseSheet}
                submitLabel={t('common.save')}
              />
            </div>
          </SheetContent>
        </Sheet>
      </div>
    </PageContainer>
  );
};

export default Cadastres;
