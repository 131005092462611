import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { format } from "date-fns";
import { CalendarIcon, Clock } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { CalendarEvent } from "@/types/calendar";
import { cn } from "@/lib/utils";

interface EventFormProps {
  initialDate: Date;
  initialEvent?: CalendarEvent | null;
  onSubmit: (event: CalendarEvent) => void;
  onCancel: () => void;
}

const formSchema = z.object({
  title: z.string().min(1, { message: "Title is required" }),
  description: z.string().optional(),
  date: z.date({
    required_error: "Please select a date",
  }),
  time: z.string().optional(),
});

type FormValues = z.infer<typeof formSchema>;

export function EventForm({ initialDate, initialEvent, onSubmit, onCancel }: EventFormProps) {
  const { t } = useTranslation();
  
  const getInitialTime = (date?: Date | string) => {
    if (!date) return "12:00";
    
    if (initialEvent) {
      // For existing events, use their time
      const d = typeof date === 'string' ? new Date(date) : date;
      return `${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`;
    } else {
      // For new events, default to 12:00
      return "12:00";
    }
  };
  
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: initialEvent?.title || "",
      description: initialEvent?.description || "",
      date: initialDate,
      time: getInitialTime(initialEvent?.date || initialDate),
    },
  });
  
  // Update form when initialEvent changes
  useEffect(() => {
    if (initialEvent) {
      const eventDate = new Date(initialEvent.date);
      form.reset({
        title: initialEvent.title,
        description: initialEvent.description,
        date: eventDate,
        time: getInitialTime(eventDate),
      });
    } else {
      form.reset({
        title: "",
        description: "",
        date: initialDate,
        time: getInitialTime(initialDate),
      });
    }
  }, [initialEvent, initialDate, form]);
  
  const handleSubmit = (values: FormValues) => {
    const eventDate = new Date(values.date);
    
    if (values.time) {
      const [hours, minutes] = values.time.split(":").map(Number);
      eventDate.setHours(hours, minutes);
    }
    
    const newEvent: CalendarEvent = {
      id: initialEvent?.id,
      title: values.title,
      description: values.description || "",
      date: eventDate.toISOString(),
    };
    
    onSubmit(newEvent);
  };
  
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("calendar.event.title")}</FormLabel>
              <FormControl>
                <Input placeholder={t("calendar.event.titlePlaceholder")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("calendar.event.description")}</FormLabel>
              <FormControl>
                <Textarea placeholder={t("calendar.event.descriptionPlaceholder")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="date"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>{t("calendar.event.date")}</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-full pl-3 text-left font-normal",
                          !field.value && "text-muted-foreground"
                        )}
                      >
                        {field.value ? (
                          format(field.value, "PPP")
                        ) : (
                          <span>{t("calendar.event.selectDate")}</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                      defaultMonth={field.value} // Set calendar to start at the selected date
                      initialFocus
                      className={cn("p-3 pointer-events-auto")}
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          
          <FormField
            control={form.control}
            name="time"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("calendar.event.time")}</FormLabel>
                <FormControl>
                  <div className="flex items-center">
                    <Clock className="mr-2 h-4 w-4 text-muted-foreground" />
                    <Input type="time" {...field} />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        
        <div className="flex justify-end space-x-2">
          <Button variant="outline" type="button" onClick={onCancel}>
            {t("common.cancel")}
          </Button>
          <Button type="submit">
            {initialEvent ? t("calendar.event.update") : t("calendar.event.save")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
