
import { useMembersList } from "./useMembersList";
import { useMemberMutations } from "./useMemberMutations";

/**
 * Main hook for members management, combining listing and mutation functionality
 */
export function useMembers() {
  const { members, isLoading } = useMembersList();
  const { addMember, updateMember, deleteMember } = useMemberMutations();

  return {
    members,
    isLoading,
    addMember,
    updateMember,
    deleteMember
  };
}
