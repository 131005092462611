
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FileUp } from "lucide-react";
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle,
} from "@/components/ui/dialog";
import { 
  Tabs, 
  TabsContent, 
  TabsList, 
  TabsTrigger 
} from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { 
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components/ui/tooltip";
import { useMembers } from "@/hooks/useMembers";
import { MemberExportTab } from "./MemberExportTab";
import { MemberImportTab } from "./MemberImportTab";

export function MemberImportExport() {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { members, addMember } = useMembers();
  
  return (
    <>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button 
              variant="outline" 
              size="icon" 
              onClick={() => setIsDialogOpen(true)}
            >
              <FileUp className="h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>{t('members.importExport')}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>{t('members.importExportTitle')}</DialogTitle>
          </DialogHeader>
          
          <Tabs defaultValue="export" className="w-full">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="export">{t('members.export')}</TabsTrigger>
              <TabsTrigger value="import">{t('members.import')}</TabsTrigger>
            </TabsList>
            
            <TabsContent value="export" className="space-y-4">
              <MemberExportTab members={members} />
            </TabsContent>
            
            <TabsContent value="import" className="space-y-4">
              <MemberImportTab 
                members={members}
                addMember={addMember}
                onComplete={() => setIsDialogOpen(false)}
              />
            </TabsContent>
          </Tabs>
        </DialogContent>
      </Dialog>
    </>
  );
}
