
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { useTenant } from "@/contexts/TenantContext";
import { useAssociationSettings } from "@/hooks/useAssociationSettings";
import type { CVRResponse } from "@/types/settings";

interface AssociationFormData {
  name: string;
  cvrNumber: string;
  streetAddress: string;
  streetNumber: string;
  postNumber: string;
  country: string;
  logoUrl: string;
  urlName: string;
}

export function useAssociationDetailsForm() {
  const { currentTenant } = useTenant();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const { data: settings, invalidateSettings } = useAssociationSettings();
  const [urlNameChanged, setUrlNameChanged] = useState(false);
  
  const [formData, setFormData] = useState<AssociationFormData>({
    name: "",
    cvrNumber: "",
    streetAddress: "",
    streetNumber: "",
    postNumber: "",
    country: "",
    logoUrl: "",
    urlName: ""
  });

  useEffect(() => {
    const loadSettings = async () => {
      if (!currentTenant) return;

      try {
        const { data: settingsData, error: settingsError } = await supabase
          .from('association_settings')
          .select('*')
          .eq('tenant_id', currentTenant.id)
          .single();

        if (settingsError) throw settingsError;

        const { data: tenantData, error: tenantError } = await supabase
          .from('tenants')
          .select('url_name')
          .eq('id', currentTenant.id)
          .single();

        if (tenantError) throw tenantError;

        if (settingsData) {
          setFormData({
            name: settingsData.name || "",
            cvrNumber: settingsData.cvr_number || "",
            streetAddress: settingsData.street_address || "",
            streetNumber: settingsData.street_number || "",
            postNumber: settingsData.post_number || "",
            country: settingsData.country || "",
            logoUrl: settingsData.logo_url || "",
            urlName: tenantData?.url_name || ""
          });
        }
      } catch (error) {
        console.error('Error loading settings:', error);
        toast({
          title: "Error",
          description: "Failed to load association settings",
          variant: "destructive"
        });
      } finally {
        setIsLoading(false);
      }
    };

    loadSettings();
  }, [currentTenant, toast]);

  const handleCVRChange = (value: string, cvrData?: Partial<CVRResponse>) => {
    if (cvrData) {
      setFormData(prev => ({
        ...prev,
        cvrNumber: value,
        name: cvrData.name || prev.name,
        streetAddress: cvrData.streetAddress || prev.streetAddress,
        streetNumber: cvrData.streetNumber || prev.streetNumber,
        postNumber: cvrData.zipcode || prev.postNumber,
        country: cvrData.country || prev.country
      }));
    } else {
      setFormData(prev => ({ ...prev, cvrNumber: value }));
    }
  };

  const handleAddressFieldChange = (field: string, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleLogoChange = (logoUrl: string) => {
    setFormData(prev => ({ ...prev, logoUrl }));
  };

  const handleUrlNameChange = (value: string) => {
    setFormData(prev => ({ ...prev, urlName: value }));
    setUrlNameChanged(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentTenant) return;

    setIsSaving(true);
    try {
      const { error: settingsError } = await supabase
        .from('association_settings')
        .update({
          name: formData.name,
          cvr_number: formData.cvrNumber,
          street_address: formData.streetAddress,
          street_number: formData.streetNumber,
          post_number: formData.postNumber,
          country: formData.country,
          logo_url: formData.logoUrl
        })
        .eq('tenant_id', currentTenant.id);

      if (settingsError) throw settingsError;

      const { error: tenantError } = await supabase
        .from('tenants')
        .update({
          url_name: formData.urlName || null
        })
        .eq('id', currentTenant.id);
        
      if (tenantError) throw tenantError;

      invalidateSettings();
      setUrlNameChanged(false);

      toast({
        title: "Success",
        description: "Association details have been updated"
      });
    } catch (error) {
      console.error('Error saving settings:', error);
      toast({
        title: "Error",
        description: "Failed to save association settings",
        variant: "destructive"
      });
    } finally {
      setIsSaving(false);
    }
  };

  return {
    isLoading,
    isSaving,
    formData,
    settings,
    urlNameChanged,
    handleCVRChange,
    handleAddressFieldChange,
    handleLogoChange,
    handleUrlNameChange,
    handleSubmit
  };
}
