
import { useState, useEffect } from "react";
import { useToast } from "@/hooks/use-toast";
import { useCadastres } from "@/contexts/CadastresContext";
import { supabase } from "@/integrations/supabase/client";
import { useTenant } from "@/contexts/TenantContext";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

type SortField = 'address' | 'residents' | 'plotNumber';
type SortDirection = 'asc' | 'desc';

export function useCadastresPage() {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { currentTenant } = useTenant();
  const queryClient = useQueryClient();

  const {
    cadastres,
    addCadastre,
    updateCadastre,
    deleteCadastre,
    isLoading
  } = useCadastres();

  // UI state
  const [streets, setStreets] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState<SortField>('address');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [selectedCadastres, setSelectedCadastres] = useState<string[]>([]);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  
  // Form state
  const [newCadastre, setNewCadastre] = useState({
    streetName: "",
    streetNumber: "",
    plotNumber: ""
  });
  const [editingCadastre, setEditingCadastre] = useState<null | {
    id: string;
    streetName: string;
    streetNumber: string;
    plotNumber: string;
    residentCount: number;
    onboarding_code?: string;
  }>(null);

  useEffect(() => {
    loadStreets();
  }, [currentTenant]);

  const loadStreets = async () => {
    if (!currentTenant) return;
    
    try {
      const { data, error } = await supabase
        .from('association_streets')
        .select('street_name')
        .eq('tenant_id', currentTenant.id)
        .order('street_name');

      if (error) throw error;

      setStreets(data.map(street => street.street_name));
    } catch (error) {
      console.error('Error loading streets:', error);
      toast({
        title: "Error",
        description: "Failed to load streets",
        variant: "destructive"
      });
    }
  };

  const handleAddCadastre = async () => {
    try {
      await addCadastre(newCadastre);
      setNewCadastre({
        streetName: "",
        streetNumber: "",
        plotNumber: ""
      });
      setIsSheetOpen(false);
      toast({
        title: t("common.save"),
        description: `Added cadastre for ${newCadastre.streetName} ${newCadastre.streetNumber}`
      });
    } catch (error) {
      // Error is already handled in the context
    }
  };

  const handleUpdateCadastre = async () => {
    if (!editingCadastre) return;
    
    try {
      await updateCadastre(editingCadastre);
      setEditingCadastre(null);
      setIsSheetOpen(false);
      toast({
        title: t("common.save"),
        description: "Cadastre updated successfully"
      });
    } catch (error) {
      // Error is already handled in the context
    }
  };

  const handleDeleteCadastre = async (id: string) => {
    if (confirm(t("cadastres.confirmDelete"))) {
      try {
        await deleteCadastre(id);
        toast({
          title: t("common.delete"),
          description: "Cadastre deleted successfully"
        });
      } catch (error) {
        // Error is already handled in the context
      }
    }
  };

  const handleEdit = (cadastre: typeof editingCadastre) => {
    setEditingCadastre(cadastre);
    setIsSheetOpen(true);
  };

  const handleOpenAdd = () => {
    setEditingCadastre(null);
    setNewCadastre({
      streetName: "",
      streetNumber: "",
      plotNumber: ""
    });
    setIsSheetOpen(true);
  };

  const handleCloseSheet = () => {
    setIsSheetOpen(false);
    setEditingCadastre(null);
    setNewCadastre({
      streetName: "",
      streetNumber: "",
      plotNumber: ""
    });
  };

  const handleSort = (field: SortField) => {
    if (sortBy === field) {
      // If already sorting by this field, toggle direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // If sorting by a new field, default to ascending
      setSortBy(field);
      setSortDirection('asc');
    }
  };

  const handleSelectCadastre = (id: string, selected: boolean) => {
    if (selected) {
      setSelectedCadastres(prev => [...prev, id]);
    } else {
      setSelectedCadastres(prev => prev.filter(cadastreId => cadastreId !== id));
    }
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      // Select all currently filtered cadastres
      setSelectedCadastres(sortedCadastres.map(cadastre => cadastre.id));
    } else {
      // Deselect all
      setSelectedCadastres([]);
    }
  };

  const handleDeleteSelected = () => {
    if (confirm(t("cadastres.confirmDelete"))) {
      // Delete all selected cadastres
      Promise.all(selectedCadastres.map(id => deleteCadastre(id)))
        .then(() => {
          toast({
            title: t("common.delete"),
            description: `${selectedCadastres.length} cadastres deleted successfully`,
          });
          setSelectedCadastres([]);
        })
        .catch((error) => {
          console.error("Error deleting selected cadastres:", error);
        });
    }
  };

  // Filter cadastres based on search query
  const filteredCadastres = cadastres.filter(cadastre => {
    const code_searchTerm = cadastre.onboarding_code ? `${cadastre.onboarding_code} qr code`.toLowerCase() : '';
    const memberFullAddress = `${cadastre.streetName} ${cadastre.streetNumber} ${cadastre.plotNumber}`.toLowerCase();    
    return memberFullAddress.toLowerCase().includes(searchQuery.toLowerCase()) || code_searchTerm.toLowerCase().includes(searchQuery.toLowerCase());
  });

  // Sort the filtered cadastres
  const sortedCadastres = [...filteredCadastres].sort((a, b) => {
    const multiplier = sortDirection === 'asc' ? 1 : -1;
    
    switch (sortBy) {
      case 'address': {
        // Sort by street name first, then by street number
        const streetNameComparison = a.streetName.localeCompare(b.streetName);
        if (streetNameComparison !== 0) return streetNameComparison * multiplier;
        
        // If street names are the same, compare street numbers
        return a.streetNumber.localeCompare(b.streetNumber, undefined, { numeric: true }) * multiplier;
      }
      case 'residents':
        return (a.residentCount - b.residentCount) * multiplier;
        
      case 'plotNumber':
        return a.plotNumber.localeCompare(b.plotNumber, undefined, { numeric: true }) * multiplier;
        
      default:
        return 0;
    }
  });

  // Get the selected cadastre objects (not just IDs)
  const selectedCadastreObjects = sortedCadastres.filter(cadastre => 
    selectedCadastres.includes(cadastre.id)
  );

  // Check if all filteredCadastres are selected
  const allSelected = sortedCadastres.length > 0 && 
    sortedCadastres.every(cadastre => selectedCadastres.includes(cadastre.id));

  return {
    // Data
    cadastres,
    streets,
    filteredCadastres,
    sortedCadastres,
    selectedCadastres,
    selectedCadastreObjects,
    isLoading,
    searchQuery,
    sortBy,
    sortDirection,
    allSelected,
    isSheetOpen,
    newCadastre,
    editingCadastre,
    
    // Functions
    setSearchQuery,
    handleSort,
    handleSelectAll,
    handleSelectCadastre,
    handleDeleteSelected,
    handleAddCadastre,
    handleUpdateCadastre,
    handleDeleteCadastre,
    handleEdit,
    handleOpenAdd,
    handleCloseSheet,
    setNewCadastre,
    setEditingCadastre
  };
}
