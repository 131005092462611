
import { useState, useRef } from "react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Upload, X } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { useTranslation } from "react-i18next";

interface LogoUploaderProps {
  tenantId: string | undefined;
  logoUrl: string;
  onLogoChange: (url: string) => void;
}

export function LogoUploader({ tenantId, logoUrl, onLogoChange }: LogoUploaderProps) {
  const { toast } = useToast();
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const validateFile = (file: File): boolean => {
    // Validate file type
    const fileType = file.type;
    if (!fileType.match(/image\/(jpeg|jpg|png|svg\+xml|gif)/)) {
      toast({
        title: "Invalid file type",
        description: "Please upload an image file (JPEG, PNG, SVG, or GIF)",
        variant: "destructive"
      });
      return false;
    }

    // Validate file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      toast({
        title: "File too large",
        description: "Logo image must be less than 2MB",
        variant: "destructive"
      });
      return false;
    }

    return true;
  };

  const uploadFile = async (file: File) => {
    if (!tenantId) return;
    
    if (!validateFile(file)) return;

    setIsUploading(true);
    try {
      // Create a unique file name
      const fileExt = file.name.split('.').pop();
      const fileName = `${tenantId}_${Date.now()}.${fileExt}`;
      const filePath = `${fileName}`;

      // Upload the file to Supabase Storage
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('association_logos')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: true
        });

      if (uploadError) throw uploadError;

      // Get the public URL
      const { data: publicUrlData } = supabase.storage
        .from('association_logos')
        .getPublicUrl(filePath);

      // Update the form data with the new logo URL
      const logoUrl = publicUrlData.publicUrl;
      onLogoChange(logoUrl);

      toast({
        title: "Logo uploaded",
        description: "The logo has been uploaded successfully"
      });
    } catch (error) {
      console.error('Error uploading logo:', error);
      toast({
        title: "Upload failed",
        description: "Failed to upload logo image",
        variant: "destructive"
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleLogoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    
    await uploadFile(file);
  };

  const handleRemoveLogo = async () => {
    if (!tenantId || !logoUrl) return;

    try {
      // Extract the file name from the URL
      const fileName = logoUrl.split('/').pop();
      
      if (fileName) {
        // Delete the file from storage
        const { error: deleteError } = await supabase.storage
          .from('association_logos')
          .remove([fileName]);
  
        if (deleteError) throw deleteError;
      }

      // Update the form data
      onLogoChange("");

      toast({
        title: "Logo removed",
        description: "The logo has been removed successfully"
      });
    } catch (error) {
      console.error('Error removing logo:', error);
      toast({
        title: "Error",
        description: "Failed to remove logo",
        variant: "destructive"
      });
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      uploadFile(files[0]);
    }
  };

  const handleAvatarClick = () => {
    // Trigger the hidden file input click when avatar is clicked
    fileInputRef.current?.click();
  };
  
  return (
    <div className="flex flex-col items-center space-y-4 sm:items-start">
      <Label className="text-left block">{t("settings.association.logo", "Association Logo")}</Label>
      
      <div 
        className="flex flex-col items-center space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div 
          className={`relative cursor-pointer ${isDragging ? 'ring-2 ring-primary ring-offset-2' : ''}`}
          onClick={handleAvatarClick}
        >
          {/* Rectangular logo container with 1:2 aspect ratio */}
          <div className="relative h-24 w-48 border rounded-md">
            <div className="h-full w-full overflow-hidden rounded-md">
              {logoUrl ? (
                <img 
                  src={logoUrl} 
                  alt="Association Logo" 
                  className="h-full w-full object-contain"
                />
              ) : (
                <div className="flex h-full w-full items-center justify-center bg-muted">
                  <span className="text-muted-foreground">
                    Logo
                  </span>
                </div>
              )}
            </div>
            
            {logoUrl && (
              <Button 
                type="button" 
                variant="destructive" 
                size="icon" 
                className="absolute -top-2 -right-2 h-6 w-6"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent avatar click when removing logo
                  handleRemoveLogo();
                }}
              >
                <X className="h-3 w-3" />
              </Button>
            )}
          </div>
        </div>
        
        <div 
          className="flex flex-col space-y-2"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <Label 
            htmlFor="logo-upload" 
            className={`flex cursor-pointer items-center gap-2 rounded-md border border-input bg-background px-3 py-2 text-sm hover:bg-accent hover:text-accent-foreground ${isDragging ? 'ring-2 ring-primary' : ''}`}
          >
            <Upload className="h-4 w-4" />
            {logoUrl 
              ? t("settings.association.changeLogo", "Change Logo") 
              : t("settings.association.uploadLogo", "Upload Logo")}
          </Label>
          <Input 
            id="logo-upload"
            ref={fileInputRef}
            type="file" 
            accept="image/*" 
            className="hidden" 
            onChange={handleLogoUpload}
            disabled={isUploading}
          />
          <p className="text-xs text-muted-foreground">
            {t("settings.association.logoRequirements", "JPEG, PNG, SVG or GIF (max 2MB)")}
          </p>
          {isDragging && (
            <p className="text-xs text-primary font-medium">
              {t("settings.association.dropToUpload", "Drop to upload")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
