
import { Layout } from "@/components/Layout";
import { useTenant } from "@/contexts/TenantContext";
import { WelcomeView } from "@/components/home/WelcomeView";
import { DashboardView } from "@/components/home/DashboardView";
import { useApprovedRequests } from "@/hooks/useApprovedRequests";
import { useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/contexts/AuthContext";

const Index = () => {
  const { currentTenant, tenants, isLoading: tenantLoading } = useTenant();
  const { user } = useAuth();
  const { isLoading: requestsLoading } = useApprovedRequests();
  
  // For debugging
  useEffect(() => {
    if (user) {
      const checkMemberships = async () => {
        const { data, error } = await supabase
          .from('tenant_memberships')
          .select('tenant:tenants(id, name), role')
          .eq('user_id', user.id);
          
        if (error) {
          console.error("Error fetching memberships:", error);
        } else {
          console.log("User memberships:", data);
        }
        
        // Log tenant context state
        console.log("Tenant context - currentTenant:", currentTenant);
        console.log("Tenant context - tenants:", tenants);
      };
      
      checkMemberships();
    }
  }, [user, currentTenant, tenants]);
  
  // Combined loading state
  const isLoading = tenantLoading || requestsLoading;

  return (
    <Layout>
      {isLoading ? (
        <div className="flex justify-center items-center h-[70vh]">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
        </div>
      ) : (
        currentTenant ? <DashboardView /> : <WelcomeView />
      )}
    </Layout>
  );
};

export default Index;
