
import { useState } from 'react';
import { supabase } from '@/integrations/supabase/client';

export function useUrlNameCheck() {
  const [isChecking, setIsChecking] = useState(false);
  const [isAvailable, setIsAvailable] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);

  const checkUrlNameAvailability = async (urlName: string, currentTenantId?: string) => {
    if (!urlName.trim()) {
      setIsAvailable(null);
      setError(null);
      return;
    }

    setIsChecking(true);
    setError(null);
    
    try {
      const query = supabase
        .from('tenants')
        .select('id')
        .eq('url_name', urlName.trim());
      
      // If we have the current tenant ID, exclude it from the check
      if (currentTenantId) {
        query.neq('id', currentTenantId);
      }
      
      const { data, error } = await query;
      
      if (error) throw error;
      
      // URL name is available if no records are found
      setIsAvailable(data.length === 0);
    } catch (err) {
      console.error('Error checking URL name availability:', err);
      setError('Unable to check URL name availability');
      setIsAvailable(null);
    } finally {
      setIsChecking(false);
    }
  };

  return {
    isChecking,
    isAvailable,
    error,
    checkUrlNameAvailability
  };
}
