import { useState, useMemo, useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import { Member } from "@/types/member";
import { useToast } from "@/components/ui/use-toast";
import { useCadastres } from "@/contexts/CadastresContext";
import { useTenant } from "@/contexts/TenantContext";
import { useTranslation } from "react-i18next";
import { ContactSection } from "./form/ContactSection";
import { AddressSection } from "./form/AddressSection";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { supabase } from "@/integrations/supabase/client";

interface MemberFormProps {
  member?: Member;
  initialData?: Partial<Member> | null;
  onboardingCode?: string;
  onSubmit: (member: Omit<Member, "id">) => void;
}

export function MemberForm({ member, initialData, onboardingCode, onSubmit }: MemberFormProps) {
  const { toast } = useToast();
  const { cadastres } = useCadastres();
  const { currentTenant } = useTenant();
  const { t } = useTranslation();
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  
  const today = new Date().toISOString().split('T')[0];
  
  const [formData, setFormData] = useState({
    name: member?.name || initialData?.name || "",
    email: member?.email || initialData?.email || "",
    phone: member?.phone || initialData?.phone || "",
    street_name: member?.street_name || initialData?.street_name || "",
    street_number: member?.street_number || initialData?.street_number || "",
    join_date: member?.join_date || initialData?.join_date || today,
    start_date: member?.start_date || initialData?.start_date || today,
    end_date: member?.end_date || initialData?.end_date || "",
    is_permanent_resident: member?.is_permanent_resident ?? initialData?.is_permanent_resident ?? true,
    status: member?.status || initialData?.status || "active",
    tenant_id: member?.tenant_id || initialData?.tenant_id || currentTenant?.id || "",
    onboarding_code: onboardingCode || "",
    cadastre_id: member?.cadastre_id || initialData?.cadastre_id || ""
  });

  // Check if all required fields are filled to enable the submit button
  const isFormValid = useMemo(() => {
    return !!(
      formData.email.trim() && 
      formData.name.trim() && 
      formData.street_name.trim() && 
      formData.street_number.trim()
    );
  }, [formData.email, formData.name, formData.street_name, formData.street_number]);

  // Focus the submit button when initialData is present
  useEffect(() => {
    if (initialData && submitButtonRef.current) {
      submitButtonRef.current.focus();
    }
  }, [initialData]);

  // If an onboarding code is provided, try to fetch the address details
  useEffect(() => {
    if (onboardingCode) {
      fetchAddressFromOnboardingCode(onboardingCode);
    }
  }, [onboardingCode]);

  // Update cadastre_id whenever street name or number changes
  useEffect(() => {
    if (formData.street_name && formData.street_number) {
      const cadastre = cadastres.find(c => 
        c.streetName === formData.street_name && 
        c.streetNumber === formData.street_number
      );
      
      if (cadastre?.id) {
        setFormData(prev => ({
          ...prev,
          cadastre_id: cadastre.id
        }));
      }
    }
  }, [formData.street_name, formData.street_number, cadastres]);

  const fetchAddressFromOnboardingCode = async (code: string) => {
    try {
      const { data, error } = await supabase
        .from('onboarding_codes')
        .select('street_name, street_number, tenant_id')
        .eq('code', code)
        .single();
      
      if (error) throw error;
      
      if (data) {
        setFormData(prev => ({
          ...prev,
          street_name: data.street_name,
          street_number: data.street_number,
          tenant_id: data.tenant_id,
          onboarding_code: code
        }));

        // Try to fetch the cadastre_id based on the street data
        try {
          const { data: cadastreData } = await supabase
            .from('cadastres')
            .select('id')
            .eq('tenant_id', data.tenant_id)
            .eq('street_name', data.street_name)
            .eq('street_number', data.street_number)
            .maybeSingle();
            
          if (cadastreData?.id) {
            setFormData(prev => ({
              ...prev,
              cadastre_id: cadastreData.id
            }));
          }
        } catch (cadastreError) {
          console.error('Error fetching cadastre id:', cadastreError);
        }
      }
    } catch (error) {
      console.error('Error fetching address from onboarding code:', error);
      // Don't show error to user as the code might be entered manually
    }
  };

  const availableStreets = useMemo(() => 
    [...new Set(cadastres.map(c => c.streetName))],
    [cadastres]
  );

  const availableStreetNumbers = useMemo(() => {
    // Get all street numbers for the selected street
    const numbers = cadastres
      .filter(c => c.streetName === formData.street_name)
      .map(c => c.streetNumber);
    
    // Sort them in ascending order
    // Use numeric sort if they can be converted to numbers
    const numericSort = numbers.every(n => !isNaN(Number(n)));
    if (numericSort) {
      return [...numbers].sort((a, b) => Number(a) - Number(b));
    } else {
      // Otherwise use locale-aware string comparison
      return [...numbers].sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
    }
  }, [cadastres, formData.street_name]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validate required fields
    const requiredFields = ['email', 'name', 'street_name', 'street_number'] as const;
    const emptyFields = requiredFields.filter(field => !formData[field]);

    if (emptyFields.length > 0) {
      toast({
        title: "Missing Required Fields",
        description: `Please fill in: ${emptyFields.join(', ')}`,
        variant: "destructive",
      });
      return;
    }

    if (!currentTenant?.id && !formData.tenant_id) {
      toast({
        title: "Error",
        description: "No tenant selected",
        variant: "destructive",
      });
      return;
    }

    // Ensure dates are properly formatted and only pass relevant member data
    // Explicitly exclude onboarding_code from the submission data
    const submissionData = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      street_name: formData.street_name,
      street_number: formData.street_number,
      join_date: formData.join_date || today,
      start_date: formData.start_date || today,
      end_date: formData.end_date || null,
      is_permanent_resident: formData.is_permanent_resident,
      status: formData.status,
      tenant_id: formData.tenant_id || currentTenant?.id || "",
      cadastre_id: formData.cadastre_id || null // Include cadastre_id in submission
    };
    
    onSubmit(submissionData);
  };

  const handleStreetChange = (streetName: string) => {
    setFormData(prev => ({ 
      ...prev, 
      street_name: streetName,
      street_number: "" // Reset street number when street changes
    }));
  };

  const handleOnboardingCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value.trim();
    setFormData(prev => ({ ...prev, onboarding_code: code }));
    
    if (code.length >= 6) {
      fetchAddressFromOnboardingCode(code);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 mt-4">
      <ContactSection
        email={formData.email}
        name={formData.name}
        phone={formData.phone}
        onEmailChange={(email) => setFormData(prev => ({ ...prev, email }))}
        onNameChange={(name) => setFormData(prev => ({ ...prev, name }))}
        onPhoneChange={(phone) => setFormData(prev => ({ ...prev, phone }))}
      />

      {onboardingCode ? (
        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="onboarding-code">{t('members.onboardingCode', 'Onboarding Code')}</Label>
            <Input
              id="onboarding-code"
              value={formData.onboarding_code}
              onChange={handleOnboardingCodeChange}
              placeholder={t('members.enterOnboardingCode', 'Enter onboarding code')}
            />
          </div>
          <div className="space-y-2">
            <Label>{t('members.address', 'Address')}</Label>
            <div className="p-3 bg-muted rounded-md">
              <p>{formData.street_name} {formData.street_number}</p>
            </div>
          </div>
        </div>
      ) : (
        <AddressSection
          streetName={formData.street_name}
          streetNumber={formData.street_number}
          availableStreets={availableStreets}
          availableStreetNumbers={availableStreetNumbers}
          onStreetChange={handleStreetChange}
          onStreetNumberChange={(number) => setFormData(prev => ({ ...prev, street_number: number }))}
        />
      )}

      <Button 
        type="submit" 
        ref={submitButtonRef} 
        className="w-full"
        disabled={!isFormValid}
      >
        {member ? t("members.editMember") : t("members.addMember")}
      </Button>
    </form>
  );
};
