
import { useState, useEffect } from "react";
import { Bell, Users, FileText, Calendar, Home } from "lucide-react";
import { DashboardCard } from "./DashboardCard";
import { useDashboardStats } from "@/hooks/useDashboardStats";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import { useLocalStorage } from "@/hooks/useLocalStorage";

export type CardType = 'members' | 'documents' | 'events' | 'cadastres';

export const DashboardCards = () => {
  const { t } = useTranslation();
  const { stats, isLoading } = useDashboardStats();
  const [cardOrder, setCardOrder] = useState<CardType[]>(['members', 'documents', 'events', 'cadastres']);
  const [dragging, setDragging] = useState<CardType | null>(null);
  const [isDragOver, setIsDragOver] = useState<CardType | null>(null);
  const { value: storedOrder, setValue: setStoredOrder } = useLocalStorage<CardType[]>('dashboardCardOrder', ['members', 'documents', 'events', 'cadastres']);
  
  // Initialize card order from localStorage if available
  useEffect(() => {
    if (storedOrder) {
      setCardOrder(storedOrder);
    }
  }, [storedOrder]);

  const handleDragStart = (card: CardType) => {
    setDragging(card);
  };

  const handleDragOver = (e: React.DragEvent, card: CardType) => {
    e.preventDefault();
    if (dragging && dragging !== card) {
      setIsDragOver(card);
    }
  };

  const handleDrop = (card: CardType) => {
    if (dragging && dragging !== card) {
      const newOrder = [...cardOrder];
      const draggedIndex = newOrder.indexOf(dragging);
      const targetIndex = newOrder.indexOf(card);
      
      newOrder.splice(draggedIndex, 1);
      newOrder.splice(targetIndex, 0, dragging);
      
      setCardOrder(newOrder);
      setStoredOrder(newOrder);
    }
    setDragging(null);
    setIsDragOver(null);
  };

  const handleDragEnd = () => {
    setDragging(null);
    setIsDragOver(null);
  };

  const renderCard = (type: CardType) => {
    const isDraggingThis = dragging === type;
    const isOver = isDragOver === type;
    
    switch (type) {
      case 'members':
        return (
          <div
            key={type}
            draggable
            onDragStart={() => handleDragStart(type)}
            onDragOver={(e) => handleDragOver(e, type)}
            onDrop={() => handleDrop(type)}
            onDragEnd={handleDragEnd}
            className={`cursor-grab ${isDraggingThis ? 'opacity-50' : ''} ${isOver ? 'border-2 border-primary border-dashed' : ''}`}
          >
            <DashboardCard
              title={t('dashboard.totalMembers')}
              value={isLoading ? "..." : stats.totalMembers.toString()}
              icon={Users}
              change={isLoading ? "..." : `+${stats.newMembersSinceLastMonth} ${t('dashboard.sinceLastMonth')}`}
            />
          </div>
        );
      
      case 'documents':
        return (
          <div
            key={type}
            draggable
            onDragStart={() => handleDragStart(type)}
            onDragOver={(e) => handleDragOver(e, type)}
            onDrop={() => handleDrop(type)}
            onDragEnd={handleDragEnd}
            className={`cursor-grab ${isDraggingThis ? 'opacity-50' : ''} ${isOver ? 'border-2 border-primary border-dashed' : ''}`}
          >
            <DashboardCard
              title={t('dashboard.activeDocuments')}
              value={isLoading ? "..." : stats.totalDocuments.toString()}
              icon={FileText}
              change={isLoading ? "..." : `+${stats.newDocumentsThisMonth} ${t('dashboard.newThisMonth')}`}
            />
          </div>
        );
      
      case 'events':
        return (
          <div
            key={type}
            draggable
            onDragStart={() => handleDragStart(type)}
            onDragOver={(e) => handleDragOver(e, type)}
            onDrop={() => handleDrop(type)}
            onDragEnd={handleDragEnd}
            className={`cursor-grab ${isDraggingThis ? 'opacity-50' : ''} ${isOver ? 'border-2 border-primary border-dashed' : ''}`}
          >
            <DashboardCard
              title={t('dashboard.upcomingEvents')}
              value={isLoading ? "..." : stats.upcomingEvents.toString()}
              icon={Calendar}
              change={isLoading ? "..." : stats.nextEventDate 
                ? `${t('dashboard.nextEventIn')} ${stats.daysUntilNextEvent} ${t('dashboard.days')}`
                : t('dashboard.noUpcomingEvents')
              }
            />
          </div>
        );
      
      case 'cadastres':
        return (
          <div
            key={type}
            draggable
            onDragStart={() => handleDragStart(type)}
            onDragOver={(e) => handleDragOver(e, type)}
            onDrop={() => handleDrop(type)}
            onDragEnd={handleDragEnd}
            className={`cursor-grab ${isDraggingThis ? 'opacity-50' : ''} ${isOver ? 'border-2 border-primary border-dashed' : ''}`}
          >
            <DashboardCard
              title={stats.cadastresWithoutMembers > 0 ? t('dashboard.cadastresNeedingMembers') : t('dashboard.cadastres')}
              value={isLoading ? "..." : 
                stats.cadastresWithoutMembers > 0 
                  ? stats.cadastresWithoutMembers.toString() 
                  : stats.totalCadastres.toString()
              }
              icon={Home}
              change={isLoading ? "..." : 
                stats.cadastresWithoutMembers > 0 
                  ? `${t('dashboard.ofTotal')} ${stats.totalCadastres}`
                  : t('dashboard.allHaveMembers')
              }
            />
          </div>
        );
      
      default:
        return null;
    }
  };

  return (
    <div className="grid gap-6 md:grid-cols-3 lg:grid-cols-4">
      {cardOrder.map(cardType => renderCard(cardType))}
    </div>
  );
};
