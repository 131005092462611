import { useState } from "react";
import { format } from "date-fns";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Check, X } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

interface AssociationCreationRequest {
  id: string;
  name: string;
  cvr_number: string;
  street_address: string;
  street_number: string;
  post_number: string;
  city: string;
  contact_email: string;
  created_at: string;
  user_id: string;
}

interface AssociationCreationRequestCardProps {
  request: AssociationCreationRequest;
  onRequestProcessed: () => void;
}

// Define interface for the tenant returned by the RPC function
interface Tenant {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export const AssociationCreationRequestCard = ({ 
  request, 
  onRequestProcessed 
}: AssociationCreationRequestCardProps) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [reviewNotes, setReviewNotes] = useState("");
  const { t } = useTranslation();

  const handleApprove = async () => {
    setIsProcessing(true);
    try {
      // Get the current user to use for reviewed_by
      const { data: userData } = await supabase.auth.getUser();
      const currentUserId = userData.user?.id;
      
      if (!currentUserId) {
        throw new Error("User not authenticated");
      }
      
      console.log('Starting approval process for request:', request.id);
      console.log('Current user ID for reviewed_by:', currentUserId);
      
      // The RPC function will now set the status to 'approved' for us
      const { data: tenantData, error: tenantError } = await supabase
        .rpc('create_tenant_for_admin', {
          tenant_name: request.name,
          request_id: request.id,
          reviewer_id: currentUserId
        });

      if (tenantError) {
        console.error('Error creating tenant:', tenantError);
        throw tenantError;
      }

      if (!tenantData || tenantData.length === 0) {
        throw new Error("Failed to create tenant");
      }

      console.log('Created tenant:', tenantData);

      // The RPC function returns an array of tenants
      const tenant = tenantData[0] as Tenant;

      // 2. Create association settings for the new tenant
      const { error: settingsError } = await supabase
        .from('association_settings')
        .insert([{
          tenant_id: tenant.id,
          name: request.name,
          cvr_number: request.cvr_number,
          street_address: request.street_address,
          street_number: request.street_number,
          post_number: request.post_number,
          country: 'Denmark'
        }]);

      if (settingsError) {
        console.error('Error creating association settings:', settingsError);
        throw settingsError;
      }

      // 3. Create tenant membership for the requesting user (as admin)
      const { error: membershipError } = await supabase
        .from('tenant_memberships')
        .insert([{
          tenant_id: tenant.id,
          user_id: request.user_id,
          role: 'admin'
        }]);

      if (membershipError) {
        console.error('Error creating tenant membership:', membershipError);
        throw membershipError;
      }

      // 4. Update the review notes if provided (status update is already handled by the RPC function)
      if (reviewNotes) {
        const { error: notesUpdateError } = await supabase
          .from('association_creation_requests')
          .update({ review_notes: reviewNotes })
          .eq('id', request.id);

        if (notesUpdateError) {
          console.error('Error updating review notes:', notesUpdateError);
          // Don't throw here - we've already created the association
          toast.error(t("admin.creationRequests.notesUpdateError"));
        }
      }

      toast.success(t("admin.creationRequests.approvedSuccess"));
      onRequestProcessed();
    } catch (error) {
      console.error('Error approving association creation request:', error);
      
      // Try to revert the request status if possible
      try {
        const { error: revertError } = await supabase
          .from('association_creation_requests')
          .update({ 
            status: 'pending',
            reviewed_by: null
          })
          .eq('id', request.id);
          
        if (revertError) {
          console.error('Error reverting request status:', revertError);
        }
      } catch (revertError) {
        console.error('Error reverting request status:', revertError);
      }
      
      toast.error(t("admin.creationRequests.approvedError"));
    } finally {
      setIsProcessing(false);
    }
  };

  const handleReject = async () => {
    if (!reviewNotes) {
      toast.error(t("admin.creationRequests.notesRequired"));
      return;
    }

    setIsProcessing(true);
    try {
      // Get the current user for reviewed_by
      const { data: userData } = await supabase.auth.getUser();
      const currentUserId = userData.user?.id;
      
      if (!currentUserId) {
        throw new Error("User not authenticated");
      }

      console.log('Rejecting request:', request.id);
      console.log('Current user ID for reviewed_by:', currentUserId);
      
      const { error, data: updateData } = await supabase
        .from('association_creation_requests')
        .update({ 
          status: 'rejected',
          review_notes: reviewNotes,
          reviewed_by: currentUserId
        })
        .eq('id', request.id)
        .select();

      if (error) {
        console.error('Error rejecting association creation request:', error);
        throw error;
      }
      
      console.log('Request rejection update completed:', updateData);
      
      toast.success(t("admin.creationRequests.rejectedSuccess"));
      onRequestProcessed();
    } catch (error) {
      console.error('Error rejecting association creation request:', error);
      toast.error(t("admin.creationRequests.rejectedError"));
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Card className="mb-4">
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          <span>{request.name}</span>
          <span className="text-sm font-normal text-muted-foreground">
            {t("admin.creationRequests.requestedOn", { date: format(new Date(request.created_at), "PP") })}
          </span>
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm font-medium">{t("admin.creationRequests.cvr")}:</p>
            <p>{request.cvr_number}</p>
          </div>
          <div>
            <p className="text-sm font-medium">{t("admin.creationRequests.contactEmail")}:</p>
            <p>{request.contact_email}</p>
          </div>
          <div>
            <p className="text-sm font-medium">{t("admin.creationRequests.address")}:</p>
            <p>{`${request.street_address} ${request.street_number}, ${request.post_number} ${request.city}`}</p>
          </div>
        </div>
        
        <div className="space-y-2">
          <label htmlFor="reviewNotes" className="text-sm font-medium">
            {t("admin.creationRequests.reviewNotes")}:
          </label>
          <Textarea
            id="reviewNotes"
            value={reviewNotes}
            onChange={(e) => setReviewNotes(e.target.value)}
            placeholder={t("admin.creationRequests.reviewNotesPlaceholder")}
            className="resize-none"
            rows={3}
          />
          <p className="text-sm text-muted-foreground">
            {t("admin.creationRequests.reviewNotesHint")}
          </p>
        </div>
      </CardContent>
      <CardFooter className="flex justify-end gap-2">
        <Button 
          variant="outline" 
          onClick={handleReject}
          disabled={isProcessing}
          className="flex gap-2"
        >
          <X className="w-4 h-4" />
          {t("admin.creationRequests.reject")}
        </Button>
        <Button 
          onClick={handleApprove}
          disabled={isProcessing}
          className="flex gap-2"
        >
          <Check className="w-4 h-4" />
          {t("admin.creationRequests.approve")}
        </Button>
      </CardFooter>
    </Card>
  );
};
