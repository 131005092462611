
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/contexts/AuthContext";
import { toast } from "sonner";
import { useTenant } from "@/contexts/TenantContext";

export function useApprovedRequests() {
  const { user } = useAuth();
  const { tenants } = useTenant();
  const [hasApprovedRequests, setHasApprovedRequests] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      setIsLoading(false);
      return;
    }

    // Don't check for approved requests if user already has tenant memberships
    if (tenants.length > 0) {
      setIsLoading(false);
      return;
    }

    const checkApprovedRequests = async () => {
      try {
        const { data, error } = await supabase
          .from('association_join_requests')
          .select('tenant_id')
          .eq('user_id', user.id)
          .eq('status', 'approved')
          .limit(1);

        if (error) throw error;

        // If there are approved requests, check if the user has corresponding tenant memberships
        if (data && data.length > 0) {
          for (const request of data) {
            const { data: membership, error: membershipError } = await supabase
              .from('tenant_memberships')
              .select('*')
              .eq('tenant_id', request.tenant_id)
              .eq('user_id', user.id)
              .maybeSingle();

            if (membershipError) {
              console.error('Error checking tenant membership:', membershipError);
              continue;
            }

            // If no membership exists for an approved request, create one
            if (!membership) {
              setHasApprovedRequests(true);
              
              const { error: insertError } = await supabase
                .from('tenant_memberships')
                .insert([{
                  tenant_id: request.tenant_id,
                  user_id: user.id,
                  role: 'member'
                }]);

              if (insertError) {
                console.error('Error creating tenant membership:', insertError);
                toast.error("Failed to add you to an association. Please contact support.");
              } else {
                toast.success("You've been added to an association that approved your request");
                // Force reload after successful membership creation
                window.location.reload();
              }
            }
          }
        }
      } catch (error) {
        console.error('Error checking approved requests:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkApprovedRequests();
  }, [user, tenants]);

  return { hasApprovedRequests, isLoading };
}
