
import { Layout } from "@/components/Layout";
import { useTenant } from "@/contexts/TenantContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAdminStatus } from "@/hooks/useAdminStatus";
import { PendingCreationRequests } from "@/components/admin/PendingCreationRequests";
import { AssociationsList } from "@/components/admin/AssociationsList";
import { CreateAssociationDialog } from "@/components/admin/CreateAssociationDialog";
import { useTenantsQuery } from "@/hooks/useTenantsQuery";

const Admin = () => {
  const navigate = useNavigate();
  const { currentTenant } = useTenant();
  const { t } = useTranslation();
  const { data: isSuperAdmin, isLoading: isAdminCheckLoading } = useAdminStatus();

  const { data: tenants = [], isLoading, refetch } = useTenantsQuery(isSuperAdmin);

  // Refresh tenant list when currentTenant changes to reflect active status
  useEffect(() => {
    if (isSuperAdmin) {
      refetch();
    }
  }, [currentTenant, refetch, isSuperAdmin]);

  // Checking if user is authorized as a super admin
  if (isAdminCheckLoading || isLoading) {
    return (
      <Layout>
        <div className="flex items-center justify-center h-[50vh]">
          <p className="text-muted-foreground">{t("common.loading")}</p>
        </div>
      </Layout>
    );
  }

  // Redirect if not a super admin
  if (!isSuperAdmin) {
    navigate('/');
    return null;
  }

  return (
    <Layout>
      <div className="space-y-8">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-bold">{t("admin.title")}</h1>
            <p className="text-muted-foreground">{t("admin.subtitle")}</p>
          </div>
          
          <CreateAssociationDialog />
        </div>

        <div className="space-y-4">
          <h2 className="text-xl font-semibold">{t("admin.pendingRequests")}</h2>
          <PendingCreationRequests />
        </div>

        <div className="space-y-4">
          <h2 className="text-xl font-semibold">{t("admin.existingAssociations")}</h2>
          <AssociationsList 
            tenants={tenants} 
            onAssociationDeleted={() => refetch()} 
          />
        </div>
      </div>
    </Layout>
  );
};

export default Admin;
