
import { useState, useEffect } from "react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useTenant } from "@/contexts/TenantContext";
import { useTranslation } from "react-i18next";
import { StreetData, StoredStreetData } from "@/types/street";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";

export function useStreetManagement() {
  const { toast: uiToast } = useToast();
  const { currentTenant } = useTenant();
  const { t } = useTranslation();
  const [streets, setStreets] = useState<StoredStreetData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStreet, setSelectedStreet] = useState<StreetData | null>(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (currentTenant) {
      loadStreets();
    }
  }, [currentTenant]);

  const loadStreets = async () => {
    try {
      const { data, error } = await supabase
        .from('association_streets')
        .select('street_name, street_id, kommune_kode, kommune_navn, postnummer, postnavn, address_count')
        .eq('tenant_id', currentTenant?.id)
        .order('street_name');

      if (error) throw error;

      setStreets(data);
    } catch (error) {
      console.error('Error loading streets:', error);
      uiToast({
        title: "Error",
        description: "Failed to load streets",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAddressCount = async (streetName: string, kommuneKode: string | null): Promise<number> => {
    if (!kommuneKode) return 0;
    
    try {
      const response = await fetch(
        `https://api.dataforsyningen.dk/adresser?vejnavn=${encodeURIComponent(streetName)}&kommunekode=${kommuneKode}`
      );
      
      if (!response.ok) {
        console.error("Failed to fetch address count:", response.statusText);
        return 0;
      }
      
      const addresses = await response.json();
      return Array.isArray(addresses) ? addresses.length : 0;
    } catch (error) {
      console.error("Error fetching address count:", error);
      return 0;
    }
  };

  const handleAddStreet = async () => {
    if (!currentTenant || !selectedStreet) return;
    
    // Get the postal code information from the selected street
    const postnummer = selectedStreet.postnumre && selectedStreet.postnumre.length > 0 
      ? selectedStreet.postnumre[0].nr 
      : null;
    
    // Get the postal name information from the selected street
    const postnavn = selectedStreet.postnumre && selectedStreet.postnumre.length > 0 
      ? selectedStreet.postnumre[0].navn 
      : null;
    
    // Fetch the address count for this street
    const addressCount = await fetchAddressCount(
      selectedStreet.navn,
      selectedStreet.kommune?.kode || null
    );

    const streetData: StoredStreetData = {
      street_name: selectedStreet.navn,
      street_id: selectedStreet.id,
      kommune_kode: selectedStreet.kommune?.kode || null,
      kommune_navn: selectedStreet.kommune?.navn || null,
      postnummer: postnummer,
      postnavn: postnavn,
      address_count: addressCount
    };

    try {
      // Check if street already exists
      if (streets.some(s => s.street_name === streetData.street_name)) {
        uiToast({
          title: t("common.exists"),
          description: `${streetData.street_name} is already in the list.`,
          variant: "destructive",
        });
        return;
      }

      const { error } = await supabase
        .from('association_streets')
        .insert([{
          tenant_id: currentTenant.id,
          street_name: streetData.street_name,
          street_id: streetData.street_id,
          kommune_kode: streetData.kommune_kode,
          kommune_navn: streetData.kommune_navn,
          postnummer: streetData.postnummer,
          postnavn: streetData.postnavn,
          address_count: streetData.address_count
        }]);

      if (error) throw error;

      setStreets([...streets, streetData]);
      setSelectedStreet(null);
      
      uiToast({
        title: t("common.save"),
        description: `${streetData.street_name} has been added to the list.`,
      });
    } catch (error) {
      console.error('Error adding street:', error);
      uiToast({
        title: "Error",
        description: "Failed to add street",
        variant: "destructive",
      });
    }
  };

  const handleRemoveStreet = async (streetName: string, removeAssociatedCadastres: boolean) => {
    if (!currentTenant) return;

    try {
      // Start a transaction
      if (removeAssociatedCadastres) {
        // First get all cadastres with this street name
        const { data: cadastres, error: cadastreError } = await supabase
          .from('cadastres')
          .select('id')
          .eq('tenant_id', currentTenant.id)
          .eq('street_name', streetName);
          
        if (cadastreError) throw cadastreError;
        
        const cadastreIds = cadastres?.map(c => c.id) || [];
        
        // Update members that reference these cadastres to clear the cadastre_id
        if (cadastreIds.length > 0) {
          const { error: memberUpdateError } = await supabase
            .from('members')
            .update({ 
              cadastre_id: null,
              street_name: null,  // Clear street name
              street_number: null // Clear street number
            })
            .eq('tenant_id', currentTenant.id)
            .in('cadastre_id', cadastreIds);
            
          if (memberUpdateError) throw memberUpdateError;
        }
        
        // Delete the cadastres
        if (cadastreIds.length > 0) {
          const { error: cadastreDeleteError } = await supabase
            .from('cadastres')
            .delete()
            .eq('tenant_id', currentTenant.id)
            .in('id', cadastreIds);
            
          if (cadastreDeleteError) throw cadastreDeleteError;
        }
      }
      
      // Finally delete the street
      const { error } = await supabase
        .from('association_streets')
        .delete()
        .eq('tenant_id', currentTenant.id)
        .eq('street_name', streetName);

      if (error) throw error;

      setStreets(streets.filter(street => street.street_name !== streetName));
      
      // Invalidate relevant cache queries to ensure lists are updated
      queryClient.invalidateQueries({ queryKey: ['cadastres'] });
      queryClient.invalidateQueries({ queryKey: ['members'] });
      
      uiToast({
        title: t("common.delete"),
        description: `${streetName} has been removed from the list.`,
      });
    } catch (error) {
      console.error('Error removing street:', error);
      uiToast({
        title: "Error",
        description: "Failed to remove street",
        variant: "destructive",
      });
    }
  };

  return {
    isLoading,
    streets,
    selectedStreet,
    setSelectedStreet,
    handleAddStreet,
    handleRemoveStreet
  };
}
