
import { useTranslation } from "react-i18next";
import { Card } from "@/components/ui/card";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { AssociationCard } from "./AssociationCard";
import { useState } from "react";

interface Tenant {
  id: string;
  name: string;
  created_at: string;
  member_count: number;
  cadastre_count: number;
  association_name: string | null;
}

interface AssociationsListProps {
  tenants: Tenant[];
  onAssociationDeleted: () => void;
}

export const AssociationsList = ({ tenants, onAssociationDeleted }: AssociationsListProps) => {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async (tenantId: string) => {
    if (isDeleting) return;
    
    if (!confirm(t('admin.deleteConfirmation', 'Are you sure you want to delete this association? This action cannot be undone.'))) {
      return;
    }

    try {
      setIsDeleting(true);
      
      const { error } = await supabase
        .from('tenants')
        .delete()
        .eq('id', tenantId);

      if (error) {
        console.error('Error deleting tenant:', error);
        throw error;
      }

      toast.success(t('admin.deleteSuccess', 'Association deleted successfully'));
      onAssociationDeleted();
    } catch (error) {
      console.error('Error deleting tenant:', error);
      toast.error(t('admin.deleteFailed', 'Failed to delete association'));
    } finally {
      setIsDeleting(false);
    }
  };

  if (tenants.length === 0) {
    return (
      <Card className="p-8 text-center text-muted-foreground">
        {t("common.noData")}
      </Card>
    );
  }

  return (
    <div className="grid gap-4">
      {tenants.map((tenant) => (
        <AssociationCard 
          key={tenant.id} 
          tenant={tenant} 
          onDelete={handleDelete} 
        />
      ))}
    </div>
  );
};
