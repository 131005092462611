
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useTranslation } from "react-i18next";

interface AddressFieldsProps {
  streetAddress: string;
  streetNumber: string;
  postNumber: string;
  country: string;
  onChange: (field: string, value: string) => void;
}

export function AddressFields({ 
  streetAddress, 
  streetNumber, 
  postNumber, 
  country, 
  onChange 
}: AddressFieldsProps) {
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-left mt-6 mb-4">{t("settings.association.address")}</h3>
      <div className="grid gap-2">
        <div className="grid grid-cols-2 gap-2">
          <div className="space-y-2">
            <Label className="text-left block" htmlFor="streetAddress">{t("settings.association.streetAddress")}</Label>
            <Input
              id="streetAddress"
              value={streetAddress}
              onChange={(e) => onChange('streetAddress', e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label className="text-left block" htmlFor="streetNumber">{t("settings.association.streetNumber")}</Label>
            <Input
              id="streetNumber"
              value={streetNumber}
              onChange={(e) => onChange('streetNumber', e.target.value)}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="space-y-2">
            <Label className="text-left block" htmlFor="postNumber">{t("settings.association.postNumber")}</Label>
            <Input
              id="postNumber"
              value={postNumber}
              onChange={(e) => onChange('postNumber', e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label className="text-left block" htmlFor="country">{t("settings.association.country")}</Label>
            <Input
              id="country"
              value={country}
              onChange={(e) => onChange('country', e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
