
import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { StatutesVotingSection } from "./StatutesVotingSection";
import { StatutesFiscalYearSection } from "./StatutesFiscalYearSection";

interface StatutesFormCardProps {
  form: any;
  onSubmit: (values: any) => void;
  isAdmin: boolean;
  isSaving: boolean;
  isDirty: boolean;
}

export function StatutesFormCard({
  form,
  onSubmit,
  isAdmin,
  isSaving,
  isDirty
}: StatutesFormCardProps) {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("settings.statutes.settingsTitle")}</CardTitle>
        <CardDescription>{t("settings.statutes.settingsDescription")}</CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <StatutesFiscalYearSection form={form} isAdmin={isAdmin} />
            <StatutesVotingSection form={form} isAdmin={isAdmin} />
            
            {isAdmin && (
              <Button 
                type="submit" 
                disabled={isSaving || !isDirty}
              >
                {isSaving ? 
                  t("common.saving") : 
                  t("common.save")
                }
              </Button>
            )}
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
