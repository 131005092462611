import { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useTenant } from "./TenantContext";
import { toast } from "sonner";
import { useQueryClient, useQuery } from "@tanstack/react-query";

interface Cadastre {
  id: string;
  streetName: string;
  streetNumber: string;
  plotNumber: string;
  residentCount: number;
  onboarding_code?: string;
}

interface CadastresContextType {
  cadastres: Cadastre[];
  addCadastre: (cadastre: Omit<Cadastre, "id" | "residentCount" | "onboarding_code">) => Promise<void>;
  updateCadastre: (cadastre: Cadastre) => Promise<void>;
  deleteCadastre: (id: string) => Promise<void>;
  isLoading: boolean;
}

const CadastresContext = createContext<CadastresContextType | undefined>(undefined);

export function CadastresProvider({ children }: { children: ReactNode }) {
  const [cadastres, setCadastres] = useState<Cadastre[]>([]);
  const { currentTenant } = useTenant();
  const queryClient = useQueryClient();

  // Replace direct data loading with React Query for better cache management
  const { data, isLoading } = useQuery({
    queryKey: ['cadastres', currentTenant?.id],
    queryFn: async () => {
      if (!currentTenant?.id) return [];
      
      try {
        const { data, error } = await supabase
          .from('cadastres')
          .select(`
            *,
            residents:members(count)
          `)
          .eq('tenant_id', currentTenant.id);

        if (error) throw error;

        return data.map(item => ({
          id: item.id,
          streetName: item.street_name,
          streetNumber: item.street_number,
          plotNumber: item.plot_number,
          residentCount: item.residents[0].count,
          onboarding_code: item.onboarding_code,
        }));
      } catch (error) {
        console.error('Error loading cadastres:', error);
        toast.error('Failed to load cadastres');
        return [];
      }
    },
    enabled: !!currentTenant?.id,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  // Update local state when query data changes
  useEffect(() => {
    if (data) {
      setCadastres(data);
    } else if (!currentTenant?.id) {
      setCadastres([]);
    }
  }, [data, currentTenant?.id]);

  const addCadastre = async (newCadastre: Omit<Cadastre, "id" | "residentCount" | "onboarding_code">) => {
    try {
      if (!currentTenant?.id) throw new Error('No tenant selected');

      const { data, error } = await supabase
        .from('cadastres')
        .insert([{
          tenant_id: currentTenant.id,
          street_name: newCadastre.streetName,
          street_number: newCadastre.streetNumber,
          plot_number: newCadastre.plotNumber,
        }])
        .select(`
          *,
          residents:members(count)
        `)
        .single();

      if (error) throw error;

      const formattedCadastre: Cadastre = {
        id: data.id,
        streetName: data.street_name,
        streetNumber: data.street_number,
        plotNumber: data.plot_number,
        residentCount: data.residents[0].count,
        onboarding_code: data.onboarding_code,
      };

      setCadastres([...cadastres, formattedCadastre]);
      
      // Invalidate cache after adding a cadastre
      queryClient.invalidateQueries({ queryKey: ['cadastres'] });
    } catch (error) {
      console.error('Error adding cadastre:', error);
      toast.error('Failed to add cadastre');
      throw error;
    }
  };

  const updateCadastre = async (updatedCadastre: Cadastre) => {
    try {
      const updateData: any = {
        street_name: updatedCadastre.streetName,
        street_number: updatedCadastre.streetNumber,
        plot_number: updatedCadastre.plotNumber,
      };
      
      // Only include onboarding_code in the update if it's explicitly set to null
      // This ensures we can clear the code when needed
      if (updatedCadastre.onboarding_code === null) {
        updateData.onboarding_code = null;
      }

      const { error } = await supabase
        .from('cadastres')
        .update(updateData)
        .eq('id', updatedCadastre.id);

      if (error) throw error;

      setCadastres(cadastres.map(c => 
        c.id === updatedCadastre.id ? updatedCadastre : c
      ));
      
      // Invalidate cache after updating a cadastre
      queryClient.invalidateQueries({ queryKey: ['cadastres'] });
    } catch (error) {
      console.error('Error updating cadastre:', error);
      toast.error('Failed to update cadastre');
      throw error;
    }
  };

  const deleteCadastre = async (id: string) => {
    try {
      const { error } = await supabase
        .from('cadastres')
        .delete()
        .eq('id', id);

      if (error) throw error;

      setCadastres(cadastres.filter(c => c.id !== id));
      
      // Invalidate cache after deleting a cadastre
      queryClient.invalidateQueries({ queryKey: ['cadastres'] });
    } catch (error) {
      console.error('Error deleting cadastre:', error);
      toast.error('Failed to delete cadastre');
      throw error;
    }
  };

  return (
    <CadastresContext.Provider 
      value={{ 
        cadastres, 
        addCadastre, 
        updateCadastre, 
        deleteCadastre,
        isLoading
      }}
    >
      {children}
    </CadastresContext.Provider>
  );
}

export const useCadastres = () => {
  const context = useContext(CadastresContext);
  if (context === undefined) {
    throw new Error("useCadastres must be used within a CadastresProvider");
  }
  return context;
};
