
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Banknote, AlertTriangle, Loader2, Database, ExternalLink } from "lucide-react";
import { useBankSettings } from "@/hooks/useBankSettings";
import { BankConnectionStatus } from "./bank/BankConnectionStatus";

export function BankSettings() {
  const { t } = useTranslation();
  const {
    bankSettings,
    isConnected,
    bankAccess,
    isLoading,
    isConnecting,
    connectToBank,
    saveBankSettings
  } = useBankSettings();

  const [formData, setFormData] = useState({
    redirect_uri: bankSettings.redirect_uri,
    market: bankSettings.market
  });

  const handleInputChange = (field: string, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    saveBankSettings(formData);
  };

  const handleConnect = async () => {
    try {
      const authUrl = await connectToBank();
      window.location.href = authUrl;
    } catch (error) {
      console.error("Error initiating bank connection:", error);
    }
  };

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <div>
            <CardTitle>Bank Integration</CardTitle>
            <CardDescription>Connect to your bank account to import transactions</CardDescription>
          </div>
        </div>
      </CardHeader>
      <CardContent className="space-y-6">
        {/* Connection Status */}
        <BankConnectionStatus isConnected={isConnected} bankAccess={bankAccess} />

        {/* API Configuration Form */}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="redirect_uri">Redirect URI</Label>
            <Input
              id="redirect_uri"
              type="text"
              value={formData.redirect_uri}
              onChange={(e) => handleInputChange('redirect_uri', e.target.value)}
              placeholder="Your application callback URL"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="market">Market</Label>
            <Select
              value={formData.market}
              onValueChange={(value) => handleInputChange('market', value)}
            >
              <SelectTrigger id="market">
                <SelectValue placeholder="Select market" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="DK">Denmark</SelectItem>
                <SelectItem value="SE">Sweden</SelectItem>
                <SelectItem value="NO">Norway</SelectItem>
                <SelectItem value="FI">Finland</SelectItem>
                <SelectItem value="GB">United Kingdom</SelectItem>
                <SelectItem value="DE">Germany</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="flex gap-4">
            <Button type="submit" disabled={isLoading}>
              {isLoading ? (
                <>
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  Saving...
                </>
              ) : (
                <>
                  <Database className="h-4 w-4 mr-2" />
                  Save Settings
                </>
              )}
            </Button>

            <Button 
              type="button" 
              variant="default" 
              onClick={handleConnect} 
              disabled={isConnecting}
            >
              {isConnecting ? (
                <>
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  Connecting...
                </>
              ) : (
                <>
                  <ExternalLink className="h-4 w-4 mr-2" />
                  Connect to Bank
                </>
              )}
            </Button>
          </div>
        </form>

        <Alert className="bg-muted border-none">
          <Banknote className="h-4 w-4" />
          <AlertTitle>GoCardless Integration</AlertTitle>
          <AlertDescription>
            This integration uses GoCardless Bank Account Data API to securely connect 
            to your bank account and import transaction data. The API credentials are 
            configured on the server for enhanced security.
          </AlertDescription>
        </Alert>

        <Alert variant="destructive" className="bg-amber-50 border-amber-200">
          <AlertTriangle className="h-4 w-4 text-amber-600" />
          <AlertTitle className="text-amber-800">Important</AlertTitle>
          <AlertDescription className="text-amber-700">
            The connection uses OAuth 2.0 to securely authenticate with your bank without ever storing 
            your bank credentials. Contact your administrator if you need to update the GoCardless API credentials.
          </AlertDescription>
        </Alert>
      </CardContent>
    </Card>
  );
}
