
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useTranslation } from "react-i18next";
import { Card } from "@/components/ui/card";
import { AssociationCreationRequestCard } from "./AssociationCreationRequestCard";

interface CreationRequest {
  id: string;
  name: string;
  cvr_number: string;
  street_address: string;
  street_number: string;
  post_number: string;
  city: string;
  contact_email: string;
  created_at: string;
  user_id: string;
}

export const PendingCreationRequests = () => {
  const { t } = useTranslation();
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const { data: pendingRequests = [], isLoading } = useQuery({
    queryKey: ['pending-creation-requests', refreshTrigger],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('association_creation_requests')
        .select('*')
        .eq('status', 'pending')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching pending creation requests:', error);
        throw error;
      }

      return data as CreationRequest[];
    }
  });

  const handleRequestProcessed = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  if (isLoading) {
    return <div className="py-4 text-center text-muted-foreground">{t("common.loading")}</div>;
  }

  if (pendingRequests.length === 0) {
    return (
      <Card className="p-6 text-center text-muted-foreground">
        {t("admin.creationRequests.noPendingRequests")}
      </Card>
    );
  }

  return (
    <div className="space-y-4">
      {pendingRequests.map(request => (
        <AssociationCreationRequestCard
          key={request.id}
          request={request}
          onRequestProcessed={handleRequestProcessed}
        />
      ))}
    </div>
  );
};
