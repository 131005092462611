
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";

interface DeclineRequestDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onDecline: () => void;
  declineNotes: string;
  onDeclineNotesChange: (notes: string) => void;
}

export function DeclineRequestDialog({
  isOpen,
  onOpenChange,
  onDecline,
  declineNotes,
  onDeclineNotesChange
}: DeclineRequestDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("members.declineRequest")}</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <div className="space-y-2">
            <Label htmlFor="decline-notes">{t("members.provideDeclineReason")}</Label>
            <Textarea
              id="decline-notes"
              placeholder={t("members.enterDeclineReason")}
              value={declineNotes}
              onChange={(e) => onDeclineNotesChange(e.target.value)}
            />
          </div>
          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              {t("common.cancel")}
            </Button>
            <Button 
              variant="destructive" 
              onClick={onDecline}
              disabled={!declineNotes.trim()}
            >
              {t("common.confirmDecline")}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
