
import { Member } from "@/types/member";
import { toast } from "sonner";

/**
 * Exports members to either XLSX or CSV format
 */
export const exportMembers = async (members: Member[], format: 'xlsx' | 'csv', t: (key: string, options?: any) => string) => {
  try {
    // Convert members to the required format
    const dataToExport = members.map(member => ({
      name: member.name,
      email: member.email,
      phone: member.phone,
      street_name: member.street_name,
      street_number: member.street_number,
      join_date: member.join_date,
      start_date: member.start_date,
      end_date: member.end_date || '',
      is_permanent_resident: member.is_permanent_resident ? 'Yes' : 'No',
      status: member.status
    }));

    // Use format-specific libraries to convert data
    let blob;
    let fileName;
    
    if (format === 'xlsx') {
      // Import xlsx dynamically to avoid bundling it unnecessarily
      const XLSX = await import('xlsx');
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Members");
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fileName = `members_export_${new Date().toISOString().split('T')[0]}.xlsx`;
    } else {
      // Create CSV content
      const header = Object.keys(dataToExport[0]).join(',');
      const rows = dataToExport.map(item => 
        Object.values(item).map(val => 
          typeof val === 'string' && val.includes(',') ? `"${val}"` : val
        ).join(',')
      );
      const csvContent = [header, ...rows].join('\n');
      blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      fileName = `members_export_${new Date().toISOString().split('T')[0]}.csv`;
    }
    
    // Create download link and trigger download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    toast.success(t('members.exportSuccess'));
    return true;
  } catch (error) {
    console.error('Export error:', error);
    toast.error(t('members.exportError'));
    return false;
  }
};
