
import { Member } from "@/types/member";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Edit, Mail, Phone, MapPin, Trash2 } from "lucide-react";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { MemberForm } from "./MemberForm";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/contexts/AuthContext";
import { IfCanEdit, IfCanDelete } from "@/components/auth/PermissionGuard";
import { useRole } from "@/hooks/useRole";
import { useState } from "react";
import { toast } from "sonner";
import { DeleteConfirmDialog } from "@/components/documents/DeleteConfirmDialog";

interface MemberListItemProps {
  member: Member;
  onEdit: (member: Member) => void;
  onDelete: (id: string) => Promise<boolean>;
}

export function MemberListItem({ member, onEdit, onDelete }: MemberListItemProps) {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { isOwnMemberData } = useRole();
  const isCurrentUser = user?.email === member.email;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  
  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      console.log("Starting to delete member:", member.id);
      
      const success = await onDelete(member.id);
      
      if (success) {
        console.log("Delete success reported, member removed from database");
        toast.success(t("members.deleteSuccess", { name: member.name }));
      } else {
        console.log("Delete failure reported, database operation failed");
        toast.error(t("members.deleteError", { name: member.name }));
      }
    } catch (error) {
      console.error("Error in handleDelete function:", error);
      toast.error(t("members.deleteError", { name: member.name }));
    } finally {
      setIsDeleting(false);
      setIsDeleteDialogOpen(false);
    }
  };
  
  return (
    <div className="grid grid-cols-[2fr_2fr_1fr_1fr_auto] items-center gap-4 py-2 px-4 hover:bg-accent/5">
      <div className="font-medium text-left">
        {member.name}
        {isCurrentUser && (
          <Badge variant="secondary" className="text-xs ml-2">
            you
          </Badge>
        )}
      </div>
      
      <HoverCard>
        <HoverCardTrigger asChild>
          <div className="flex items-center gap-2 cursor-pointer">
            <MapPin className="w-4 h-4 text-muted-foreground" />
            <span className="text-sm">
              {member.street_name} {member.street_number}
            </span>
          </div>
        </HoverCardTrigger>
        <HoverCardContent className="w-80">
          <div className="w-full h-48 rounded-md bg-accent/5 flex items-center justify-center">
            Map preview placeholder
          </div>
        </HoverCardContent>
      </HoverCard>

      <div className="flex items-center gap-2">
        <Mail className="w-4 h-4 text-muted-foreground" />
        <span className="text-sm">{member.email}</span>
      </div>
      
      <div className="flex items-center gap-2">
        {member.phone &&
        <><Phone className="w-4 h-4 text-muted-foreground" /><span className="text-sm">{member.phone}</span></>
        }
      </div>

      <div className="flex gap-2 justify-end">
        <IfCanEdit 
          resource="member" 
          resourceOwnerId={member.id}
          fallback={<Button variant="ghost" size="icon" disabled><Edit className="h-4 w-4" /></Button>}
        >
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon">
                <Edit className="h-4 w-4" />
              </Button>
            </SheetTrigger>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>{t("members.editMember")}</SheetTitle>
              </SheetHeader>
              <MemberForm
                member={member}
                onSubmit={(updatedData) => onEdit({ ...updatedData, id: member.id })}
              />
            </SheetContent>
          </Sheet>
        </IfCanEdit>

        {isCurrentUser ? (
          <Button variant="ghost" size="icon" disabled>
            <Trash2 className="h-4 w-4 text-destructive" />
          </Button>
        ) : (
          <IfCanDelete 
            resource="member" 
            resourceOwnerId={member.id}
            fallback={<Button variant="ghost" size="icon" disabled><Trash2 className="h-4 w-4 text-destructive" /></Button>}
          >
            <Button 
              variant="ghost" 
              size="icon" 
              onClick={() => setIsDeleteDialogOpen(true)}
            >
              <Trash2 className="h-4 w-4 text-destructive" />
            </Button>
            
            <DeleteConfirmDialog
              isOpen={isDeleteDialogOpen}
              onClose={() => setIsDeleteDialogOpen(false)}
              onDelete={handleDelete}
              title={t("members.confirmDelete")}
              description={t("members.deleteDescription", { name: member.name })}
              isDeleting={isDeleting}
            />
          </IfCanDelete>
        )}
      </div>
    </div>
  );
}
