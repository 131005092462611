
import { 
  FileIcon, 
  FileSpreadsheet, 
  FileText, 
  Image, 
  FileX, 
  FileArchive,
  Video,
  Music,
  FileCode,
  File,
  MoreVertical,
  Download,
  Trash,
  Eye
} from "lucide-react";
import { DocumentFile } from "@/types/document";
import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuItem, 
  DropdownMenuTrigger 
} from "@/components/ui/dropdown-menu";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { useRole } from "@/hooks/useRole";

interface FileCardProps {
  file: DocumentFile;
  onDownload: (file: DocumentFile) => void;
  onDelete: (fileId: string, storagePath: string) => void;
  onView: (file: DocumentFile) => void;  // New prop for viewing file
}

export function FileCard({ file, onDownload, onDelete, onView }: FileCardProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { canDelete } = useRole();

  const formatFileSize = (bytes: number) => {
    if (bytes < 1024) return bytes + ' B';
    if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + ' KB';
    return (bytes / (1024 * 1024)).toFixed(1) + ' MB';
  };

  const getFileIcon = () => {
    const type = file.content_type.split('/')[0];
    const subtype = file.content_type.split('/')[1];
    
    switch (type) {
      case 'image':
        return <Image className="h-12 w-12 text-blue-500" />;
      case 'application':
        if (subtype.includes('pdf')) return <FileX className="h-12 w-12 text-red-500" />; // Changed from FilePdf to FileX
        if (subtype.includes('spreadsheet') || subtype.includes('excel')) return <FileSpreadsheet className="h-12 w-12 text-green-500" />;
        if (subtype.includes('document') || subtype.includes('word')) return <FileText className="h-12 w-12 text-blue-500" />;
        if (subtype.includes('zip') || subtype.includes('rar') || subtype.includes('compressed')) return <FileArchive className="h-12 w-12 text-orange-500" />;
        if (subtype.includes('json') || subtype.includes('xml') || subtype.includes('javascript') || subtype.includes('html')) return <FileCode className="h-12 w-12 text-purple-500" />;
        return <FileIcon className="h-12 w-12 text-gray-500" />;
      case 'text':
        return <FileText className="h-12 w-12 text-blue-500" />;
      case 'video':
        return <Video className="h-12 w-12 text-red-500" />;
      case 'audio':
        return <Music className="h-12 w-12 text-purple-500" />;
      default:
        return <File className="h-12 w-12 text-gray-500" />;
    }
  };

  const handleDownload = async () => {
    setIsLoading(true);
    try {
      await onDownload(file);
    } finally {
      setIsLoading(false);
    }
  };

  const handleView = () => {
    onView(file);
  };

  return (
    <div 
      className="group relative border rounded-md p-3 hover:border-primary transition-colors flex items-center cursor-pointer"
      onClick={handleView}
    >
      {getFileIcon()}
      <div className="flex-1 min-w-0 ml-3">
        <h3 className="font-medium truncate">{file.name}</h3>
        <p className="text-xs text-muted-foreground mt-1">{formatFileSize(file.size)}</p>
      </div>
      
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button 
            variant="ghost" 
            size="icon" 
            className="opacity-0 group-hover:opacity-100 absolute top-2 right-2"
            onClick={(e) => e.stopPropagation()} // Prevent triggering the parent onClick
          >
            <MoreVertical className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" onClick={(e) => e.stopPropagation()}>
          <DropdownMenuItem onClick={handleView}>
            <Eye className="h-4 w-4 mr-2" />
            {t("documents.view")}
          </DropdownMenuItem>
          
          <DropdownMenuItem disabled={isLoading} onClick={handleDownload}>
            <Download className="h-4 w-4 mr-2" />
            {t("documents.download")}
          </DropdownMenuItem>
          
          {canDelete('documents') && (
            <DropdownMenuItem 
              className="text-destructive"
              onClick={() => onDelete(file.id, file.storage_path)}
            >
              <Trash className="h-4 w-4 mr-2" />
              {t("common.delete")}
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
