
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useTranslation } from "react-i18next";
import { useToast } from "@/components/ui/use-toast";
import { getGravatarName } from "@/utils/gravatar";

interface ContactSectionProps {
  email: string;
  name: string;
  phone: string;
  onEmailChange: (email: string) => void;
  onNameChange: (name: string) => void;
  onPhoneChange: (phone: string) => void;
}

export function ContactSection({
  email,
  name,
  phone,
  onEmailChange,
  onNameChange,
  onPhoneChange,
}: ContactSectionProps) {
  const { t } = useTranslation();
  const { toast } = useToast();

  const handleEmailChange = async (email: string) => {
    onEmailChange(email);
    
    if (email && !name) {
      const gravatarName = await getGravatarName(email);
      if (gravatarName) {
        onNameChange(gravatarName);
        toast({
          title: "Name Found",
          description: `Name automatically filled from Gravatar: ${gravatarName}`,
        });
      }
    }
  };

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="email">{t("members.form.email")}</Label>
        <Input
          id="email"
          type="email"
          value={email}
          onChange={(e) => handleEmailChange(e.target.value)}
          required
          autoFocus={!email} // Only autofocus if email is empty
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="name">{t("members.form.name")}</Label>
        <Input
          id="name"
          value={name}
          onChange={(e) => onNameChange(e.target.value)}
          required
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="phone">{t("members.form.phone")}</Label>
        <Input
          id="phone"
          type="tel"
          value={phone}
          onChange={(e) => onPhoneChange(e.target.value)}
        />
      </div>
    </div>
  );
}
