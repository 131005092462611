
import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "@/components/ui/sonner";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./contexts/AuthContext";
import { TenantProvider } from "./contexts/TenantContext";
import { CadastresProvider } from "./contexts/CadastresContext";

import "./App.css";
import { Routes } from "./Routes";

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <NextThemesProvider attribute="class" defaultTheme="light" enableSystem>
        <Router>
          <AuthProvider>
            <TenantProvider>
              <CadastresProvider>
                <Routes />
                <Toaster />
              </CadastresProvider>
            </TenantProvider>
          </AuthProvider>
        </Router>
      </NextThemesProvider>
    </QueryClientProvider>
  );
}

export default App;
