
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Building2, Trash2, Users, CheckCircle } from "lucide-react";
import { useTenant } from "@/contexts/TenantContext";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface Tenant {
  id: string;
  name: string;
  created_at: string;
  member_count: number;
  cadastre_count: number;
  association_name: string | null;
}

interface AssociationCardProps {
  tenant: Tenant;
  onDelete: (tenantId: string) => Promise<void>;
}

export const AssociationCard = ({ tenant, onDelete }: AssociationCardProps) => {
  const { t } = useTranslation();
  const { currentTenant, setCurrentTenant } = useTenant();
  const navigate = useNavigate();
  const [isSwitching, setIsSwitching] = useState(false);

  const isActiveTenant = currentTenant?.id === tenant.id;

  const handleSelect = async () => {
    if (isActiveTenant) {
      toast.info(t('admin.associationCard.alreadySelected', 'Already using this association'));
      return;
    }

    try {
      setIsSwitching(true);
      
      // Store the selected tenant ID before setting it in context
      const selectedTenantId = tenant.id;
      
      // Set the current tenant in context
      setCurrentTenant({
        id: tenant.id,
        name: tenant.name
      });
      
      // Show success toast with the selected association name
      toast.success(`Switched to ${tenant.association_name || tenant.name}`);
      
      // Log the selection for debugging
      console.log(`Selected tenant: ${tenant.id} (${tenant.association_name || tenant.name})`);
      
      // Navigate after a small delay to ensure the context has updated
      setTimeout(() => {
        navigate('/');
      }, 300);
    } catch (error) {
      console.error("Error switching tenant:", error);
      toast.error("Failed to switch tenant");
    } finally {
      setIsSwitching(false);
    }
  };

  return (
    <Card className="p-6">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-xl font-semibold text-left flex items-center gap-2">
            {tenant.association_name || tenant.name}
            {isActiveTenant && (
              <Badge variant="secondary" className="flex items-center gap-1 ml-2">
                <CheckCircle className="w-3 h-3" />
                {t("admin.associationCard.current", "Active")}
              </Badge>
            )}
          </h3>
          <div className="flex items-center gap-4 mt-1">
            <div className="flex items-center gap-2">
              <Users className="w-4 h-4 text-muted-foreground" />
              <span className="text-sm text-muted-foreground">
                {tenant.member_count} {t("admin.associationCard.members")}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <Building2 className="w-4 h-4 text-muted-foreground" />
              <span className="text-sm text-muted-foreground">
                {tenant.cadastre_count} {t("admin.associationCard.cadastres")}
              </span>
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <Button 
            onClick={handleSelect}
            variant={isActiveTenant ? "secondary" : "default"}
            disabled={isSwitching}
          >
            {isActiveTenant 
              ? t("admin.associationCard.current", "Current") 
              : t("admin.associationCard.select", "Select")}
          </Button>
          <Button 
            variant="destructive"
            onClick={() => onDelete(tenant.id)}
            disabled={isSwitching}
          >
            <Trash2 className="w-4 h-4 mr-2" />
            {t("admin.associationCard.delete")}
          </Button>
        </div>
      </div>
    </Card>
  );
};
