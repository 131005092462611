
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

interface Tenant {
  id: string;
  name: string;
  created_at: string;
  member_count: number;
  cadastre_count: number;
  association_name: string | null;
}

export const useTenantsQuery = (isSuperAdmin: boolean | undefined) => {
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['tenants'],
    queryFn: async () => {
      // If not a super admin, redirect to home
      if (!isSuperAdmin) {
        navigate('/');
        throw new Error('Unauthorized');
      }

      try {
        // RLS will limit what tenants are returned based on user permissions
        const { data, error } = await supabase
          .from('tenants')
          .select(`
            *,
            members:members(count),
            cadastres:cadastres(count),
            association_settings(name)
          `);

        if (error) {
          console.error("Error fetching tenants:", error);
          toast.error("Failed to load tenants");
          throw error;
        }

        if (!data) {
          return [] as Tenant[];
        }

        return data.map(tenant => ({
          ...tenant,
          member_count: tenant.members?.[0]?.count || 0,
          cadastre_count: tenant.cadastres?.[0]?.count || 0,
          association_name: tenant.association_settings?.[0]?.name || tenant.name
        })) as Tenant[];
      } catch (error) {
        console.error("Error in tenant query:", error);
        toast.error("Failed to load tenants");
        throw error;
      }
    },
    enabled: isSuperAdmin === true
  });
};
