
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FileUploader } from "@/components/documents/FileUploader";
import { Member } from "@/types/member";
import { toast } from "sonner";
import { 
  parseImportFile, 
  validateMembers, 
  filterExistingMembers 
} from "@/utils/memberImport";
import {
  Alert,
  AlertTitle,
  AlertDescription
} from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";

interface MemberImportTabProps {
  members: Member[];
  addMember: (member: Omit<Member, "id">) => Promise<void>;
  onComplete: () => void;
}

export function MemberImportTab({ 
  members, 
  addMember, 
  onComplete 
}: MemberImportTabProps) {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [parseErrors, setParseErrors] = useState<Array<{ line: number; message: string }>>([]);
  const [validationErrors, setValidationErrors] = useState<Array<{ line: number; errors: string[] }>>([]);
  const queryClient = useQueryClient();

  const handleFileUpload = async (file: File) => {
    setIsUploading(true);
    setParseErrors([]);
    setValidationErrors([]);
    
    try {
      // Parse the file into member objects
      const { parsedMembers, errors } = await parseImportFile(file);
      
      if (errors.length > 0) {
        setParseErrors(errors);
        setIsUploading(false);
        return;
      }
      
      // Validate all members have required fields
      const { validMembers, invalidEntries } = validateMembers(parsedMembers);
      
      if (invalidEntries.length > 0) {
        setValidationErrors(invalidEntries);
        if (validMembers.length === 0) {
          setIsUploading(false);
          return;
        }
      }

      // Filter out members that already exist in the database
      const { newMembers, duplicateCount } = filterExistingMembers(validMembers, members);

      // Add each new member to the database
      let successCount = 0;
      let failedCount = 0;
      
      // Debug logs to track what's happening
      console.log(`Processing ${newMembers.length} new members to add to database`);
      
      if (newMembers.length === 0) {
        toast.info(t('members.noNewMembersToImport'));
        setIsUploading(false);
        if (duplicateCount > 0) {
          toast.info(t('members.importDuplicatesSkipped', { count: duplicateCount }));
        }
        return;
      }
      
      const addPromises = newMembers.map(async (member) => {
        try {
          // Make sure we're passing a proper member object as expected by addMember
          const memberToAdd: Omit<Member, "id"> = {
            name: member.name!,
            email: member.email!,
            phone: member.phone || "",
            street_name: member.street_name!,
            street_number: member.street_number!,
            join_date: member.join_date!,
            start_date: member.start_date!,
            end_date: member.end_date,
            is_permanent_resident: Boolean(member.is_permanent_resident),
            status: member.status === 'active' ? 'active' : 'inactive',
            tenant_id: "" // This will be set by the addMember function
          };
          
          await addMember(memberToAdd);
          successCount++;
          return true;
        } catch (error) {
          console.error('Error adding member:', error, member);
          failedCount++;
          return false;
        }
      });
      
      // Wait for all members to be processed
      await Promise.all(addPromises);

      // Invalidate the cadastres query cache to update resident counts
      queryClient.invalidateQueries({ queryKey: ['cadastres'] });

      // Show success message with counts
      if (successCount > 0) {
        toast.success(t('members.importSuccess', { count: successCount }));
      }
      
      if (failedCount > 0) {
        toast.error(t('members.importFailed', { count: failedCount }));
      }
      
      if (duplicateCount > 0) {
        toast.info(t('members.importDuplicatesSkipped', { count: duplicateCount }));
      }
      
      if (invalidEntries.length > 0) {
        toast.warning(t('members.importWithErrors', { count: invalidEntries.length }));
      } else if (successCount > 0 && failedCount === 0) {
        onComplete();
      }
    } catch (error) {
      console.error('Import error:', error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error(t('members.importError'));
      }
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div>
      <h3 className="text-lg font-medium mb-2">{t('members.importTitle')}</h3>
      <p className="text-muted-foreground mb-4">
        {t('members.importDescription')}
      </p>
      
      {(parseErrors.length > 0 || validationErrors.length > 0) && (
        <Alert variant="destructive" className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle className="ml-2">{t('members.importErrors')}</AlertTitle>
          <AlertDescription>
            <div className="mt-2 max-h-60 overflow-y-auto">
              {parseErrors.map((error, index) => (
                <div key={`parse-${index}`} className="text-sm mb-1">
                  {error.line > 0 
                    ? t('members.lineError', { line: error.line, message: error.message }) 
                    : error.message}
                </div>
              ))}
              
              {validationErrors.map((error, index) => (
                <div key={`validation-${index}`} className="text-sm mb-1">
                  {t('members.lineValidationError', { 
                    line: error.line, 
                    errors: error.errors.join(', ') 
                  })}
                </div>
              ))}
            </div>
          </AlertDescription>
        </Alert>
      )}
      
      <FileUploader 
        onUpload={handleFileUpload}
        isUploading={isUploading}
        accept=".csv, .xlsx"
      />
    </div>
  );
}
