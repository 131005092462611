import { useState, useEffect } from "react";
import { Search, Loader2 } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { useTranslation } from "react-i18next";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { StreetData, StreetSuggestion } from "@/types/street";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";

interface StreetSearchAutocompleteProps {
  selectedStreet: StreetData | null;
  setSelectedStreet: (street: StreetData | null) => void;
}

export const StreetSearchAutocomplete = ({ 
  selectedStreet, 
  setSelectedStreet 
}: StreetSearchAutocompleteProps) => {
  const { toast } = useToast();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [streetSuggestions, setStreetSuggestions] = useState<StreetSuggestion[]>([]);
  const [streetOptions, setStreetOptions] = useState<StreetData[]>([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState<StreetSuggestion | null>(null);
  const [optionsDialogOpen, setOptionsDialogOpen] = useState(false);

  // First step: Search for street suggestions (autocomplete)
  const searchStreetSuggestions = async (query: string) => {
    if (!query || query.length < 2) {
      setStreetSuggestions([]);
      return;
    }

    setIsSearching(true);
    try {
      const response = await fetch(`https://api.dataforsyningen.dk/vejnavne/autocomplete?q=${encodeURIComponent(query)}`);
      if (!response.ok) throw new Error('Network response was not ok');
      
      const data = await response.json();
      setStreetSuggestions(data);
      console.log("Fetched street suggestions:", data);
    } catch (error) {
      console.error('Error searching streets:', error);
      toast({
        title: "Error",
        description: "Failed to search for streets",
        variant: "destructive",
      });
      setStreetSuggestions([]);
    } finally {
      setIsSearching(false);
    }
  };

  // Second step: After selecting a suggestion, fetch detailed street data
  const fetchStreetDetails = async (streetName: string) => {
    setIsLoadingOptions(true);
    try {
      const response = await fetch(`https://api.dataforsyningen.dk/vejstykker?navn=${encodeURIComponent(streetName)}`);
      if (!response.ok) throw new Error('Network response was not ok');
      
      const data = await response.json();
      console.log("Fetched street details:", data);
      
      // If data is empty, handle the error case
      if (!data || data.length === 0) {
        toast({
          title: "Error",
          description: "No street details found",
          variant: "destructive",
        });
        return;
      }
      
      setStreetOptions(data);
      
      // If there's only one result, select it automatically
      if (data.length === 1) {
        handleSelectStreetOption(data[0]);
      } else {
        setOptionsDialogOpen(true);
      }
    } catch (error) {
      console.error('Error fetching street details:', error);
      toast({
        title: "Error",
        description: "Failed to fetch street details",
        variant: "destructive",
      });
    } finally {
      setIsLoadingOptions(false);
    }
  };

  // Handle the selection of a street option from the dialog
  const handleSelectStreetOption = (street: StreetData) => {
    console.log("Selected street option:", street);
    setSelectedStreet(street);
    setOptionsDialogOpen(false);
    setOpen(false);
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      searchStreetSuggestions(searchTerm);
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  const formatPostnumre = (postnumre: Array<{ nr: string; navn: string }>) => {
    if (!postnumre || postnumre.length === 0) return "";
    
    const uniquePostnumre = [...new Set(postnumre.map(p => `${p.nr} ${p.navn}`))];
    return uniquePostnumre.slice(0, 3).join(", ") + 
           (uniquePostnumre.length > 3 ? `, +${uniquePostnumre.length - 3} more` : "");
  };

  // Format the selected street display information
  const getFormattedStreetDisplay = () => {
    if (!selectedStreet) return t("settings.streets.selectStreet", "Select a street...");
    
    // Get the first post number if available
    const postNumre = selectedStreet.postnumre && selectedStreet.postnumre.length > 0 
      ? `${selectedStreet.postnumre[0].nr} ${selectedStreet.postnumre[0].navn}` 
      : "";
    
    // Format as "Street name, Post number" without duplicating kommune
    let displayText = selectedStreet.navn;
    
    if (postNumre) {
      displayText = `${displayText}, ${postNumre}`;
    }
    
    return displayText;
  };

  const handleSuggestionSelect = (value: string) => {
    console.log("Selected value:", value);
    
    // Find the suggestion that matches the selected value
    const suggestion = streetSuggestions.find(s => s.tekst === value);
    
    if (suggestion) {
      console.log("Found matching suggestion:", suggestion);
      setSelectedSuggestion(suggestion);
      fetchStreetDetails(suggestion.vejnavn.navn);
    }
    
    setSearchTerm("");
  };

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button 
            variant="outline" 
            role="combobox" 
            aria-expanded={open}
            className="justify-between w-full"
          >
            {getFormattedStreetDisplay()}
            <Search className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[400px] p-0" align="start">
          <Command>
            <CommandInput 
              placeholder={t("settings.streets.searchPlaceholder", "Search streets...")}
              value={searchTerm}
              onValueChange={setSearchTerm}
            />
            {isSearching && (
              <div className="flex items-center justify-center p-4">
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
                {t("common.searching", "Searching...")}
              </div>
            )}
            <CommandList>
              <CommandEmpty>{t("settings.streets.noResults", "No streets found")}</CommandEmpty>
              <CommandGroup>
                {streetSuggestions.map((suggestion) => (
                  <CommandItem
                    key={suggestion.vejnavn.href}
                    value={suggestion.tekst}
                    onSelect={handleSuggestionSelect}
                    className="cursor-pointer"
                  >
                    {suggestion.tekst}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>

      <Dialog open={optionsDialogOpen} onOpenChange={setOptionsDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{t("settings.streets.selectSpecificStreet", "Select Specific Street")}</DialogTitle>
            <DialogDescription>
              {t("settings.streets.multipleOptions", "Multiple options found. Please select the specific street.")}
            </DialogDescription>
          </DialogHeader>
          
          {isLoadingOptions ? (
            <div className="flex items-center justify-center p-6">
              <Loader2 className="h-5 w-5 animate-spin mr-2" />
              {t("common.loading", "Loading...")}
            </div>
          ) : (
            <div className="max-h-[300px] overflow-y-auto space-y-2 py-4">
              {streetOptions.map((street) => (
                <div 
                  key={street.id}
                  className="flex flex-col p-3 border rounded-md cursor-pointer hover:bg-muted transition-colors"
                  onClick={() => handleSelectStreetOption(street)}
                >
                  <div className="font-medium text-foreground">{street.navn}</div>
                  <div className="text-xs text-muted-foreground">
                    {street.kommune.navn} 
                    {street.postnumre.length > 0 ? ` - ${formatPostnumre(street.postnumre)}` : ""}
                  </div>
                </div>
              ))}
            </div>
          )}
          
          <DialogFooter>
            <Button variant="outline" onClick={() => setOptionsDialogOpen(false)}>
              {t("common.cancel", "Cancel")}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
