
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './locales/en.json';
import daTranslations from './locales/da.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslations
      },
      da: {
        translation: daTranslations
      }
    },
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development', // Enable debug in development mode
    interpolation: {
      escapeValue: false, // React already escapes values
      skipOnVariables: false // Don't skip interpolation when variables are missing
    }
  });

export default i18n;
