import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { DayMonthPicker } from "@/components/ui/day-month-picker";
import { DayMonthValue } from "@/types/settings";
interface StatutesFiscalYearSectionProps {
  form: any;
  isAdmin: boolean;
}
export function StatutesFiscalYearSection({
  form,
  isAdmin
}: StatutesFiscalYearSectionProps) {
  const {
    t
  } = useTranslation();
  return <div className="grid gap-6 sm:grid-cols-1">
      <FormField control={form.control} name="fiscalYearStart" render={({
      field
    }) => <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
            <div  className="text-left">
              <FormLabel>{t("settings.statutes.fiscalYearStart")}</FormLabel>
              <FormDescription>
                {t("settings.statutes.fiscalYearStartDescription")}
              </FormDescription>
            </div>
            <FormMessage />
            <FormControl>
              <DayMonthPicker value={field.value as DayMonthValue | undefined} onChange={field.onChange} disabled={!isAdmin} />
            </FormControl>
          </FormItem>} />

      <FormField control={form.control} name="annualReportDelivery" render={({
      field
    }) => <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
            <div  className="text-left">
              <FormLabel>{t("settings.statutes.annualReportDeliveryDay")}</FormLabel>
              <FormDescription>
                {t("settings.statutes.annualReportDeliveryDayDescription")}
              </FormDescription>
            </div>
            <FormMessage />
            <FormControl>
              <DayMonthPicker value={field.value as DayMonthValue | undefined} onChange={field.onChange} disabled={!isAdmin} />
            </FormControl>
          </FormItem>} />

      <FormField control={form.control} name="annualReportApproval" render={({
      field
    }) => <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
            <div  className="text-left">
              <FormLabel>{t("settings.statutes.annualReportApprovalDay")}</FormLabel>
              <FormDescription>
                {t("settings.statutes.annualReportApprovalDayDescription")}
              </FormDescription>
            </div>
            <FormMessage />
            <FormControl>
              <DayMonthPicker value={field.value as DayMonthValue | undefined} onChange={field.onChange} disabled={!isAdmin} />
            </FormControl>
          </FormItem>} />
    </div>;
}