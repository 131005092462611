
import { useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Member } from "@/types/member";
import { useTenant } from "@/contexts/TenantContext";
import { toast } from "sonner";
import { useMemberCadastre } from "./useMemberCadastre";

/**
 * Hook for member mutation operations (add, update, delete)
 */
export function useMemberMutations() {
  const { currentTenant } = useTenant();
  const queryClient = useQueryClient();
  const { findCadastreId } = useMemberCadastre();

  const addMember = async (newMember: Omit<Member, "id">) => {
    try {
      // If cadastre_id is not provided, try to find it
      let cadastreId = newMember.cadastre_id;
      if (!cadastreId) {
        cadastreId = findCadastreId(newMember.street_name, newMember.street_number);
      }
      
      if (!cadastreId) {
        toast.error("Could not find matching cadastre for the given address");
        return;
      }

      // Create a cleaned version of the member data to insert
      const memberToInsert = {
        name: newMember.name,
        email: newMember.email,
        phone: newMember.phone,
        street_name: newMember.street_name,
        street_number: newMember.street_number,
        join_date: newMember.join_date,
        start_date: newMember.start_date,
        end_date: newMember.end_date,
        is_permanent_resident: newMember.is_permanent_resident,
        status: newMember.status,
        tenant_id: currentTenant?.id,
        cadastre_id: cadastreId
      };

      const { data, error } = await supabase
        .from('members')
        .insert([memberToInsert])
        .select()
        .single();

      if (error) throw error;

      // Invalidate queries to refresh data from database
      queryClient.invalidateQueries({ queryKey: ['members'] });
      queryClient.invalidateQueries({ queryKey: ['cadastres'] });
      
      toast.success("Member added successfully");
    } catch (error) {
      console.error('Error adding member:', error);
      toast.error("Failed to add member");
    }
  };

  const updateMember = async (updatedMember: Member) => {
    try {
      // Always try to find the cadastre_id based on street name and number
      // This ensures the cadastre_id is updated when street details change
      const cadastreId = findCadastreId(updatedMember.street_name, updatedMember.street_number);
      
      if (!cadastreId) {
        toast.error("Could not find matching cadastre for the given address");
        return;
      }

      // Create a cleaned version of the member data to update
      const memberToUpdate = {
        id: updatedMember.id,
        name: updatedMember.name,
        email: updatedMember.email,
        phone: updatedMember.phone,
        street_name: updatedMember.street_name,
        street_number: updatedMember.street_number,
        join_date: updatedMember.join_date,
        start_date: updatedMember.start_date,
        end_date: updatedMember.end_date,
        is_permanent_resident: updatedMember.is_permanent_resident,
        status: updatedMember.status,
        tenant_id: updatedMember.tenant_id,
        cadastre_id: cadastreId // Always use the cadastre_id that matches the street details
      };

      const { error } = await supabase
        .from('members')
        .update(memberToUpdate)
        .eq('id', updatedMember.id)
        .eq('tenant_id', currentTenant?.id);

      if (error) throw error;

      // Invalidate queries to refresh data from database
      queryClient.invalidateQueries({ queryKey: ['members'] });
      queryClient.invalidateQueries({ queryKey: ['cadastres'] });
      
      toast.success("Member updated successfully");
    } catch (error) {
      console.error('Error updating member:', error);
      toast.error("Failed to update member");
    }
  };

  const deleteMember = async (id: string) => {
    try {
      console.log("Attempting to delete member with ID:", id);
      
      // Make sure the tenant context is available
      if (!currentTenant?.id) {
        console.error("Cannot delete member: No tenant context available");
        toast.error("Cannot delete member: No tenant context available");
        return false;
      }
      
      // Check if member exists and belongs to the current tenant
      const { data: memberCheck, error: checkError } = await supabase
        .from('members')
        .select('id, tenant_id')
        .eq('id', id)
        .eq('tenant_id', currentTenant.id)
        .single();
      
      if (checkError) {
        console.error("Error verifying member:", checkError);
        toast.error("Failed to verify member");
        return false;
      }
      
      if (!memberCheck) {
        console.error("Member not found or doesn't belong to current tenant");
        toast.error("Member not found");
        return false;
      }
      
      // Perform the actual delete operation with tenant_id constraint
      const { error } = await supabase
        .from('members')
        .delete()
        .eq('id', id)
        .eq('tenant_id', currentTenant.id);

      if (error) {
        console.error("Supabase deletion error:", error);
        toast.error("Failed to delete member");
        return false;
      }

      console.log("Member successfully deleted from database");
      
      // Invalidate queries to refresh data from database
      queryClient.invalidateQueries({ queryKey: ['members'] });
      queryClient.invalidateQueries({ queryKey: ['cadastres'] });
      
      return true;
    } catch (error) {
      console.error('Error deleting member:', error);
      toast.error("Failed to delete member");
      return false;
    }
  };

  return {
    addMember,
    updateMember,
    deleteMember
  };
}
