
import { useTranslation } from "react-i18next";
import { BoardMemberItem } from "@/components/board/BoardMemberItem";
import { Member } from "@/types/member";
import { Separator } from "@/components/ui/separator";

interface BoardMember {
  position: string;
  positionKey: string;
  memberId: string | null;
  type: 'board' | 'deputy' | 'accountant' | 'accountantDeputy';
}

interface BoardPositionsSectionProps {
  title: string;
  boardMembers: BoardMember[];
  type: 'board' | 'deputy' | 'accountant';
  members: Member[];
  isLoading: boolean;
  assignedMemberIds: string[];
  onMemberSelect: (positionKey: string, memberId: string) => void;
  readOnly?: boolean;
}

export const BoardPositionsSection = ({
  title,
  boardMembers,
  type,
  members,
  isLoading,
  assignedMemberIds,
  onMemberSelect,
  readOnly = false
}: BoardPositionsSectionProps) => {
  const { t } = useTranslation();
  
  // Filter positions based on type
  const positions = boardMembers.filter(bm => {
    if (type === 'board') return bm.type === 'board';
    if (type === 'deputy') return bm.type === 'deputy';
    if (type === 'accountant') return bm.type === 'accountant' || bm.type === 'accountantDeputy';
    return false;
  });
  
  if (positions.length === 0) return null;
  
  return (
    <div>
      {type !== 'board' && <Separator className="my-4" />}
      <h2 className="text-xl font-semibold mb-4 text-left">{title}</h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
        {positions.map((boardMember) => (
          <BoardMemberItem
            key={boardMember.positionKey}
            position={boardMember.position}
            members={members}
            selectedMemberId={boardMember.memberId}
            onMemberSelect={(memberId) => onMemberSelect(boardMember.positionKey, memberId)}
            assignedMemberIds={assignedMemberIds}
            isLoading={isLoading}
            readOnly={readOnly}
          />
        ))}
      </div>
    </div>
  );
};
