
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { UserPlus } from "lucide-react";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { MemberForm } from "./MemberForm";
import { Member } from "@/types/member";
import { StatisticsButton } from "@/components/common/StatisticsButton";
import { MemberImportExport } from "./MemberImportExport";
import { MemberStatistics } from "./MemberStatistics";
import { JoinRequestsDialog } from "./JoinRequestsDialog";
import { DeclineRequestDialog } from "./DeclineRequestDialog";

interface MemberActionButtonsProps {
  members: Member[];
  pendingRequests: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  joinRequests: any[];
  addMember: (member: Omit<Member, "id">) => Promise<void>;
  handleJoinRequest: (requestId: string, status: 'approved' | 'rejected', notes?: string) => Promise<void>;
  newMemberData: Partial<Member> | null;
  setNewMemberData: (data: Partial<Member> | null) => void;
}

export function MemberActionButtons({
  members,
  pendingRequests,
  joinRequests,
  addMember,
  handleJoinRequest,
  newMemberData,
  setNewMemberData
}: MemberActionButtonsProps) {
  const { t } = useTranslation();
  const [showJoinRequestsDialog, setShowJoinRequestsDialog] = useState(false);
  const [showDeclineDialog, setShowDeclineDialog] = useState(false);
  const [declineNotes, setDeclineNotes] = useState("");
  const [selectedRequestId, setSelectedRequestId] = useState<string | null>(null);
  const [showAddMemberSheet, setShowAddMemberSheet] = useState(false);

  const handleDeclineClick = (requestId: string) => {
    setSelectedRequestId(requestId);
    setShowDeclineDialog(true);
  };

  const handleDeclineSubmit = () => {
    if (!selectedRequestId) return;
    handleJoinRequest(selectedRequestId, 'rejected', declineNotes);
    setShowDeclineDialog(false);
    setDeclineNotes("");
    setSelectedRequestId(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleApproveClick = (request: any) => {
    setSelectedRequestId(request.id);
    setNewMemberData({
      name: request.full_name,
      email: request.email,
      phone: request.phone || "",
      street_name: request.street_name,
      street_number: request.street_number,
      join_date: new Date().toISOString().split('T')[0],
      start_date: new Date().toISOString().split('T')[0],
      status: "active",
      is_permanent_resident: true,
      tenant_id: request.tenant_id || "",
    });
    setShowAddMemberSheet(true);
  };

  const handleAddMember = async (memberData: Omit<Member, "id">) => {
    await addMember(memberData);
    setShowAddMemberSheet(false);
    
    // After adding member, update the join request status
    if (selectedRequestId) {
      await handleJoinRequest(selectedRequestId, 'approved');
      setSelectedRequestId(null);
    }
  };

  return (
    <div className="flex justify-center gap-2">
      <MemberImportExport />
      
      <StatisticsButton 
        dialogTitle={t('members.statistics')}
        tooltipText={t('members.statistics')}
        renderStatistics={() => <MemberStatistics members={members} />}
      />

      <JoinRequestsDialog
        pendingRequests={pendingRequests}
        joinRequests={joinRequests}
        isOpen={showJoinRequestsDialog}
        onOpenChange={setShowJoinRequestsDialog}
        onDecline={handleDeclineClick}
        onApprove={handleApproveClick}
      />

      <DeclineRequestDialog
        isOpen={showDeclineDialog}
        onOpenChange={(open) => {
          setShowDeclineDialog(open);
          if (!open) {
            setDeclineNotes("");
            setSelectedRequestId(null);
          }
        }}
        onDecline={handleDeclineSubmit}
        declineNotes={declineNotes}
        onDeclineNotesChange={setDeclineNotes}
      />

      <Sheet 
        open={showAddMemberSheet} 
        onOpenChange={(open) => {
          setShowAddMemberSheet(open);
          if (!open) {
            setNewMemberData(null);
            setSelectedRequestId(null);
          }
        }}
      >
        <SheetContent>
          <SheetHeader>
            <SheetTitle>{t("members.addNewMember")}</SheetTitle>
          </SheetHeader>
          <MemberForm 
            onSubmit={handleAddMember}
            initialData={newMemberData}
          />
        </SheetContent>
      </Sheet>

      <Sheet>
        <SheetTrigger asChild>
          <Button>
            <UserPlus className="mr-2 h-4 w-4" />
            {t("members.addMember")}
          </Button>
        </SheetTrigger>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>{t("members.addMember")}</SheetTitle>
          </SheetHeader>
          <MemberForm onSubmit={addMember} />
        </SheetContent>
      </Sheet>
    </div>
  );
}
