
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Member } from "@/types/member";
import { useTenant } from "@/contexts/TenantContext";
import { toast } from "sonner";

/**
 * Hook for fetching the members list
 */
export function useMembersList() {
  const { currentTenant } = useTenant();

  const { data: members = [], isLoading } = useQuery({
    queryKey: ['members', currentTenant?.id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('members')
        .select('*')
        .eq('tenant_id', currentTenant?.id)
        .order('name');
      
      if (error) {
        toast.error("Failed to load members");
        throw error;
      }
      
      return data as Member[];
    },
    enabled: !!currentTenant?.id,
  });

  return {
    members,
    isLoading
  };
}
